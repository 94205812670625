import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import HeaderActions from '@/Components/HeaderActions'

const HeaderContainer = styled.header`
  display: flex;
  height: var(--top-bar-height);
  justify-content: space-between;
  left: 0;
  padding: 0 24px;
  position: fixed;
  top: 0;
  user-select: none;
  width: 100%;
  z-index: 1060;
`

function Header () {
  return (
    <HeaderContainer className="container-fluid bg-layout-header">
      <NavLink to="/dashboard">
        <div className="flex h-full items-center">
          <img className="h-8 pr-2 md:hidden" src="/images/logos/futureops/logo.svg" alt="FutureOps" />
          <img className="hidden h-8 md:flex" src="/images/logos/futureops/logo.svg" alt="FutureOps" />
        </div>
      </NavLink>

      <div className="d-none d-lg-flex items-center justify-between">
        {/* Empty: Future Search */}
      </div>

      <HeaderActions />
    </HeaderContainer>
  )
}

export default Header
