import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function usePageGuard({
  auth,
  permission,
  redirectTo = '/401',
}) {
  const navigate = useNavigate()

  useEffect(() => {
    // Super administrators have permission to all functionality
    if (auth.isSuperAdmin) {
      return
    }

    // All other roles require explicit permission checks
    if (!auth.can(permission)) {
      navigate(redirectTo, { replace: true })
    }
  }, [])
}
