import { some, toString, trimStart } from 'lodash-es'
import * as yup from 'yup'

import { matchPattern } from '@/Utilities/String'
import { transformToNumber } from '@/Utilities/Validation/Transforms/ToNumber'

const conditionSchema = yup.object().shape({
  siteId: yup.mixed()
    .label('Site')
    .populatedObject()
    .meta({ tabKey: 'general' }),

  name: yup.string()
    .label('Name')
    .required()
    .min(3)
    .max(50)
    .meta({ tabKey: 'general' }),

  onStabilityTimeHours: yup.number()
    .label('On stability hours')
    .min(0)
    .max(23)
    .required()
    .meta({ tabKey: 'general' }),

  onStabilityTimeMinutes: yup.number()
    .label('On stability minutes')
    .min(0)
    .max(59)
    .required()
    .meta({ tabKey: 'general' }),

  onStabilityTimeSeconds: yup.number()
    .label('On stability seconds')
    .min(0)
    .max(59)
    .required()
    .meta({ tabKey: 'general' }),

  offStabilityTimeHours: yup.number()
    .label('Off stability hours')
    .min(0)
    .max(23)
    .required()
    .meta({ tabKey: 'general' }),

  offStabilityTimeMinutes: yup.number()
    .label('Off stability minutes')
    .min(0)
    .max(59)
    .required()
    .meta({ tabKey: 'general' }),

  offStabilityTimeSeconds: yup.number()
    .label('Off stability seconds')
    .min(0)
    .max(59)
    .required()
    .meta({ tabKey: 'general' }),

  conditionRules: yup.array().of(
    yup.object({
      elementType: yup.mixed()
        .label('Element type')
        .populatedObject()
        .meta({ tabKey: 'conditions' }),

      inputOutputElementId: yup.mixed()
        .label('Element')
        .populatedObject()
        .meta({ tabKey: 'conditions' }),

      operator: yup.mixed()
        .label('Operator')
        .populatedObject()
        .meta({ tabKey: 'conditions' }),

      value: yup.mixed()
        .test('validateValueByType', '${path} is not valid', function(value) {
          const { elementType } = this.parent
          const requiresObject = ['digitalOutput*', 'digitalInput']
          const elementWithoutPrefix = trimStart(elementType?.value, 'inputOutput.')

          const hasElementType = some(requiresObject, (pattern) => {
            return matchPattern(pattern, elementWithoutPrefix)
          })

          if (hasElementType) {
            const objectSchema = yup.mixed().label('Value').populatedObject()

            try {
              objectSchema.validateSync(value)

              return true
            } catch (err) {
              return this.createError({ message: err.message })
            }
          } else {
            const decimalRegex = new RegExp('^(?:\\d+|\\d*\\.\\d{1,3})$')
            let numberSchema = yup.number()
              .label('Value')
              .nullable()
              .required()
              .test(
                'is-decimal',
                'Maximum of 3 decimal places',
                (value) => {
                  return decimalRegex.test(toString(value))
                },
              )

            const validationRules = {
              analogInput: {
                min: -100000,
                max: 100000,
              },
            }

            if (validationRules[elementType?.value]) {
              let min = validationRules[elementType?.value]?.min
              let max = validationRules[elementType?.value]?.max

              numberSchema = numberSchema.min(min).max(max)
            } else {
              numberSchema = numberSchema.min(0).max(100000)
            }

            try {
              numberSchema.validateSync(transformToNumber(value))

              return true
            } catch (err) {
              return this.createError({ message: err.message })
            }
          }
        })
        .meta({ tabKey: 'conditions' }),

      propertyDepth: yup.mixed()
        .test('validateValueByType', '${path} is not valid', function(value) {
          const { elementType } = this.parent
          const requiresObject = ['sensorSoilProbe']
          const elementWithoutPrefix = trimStart(elementType?.value, 'inputOutput.')

          const hasElementType = some(requiresObject, (pattern) => {
            return matchPattern(pattern, elementWithoutPrefix)
          })

          if (hasElementType) {
            const objectSchema = yup.mixed().label('Depth').populatedObject()

            try {
              objectSchema.validateSync(value)

              return true
            } catch (err) {
              return this.createError({ message: err.message })
            }
          }

          return true
        })
        .meta({ tabKey: 'conditions' }),

      trailingLogicalOperator: yup.string()
        .label('Logical operator')
        .when('trailingLogicalOperatorExceptLast', (value, schema, context) => {
          if (context?.index == context?.context?.conditionCount - 1) {
            return schema.nullable()
          }

          return schema.nullable().required()
        })
        .meta({ tabKey: 'conditions' }),
    }),
  )
    .meta({ tabKey: 'conditions' }),
})

export default conditionSchema
