import { useRef } from 'react'
import { twMerge } from 'tailwind-merge'
import { useDebouncedCallback } from 'use-debounce'

export default function ({
  className,
  term,
  onTermUpdate,
}) {
  // Debounced callback handler
  const debouncedChangeHandler = useDebouncedCallback(
    (event) => {
      return onTermUpdate(event.target.value)
    },
    300,
  )

  // Tracked by useRef
  const inputRef = useRef(null)

  // Clear input event handler
  const clearInput = () => {
    onTermUpdate('')

    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  return (
    <div className="group relative flex items-center">
      <input
        ref={inputRef}
        className={
          twMerge(
            'peer',
            'block',
            'w-full',
            'rounded',
            'border-0',
            'bg-transparent',
            'py-2.5',
            'px-4',
            'pl-9',
            'text-gray-900',
            'ring-1',
            'ring-inset',
            'ring-gray-300',
            'placeholder:text-gray-400',
            'focus:ring-2',
            'focus:ring-inset',
            'focus:ring-primary',
            'sm:text-sm',
            'sm:leading-6',
            className,
          )
        }
        type="text"
        placeholder="Search"
        defaultValue={term.value || ''}
        onChange={debouncedChangeHandler}
      />
      <div className="absolute inset-y-0 left-3 flex py-1.5 pr-1.5 text-slate-400 peer-focus:text-primary">
        <i className="fa-regular fa-magnifying-glass inline-flex items-center"></i>
      </div>
      {inputRef.current?.value.length > 0 && (
        <div
          className="absolute inset-y-0 right-3 flex cursor-pointer py-1.5 text-slate-400 peer-focus:text-primary"
          onClick={clearInput}
        >
          <i className="fa-regular fa-times-circle inline-flex items-center"></i>
        </div>
      )}
    </div>
  )
}
