import { isBoolean, isNumber } from 'lodash-es'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import useLocalStorage from 'use-local-storage'
import { v4 } from 'uuid'

import LeafletMap from '@/Components/LeafletMap'
import { subscribe, unsubscribe } from '@/Utilities/Events'

const MapWrapper = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
`

const LoaderWrapper = styled.div`
  border-radius: ${(props) => {
    return props.square ? 0 : '5px'
  }};
  height: 100%;
  overflow: hidden;
  position: absolute;
  z-index: 9500;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e5e5e5;
`

function Map(props) {
  const id = useRef(v4())
  const {
    loading,
    loadingBlocksMap,
    dialog,
  } = props
  const [isLoading, setLoading] = useState(loading ? true : false)
  const [drawingDialog, setDrawingDialog] = useLocalStorage('map.drawingDialog', true)

  useEffect(() => {
    let timeoutId

    if (isNumber(loading)) {
      timeoutId = setTimeout(() => {
        setLoading(false)
      }, loading)
    } else if (isBoolean(loading)) {
      setLoading(loading)
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [loading])

  useEffect(() => {
    const resetMapDrawingDialog = () => {
      setDrawingDialog(true)
    }

    subscribe('map.drawingDialog.reset', resetMapDrawingDialog)

    return () => {
      unsubscribe('map.drawingDialog.reset', resetMapDrawingDialog)
    }
  }, [])

  const map = () => {
    return <LeafletMap id={id.current} {...props} />
  }

  return (
    <>
      <MapWrapper>
        {
          isLoading && (
            <LoaderWrapper>
              <div className="primary-loader"></div>
            </LoaderWrapper>
          )
        }

        {
          loadingBlocksMap ? (
            !isLoading && map()
          ) : (
            map()
          )
        }

        {dialog == 'drawing' && drawingDialog && (
          <div className="absolute left-14 top-map-help-dialog z-1000 max-h-map-height-minus-spacing w-3/4 overflow-auto rounded bg-white py-4 px-5">
            <div
              className="absolute top-2 right-0 mr-2 cursor-pointer"
              onClick={() => {
                return setDrawingDialog(false)
              }}
            >
              <i className="fa-regular fa-circle-xmark text-lg"></i>
            </div>

            <div className="mb-2 text-sm">
              <strong>Drawing Blocks:</strong>
              <p>
              To create a shape, click on the polygon icon, then click on the map to plot a point.
              Keep clicking to add more points, and finish by clicking on the first point again.
              </p>
            </div>

            <div className="mb-2 text-sm">
              <strong>Editing Blocks:</strong>
              <p>
              To edit a shape, click on the pencil icon. Points will appear on the edges of the shape.
              You can remove a point by clicking on a solid white block, add a point by clicking on a
              transparent white block, or move the entire shape by dragging it.
              </p>
            </div>

            <div className="mb-2 text-sm">
              <strong>Deleting Blocks:</strong>
              <p>
              To delete a shape, click on the trash can icon and then click on the shape to remove it.
              Save your changes by clicking "Save" next to the trash can, but be aware that this
              action cannot be undone.
              </p>
            </div>

            <div className="mb-2 text-sm">
              <strong>Confirm Changes:</strong>
              <p>
              After making all the desired changes, click the save button to permanently save the edits.
              </p>
            </div>

            <div className="text-sm">
              <strong>Closing this Help Dialog:</strong>
              <p>
              To close this dialog box, click the "X" in the top right corner. This dialog will not appear
              again on this device. If you would like to access this dialog again, you can do so under the actions dropdown.
              </p>
            </div>
          </div>
        )}
      </MapWrapper>
    </>
  )
}

export default Map
