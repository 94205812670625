import { isEmpty, map } from 'lodash-es'

import { formatKeys } from '@/Utilities/Form/Formatter'

export const getInputOutputsTypes = async (apiClient) => {
  let { data } = await apiClient.get('/input-output-types')

  const inputOutputTypes = formatKeys(data.inputOutputTypes, 'camel')

  const groupedInputOutputTypes = map(inputOutputTypes, (type) => {
    if (isEmpty(type.children)) {
      return {
        label: type.name,
        value: type.key,
      }
    }

    const children = map(type.children, (type) => {
      return {
        label: type.name,
        value: type.key,
      }
    })

    return {
      label: type.name,
      options: children,
    }
  })

  return groupedInputOutputTypes
}
