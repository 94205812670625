import { useSetRecoilState } from 'recoil'

import PermissionGuard from '@/Components/auth/PermissionGuard'
import { Anchor, RoundedIconButton } from '@/Components/form/Buttons'
import PageContainer from '@/Components/layout/helpers/PageContainer'
import SitesAll from '@/Components/tables/SitesAll'
import { modalState } from '@/Config/Atoms/General'
import useAuth from '@/Utilities/useAuth'
import usePageGuard from '@/Utilities/usePageGuard'
import useTitle from '@/Utilities/useTitle'

function SiteIndex() {
  const setModal = useSetRecoilState(modalState)
  const auth = useAuth()

  usePageGuard({
    auth,
    permission: 'view-site',
  })

  useTitle('All sites')

  const showSiteModal = () => {
    setModal({ name: 'site' })
  }

  return (
    <>
      <PageContainer contained>
        <div className="mb-5 grid grid-cols-2 text-3xl font-light text-slate-900">
          <div>
            All <span className="font-medium">sites</span>
          </div>

          <PermissionGuard permission="create-site" auth={auth}>
            <div className="text-right @md:mt-0">
              <div className="hidden justify-items-end md:block">
                <Anchor
                  style={{ minWidth: '200px' }}
                  onClick={showSiteModal}
                >
                  Add site
                </Anchor>
              </div>
              <div className="md:hidden">
                <RoundedIconButton
                  icon="plus"
                  onClick={showSiteModal}
                />
              </div>
            </div>
          </PermissionGuard>

        </div>

        <PermissionGuard permission="view-site" auth={auth}>
          <SitesAll/>
        </PermissionGuard>
      </PageContainer>
    </>
  )
}

export default SiteIndex
