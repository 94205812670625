import * as Sentry from '@sentry/react'
import { isEmpty } from 'lodash-es'
import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'

import useApiClient from '@/Utilities/useApiClient'
import useAuth from '@/Utilities/useAuth'

export default function AuthProvider() {
  const [setup, setSetup] = useState(false)
  const apiClient = useApiClient()
  const {
    fetchUser,
    user,
  } = useAuth()

  useEffect(() => {
    let isMounted = true

    const userSetup = async () => {
      await apiClient.get('/sanctum/csrf-cookie')

      try {
        await fetchUser()
      } catch (error) {
        Sentry.captureException(error)
      }

      if (isMounted) {
        setSetup(true)
      }
    }

    userSetup()

    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(user)) {
      Sentry.setUser({
        id: user.id,
        email: user.email,
        name: user.name,
      })
    } else {
      Sentry.setUser(null)
    }
  }, [user])

  return setup ? <Outlet /> : null
}
