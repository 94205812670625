import '@/Utilities/Form/Yup'

import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/react'
import { filter, find, get, includes, isEmpty, isFunction, some } from 'lodash-es'
import { useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useRecoilState, useSetRecoilState } from 'recoil'
import * as yup from 'yup'

import { Anchor, Button } from '@/Components/form/Buttons'
import Input from '@/Components/form/Input'
import InputError from '@/Components/form/InputError'
import Select from '@/Components/form/Select'
import Modal from '@/Components/Modal'
import { isLoadingState, pageAlertState } from '@/Config/Atoms/General'
import { toOptions } from '@/Utilities/Form/Formatter'
import useApiClient from '@/Utilities/useApiClient'

const requireGaugeType = (inputOutputType) => {
  if (!inputOutputType) {
    return false
  }

  return !some([
    'digitalOutput',
    'virtualDigitalOutput',
    'digitalInput',
  ], (type) => {
    return includes(inputOutputType, type)
  })
}

const schema = yup.object({
  name: yup.string().label('Name').required().min(3).max(50),
  inputOutputId: yup.mixed().label('Element').populatedObject(),
  gaugeType: yup.mixed().label('Gauge type').when('inputOutputId', ([inputOutputId], schema) => {
    if (requireGaugeType(inputOutputId.detailsType)) {
      return schema.populatedObject()
    }

    return schema
  }),
})

export default function CardModal(props) {
  const apiClient = useApiClient()
  const [isLoading, setIsLoading] = useRecoilState(isLoadingState)
  const setAlert = useSetRecoilState(pageAlertState)
  const {
    isEditing,
    site,
    siteCard,
  } = props.data

  const inputOutputOptions = useMemo(() => {
    if (isEmpty(site.inputOutputs)) {
      return []
    }

    const changeOfStateEnabled = filter(site.inputOutputs, ['changeOfState', true])

    return toOptions(changeOfStateEnabled, ['name', 'label'], ['id', 'value'], ['detailsType', 'detailsType'])
  }, [site])

  const indicatorTypes = [
    {
      label: 'Full circle',
      value: 'fullCircle',
    },
    {
      label: 'Half circle',
      value: 'halfCircle',
    },
    {
      label: 'Horizontal line',
      value: 'horizontalLine',
    },
    {
      label: 'Vertical line',
      value: 'verticalLine',
    },
  ]

  const {
    reset,
    register,
    handleSubmit,
    watch,
    setValue,
    formState,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      inputOutputId: find(inputOutputOptions, ['value', get(siteCard, 'inputOutputId', '')]) || '',
      name: get(siteCard, 'name', ''),
      gaugeType: find(indicatorTypes, ['value', get(siteCard, 'gaugeType', '')]) || '',
    },
  })
  const { errors } = formState
  const selectedInputOutput = watch('inputOutputId')

  useEffect(() => {
    if (!requireGaugeType(get(selectedInputOutput, 'detailsType', false))) {
      setValue('gaugeType', '')
    }
  }, [selectedInputOutput])

  const indicatorTypeOptions = useMemo(() => {
    if (get(selectedInputOutput, 'detailsType', false) === 'inputOutput.sensorSoilProbe') {
      return filter(indicatorTypes, (type) => {
        return type.value !== 'fullCircle' && type.value !== 'halfCircle'
      })
    }

    return indicatorTypes
  }, [indicatorTypes, selectedInputOutput])

  const onSubmit = async (data) => {
    try {
      setAlert(null)
      setIsLoading(true)

      let payload = {
        site_id: site.id,
        input_output_id: data.inputOutputId.value,
        name: data.name,
      }

      if (requireGaugeType(get(selectedInputOutput, 'detailsType', false))) {
        payload.gauge_type = data.gaugeType.value
      }

      let { data: responseData } = await apiClient.post(`${isEditing ? `/site-card/update/${get(props, 'data.siteCard.id', null)}` : '/site-card/create'}`, payload)

      if (responseData.success) {
        if (isFunction(props.data?.onSave)) {
          props.data.onSave()
        }

        setAlert({
          type: 'success',
          content: `${responseData.siteCard.name} has been successfully ${isEditing ? 'updated' : 'created'}.`,
        })

        if (isFunction(props.close)) {
          props.close()
        }
      }
    } catch (error) {
      Sentry.captureException(error)
      setAlert({
        type: 'error',
        content: 'An error has occured and we were not able to save this card. Please try again.',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      title={`${isEditing ? 'Edit' : 'Add' } card`}
      close={props.close}
      closeOnOutsideClick={props.closeOnOutsideClick}
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Input
          name="name"
          label="Name"
          id="name"
          isRequired={true}
          className={errors.name && 'border-red-400 focus:outline-1 focus:outline-red-400'}
          {...register('name', { value: get(props, 'data.siteCard.name', '') })}
        />
        {errors.name && <InputError message={errors.name.message} />}

        {
          inputOutputOptions &&
          <div className="mt-5">
            <Controller
              control={control}
              name="inputOutputId"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    helpTooltip="Please note: Selection is restricted to I/Os with reporting enabled."
                    isMulti={false}
                    isSearchable={true}
                    isRequired={true}
                    label="Element"
                    id="inputOutputId"
                    options={inputOutputOptions}
                    placeholder="Search"
                    hasError={!!errors.inputOutputId}
                  />
                )
              }}
            />
            {errors.inputOutputId && <InputError message={errors.inputOutputId.message} />}
          </div>
        }

        {requireGaugeType(get(selectedInputOutput, 'detailsType', false)) && (
          <div className="mt-5">
            <Controller
              control={control}
              name="gaugeType"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    isMulti={false}
                    isSearchable={true}
                    isRequired={true}
                    label="Type"
                    options={indicatorTypeOptions}
                    hasError={!!errors.gaugeType}
                  />
                )
              }}
            />
            {errors.gaugeType && <InputError message={errors.gaugeType.message} />}
          </div>
        )}

        <div className="mt-10 flex justify-between">
          <Anchor
            style={{ width: 'calc(50% - 5px)' }}
            className="transparent"
            onClick={() => {
              setAlert(null)
              reset()

              props.close()
            }}
          >
            Close
          </Anchor>

          <Button
            style={{ width: 'calc(50% - 5px)' }}
            disabled={isLoading ? true : false}
          >
            {isLoading ? <div className="primary-loader light"></div> : 'Save'}
          </Button>
        </div>
      </form>
    </Modal>
  )
}
