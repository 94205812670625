import classNames from 'classnames'
import { isEmpty } from 'lodash-es'
import { twMerge } from 'tailwind-merge'

export const faIcon = (options = {}) => {
  let {
    title = '',
    iconClass = '',
    faClass,
  } = options

  if (isEmpty(faClass)) {
    throw 'faClass missing from faIcon'
  }

  iconClass = twMerge(classNames(faClass, iconClass))

  return `<i class="${iconClass}" title="${title}"></i>`
}
