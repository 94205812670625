import { head } from 'lodash-es'

import SearchInput from '@/Components/common/input/SearchInput'
import Select from '@/Components/headless/form/Select'

export default function TypeSearch({
  types,
  search,
  onChange,
}) {
  const onSearchTypeChange = ({ value }) => {
    // Pass changed event up to parent
    onChange({
      ...search,
      type: value,
    })
  }

  return (
    <div className="grid grid-flow-row gap-0 -space-y-px rounded-md shadow-sm md:grid-flow-col md:grid-cols-2-150-auto md:space-y-0 md:-space-x-px">
      <Select
        className="w-full rounded-t border-b-0 shadow-none md:rounded-none md:rounded-l"
        defaultValue={head(types)}
        options={types}
        onChange={onSearchTypeChange}
      />

      <SearchInput
        className="rounded-none rounded-b-md md:rounded-none md:rounded-r-md"
        term={search}
        onTermUpdate={(value) => {
          return onChange({
            ...search,
            value,
          })
        }}
      />
    </div>
  )
}
