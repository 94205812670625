import Tippy from '@tippyjs/react'
import { map } from 'lodash-es'
import styled from 'styled-components'

import { hexFromText, normaliseContrast } from '@/Utilities/Colors'
import { initials } from '@/Utilities/String'
import { asset } from '@/Utilities/Url'

const CircleWrapper = styled.div`
  display: flex;
  margin: ${(props) => {
    return props.compact ? '-6px 0' : '0'
  }};
`

const Circle = styled.div`
  align-items: center;
  background-color: ${(props) => {
    return props.backgroundColor
  }};
  border-radius: 100%;
  border: 2px solid #ffffff;
  color: #ffffff;
  cursor: ${(props) => {
    return props.clickable ? 'pointer' : 'default'
  }};
  display: flex;
  font-weight: bold;
  height: 36px;
  justify-content: center;
  margin-left: -12px;
  overflow: hidden;
  user-select: none;
  width: 36px;

  &:first-of-type {
    margin-left: 0;
  }
`

const Avatar = styled.img`
  border-radius: 100%;
  height: 36px;
  margin-left: -12px;
  object-fit: cover;
  width: 36px;

  &:first-of-type {
    margin-left: 0;
  }
`

const Item = (props, circle, index) => {
  return <Circle
    backgroundColor={props.bland ? 'var(--text-light)' : normaliseContrast(hexFromText(circle.text), '#fff')}
    key={index}
    style={{ ...props.style }}
  >
    {circle.avatar && (
      <Avatar src={`${asset(circle.avatar)}?t=${new Date().getTime()}`} alt="avatar"/>
    )}

    {!circle.avatar && (
      circle.noInitialsText ? circle.noInitialsText : initials(circle.text)
    )}
  </Circle>
}

function CircleList (props) {
  return (
    <CircleWrapper compact={props.compact}>
      {map(props.circles, (circle, index) => {
        if (circle) {
          return (
            props.tooltips ? (
              <Tippy content={circle.text} key={index}>
                {Item(props, circle, index)}
              </Tippy>
            ) : (
              Item(props, circle, index)
            )
          )
        }
      })}
    </CircleWrapper>
  )
}

export default CircleList
