import { filter, isEmpty, startsWith } from 'lodash-es'
import { useEffect, useMemo } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'

import { pageAlertState } from '@/Config/Atoms/General'
import useAuth from '@/Utilities/useAuth'

export default function UserLayout() {
  const { loggedIn } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const setAlert = useSetRecoilState(pageAlertState)

  const excemptFromRedirect = useMemo(() => {
    const urls = ['/user/logout']

    const result = filter(urls, (url) => {
      return startsWith(url, location.pathname)
    })

    return !isEmpty(result)
  }, [location])

  // initialLoggedInState is set on first render to capture user's initial login status.
  // The useEffect below triggers a redirect to '/user/login' only if the user was
  // initially logged in and remains logged in, preventing unnecessary redirects
  // during component setup.
  const initialLoggedInState = useMemo(() => {
    return loggedIn
  }, [])

  useEffect(() => {
    // Reset page alerts when the login state changes
    setAlert(null)

    // Ensure the user was and still is logged in
    // and that the url is not except from redirecting.
    if (initialLoggedInState && loggedIn && !excemptFromRedirect) {
      navigate('/dashboard', { replace: true })
    }
  }, [loggedIn])

  if (initialLoggedInState && loggedIn && !excemptFromRedirect) {
    return null
  }

  return (
    <div className="h-screen w-screen bg-white md:bg-layout-bg-image md:bg-cover md:bg-center">
      <div className="grid h-full grid-cols-12 items-center justify-center overflow-y-auto md:backdrop-brightness-75">
        <div className="col-span-12 md:col-span-8 md:col-start-3 lg:col-span-4 lg:col-start-2 xl:col-span-4 xl:col-start-2 xl:max-w-lg">
          <div className="mx-auto mt-6 mb-10 lg:mx-0">
            <img src="/images/logos/branif/logo.svg" alt="Branif Logo" className="mx-auto hidden w-6/12 md:mx-0 md:flex"/>
            <img src="/images/logos/branif/logo-invert.svg" alt="Branif Logo" className="mx-auto w-6/12 md:mx-0 md:hidden"/>
          </div>

          <div className="bg-white p-6 md:p-10 lg:rounded">
            <Outlet className="main-container"/>
          </div>

          <div className="flex pt-6">
            <img src="/images/logos/motorola/logo.svg" alt="Motorola Solutions" className="mx-auto hidden w-6/12 md:flex" />
            <img src="/images/logos/motorola/logo-invert.svg" alt="Motorola Solutions" className="mx-auto flex w-6/12 md:hidden" />
          </div>
        </div>
        <div className="col-span-12 my-6 items-center text-center lg:col-span-2 lg:col-start-10 lg:row-start-auto">
          <span className="pt-12 text-5xl font-bold text-black md:text-white">FutureOps</span>
        </div>
      </div>
    </div>
  )
}
