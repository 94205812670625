import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/react'
import { default as debouncePromise } from 'awesome-debounce-promise'
import { isFunction, map } from 'lodash-es'
import { useCallback, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { atom, useRecoilState, useSetRecoilState } from 'recoil'
import styled from 'styled-components'
import * as yup from 'yup'

import AlertContent from '@/Components/alerts/AlertContent'
import { Anchor, Button } from '@/Components/form/Buttons'
import GooglePlacesAutocomplete from '@/Components/form/GooglePlacesAutocomplete'
import Input from '@/Components/form/Input'
import InputError from '@/Components/form/InputError'
import LightSwitch from '@/Components/form/LightSwitch'
import Select from '@/Components/form/Select'
import Modal from '@/Components/Modal'
import { isLoadingState, pageAlertState } from '@/Config/Atoms/General'
import { publish } from '@/Utilities/Events'
import { flattenSelectValues, formatKeys } from '@/Utilities/Form/Formatter'
import useApiClient from '@/Utilities/useApiClient'
import useAuth from '@/Utilities/useAuth'
import useEntityMonitor from '@/Utilities/useEntityMonitor'
import useTitle from '@/Utilities/useTitle'

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
`

const AreaForm = styled.form`
  label {
    margin-top: 20px;
  }

  .buttons {
    margin-top: 40px;
  }
`

const allUsersState = atom({
  key: 'allUsers',
  default: [],
})

const usersLoadingState = atom({
  key: 'usersLoading',
  default: true,
})

const generateSchema = (apiClient, areaId = null) => {
  const schema = yup.object({
    name: yup.string().label('Name').required().min(3).max(50),
    description: yup.string().label('Description').max(250).nullable(),
    address: yup.string().label('Address').required().min(3).max(250),
    serialNumber: yup.string().label('Serial Number').nullable().required().min(10).max(250).test('validator', 'This serial number is already in use', debouncePromise(async (value) => {
      if (value) {
        const serialExists = await validateSerial(value, areaId, apiClient)

        return !serialExists
      }

      return true
    }, 400)),
  })

  return schema
}

const validateSerial = (serialNumber, areaId, apiClient) => {
  return new Promise((resolve) => {
    let userExists

    apiClient.post('/validation/area/serial-number', {
      serialNumber,
      areaId,
    })
      .then((response) => {
        userExists = response.data.exists
        resolve(userExists)
      })
      .catch(() => {
        userExists = false
        resolve(userExists)
      })
  })
}

function AreaModal(props) {
  const [allUsers, setAllUsers] = useRecoilState(allUsersState)
  const [isLoading, setIsLoading] = useRecoilState(isLoadingState)
  const [usersLoading, setUsersLoading] = useRecoilState(usersLoadingState)
  const auth = useAuth()
  const navigate = useNavigate()
  const setAlert = useSetRecoilState(pageAlertState)
  const apiClient = useApiClient()

  const {
    isInRunningState,
    getRelatedProgramNames,
  } = useEntityMonitor()

  const preventSaving = isInRunningState('site', props.data?.id)
  const runningProgramNames = getRelatedProgramNames('site', props.data?.id)
  const isEditing = props.data?.isEditing || false

  const {
    reset,
    setValue,
    watch,
    control,
    register,
    handleSubmit,
    formState,
  } = useForm({ resolver: yupResolver(generateSchema(apiClient, props.data?.id)) })
  const {
    errors,
    isSubmitted,
  } = formState

  const address = watch('address')

  useTitle([`${isEditing ? 'Edit' : 'Add'} area`, props.data?.name])

  // Manually register the address field
  // onChange is manually handled
  useEffect(() => {
    register('address', { value: props.data?.address })
  }, [register, props])

  const getUsers = useCallback(async () => {
    try {
      let { data } = await apiClient.get('/users')

      if (data && data.success) {
        const users = map(data.users, (user) => {
          return {
            value: user.id,
            label: user.name,
          }
        })

        setAllUsers(users)
        publish('areaCreated')
      }
    } catch (error) {
      Sentry.captureException(error)
      setAlert({
        type: 'error',
        content: 'Failed to retrieve users.',
      })
    } finally {
      setUsersLoading(false)
    }
  }, [
    setAllUsers,
    setAlert,
    setUsersLoading,
  ])

  const fieldChange = useCallback((field, value) => {
    setValue(field, value, { shouldValidate: isSubmitted })
  }, [isSubmitted, setValue])

  useEffect(() => {
    getUsers()
  }, [getUsers])

  const onSubmit = async (data) => {
    setAlert(null)

    try {
      setIsLoading(true)

      data = flattenSelectValues(data)
      data = formatKeys(data, 'snake')

      let endpoint = '/area/create'
      if (isEditing) {
        endpoint = `/area/update/${props.data?.id}`
      }

      let { data: responseData } = await apiClient.post(`${endpoint}`, data)

      if (responseData.success) {
        if (isFunction(props.data?.onSave)) {
          props.data.onSave()
        } else {
          navigate(`/area/manage/${responseData.area.id}`)
        }

        setAlert({
          type: 'info',
          content: `Attempting to ${isEditing ? 'updated' : 'created'} Area ${responseData.area.name}.`,
        })

        props.close()
      }
    } catch (error) {
      Sentry.captureException(error)
      setAlert({
        type: 'error',
        content: 'An error has occured and we were not able to save this area. Please try again.',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      title={isEditing ? `Edit ${props.data?.name}` : 'Add area'}
      close={props.close}
      closeOnOutsideClick={props.closeOnOutsideClick}
    >
      {preventSaving && (
        <AlertContent className="mb-4">
          You cannot edit or delete this as the following programs are running: {runningProgramNames}
        </AlertContent>
      )}
      <AreaForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Input
          name="name"
          label="Name"
          isRequired={true}
          className={errors.name && 'error'}
          {...register('name', { value: props.data?.name })}
        />
        {errors.name && <InputError message={errors.name.message} />}

        <Input
          name="description"
          label="Description"
          className={errors.description && 'error'}
          {...register('description', { value: props.data?.description })}
        />
        {errors.description && <InputError message={errors.description.message} />}

        <GooglePlacesAutocomplete
          label="Address"
          isRequired={true}
          name="address"
          value={address || ''}
          onChange={fieldChange}
          className={errors.address && 'error'}
        />
        {errors.address && <InputError message={errors.address.message} />}

        <Input
          name="serialNumber"
          label="Serial Number"
          isRequired={true}
          className={errors.serialNumber && 'error'}
          {...register('serialNumber', { value: props.data?.serialNumber })}
        />
        {errors.serialNumber && <InputError message={errors.serialNumber.message} />}

        <Controller
          control={control}
          defaultValue={map(props.data?.users, (user) => {
            return {
              value: user.id,
              label: user.name,
            }
          })}
          name="authorisedUserIds"
          render={({ field }) => {
            return (
              <Select
                {...field}
                isMulti={true}
                isSearchable={true}
                label="Authorized users"
                isLoading={usersLoading}
                options={allUsers}
                hasError={!!errors.authorisedUserIds}
              />
            )
          }}
        />
        {errors.authorisedUserIds && <InputError message={errors.authorisedUserIds.message} />}

        {auth.isAdmin && !isEditing && (
          <div className="mt-6 hidden">
            <LightSwitch
              label="Reset FEP"
              onToggle={(name, value) => {
                setValue('reset', value)
              }}
              defaultState={props.data?.reset || false}
              {...register('reset', { value: props.data?.reset || false })}
            />
          </div>
        )}

        <ButtonGroup className="buttons">
          <Anchor
            style={{ width: 'calc(50% - 5px)' }}
            className="transparent"
            onClick={() => {
              setAlert(null)
              reset()
              props.close()
            }}
          >
            Close
          </Anchor>

          <Button
            style={{ width: 'calc(50% - 5px)' }}
            disabled={isLoading ? true : false}
            disabledWithReason={preventSaving && runningProgramNames}
          >
            {isLoading ? <div className="primary-loader light"></div> : 'Save'}
          </Button>
        </ButtonGroup>
      </AreaForm>
    </Modal>
  )
}

export default AreaModal
