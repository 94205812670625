import { map, orderBy, split } from 'lodash-es'
import { rem } from 'polished'
import styled from 'styled-components'

import { formattedDateTime } from '@/Utilities/DateTime'

const CommentsTable = styled.table`
  border-collapse: collapse;
  color: #667085;
  width: 100%;

  th {
    background: #F9FAFB;
    padding: 15px 20px;
    font-size: ${rem(14)};
    font-weight: 400;
    border-bottom: 1px solid #E4E7EC;

    &.date, &.name {
      width: 20%;
    }

    &.comment {
      width: calc(60% - 80px);
    }

    &.action {
      width: 80px;
    }
  }
`

const EditComment = styled.span`
  color: #175CD3;
  font-weight: 500;
  cursor: pointer;
`

const AlarmCommentSection = (props) => {
  const {
    alarm,
    setModal,
    user,
  } = props

  return (
    <CommentsTable>
      <thead>
        <tr>
          <th className="date">
            Date
          </th>

          <th className="name">
            Name
          </th>

          <th className="comment">
            Comment
          </th>

          <th className="action">
          </th>
        </tr>
      </thead>

      <tbody>
        {
          !alarm.comments.length &&
          <tr>
            <td colSpan={4}>
              No comments yet.
            </td>
          </tr>
        }

        {map(orderBy(alarm.comments, ['created_at'], ['desc']), (comment) => {
          return (
            <tr key={comment.id}>
              <td>
                {formattedDateTime(comment.created_at)}
              </td>

              <td>
                {comment.user.name}
              </td>

              <td>
                {map(split(comment.comment, '\n'), (line, index) => {
                  return (
                    <div className="pb-1" key={index}>
                      {line}
                    </div>
                  )
                })}
              </td>

              <td>
                {user && (comment.user.id === user.id) && comment.can_edit && (
                  <EditComment
                    onClick={() => {
                      setModal({
                        name: 'comment',
                        data: {
                          comment: comment,
                          alarmId: alarm.id,
                        },
                      })
                    }}
                  >
                    Edit
                  </EditComment>
                )}
              </td>
            </tr>
          )
        })}
      </tbody>
    </CommentsTable>
  )
}

export default AlarmCommentSection
