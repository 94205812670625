import Skeleton from 'react-loading-skeleton'

export default function SkeletonTable(props) {
  return (
    <div className="overflow-hidden rounded-md bg-white shadow-md ring-1 ring-black/5">
      {!props.hideTitle && (
        <div className="grid grid-cols-2 border-b border-gray-200 p-4 text-lg">
          <div>
            <Skeleton className="w-64" />
          </div>

          <div className="flex justify-end">
            <Skeleton className="w-96" />
          </div>
        </div>
      )}

      <table className="min-w-full divide-y divide-gray-200 border-b border-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="py-3 px-4 text-left">
              <Skeleton className="w-40" />
            </th>
            <th className="py-3 px-4 text-left">
              <Skeleton className="w-40" />
            </th>
            <th className="py-3 px-4 text-left">
              <Skeleton className="w-40" />
            </th>
            <th className="py-3 px-4 text-left">
              <Skeleton className="w-40" />
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          <tr>
            <td colSpan="4" className="whitespace-nowrap py-3 px-4 text-sm text-gray-900 @sm:pl-6">
              <Skeleton />
            </td>
          </tr>
        </tbody>
      </table>

      {!props.hidePagination && (
        <div className="grid grid-cols-2 text-gray-400">
          <div className="w-64 py-3 px-4">
            <Skeleton />
          </div>

          <div className="flex justify-end py-3 px-4">
            <div className="mr-4 inline-block w-28 px-3">
              <Skeleton />
            </div>

            <div className="inline-block px-3 text-sm">
              <i className="far fa-chevron-double-left"></i>
            </div>

            <div className="inline-block px-3 text-sm">
              <i className="far fa-chevron-left"></i>
            </div>

            <div className="inline-block px-3 text-sm">
              <i className="far fa-chevron-right"></i>
            </div>

            <div className="inline-block px-3 text-sm">
              <i className="far fa-chevron-double-right"></i>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
