import * as Sentry from '@sentry/react'
import classNames from 'classnames'
import { marker } from 'leaflet'
import { each, head, isEmpty, isUndefined, toNumber } from 'lodash-es'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import styled from 'styled-components'

import AlertContent from '@/Components/alerts/AlertContent'
import { Anchor } from '@/Components/form/Buttons'
import HUISelect from '@/Components/headless/form/Select'
import Map from '@/Components/Map'
import InputOutputsBySite from '@/Components/tables/InputOutputsBySite'
import MainLinesBySite from '@/Components/tables/MainLinesBySite'
import ProgramSetsBySite from '@/Components/tables/programs/ProgramSetsBySite'
import Tabs from '@/Components/Tabs'
import { modalState } from '@/Config/Atoms/General'
import { gpsCoordinatesCanSaveState, gpsCoordinatesState } from '@/Config/Atoms/Site'
import SiteManageBlocks from '@/Pages/site/manage/SiteManageBlocks'
import SiteManageCoordinates from '@/Pages/site/manage/SiteManageCoordinates'
import SiteManageDetailsCard from '@/Pages/site/manage/SiteManageDetailsCard'
import { standardActions } from '@/Utilities/Events'
import { formatKeys } from '@/Utilities/Form/Formatter'
import labelIcon from '@/Utilities/Map/Icons/LabelIcon'
import { addBlocks } from '@/Utilities/Map/Map'
import useApiClient from '@/Utilities/useApiClient'
import useAuth from '@/Utilities/useAuth'
import useEventSubscriber from '@/Utilities/useEventSubscriber'
import usePageGuard from '@/Utilities/usePageGuard'
import useTitle from '@/Utilities/useTitle'

const MapLoader = styled.div`
  align-items: center;
  background: rgba(247, 247, 247, 1);
  border-radius: 5px;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
`

const PageContainer = styled.div`
  padding: 0 24px;
  width: 100%;
`

const DetailContainer = styled.div`
  .button-container {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
  }
`

const MapContainer = styled.div`
  display: flex;
  height: calc(100vh - 265px);
  justify-content: center;
  position: sticky;
  top: 24px;
`

const tabs = [
  {
    title: 'General',
    key: 'general',
  },
  {
    title: 'Main lines',
    key: 'mainLines',
  },
  {
    title: 'I/Os',
    key: 'inputOutputs',
  },
  {
    title: 'Programs',
    key: 'programs',
  },
]

export default function SiteManage(props) {
  const {
    manageCoordinates,
    manageBlocks,
  } = props

  const apiClient = useApiClient()
  const auth = useAuth()
  const navigate = useNavigate()
  const urlParams = useParams()

  const [fittedBlockBounds, setFittedBlockBounds] = useState(false)
  const [mapLoaded, setMapLoaded] = useState(false)
  const [selectedTab, setSelectedTab] = useState([])
  const [site, setSite] = useState({})
  const [siteArea, setSiteArea] = useState({})
  const setCanSaveCoordinates = useSetRecoilState(gpsCoordinatesCanSaveState)
  const setGpsCoordinates = useSetRecoilState(gpsCoordinatesState)
  const setModal = useSetRecoilState(modalState)

  const { setSubscriberModelMap } = useEventSubscriber([
    'site',
    'inputOutput',
    'programSet',
    'program',
  ], standardActions, () => {
    getSite()
  })

  const mapInstance = useRef(null)
  const originalGpsCoordinates = useRef()

  usePageGuard({
    auth,
    permission: 'view-site',
  })

  useTitle(['Managing site', site?.name])

  const mapInit = (map) => {
    mapInstance.current = map
    setMapLoaded(true)
    setSiteMapIcon()
  }

  const addMarker = (lat, lng, shouldClear = false, shouldPan = false, draggable = false) => {
    if (!mapInstance.current) {
      return false
    }

    if (shouldClear) {
      mapInstance.current.groups.marker.clearLayers()
    }

    let mapMarker = marker([lat, lng], {
      icon: labelIcon(site.name),
      draggable: draggable,
    }).on('dragend', (event) => {
      setGpsCoordinates(event.target.getLatLng())
      setCanSaveCoordinates(true)
    })

    mapMarker.addTo(mapInstance.current.groups.marker)

    if (shouldPan) {
      mapInstance.current.map.setView([lat, lng], 18)
    }
  }

  const getSite = useCallback(async () => {
    const query = new URLSearchParams([
      ['with[]', 'area'],
      ['with[]', 'programSets'],
      ['with[]', 'blocks'],
      ['with[]', 'inputOutputs'],
      ['with[]', 'mainLines'],
    ])

    try {
      let { data } = await apiClient.get(`/site/view/${urlParams.id}?${query}`)

      if (data && data.success) {
        const siteData = formatKeys(data.site, 'camel')

        setSite(siteData)
        setSubscriberModelMap({ site: [siteData.id] })

        originalGpsCoordinates.current = {
          lat: siteData.lat,
          lng: siteData.lng,
        }

        if (siteData.lat && siteData.lng) {
          setSiteArea({
            lat: toNumber(siteData.lat),
            lng: toNumber(siteData.lng),
          })
        } else {
          const getCoordinates = async () => {
            let { data } = await apiClient.get(`/google/maps/search/text?query=${siteData.area.address}`)

            if (data.success) {
              setSiteArea(data.result.geometry.location)

              if (mapInstance.current.map) {
                mapInstance.current.map.setView([data.result.geometry.location.lat, data.result.geometry.location.lng], 18)
              }
            }
          }

          getCoordinates()
        }
      }
    } catch (error) {
      Sentry.captureException(error)
      navigate('/404')
    }
  }, [
    setSite,
    urlParams,
    navigate,
    setSiteArea,
  ])

  useEffect(() => {
    getSite()

    return () => {
      setGpsCoordinates(null)
    }
  }, [getSite, setGpsCoordinates])

  useEffect(() => {
    const hasBlocks = !isEmpty(site.blocks)
    const isGeneralTab = selectedTab == 'general'
    const managingBlocksOrCoords = manageBlocks || manageCoordinates

    if (isGeneralTab && mapLoaded && hasBlocks && (!managingBlocksOrCoords || !fittedBlockBounds)) {
      let blocks = []

      each(site.blocks, (block) => {
        let geoJson = block.geoJson

        blocks.push({
          ...geoJson,
          properties: {
            ...geoJson.properties,
            id: block.id,
          },
        })
      })

      const blocksResult = addBlocks({
        blocks: blocks,
        drawingLayer: mapInstance.current.groups.drawings,
        clearLayer: true,
        withBounds: true,
      })

      if (blocksResult.bounds && blocksResult.bounds.isValid()) {
        mapInstance.current.map.fitBounds(blocksResult.bounds)
      }

      setFittedBlockBounds(true)
    }
  }, [
    mapLoaded,
    site,
    manageBlocks,
    selectedTab,
  ])

  const setSiteMapIcon = useCallback(() => {
    if (site.lat && site.lng && mapInstance?.current?.groups.marker.getLayers().length == 0) {
      const shouldPan = isEmpty(site.blocks)

      addMarker(site.lat, site.lng, true, shouldPan, manageCoordinates)
    }
  }, [
    site.lat,
    site.lng,
    manageCoordinates,
  ])

  useEffect(() => {
    if (mapInstance.current) {
      mapInstance.current.groups.marker.clearLayers()
    }

    setSiteMapIcon()
  }, [manageCoordinates])

  const mapClick = useCallback((event) => {
    if (mapInstance.current.groups.marker.getLayers().length >= 2 || !manageCoordinates) {
      return
    }

    addMarker(event.latlng.lat, event.latlng.lng, true, true, true)

    setGpsCoordinates(event.latlng)
    setCanSaveCoordinates(true)
  }, [
    manageCoordinates,
    mapInstance,
    site,
  ])

  const tabChange = useCallback((value) => {
    setSelectedTab(value)
  }, [setSelectedTab])

  useEffect(() => {
    if (!selectedTab?.length) {
      setSelectedTab('general')
    }
  }, [setSelectedTab])

  const reloadPage = () => {
    window.location.reload()
  }

  return (
    <PageContainer>
      <div className="mb-4 grid grid-cols-1 items-center gap-4 @md:grid-cols-2">
        <div className="text-3xl font-light text-slate-900">
          Managing <span className="font-medium">{site.name}</span>
        </div>

        <div className="hidden @md:text-right md:inline-block">
          <Tabs
            defaultTab="general"
            tabs={tabs}
            disabled={manageBlocks || manageCoordinates}
            tooltipMessage={(manageBlocks || manageCoordinates) && 'Disabled while managing blocks and coordinates.'}
            onChange={tabChange}
          />
        </div>

        <div className="block md:hidden">
          <HUISelect
            className="mt-3 w-full rounded-md"
            disabled={manageBlocks || manageCoordinates}
            defaultValue={head(tabs)}
            options={tabs}
            labelProperty="title"
            onChange={(selected) => {
              if (selected.key !== selectedTab) {
                setSelectedTab(selected.key)
              }
            }}
          />
        </div>
      </div>

      {selectedTab === 'general' && (
        <div className="row">
          <DetailContainer className="col-12 col-md-4">
            {!isEmpty(site) && !isUndefined(site.active) && !site.active && (
              <AlertContent title={`Site "${site.name}" has not been setup yet`} hideIcon={false} type="warning"
                className="mb-5">
                <p className="mb-2">
                  FutureOps has not received a response from the responsible FEP.
                  You'll be able to use this site once setup is confirmed.
                </p>

                <p>
                  If this issue persists, please try <a className="cursor-pointer underline" onClick={reloadPage}>refreshing</a> or contact our support team.
                </p>
              </AlertContent>
            )}

            <SiteManageDetailsCard
              site={site}
              coordinates={originalGpsCoordinates.current}
              manageCoordinates={manageCoordinates}
              manageBlocks={manageBlocks}
            />

            {manageCoordinates && !isEmpty(site) && (
              <SiteManageCoordinates
                addMarker={addMarker}
                site={site}
                onCoordinatesSaved={async () => {
                  await getSite()

                  navigate(`/site/manage/${site.id}`)
                }}
              />
            )}

            {mapLoaded && manageBlocks && site && (
              <SiteManageBlocks
                mapInstance={mapInstance}
                existingBlocks={site.blocks}
                site={site}
                getSite={getSite}
              />
            )}

            {!manageCoordinates && !manageBlocks && (
              <div className="button-container">
                <Anchor
                  className={classNames('transparent', { hidden: !auth.can('create-program') })}
                  style={{ width: 'calc(50% - 10px)' }}
                  onClick={() => {
                    setModal({
                      name: 'set',
                      data: {
                        site: {
                          name: site.name,
                          id: site.id,
                        },
                      },
                    })
                  }}
                >
                  Add program set
                </Anchor>

                <Anchor
                  className={classNames({ hidden: !auth.can('create-input-output') })}
                  style={{ width: 'calc(50% - 10px)' }}
                  onClick={() => {
                    setModal({
                      name: 'io',
                      data: {
                        site: site,
                        onSave: (inputOutput, mapLinkingEnabled) => {
                          if (mapLinkingEnabled) {
                            navigate(`/io/manage/${inputOutput.id}/coordinates`)
                          } else {
                            getSite()
                          }
                        },
                      },
                    })
                  }}
                >
                  Add I/O
                </Anchor>
              </div>
            )}
          </DetailContainer>

          <MapContainer className="col-12 col-md-8 mt-5 md:mt-0">
            {
              !isEmpty(site) ?
                <Map
                  loading={(siteArea.lat && siteArea.lng) || 1000}
                  onInit={mapInit}
                  onClick={mapClick}
                  dialog={manageBlocks && 'drawing' || null}
                /> :
                <MapLoader>
                  <div className="primary-loader"></div>
                </MapLoader>
            }
          </MapContainer>
        </div>
      )}

      {selectedTab === 'mainLines' && (
        <MainLinesBySite siteId={site.id} site={site} />
      )}

      {selectedTab === 'inputOutputs' && (
        <InputOutputsBySite siteId={site.id} site={site} />
      )}

      {selectedTab === 'programs' && (
        <ProgramSetsBySite siteId={site.id} site={site} />
      )}
    </PageContainer>
  )
}
