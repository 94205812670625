import * as Sentry from '@sentry/react'
import classNames from 'classnames'
import { endsWith, filter, find, head, isEmpty, map, split, toNumber } from 'lodash-es'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import Input from '@/Components/form/Input'
import InputError from '@/Components/form/InputError'
import LightSwitch from '@/Components/form/LightSwitch'
import RequiredAsterisk from '@/Components/form/RequiredAsterisk'
import Select from '@/Components/form/Select'
import { formatKeys, toOptions } from '@/Utilities/Form/Formatter'
import { unitOptions } from '@/Utilities/Units'
import useApiClient from '@/Utilities/useApiClient'
import useTitle from '@/Utilities/useTitle'

export default function General(props) {
  const apiClient = useApiClient()
  const [rtuProgramNumberOptions, setRtuProgramNumberOptions] = useState([])
  const rtuProgramNumberFirstLoad = useRef(true)

  const {
    setValue,
    control,
    register,
    errors,
    watch,
  } = useFormContext()

  const isEditing = props.data?.isEditing || false
  let program

  if (props.data?.program) {
    program = useMemo(() => {
      return formatKeys(props.data.program, 'camel')
    }, [props.data.program])
  }

  useTitle([`${program ? 'Edit' : 'Add'} program`, program?.name])

  const changeOfStateEnabled = watch('changeOfState')
  const awaitOutputsCloseEnabled = watch('awaitOutputsClose')
  const expectedFlowDisabled = watch('expectedFlowDisabled')
  const programSetId = watch('programSetId')
  const programDisabled = watch('disabled')

  const waterQuantityMeasurementOptions = useMemo(() => {
    return unitOptions('waterQuantity')
  }, [])

  const expectedFlowMeasurementOptions = useMemo(() => {
    return unitOptions('expectedFlow')
  }, [])

  const times = useMemo(() => {
    if (program) {
      return { runtime: split(program.runtime, ':') }
    }

    return null
  }, [program])

  const isAnalogFlowMeter = useMemo(() => {
    return endsWith(props.selectedMainLine?.flow_meter?.details_type, 'analogFlowMeter')
  }, [props.selectedMainLine])

  const irrigationBasis = useMemo(() => {
    return props.selectedProgramSet?.irrigationBasis
  }, [props.selectedProgramSet?.irrigationBasis])

  useEffect(() => {
    if (isAnalogFlowMeter || props.selectedProgramSet?.irrigationBasis == 'quantity') {
      setValue('expectedFlowDisabled', false)
    }
  }, [isAnalogFlowMeter, props.selectedProgramSet?.irrigationBasis])

  useEffect(() => {
    const set = find(props.programSets, ['id', programSetId?.value])

    if (set) {
      setValue('siteId', set.siteId)
      getRtuProgramNumbers(set.siteId)
    }
  }, [programSetId, props.programSets])

  const getRtuProgramNumbers = useCallback(async (siteId) => {
    rtuProgramNumberFirstLoad.current = false

    if (siteId) {
      try {
        const { data } = await apiClient.get(`/program/available-rtu-program-numbers?site_id=${siteId}`)

        if (data?.success) {
          const options = map(data.programNumbers, (programNumber) => {
            return {
              label: programNumber,
              value: programNumber,
            }
          })

          setRtuProgramNumberOptions(options)
        }
      } catch (error) {
        Sentry.captureException(error)
      }
    }
  }, [setRtuProgramNumberOptions])

  useEffect(() => {
    if (props.selectedProgramSet) {
      getRtuProgramNumbers(props.selectedProgramSet.siteId)
    }

    return () => {
      setRtuProgramNumberOptions([])
    }
  }, [])

  useEffect(() => {
    const currentTab = document.getElementById('general')

    if (currentTab) {
      props.setTabErrors((prevState) => {
        return {
          ...prevState,
          general: !!currentTab?.querySelector('.error-message'),
        }
      })
    }
  }, [errors, props.setTabErrors])

  const showProgramSetsBySite = useMemo(() => {
    const limitProgramSetsBySite = props.data?.limitProgramSetsBySite || false
    return (isEditing && props.selectedSiteId) || limitProgramSetsBySite
  }, [
    isEditing,
    props.selectedSiteId,
    props.data?.limitProgramSetsBySite,
  ])

  const programSetsBySite = useMemo(() => {
    let programSets = formatKeys(props.programSets, 'camel')

    if (showProgramSetsBySite) {
      programSets = filter(programSets, (programSet) => {
        return programSet.siteId === props.selectedSiteId
      })
    }

    return toOptions(programSets, ['name', 'label'], ['id', 'value'])
  }, [
    showProgramSetsBySite,
    props.programSets,
    props.selectedSiteId,
  ])

  return (
    <>
      {
        !props.setupLoading &&
        <>
          <Controller
            control={control}
            defaultValue={find(props.programSetOptions, ['value', program?.programSetId])}
            name="programSetId"
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  isMulti={false}
                  isSearchable={true}
                  label="Program Set"
                  isRequired={true}
                  isLoading={props.setupLoading}
                  options={showProgramSetsBySite ? programSetsBySite : props.programSetOptions}
                  afterChange={() => {
                    setValue('mainLineId', null)
                  }}
                  placeholder="Search"
                  hasError={!!errors.programSetId}
                />
              )
            }}
          />
          {errors.programSetId && <InputError message={errors.programSetId.message} />}
        </>
      }

      <Input
        label="Name"
        isRequired={true}
        type="text"
        className={errors.name && 'error'}
        {...register('name', { value: program?.name })}
      />
      {errors.name && <InputError message={errors.name.message} />}

      <Input
        label="Description"
        type="text"
        className={errors.description && 'error'}
        {...register('description', { value: program?.description })}
      />
      {errors.description && <InputError message={errors.description.message} />}

      <Controller
        control={control}
        defaultValue={
          program?.rtuProgramNumber ?
            {
              label: program?.rtuProgramNumber,
              value: program?.rtuProgramNumber,
            } : ''
        }
        name="rtuProgramNumber"
        render={({ field }) => {
          return (
            <Select
              {...field}
              isMulti={false}
              isSearchable={true}
              label="RTU program number"
              isRequired={true}
              options={rtuProgramNumberOptions}
              hasError={!!errors.rtuProgramNumber}
              noOptionsMessage={() => {
                return 'Select a set to see available RTU program numbers'
              }}
            />
          )
        }}
      />
      {errors.rtuProgramNumber && <InputError message={errors.rtuProgramNumber.message} />}

      {!isEditing || (isEditing && !isEmpty(props.mainLineOptions)) ? (
        <>
          <Controller
            control={control}
            defaultValue={find(props.mainLineOptions, ['value', program?.mainLineId])}
            name="mainLineId"
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  isMulti={false}
                  isSearchable={true}
                  label="Main line"
                  isClearable={true}
                  isLoading={props.mainLinesLoading}
                  options={props.mainLineOptions}
                  placeholder={programSetId && isEmpty(props.mainLineOptions) ? 'No options available' : programSetId && !isEmpty(props.mainLineOptions) ? 'Search' : 'Select a set to see available options'}
                  hasError={!!errors.mainLineId}
                  noOptionsMessage={() => {
                    return programSetId ? 'There are no options for the selected set.' : 'Select a set to see available options.'
                  }}
                />
              )
            }}
          />
          {errors.mainLineId && <InputError message={errors.mainLineId.message} />}
        </>
      ) : null}

      <Controller
        control={control}
        defaultValue={find([{
          value: 'wait',
          label: 'Wait',
        }, {
          value: 'ignore',
          label: 'Ignore',
        }], ['value', program?.actionOnCommunicationFailure])}
        name="actionOnCommunicationFailure"
        render={({ field }) => {
          return (
            <Select
              {...field}
              isMulti={false}
              isSearchable={true}
              label="Action on comms failure"
              isRequired={true}
              options={[{
                value: 'wait',
                label: 'Wait',
              }, {
                value: 'ignore',
                label: 'Ignore',
              }]}
              hasError={!!errors.actionOnCommunicationFailure}
            />
          )
        }}
      />
      {errors.actionOnCommunicationFailure && <InputError message={errors.actionOnCommunicationFailure.message} />}

      <div className="row">
        <div className="col-12 col-md-6">
          <Controller
            control={control}
            defaultValue={head(filter(expectedFlowMeasurementOptions, ['value', program?.expectedFlowUnitOfMeasurement]))}
            name="expectedFlowUnitOfMeasurement"
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  isMulti={false}
                  isSearchable={true}
                  label="Expected flow unit of measure"
                  isRequired={!expectedFlowDisabled ? true : false}
                  options={expectedFlowMeasurementOptions}
                  isDisabled={expectedFlowDisabled}
                  hasError={!!errors.expectedFlowUnitOfMeasurement}
                />
              )
            }}
          />
          {errors.expectedFlowUnitOfMeasurement && <InputError message={errors.expectedFlowUnitOfMeasurement.message} />}
        </div>

        <div className={classNames({
          'col-md-4': irrigationBasis != 'quantity',
          'col-md-6': irrigationBasis == 'quantity',
        }, 'col-12')}>
          <Input
            label="Expected flow"
            isRequired={!expectedFlowDisabled ? true : false}
            type="number"
            className={errors.expectedFlow && 'error'}
            disabled={expectedFlowDisabled}
            {...register('expectedFlow', { value: program?.expectedFlow })}
          />
          {errors.expectedFlow && <InputError message={errors.expectedFlow.message} />}
        </div>

        {irrigationBasis != 'quantity' ? (
          <div className="col-12 col-md-2 flex items-start">
            <LightSwitch
              label="Disable"
              wrapperClassNames="mt-14"
              onToggle={(name, value) => {
                setValue('expectedFlowDisabled', value)

                if (!expectedFlowDisabled) {
                  setValue('expectedFlow', null, { shouldValidate: true })
                  setValue('expectedFlowUnitOfMeasurement', null, { shouldValidate: true })
                  setValue('lowTolerancePercentage', null, { shouldValidate: true })
                  setValue('highTolerancePercentage', null, { shouldValidate: true })
                }
              }}
              defaultState={toNumber(program?.expectedFlowDisabled) ? true : expectedFlowDisabled || false}
              {...register('expectedFlowDisabled', { value: toNumber(program?.expectedFlowDisabled) || false })}
            />
          </div>
        ) : null}
      </div>

      <div className="row">
        <div className="col-12 col-md-6">
          <Input
            label="Low tolerance %"
            isRequired={!expectedFlowDisabled ? true : false}
            type="number"
            className={errors.lowTolerancePercentage && 'error'}
            {...register('lowTolerancePercentage', { value: program?.lowTolerancePercentage })}
            min="1"
            max="100"
            disabled={expectedFlowDisabled}
          />
          {errors.lowTolerancePercentage && <InputError message={errors.lowTolerancePercentage.message} />}
        </div>

        <div className="col-12 col-md-6">
          <Input
            label="High tolerance %"
            isRequired={!expectedFlowDisabled ? true : false}
            type="number"
            className={errors.highTolerancePercentage && 'error'}
            {...register('highTolerancePercentage', { value: program?.highTolerancePercentage })}
            min="1"
            max="100"
            disabled={expectedFlowDisabled}
          />
          {errors.highTolerancePercentage && <InputError message={errors.highTolerancePercentage.message} />}
        </div>
      </div>

      {irrigationBasis && (
        <Input
          type="hidden"
          className={errors.irrigationBasis && 'error'}
          {...register('irrigationBasis', { value: irrigationBasis })}
        />
      )}

      {irrigationBasis == 'time' && (
        <div className="col-12 col-md-6">
          <div className="mb-2 mt-5 flex text-sm text-dark">Program runtime <RequiredAsterisk /></div>

          <div className="flex items-center">
            <Input
              placeholder="hh"
              maxlength="2"
              type="number"
              width="60px"
              textCenter
              className={classNames('mr-5', { error: errors.runtimeHours })}
              {...register('runtimeHours', { value: times?.runtime[0] || '' })}
            />

            <div className="mx-2.5 -ml-2.5">
              :
            </div>

            <Input
              placeholder="mm"
              maxlength="2"
              type="number"
              width="60px"
              textCenter
              className={classNames('mr-5', { error: errors.runtimeMinutes })}
              {...register('runtimeMinutes', { value: times?.runtime[1] || '' })}
            />

            <div className="mx-2.5 -ml-2.5">
              :
            </div>

            <Input
              placeholder="ss"
              maxlength="2"
              type="number"
              width="60px"
              textCenter
              className={classNames('mr-5', { error: errors.runtimeSeconds })}
              {...register('runtimeSeconds', { value: times?.runtime[2] || '' })}
            />
          </div>

          {
            (
              (
                errors.runtimeHours?.type === 'min' ||
                errors.runtimeHours?.type === 'max' ||
                errors.runtimeMinutes?.type === 'min' ||
                errors.runtimeMinutes?.type === 'max' ||
                errors.runtimeSeconds?.type === 'min' ||
                errors.runtimeSeconds?.type === 'max'
              ) &&
              <InputError message="Runtime should be between 00:00 and 23:59" />
            ) ||
            (
              (errors.runtimeHours || errors.runtimeMinutes || errors.runtimeSeconds) &&
              <InputError message="Runtime is a required field" />
            )
          }
        </div>
      )}

      {irrigationBasis == 'quantity' && (
        <div className="row">
          <div className="col-12 col-md-6">
            <Controller
              control={control}
              defaultValue={find(waterQuantityMeasurementOptions, ['value', program?.waterQuantityUnitOfMeasurement])}
              name="waterQuantityUnitOfMeasurement"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    isMulti={false}
                    isSearchable={true}
                    label="Water quantity unit of measure"
                    isRequired={true}
                    options={waterQuantityMeasurementOptions}
                    placeholder="Search"
                    hasError={!!errors.waterQuantityUnitOfMeasurement}
                  />
                )
              }}
            />
            {errors.waterQuantityUnitOfMeasurement && <InputError message={errors.waterQuantityUnitOfMeasurement.message} />}
          </div>

          <div className="col-12 col-md-6">
            <Input
              label="Water quantity"
              isRequired={true}
              type="number"
              className={errors.waterQuantity && 'error'}
              {...register('waterQuantity', { value: program?.waterQuantity })}
            />
            {errors.waterQuantity && <InputError message={errors.waterQuantity.message} />}
          </div>
        </div>
      )}

      <LightSwitch
        label="Reporting"
        helpTooltip="If enabled, data will be reported to FutureOps and the data will be available for reporting."
        onToggle={(name, value) => {
          setValue('changeOfState', value)
        }}
        defaultState={toNumber(program?.changeOfState) ? true : changeOfStateEnabled || true}
        {...register('changeOfState', { value: toNumber(program?.changeOfState) || true })}
      />

      <div className="hidden">
        <LightSwitch
          label="Wait program end due to outputs close delay"
          onToggle={(name, value) => {
            setValue('awaitOutputsClose', value)
          }}
          defaultState={toNumber(program?.awaitOutputsClose) ? true : awaitOutputsCloseEnabled || false}
          {...register('awaitOutputsClose', { value: toNumber(program?.awaitOutputsClose) || false })}
        />
      </div>

      <LightSwitch
        label="Disable program"
        onToggle={(name, value) => {
          setValue('disabled', value)
        }}
        defaultState={toNumber(program?.disabled) ? true : programDisabled || false}
        {...register('disabled', { value: toNumber(program?.disabled) || false })}
      />

      <Input
        type="hidden"
        className={errors.siteId && 'error'}
        {...register('siteId', { value: program?.siteId })}
      />
    </>
  )
}
