import classNames from 'classnames'
import { useMemo } from 'react'

export default function AlertContent(props) {
  const {
    title,
    type,
    hideIcon,
    children,
    className,
  } = props

  const iconClass = useMemo(() => {
    if (hideIcon) {
      return null
    }

    let classes = null

    switch (type) {
      case 'warning':
        classes = 'fa-solid fa-circle-exclamation'
        break

      case 'info':
        classes = 'fa-solid fa-circle-info'
        break

      case 'error':
      default:
        classes = 'fa-sharp fa-solid fa-circle-xmark'
        break
    }

    return classes
  }, [type, hideIcon])

  const colors = useMemo(() => {
    let typeColors = {}
    let defaultColors = {
      icon: 'text-red-800',
      text: 'text-red-700',
      heading: 'text-red-800',
      wrapper: 'border-red-400 bg-red-50',
    }

    if (type === 'warning') {
      typeColors = {
        icon: 'text-yellow-600',
        text: 'text-yellow-700',
        heading: 'text-yellow-700',
        wrapper: 'border-yellow-400 bg-yellow-50',
      }
    }

    if (type === 'info') {
      typeColors = {
        icon: 'text-sky-600',
        text: 'text-sky-600',
        heading: 'text-sky-600',
        wrapper: 'border-sky-400 bg-sky-50',
      }
    }

    return {
      ...defaultColors,
      ...typeColors,
    }
  }, [type])

  return (
    <div className={classNames('rounded p-4 border shadow border-l-4', colors.wrapper, className)}>
      <div className="relative">
        <h3 className={classNames({
          'ml-8': iconClass,
          'font-medium': title,
        }, colors.heading, 'text-sm')}>
          {iconClass && (
            <i className={classNames(iconClass, colors.icon, 'text-base mr-3 w-5 h-5 absolute top-0 left-0 flex justify-center items-center')}></i>
          )}

          {title || children}
        </h3>

        {title && (
          <div className={classNames({ 'ml-8': iconClass }, colors.text, 'mt-2 text-sm')}>
            {children}
          </div>
        )}
      </div>
    </div>
  )
}
