import { Listbox, Transition } from '@headlessui/react'
import { isFunction, map } from 'lodash-es'
import { Fragment, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import Pill from '@/Components/pill/Pill'

export default function HeadlessUISelect({
  className,
  options,
  defaultValue,
  onChange,
  disabled,
  selectedOptionTick = false,
  labelProperty = 'label',
}) {
  const [selected, setSelected] = useState(defaultValue)

  useEffect(() => {
    if (isFunction(onChange)) {
      onChange(selected)
    }
  }, [selected])

  return (
    <Listbox value={selected} onChange={setSelected} disabled={disabled}>
      {({ open }) => {
        return (
          <>
            <div className="relative">
              <Listbox.Button
                className={
                  twMerge(
                    'relative',
                    'w-full',
                    'cursor-default',
                    'rounded-none',
                    'bg-white',
                    'py-2.5',
                    'pl-4',
                    'pr-10',
                    'text-left',
                    'text-gray-900',
                    'shadow-sm',
                    'ring-1',
                    'ring-inset',
                    'ring-gray-300',
                    'focus:outline-none',
                    'focus:ring-2',
                    'focus:ring-primary',
                    'disabled:opacity-75',
                    'disabled:text-slate-400',
                    'sm:text-sm',
                    'sm:leading-6',
                    className,
                  )
                }
              >
                <span className="block truncate">{selected[labelProperty]}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                  <i className="fa-regular fa-chevron-down icon mr-1 text-gray-400" />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-1100 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/10 focus:outline-none sm:text-sm">
                  {map(options, (option, index) => {
                    return (
                      <Listbox.Option
                        key={index}
                        className={({ active }) => {
                          return twMerge(
                            active ? 'bg-primary text-white' : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9',
                          )
                        }
                        }
                        value={option}
                      >
                        {({
                          selected,
                          active,
                        }) => {
                          return (
                            <>
                              <span className="block truncate font-normal">
                                {option[labelProperty]}
                              </span>

                              {(selected && selectedOptionTick) ? (
                                <span
                                  className={
                                    twMerge(
                                      active ? 'text-white' : 'text-primary',
                                      'absolute inset-y-0 right-0 flex items-center pr-4',
                                    )
                                  }
                                >
                                  <i className="fa-regular fa-check icon mr-1 text-gray-400" />
                                </span>
                              ) : null}

                              {(option.pill?.visible) && (
                                <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                                  <Pill color={option.pill.color}>{option.pill.title}</Pill>
                                </span>
                              )}
                            </>
                          )
                        }}
                      </Listbox.Option>
                    )
                  })}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )
      }}
    </Listbox>
  )
}
