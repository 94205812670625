import * as Sentry from '@sentry/react'
import { get, includes, isArray, isEmpty, join } from 'lodash-es'
import { useCallback, useEffect } from 'react'
import { useRecoilCallback, useRecoilValue } from 'recoil'

import { runningProgramNamesForEntity,
  runningTrackedEntitiesState,
  trackedEntitiesState } from '@/Config/Atoms/EntityMonitor'
import { subscribe, unsubscribe } from '@/Utilities/Events'

export default function useEntityMonitor(apiClient) {
  try {
    // Recoil states
    const trackedEntities = useRecoilValue(trackedEntitiesState)
    const runningEntities = useRecoilValue(runningTrackedEntitiesState)

    /**
     * Fetches the tracked entities from a specific API endpoint, and updates the tracked entities
     * Recoil state with the received data.
     */
    const fetchTrackedEntities = useRecoilCallback(({ set }) => {
      return async () => {
        if (!apiClient) {
          return
        }

        const response = await apiClient.get('/program/running-with-impacted')

        set(trackedEntitiesState, response.data)
      }
    }, [apiClient])

    /**
     * Determines if a given id is present in the running entities for a specified model.
     *
     * @param {string} model - The model for which to check the running entities.
     * @param {string} id - The id to check for immutability within the running entities.
     * @return {boolean} - Returns true if the id is immutable, otherwise returns false.
     */
    const isInRunningState = useCallback((model, id) => {
      return includes(get(runningEntities, model, []), id)
    }, [runningEntities])

    /**
     * Retrieves the related program names for a given model and entity ID.
     *
     * @param {string} model - The model associated with the entity.
     * @param {number|string} entityId - The ID of the entity.
     * @returns {Array<string>} - The related program names.
     */
    const getRelatedProgramNames = useCallback((model, entityId) => {
      return useRecoilValue(runningProgramNamesForEntity({
        model,
        entityId,
      }))
    }, [trackedEntities])

    const getProgramDisabledReason = useCallback((model, entityId) => {
      const programNames = getRelatedProgramNames(model, entityId)

      if (isEmpty(programNames)) {
        return null
      }

      const readableProgramNames = isArray(programNames)
        ? join(programNames, ', ')
        : programNames

      return `You cannot edit or delete this as the following programs are running: ${readableProgramNames}.`
    }, [trackedEntities])

    useEffect(() => {
      subscribe('program.report', fetchTrackedEntities)
      subscribe('program.stateChange', fetchTrackedEntities)

      return () => {
        unsubscribe('program.report', fetchTrackedEntities)
        unsubscribe('program.stateChange', fetchTrackedEntities)
      }
    }, [])

    return {
      trackedEntities,
      runningEntities,
      fetchTrackedEntities,
      getRelatedProgramNames,
      getProgramDisabledReason,
      isInRunningState,
    }
  } catch (error) {
    Sentry.captureException(error)
  }
}
