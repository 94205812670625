import classNames from 'classnames'
import { clamp, find, get, includes, multiply, replace, round, times } from 'lodash-es'
import React, { useMemo } from 'react'
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar'
import { useSetRecoilState } from 'recoil'
import { twMerge } from 'tailwind-merge'

import ActionIcon from '@/Components/ActionIcon'
import Card from '@/Components/common/Card'
import HorizontalProgressBar from '@/Components/common/progressbars/HorizontalProgressBar'
import VerticalProgressBar from '@/Components/common/progressbars/VerticalProgressBar'
import DropdownList from '@/Components/DropdownList'
import { modalState, pageAlertState } from '@/Config/Atoms/General'
import { iconClasses } from '@/Utilities/Icons/InputOutputIcons'
import { abbreviationMap } from '@/Utilities/Units'
import useAuth from '@/Utilities/useAuth'

export default function UserDefinedCard({
  site,
  card,
  updateDashboard,
  programActiveStatus,
  programStatusColour,
  reportingStat,
}) {
  const setAlert = useSetRecoilState(pageAlertState)
  const setModal = useSetRecoilState(modalState)
  const auth = useAuth()

  const inputOutput = useMemo(() => {
    if (card && site) {
      const inputOutput = find(site.inputOutputs, { id: card.inputOutputId })

      return inputOutput
    }

    return []
  }, [site, card])

  const depths = useMemo(() => {
    if (get(inputOutput, 'type', false) !== 'sensorSoilProbe') {

      return false
    }

    return get(inputOutput, 'details.manufacturerModel.configuration.depths')
  }, [inputOutput])

  const [
    stateChangeOnly,
    inputOutputType,
    percentValue,
    reportingKey,
    unitOfMeasurement,
  ] = useMemo(() => {
    if (!inputOutput) {
      return []
    }

    const inputOutputType = replace(inputOutput.detailsType, 'inputOutput.', '')

    if (
      includes(inputOutput.detailsType, 'digitalOutput') ||
      includes(inputOutput.detailsType, 'virtualDigitalOutput') ||
      includes(inputOutput.detailsType, 'digitalInput')
    ) {
      return [
        true,
        inputOutputType,
        null,
        null,
        null,
      ]
    } else if (includes(inputOutput.detailsType, 'sensorSoilProbe')) {
      const percentage = get(reportingStat, 'moisture', [])
      const reportingKey = 'moisture'
      const unitOfMeasurement = '%'

      return [
        false,
        inputOutputType,
        percentage,
        reportingKey,
        unitOfMeasurement,
      ]
    } else if (includes(inputOutput.detailsType, 'analogInput') || includes(inputOutput.detailsType, 'analogOutput')) {
      const pulseMax = multiply(inputOutput.details.scaleMax, 1.5)
      const percentage = clamp(get(reportingStat, 'changeOfState', 0) / pulseMax * 100, 0, 100)
      const reportingKey = 'Value'
      const unitOfMeasurement = abbreviationMap[get(inputOutput, 'details.scaleUnitOfMeasurement', 'galPerMin')]

      return [
        false,
        inputOutputType,
        percentage,
        reportingKey,
        unitOfMeasurement,
      ]
    } else if (includes(inputOutput.detailsType, 'analogFlowMeter')) {
      const scaleMax = multiply(inputOutput.details.scaleMax, 1.5)
      const percentage = clamp(get(reportingStat, 'flow', 0) / scaleMax * 100, 0, 100)
      const reportingKey = 'Flow'
      const unitOfMeasurement = abbreviationMap[get(inputOutput, 'details.scaleUnitOfMeasurement', 'galPerMin')]

      return [
        false,
        inputOutputType,
        percentage,
        reportingKey,
        unitOfMeasurement,
      ]
    } else if (includes(inputOutput.detailsType, 'digitalFlowMeter')) {
      const pulseMax = multiply(inputOutput.details.pulseValue, 1.5)
      const percentage = clamp(get(reportingStat, 'flow', 0) / pulseMax * 100, 0, 100)
      const reportingKey = 'Flow'
      const unitOfMeasurement = abbreviationMap[get(inputOutput, 'details.pulseValueUnitOfMeasurement', 'galPerMin')]

      return [
        false,
        inputOutputType,
        percentage,
        reportingKey,
        unitOfMeasurement,
      ]
    }
  }, [inputOutput, reportingStat])

  const reportingStatTotal = useMemo(() => {
    return round(get(reportingStat, 'total', 0) / 1000, 2)
  }, [reportingStat])

  return (
    <Card className="h-full">
      <Card.Header
        title={card.name}
        className="flex items-center justify-between gap-1 p-3.5"
      >
        {auth.can('update-site') ? (
          <div className="text-right">
            <DropdownList
              icon={<ActionIcon />}
              options={[{
                label: 'Edit card',
                onClick: () => {
                  setModal({
                    name: 'card',
                    data: {
                      site: site,
                      siteCard: card,
                      isEditing: true,
                      onSave: () => {
                        updateDashboard()
                      },
                    },
                  })
                },
              }, {
                label: 'Delete card',
                topLine: true,
                onClick: () => {
                  setModal({
                    name: 'warning',
                    data: {
                      endpoint: `/site-card/delete/${card.id}`,
                      title: `Delete ${card.name}`,
                      content: `Are you sure you want to delete ${card.name}?`,
                      successFlashMessage: `Successfully deleted ${card.name}.`,
                      onComplete: () => {
                        setModal(null)
                        updateDashboard()
                      },
                      close: () => {
                        setModal(null)
                      },
                      onFailure: () => {
                        setModal(null)
                        setAlert({
                          type: 'error',
                          content: 'We were unable to process your request. Please try again.',
                        })
                      },
                    },
                  })
                },
              }]}
            />
          </div>
        ) : ''}
      </Card.Header>

      <Card.Body className="my-auto flex flex-col justify-start p-3.5">
        <div className="text-sm">
          <span className="mr-1 text-gray-500">I/O:</span>
          <span>{get(inputOutput, 'name', '-')}</span>
        </div>

        {reportingStatTotal ? (
          <div className="text-sm">
            <span className="mr-1 text-gray-500">Total:</span>
            <span>
              {reportingStatTotal ? (
                <>
                  {reportingStatTotal}
                  <span className="text-xs text-slate-700">
                    (kgal)
                  </span>
                </>
              ) : '-'}
            </span>
          </div>
        ) : ''}

        {!stateChangeOnly && inputOutputType !== 'sensorSoilProbe' && (
          <div className="flex grow flex-wrap items-center justify-center gap-4">
            {(card.gaugeType === 'fullCircle' || card.gaugeType === 'halfCircle') && (
              <div className={classNames('w-40', { 'mb-12 translate-y-12': card.gaugeType === 'halfCircle' })}>
                <CircularProgressbarWithChildren
                  value={percentValue ? percentValue : 0}
                  strokeWidth="10"
                  circleRatio={card.gaugeType === 'fullCircle' ? 1 : 0.5}
                  styles={buildStyles({
                    rotation: -0.25,
                    pathColor: programStatusColour.pathColor,
                    trailColor: programStatusColour.trailColor,
                  })}
                >
                  <div className="text-xs text-gray-500">{reportingKey}</div>
                  <div className="text-xl font-medium">
                    {(reportingStat?.changeOfState || reportingStat?.flow) ? round(reportingStat?.changeOfState || reportingStat?.flow, 2) : '-'} {unitOfMeasurement}
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            )}

            {card.gaugeType === 'horizontalLine' && (
              <div className="my-10 w-40 text-center">
                <HorizontalProgressBar
                  className="h-3 w-44"
                  colorName={programActiveStatus !== 'none' ? `status-${programActiveStatus}` : 'status-stopped'}
                  percent={percentValue}
                />

                <div className="mt-3 text-xs text-gray-500">{reportingKey}</div>
                <div className="text-xl font-medium">
                  {reportingStat?.flow ? round(reportingStat.flow, 2) : '-'} {unitOfMeasurement}
                </div>
              </div>
            )}

            {card.gaugeType === 'verticalLine' && (
              <div className="my-5 flex gap-4">
                <VerticalProgressBar colorName={programActiveStatus !== 'none' ? `status-${programActiveStatus}` : 'status-stopped'} percent={percentValue} />

                <div className="flex flex-col justify-center text-center">
                  <div className="text-xs text-gray-500">{reportingKey}</div>
                  <div className="text-xl font-medium">
                    {reportingStat?.flow ? round(reportingStat.flow, 2) : '-'} {unitOfMeasurement}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {(!stateChangeOnly && inputOutputType === 'sensorSoilProbe') && (
          <>
            {card.gaugeType === 'horizontalLine' && (
              <div className="flex grow flex-col items-center justify-center gap-1">
                {times(depths, (index) => {
                  return (
                    <div className="flex items-center text-center" key={index}>
                      <HorizontalProgressBar
                        className="h-3 w-40"
                        colorName={programActiveStatus !== 'none' ? `status-${programActiveStatus}` : 'status-stopped'}
                        percent={percentValue ? round(percentValue[index]) : 0}
                      />

                      <div className="ml-3 max-h-8">
                        <div className="flex items-center text-xs text-gray-500">
                          <span>Level</span>
                          <span className="ml-1">{index + 1}</span>
                        </div>

                        <div className="flex text-sm font-medium">
                          {!isNaN(percentValue[index]) ? round(percentValue[index], 2) : '-'}{unitOfMeasurement}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            )}

            {card.gaugeType === 'verticalLine' && (
              <div className="flex flex-wrap justify-center gap-4">
                {times(depths, (index) => {
                  return (
                    <div className="my-5 flex flex-col flex-wrap items-center gap-4" key={index}>
                      <VerticalProgressBar
                        colorName={programActiveStatus !== 'none' ? `status-${programActiveStatus}` : 'status-stopped'}
                        percent={percentValue ? round(percentValue[index]) : 0}
                      />

                      <div>
                        <div className="flex flex-col items-center text-xs text-gray-500">
                          <span>Level</span>
                          <span className="ml-1">{index + 1}</span>
                        </div>

                        <div className="flex whitespace-nowrap text-sm font-medium">
                          {!isNaN(percentValue[index]) ? round(percentValue[index], 2) : '-'}{unitOfMeasurement}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </>
        )}

        {stateChangeOnly && (
          <div className="flex grow flex-wrap items-center justify-center gap-4">
            <div
              className={twMerge(classNames(
                'my-10 inline-block cursor-pointer text-center opacity-80 text-slate-500',
                { 'text-status-running': inputOutput.changeOfState ? reportingStat.changeOfState : programActiveStatus === 'running' },
              ))}
            >
              <i className={classNames(iconClasses[inputOutputType], 'text-6xl')}></i>
            </div>
          </div>
        )}
      </Card.Body>
    </Card>
  )
}
