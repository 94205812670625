import Tippy from '@tippyjs/react'
import classNames from 'classnames'
import { map } from 'lodash-es'
import { useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

function Tabs(props) {
  const [selectedTab, setSelectedTab] = useState(null)
  const firstLoad = useRef(true)
  const {
    className,
    currentlySelected,
    defaultTab,
    disabled,
    onChange,
    tabs,
    tooltipMessage,
  } = props

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false

      if (defaultTab) {
        setSelectedTab(defaultTab)
      }
    }
  }, [setSelectedTab, defaultTab])

  useEffect(() => {
    onChange(selectedTab)
  }, [selectedTab])

  useEffect(() => {
    if (currentlySelected && selectedTab && currentlySelected !== selectedTab) {
      setSelectedTab(currentlySelected)
    }
  }, [currentlySelected, setSelectedTab])

  return (
    tabs ?
      <div className={twMerge('bg-white rounded outline outline-gray-300 outline-1 border-gray-300 inline-flex flex-wrap overflow-hidden select-none sm:inline-flex sm:flex-nowrap p-1', className)}>
        {map(tabs, (tab, index) => {
          return (
            <Tippy content={tooltipMessage} disabled={tooltipMessage ? (selectedTab === tab.key || false) : true} zIndex="99999" theme="light" key={tab.key}>
              <div
                key={index}
                className={twMerge(classNames(
                  'hover:bg-gray-200 hover:cursor-pointer whitespace-nowrap inline-flex px-4 items-center rounded first-of-type:ml-0 ml-0.5 h-9 max-h-full text-sm',
                  {
                    'text-white bg-dark shadow-md hover:bg-dark': selectedTab === tab.key,
                    'hover:cursor-not-allowed opacity-40': disabled && selectedTab !== tab.key,
                    'bg-gray-100': disabled,
                    'bg-red-50 hover:bg-red-100 border-b-2 border-red-500 text-red-500 first:rounded-l last:round-r': tab.hasError,
                    'text-red-700 bg-red-100': tab.hasError && selectedTab === tab.key,
                  },
                ))}
                onClick={() => {
                  if (!disabled) {
                    setSelectedTab(tab.key)
                  }
                }}
              >
                {tab.title}
              </div>
            </Tippy>
          )
        })}
      </div> : ''
  )
}

export default Tabs
