import { rem } from 'polished'
import styled from 'styled-components'

import { RouterButton } from '@/Components/form/Buttons'
import useTitle from '@/Utilities/useTitle'

const CenterContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 113px);
  width: 100%;
`

const Content = styled.div`
  width: auto;
  text-align: center;
`

const Heading = styled.h1`
  font-size: ${rem(60)};
`

const SubHeading = styled.p`
  font-size: ${rem(20)};
  color: #667085;
  margin: 25px 0 35px 0;
`

function NoMatch() {
  useTitle('Page not found')

  return (
    <CenterContent>
      <Content>
        <Heading>
          Page not found
        </Heading>

        <SubHeading>
          The page you are looking for doesn't exist.
        </SubHeading>

        <RouterButton href="/">
          Take me home
        </RouterButton>
      </Content>
    </CenterContent>
  )
}

export default NoMatch
