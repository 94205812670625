import classNames from 'classnames'
import { isEmpty, join, map, padStart, size, startCase, toLower, toUpper, upperFirst, words } from 'lodash-es'
import { rem } from 'polished'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import styled from 'styled-components'

import ActionIcon from '@/Components/ActionIcon'
import DropdownList from '@/Components/DropdownList'
import { modalState } from '@/Config/Atoms/General'
import { getByPath } from '@/Utilities/Accessors/Helpers'
import { publish } from '@/Utilities/Events'
import useEntityMonitor from '@/Utilities/useEntityMonitor'

const Card = styled.div`
  border: 1px solid #E4E7EC;
  border-radius: 5px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  width: 100%;
  background: #ffffff;
`

const CardTitle = styled.div`
  align-items: center;
  border-bottom: 1px solid #E4E7EC;
  color: var(--heading-color);
  display: flex;
  justify-content: space-between;
  font-size: ${rem(18)};
  padding: 14px 24px;

  .more-actions {
    align-items: center;
    display: flex;
    position: relative;

    svg {
      &:hover {
        cursor: pointer;
      }
    }
  }
`

const CardRow = styled.div`
  display: flex;
  font-size: ${rem(14)};
  justify-content: space-between;
  padding: 6px 24px;
`

const CardDetails = styled.div`
  margin: 15px 0;
`

const CardRowTitle = styled.div`
  align-items: center;
  color: var(--heading-color);
  display: flex;
  font-size: ${rem(14)};
  flex-basis: 50%;
`

const CardRowContent = styled.div`
  color: var(--text-light);
  display: flex;
  font-size: ${rem(14)};
  flex-basis: 50%;
`

const SiteName = styled.div`
  text-transform: capitalize;
`

function SiteManageDetailsCard(props) {
  const navigate = useNavigate()
  const setModal = useSetRecoilState(modalState)
  const {
    site,
    manageCoordinates,
    manageBlocks,
  } = props

  const {
    isInRunningState,
    getRelatedProgramNames,
  } = useEntityMonitor()

  const [
    relatedProgramSets,
    relatedPrograms,
    relatedMainLines,
    relatedInputOutputs,
  ] = useMemo(() => {
    if (isEmpty(site)) {
      return []
    }

    const programSets = getByPath(site, 'programSets')
    const programs = getByPath(site, 'programSets.*.programs')
    const mainLines = getByPath(site, 'mainLines')
    const inputOutputs = getByPath(site, 'inputOutputs')

    return [
      {
        count: programSets.length ? programSets.length : 0,
        names: programSets.length ? map(programSets, 'name') : ['N/A'],
      },
      {
        count: programs.length ? programs.length : 0,
        names: programs.length ? map(programs, 'name') : ['N/A'],
      },
      {
        count: mainLines.length ? mainLines.length : 0,
        names: mainLines.length ? map(mainLines, 'name') : ['N/A'],
      },
      {
        count: inputOutputs.length ? inputOutputs.length : 0,
        names: inputOutputs.length ? map(inputOutputs, 'name') : ['N/A'],
      },
    ]
  }, [site])

  // TODO: useCallback or useMemo as appropriate.
  const mainCardOptions = () => {
    const preventSaving = isInRunningState('site', site?.id)
    const runningProgramNames = getRelatedProgramNames('site', site?.id)

    if (manageCoordinates) {
      return [{
        label: 'Stop managing coordinates',
        onClick: () => {
          navigate(`/site/manage/${site.id}`)
        },
      }]
    }

    if (manageBlocks) {
      return [{
        label: 'Stop managing blocks',
        onClick: () => {
          navigate(`/site/manage/${site.id}`)
        },
      }, {
        label: 'Show help dialog',
        topLine: true,
        onClick: () => {
          publish('map.drawingDialog.reset')
        },
      }]
    }

    return [
      {
        label: 'Manage coordinates',
        onClick: () => {
          navigate(`/site/manage/${site.id}/coordinates`)
        },
      },
      {
        label: 'Manage blocks',
        onClick: () => {
          navigate(`/site/manage/${site.id}/blocks`)
        },
      },
      {
        label: 'Edit site',
        disabledWithReason: preventSaving && runningProgramNames,
        topLine: true,
        onClick: () => {
          setModal({
            name: 'site',
            data: {
              site: site,
              areaId: site.areaId,
              isEditing: true,
            },
          })
        },
      },
      {
        label: 'Delete site',
        disabledWithReason: preventSaving && runningProgramNames,
        onClick: () => {
          setModal({
            name: 'warning',
            data: {
              endpoint: `/site/delete/${site.id}`,
              title: 'Delete site',
              content: site.programSets.length ? `${site.name} has ${site.programSets.length} associated program${site.programSets.length > 1 ? 's' : ''}. Please delete the associated program${site.programSets.length > 1 ? 's' : ''} before deleting the site.` : `Are you sure you want to delete ${site.name}? This action cannot be undone.`,
              successFlashMessage: 'Site deleted successfully.',
              redirect: '/sites',
              deleteConfirmation: true,
              affectedRelationships: {
                'Program Sets': relatedProgramSets,
                'Programs': relatedPrograms,
                'Main Lines': relatedMainLines,
                'I/O\'s': relatedInputOutputs,
              },
              savePreventionState: {
                model: 'site',
                id: site.id,
              },
            },
          })
        },
      },
    ]
  }

  return (
    <>
      <Card>
        <CardTitle>
          <SiteName>
            {site.name}
          </SiteName>

          <div className="more-actions">
            <DropdownList
              icon={<ActionIcon />}
              options={mainCardOptions()}
            />
          </div>
        </CardTitle>

        <CardDetails>
          {site.description ? (
            <CardRow>
              <CardRowTitle>Description</CardRowTitle>
              <CardRowContent>{site.description}</CardRowContent>
            </CardRow>
          ) : null}

          <CardRow>
            <CardRowTitle>Site ID</CardRowTitle>
            <CardRowContent>{site.rtuId}</CardRowContent>
          </CardRow>

          <CardRow>
            <CardRowTitle>Communication Type</CardRowTitle>
            <CardRowContent>
              {upperFirst(toLower(site.linkType))}
            </CardRowContent>
          </CardRow>

          {site.linkType === 'LINE' && (
            <CardRow>
              <CardRowTitle>Line Link ID</CardRowTitle>
              <CardRowContent>{site.lineLinkId}</CardRowContent>
            </CardRow>
          )}

          {site.linkType === 'RADIO' && (
            <CardRow>
              <CardRowTitle>Radio Zone</CardRowTitle>
              <CardRowContent>{site.radioZone}</CardRowContent>
            </CardRow>
          )}

          {site.linkType === 'RADIO' && (
            <CardRow>
              <CardRowTitle>Radio Link ID</CardRowTitle>
              <CardRowContent>{site.radioLinkId}</CardRowContent>
            </CardRow>
          )}

          {(props.coordinates?.lat && props.coordinates?.lng) && (
            <CardRow>
              <CardRowTitle>GPS Coordinates</CardRowTitle>
              <CardRowContent>{props.coordinates?.lat}, {props.coordinates?.lng}</CardRowContent>
            </CardRow>
          )}
        </CardDetails>
      </Card>

      {!manageBlocks && !manageCoordinates && !isEmpty(site.modules) && (
        <Card className="mt-6">
          <CardTitle>
            <SiteName>
              Module Information
            </SiteName>
          </CardTitle>

          <div className="py-2">
            {map(site.modules, (module, index) => {
              return (
                <div className="px-4 pt-3">
                  <div className={classNames({ 'border-b': size(site.modules) !== index + 1 }, 'w-full px-3 pb-3 basis-full')}>
                    <div className="font-light">
                      <span className="mb-1 inline-block w-full font-medium">Module Slot {index + 1}</span>

                      {module.modelName && (
                        <>
                          <div
                            title={`Model: ${module.modelName}`}
                            className="w-full overflow-hidden text-ellipsis whitespace-nowrap text-sm"
                          >
                            <span className="font-normal">
                              Name
                            </span>

                            <span className="mx-2 text-slate-300">/</span>

                            <span className="text-slate-600">
                              {module.modelName}
                            </span>
                          </div>

                          <div
                            title={`Model Number: ${module.modelNumber}`}
                            className="inline-block w-full overflow-hidden text-ellipsis whitespace-nowrap text-sm"
                          >
                            <span className="font-normal">
                              Number
                            </span>

                            <span className="mx-2 text-slate-300">/</span>

                            <span className="text-slate-600">
                              {module.modelNumber}
                            </span>
                          </div>
                        </>
                      ) || (
                        <span className="text-slate-600">
                            Empty
                        </span>
                      )}
                    </div>

                    <ul className="mt-1 text-sm font-light">
                      {map(module.range, (range) => {
                        const titleCaseType = startCase(range.type)
                        const letterIntials = map(words(range.type), (word) => {
                          return toUpper(word[0])
                        })
                        const typeInitials = join(letterIntials, '')

                        return (
                          <li className="py-0.5">
                            <span className="font-normal">
                              Channels {padStart(range.start, 2, '0')} - {padStart(range.stop, 2, '0')}
                            </span>

                            <span className="mx-2 text-slate-300">/</span>

                            <span className="text-slate-600">
                              {titleCaseType} ({typeInitials})
                            </span>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
              )
            })}
          </div>
        </Card>
      )}
    </>
  )
}

export default SiteManageDetailsCard
