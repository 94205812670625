import { Control, Draw } from 'leaflet'
import { debounce, each, find, map } from 'lodash-es'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import styled from 'styled-components'
import { v4 } from 'uuid'

import { Anchor } from '@/Components/form/Buttons'
import Input from '@/Components/form/Input'
import InstructionBox from '@/Components/InstructionBox'
import { isLoadingState, modalState, pageAlertState } from '@/Config/Atoms/General'
import { formatKeys } from '@/Utilities/Form/Formatter'
import { addBlock, addBlocks } from '@/Utilities/Map/Map'
import useApiClient from '@/Utilities/useApiClient'
import useTitle from '@/Utilities/useTitle'

const colors = { blue: '#1570EF' }

const ActionContainer = styled.div`
  margin-top: 20px;
`

function SiteManageBlocks(props) {
  const {
    mapInstance,
    site,
    getSite,
    existingBlocks,
  } = props

  const apiClient = useApiClient()
  const [activeBlock, setActiveBlock] = useState(null)
  const actionActive = useRef(false)
  const drawControl = useRef(null)
  const setAlert = useSetRecoilState(pageAlertState)
  const navigate = useNavigate()
  const setModal = useSetRecoilState(modalState)
  const setIsLoading = useSetRecoilState(isLoadingState)

  useTitle(['Managing site blocks', site?.name])

  const getLayer = useCallback((uuid) => {
    return find(mapInstance.current.groups.drawings.getLayers(), (layer) => {
      return layer.feature.properties.uuid === uuid
    })
  }, [mapInstance])

  const resetStyles = useCallback(() => {
    each(mapInstance.current.groups.drawings.getLayers(), (layer) => {
      layer.setStyle({ fillOpacity: 0.2 })
    })
  }, [mapInstance])

  const activateBlock = useCallback((uuid) => {
    if (actionActive.current) {
      return false
    }

    resetStyles()

    const existingLayer = getLayer(uuid)

    if (!existingLayer) {
      setActiveBlock(null)
      return false
    }

    existingLayer.setStyle({
      fillColor: colors.blue,
      color: colors.blue,
      fillOpacity: 0.6,
    })

    const properties = existingLayer?.feature?.properties

    if (!properties.uuid) {
      return false
    }

    setActiveBlock({
      uuid: properties.uuid,
      title: properties.title || '',
    })
  }, [getLayer, resetStyles])

  const addLayer = useCallback((block) => {
    addBlock(block, mapInstance.current.groups.drawings, (event) => {
      activateBlock(event.sourceTarget.feature.properties.uuid)
    })

    activateBlock(block.properties.uuid)
  }, [mapInstance])

  const drawingCreated = useCallback((event) => {
    let uuid = v4()
    let geoJson = event.layer.toGeoJSON()

    geoJson.properties.uuid = uuid
    geoJson.properties.title = 'New Block'

    addLayer(geoJson)
  }, [])

  const drawingDeleted = useCallback(() => {
    activateBlock(activeBlock?.uuid || null)
  }, [activeBlock])

  const centerTooltips = useCallback(() => {
    each(mapInstance.current.groups.drawings.getLayers(), (layer) => {
      if (layer?.feature?.properties?.title) {
        layer.openTooltip()
      }
    })
  }, [mapInstance])

  const actionStart = useCallback(() => {
    resetStyles()
    setActiveBlock(null)
    actionActive.current = true
    centerTooltips()
  }, [actionActive])

  const actionStop = useCallback(() => {
    actionActive.current = false
    centerTooltips()
  }, [actionActive])

  const registerEvents = useCallback(() => {
    const map = mapInstance.current.map

    map.on(Draw.Event.CREATED, drawingCreated)
    map.on(Draw.Event.DELETED, drawingDeleted)
    map.on(Draw.Event.EDITSTART, actionStart)
    map.on(Draw.Event.EDITSTOP, actionStop)
    map.on(Draw.Event.DELETESTART, actionStart)
    map.on(Draw.Event.DELETESTOP, actionStop)
  }, [mapInstance])

  const deregisterEvents = useCallback(() => {
    const map = mapInstance.current.map

    map.off(Draw.Event.CREATED, drawingCreated)
    map.off(Draw.Event.DELETED, drawingDeleted)
    map.off(Draw.Event.EDITSTART, actionStart)
    map.off(Draw.Event.EDITSTOP, actionStop)
    map.off(Draw.Event.DELETESTART, actionStart)
    map.off(Draw.Event.DELETESTOP, actionStop)
  }, [mapInstance])

  const saveBlocks = async () => {
    let hasMissingTitle = false
    const geoJson = mapInstance.current.groups.drawings.toGeoJSON()
    const blocks = map(geoJson.features, (feature) => {
      if (!feature.properties?.title) {
        hasMissingTitle = true
      }

      let id = feature.properties?.id
      delete feature.properties.id

      return {
        id: id,
        title: feature.properties?.title,
        geoJson: feature,
      }
    })

    if (hasMissingTitle) {
      setModal(null)
      setAlert({
        type: 'error',
        content: 'Please ensure all fields have a title and try again.',
      })

      return false
    }

    try {
      setIsLoading(true)

      let { data } = await apiClient.post(`/site/update/${site.id}`, { blocks: formatKeys(blocks, 'snake') })

      if (data.success) {
        await getSite()

        setModal(null)
        setAlert({
          type: 'success',
          content: 'Blocks saved successfully.',
        })

        navigate(`/site/manage/${site.id}`)
      }
    } catch (error) {
      setModal(null)
      setAlert({
        type: 'error',
        content: 'An error has occured while saving. Please try again.',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const blockTitleChange = debounce((event) => {
    let value = event.target.value

    setActiveBlock({
      ...activeBlock,
      title: value,
    })

    const layer = getLayer(activeBlock.uuid)

    if (value) {
      layer.setTooltipContent(value).openTooltip()
    } else {
      layer.closeTooltip()
    }

    let geoJson = layer.toGeoJSON()
    let newGeoJson = {
      ...geoJson,
      properties: {
        ...geoJson.properties,
        title: value,
      },
    }

    mapInstance.current.groups.drawings.removeLayer(layer)
    addBlock(newGeoJson, mapInstance.current.groups.drawings, (event) => {
      activateBlock(event.sourceTarget.feature.properties.uuid)
    })

    activateBlock(newGeoJson.properties.uuid)
  }, 100, {
    leading: true,
    trailing: false,
  })

  useEffect(() => {
    const currentMapInstance = mapInstance.current

    registerEvents()

    drawControl.current = new Control.Draw({
      edit: {
        featureGroup: currentMapInstance.groups.drawings,
        poly: { allowIntersection: false },
        edit: {
          selectedPathOptions: {
            color: colors.blue,
            fillColor: colors.blue,
            fillOpacity: 0.2,
          },
        },
      },
      draw: {
        circle: false,
        circlemarker: false,
        marker: false,
        polyline: false,
        rectangle: false,
        polygon: {
          allowIntersection: false,
          showArea: false,
        },
      },
    })

    currentMapInstance.map.addControl(drawControl.current)

    let blocks = map(existingBlocks, (block) => {
      let geoJson = block.geoJson

      return {
        ...geoJson,
        properties: {
          ...geoJson.properties,
          id: block.id,
        },
      }
    })

    const blocksResult = addBlocks({
      blocks: blocks,
      drawingLayer: mapInstance.current.groups.drawings,
      clearLayer: true,
      withBounds: true,
      onClick: (event) => {
        activateBlock(event.sourceTarget.feature.properties.uuid)
      },
    })

    if (blocksResult.bounds && blocksResult.bounds.isValid()) {
      mapInstance.current.map.fitBounds(blocksResult.bounds)
    }

    return () => {
      deregisterEvents()

      currentMapInstance.groups.drawings.clearLayers()
      currentMapInstance.map.removeControl(drawControl.current)
    }
  }, [])

  return (
    <div className="row">
      <div className="col-12">
        <InstructionBox heading={!activeBlock ? 'Draw your irrigation block' : ''}>
          {activeBlock ? (
            <Input
              label="Block name"
              name="block"
              value={activeBlock?.title}
              onChange={(event) => {
                blockTitleChange(event)
              }}
              background="#ffffff"
            />
          ) : (
            <div>
              Tap the map to start drawing your block.
            </div>
          )}
        </InstructionBox>
      </div>

      <ActionContainer className="col-12">
        <div className="row g-2">
          <div className="col-12 col-md-6">
            <Anchor
              className="cancel"
              style={{ width: '100%' }}
              onClick={() => {
                if (actionActive.current) {
                  return false
                }

                navigate(`/site/manage/${site.id}`)
              }}
            >
              Cancel
            </Anchor>
          </div>

          <div className="col-12 col-md-6">
            <Anchor
              style={{ width: '100%' }}
              onClick={() => {
                if (actionActive.current) {
                  return false
                }

                setModal({
                  name: 'confirmation',
                  data: {
                    title: 'Save blocks',
                    content: 'Are you sure you would like to save changes to the blocks? ',
                    onConfirm: () => {
                      saveBlocks()
                    },
                  },
                })
              }}
            >
              Save
            </Anchor>
          </div>
        </div>
      </ActionContainer>
    </div>
  )
}

export default SiteManageBlocks
