import { find, isEmpty, toNumber } from 'lodash-es'
import React, { useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useNavigate, useParams } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'

import ActionIcon from '@/Components/ActionIcon'
import DropdownList from '@/Components/DropdownList'
import { Anchor } from '@/Components/form/Buttons'
import SelectField from '@/Components/form/Select'
import { modalState } from '@/Config/Atoms/General'

function TopBar(props) {
  const {
    getDashboardOptions,
    getActiveDashboard,
    reportingDashboardOptions,
    activeDashboard,
    reportingDashboardOptionsLoading,
    setLastViewedDashboardId,
  } = props

  const setModal = useSetRecoilState(modalState)
  const navigate = useNavigate()
  const urlParams = useParams()

  const title = useMemo(() => {
    if (!reportingDashboardOptionsLoading && isEmpty(reportingDashboardOptions)) {
      return 'Create a dashboard'
    }

    if (!isEmpty(reportingDashboardOptions) && !isEmpty(activeDashboard)) {
      return activeDashboard.name
    }

    return (
      <div className="w-full min-w-28">
        <Skeleton />
      </div>
    )
  }, [
    reportingDashboardOptions,
    activeDashboard,
    reportingDashboardOptionsLoading,
  ])

  return (
    <div className="flex flex-col rounded border bg-white p-3.5 shadow @md:flex-row @md:items-center">
      <div className="flex grow justify-between @md:justify-start">
        <div className="inline-flex pr-4">
          { title }
        </div>

        {!reportingDashboardOptionsLoading && activeDashboard && (
          <DropdownList
            icon={<ActionIcon />}
            wrapperStyle={{ display: 'inline-flex' }}
            style={{
              minWidth: '200px',
              textAlign: 'left',
              transform: 'translate(calc(100% - 30px), 5px)',
            }}
            options={!isEmpty(reportingDashboardOptions) ? [
              {
                label: 'Edit dashboard',
                onClick: () => {
                  setModal({
                    name: 'reportingDashboard',
                    data: {
                      reportingDashboard: activeDashboard,
                      isEditing: true,
                      onSave: async () => {
                        await getDashboardOptions()
                        await getActiveDashboard(activeDashboard.id)
                        setModal(null)
                      },
                    },
                  })
                },
              },
              {
                label: 'Delete dashboard',
                onClick: () => {
                  setModal({
                    name: 'warning',
                    data: {
                      title: 'Delete dashboard',
                      content: `Please note that ${activeDashboard.name} and all associated chart data will be permanently removed from the system.`,
                      endpoint: `/reporting-dashboard/delete/${activeDashboard.id}`,
                      successFlashMessage: `${activeDashboard.name} deleted successfully.`,
                      onComplete: async () => {
                        await getDashboardOptions()
                        setLastViewedDashboardId(null)
                        setModal(null)
                        navigate('/reporting')
                      },
                    },
                  })
                },
              },
              {
                label: 'Add dashboard',
                topLine: true,
                onClick: () => {
                  setModal({
                    name: 'reportingDashboard',
                    data: {
                      onSave: async (reportingDashboardId) => {
                        await getDashboardOptions()
                        setModal(null)
                        navigate(`/reporting/${reportingDashboardId}`)
                      },
                    },
                  })
                },
              },
            ] : [{
              label: 'Add dashboard',
              onClick: () => {
                setModal({
                  name: 'reportingDashboard',
                  data: {
                    onSave: async (reportingDashboardId) => {
                      await getDashboardOptions()
                      setModal(null)
                      navigate(`/reporting/${reportingDashboardId}`)
                    },
                  },
                })
              },
            }]}
          />
        )}
      </div>

      <div className="mt-5 flex w-full justify-between @md:mt-0 @md:w-96 @md:justify-end">
        <SelectField
          autoFocus={true}
          isSearchable={true}
          isLoading={reportingDashboardOptionsLoading}
          display="inline-flex"
          className="mr-3"
          placeholder="Search"
          options={reportingDashboardOptions}
          value={find(reportingDashboardOptions, ['value', toNumber(urlParams?.id)]) || ''}
          onChange={(option) => {
            navigate(`/reporting/${option.value}`)
          }}
        />

        {!isEmpty(reportingDashboardOptions) && (
          <Anchor
            onClick={() => {
              setModal({
                name: 'reportingFilter',
                data: { activeDashboard: activeDashboard },
              })
            }}
          >
            <div className="flex items-center "><i className="fa-regular fa-bars-filter mr-3"></i> Filter</div>
          </Anchor>
        )}
      </div>
    </div>
  )
}

export default TopBar
