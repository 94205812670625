import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'

export default function CurrentDateTime({
  initialTime,
  timezone,
  showAbbreviation = true,
}) {
  // State to hold the current dateTime
  const [dateTime, setDateTime] = useState(moment.tz(initialTime, timezone))

  // Effect hook to set up the interval timer
  useEffect(() => {
    // Function to update the state with the current dateTime
    const updateCurrentTime = () => {
      setDateTime(moment.tz(timezone))
    }

    // Set up an interval to update the dateTime every second
    const intervalId = setInterval(updateCurrentTime, 1000)

    // Clean up the interval on component unmount
    return () => {
      return clearInterval(intervalId)
    }
  }, [timezone])

  // Render the current date and dateTime in a readable format
  return (
    <>
      <span>{dateTime.format('MM-DD-YYYY')}</span>
      <span className="mx-1">/</span>
      <span>{dateTime.format('HH:mm:ss')}</span>
      {showAbbreviation && (
        <span className="ml-1">{dateTime.format('z')}</span>
      )}
    </>
  )
}
