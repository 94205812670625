import { useSetRecoilState } from 'recoil'

import PermissionGuard from '@/Components/auth/PermissionGuard'
import { Anchor, RoundedIconButton } from '@/Components/form/Buttons'
import PageContainer from '@/Components/layout/helpers/PageContainer'
import ConditionsAll from '@/Components/tables/ConditionsAll'
import { modalState } from '@/Config/Atoms/General'
import useAuth from '@/Utilities/useAuth'
import usePageGuard from '@/Utilities/usePageGuard'
import useTitle from '@/Utilities/useTitle'

function ConditionIndex() {
  const auth = useAuth()
  const setModal = useSetRecoilState(modalState)

  usePageGuard({
    auth,
    permission: 'view-condition',
  })

  useTitle('All conditions')

  const showConditionModal = () => {
    setModal({ name: 'condition' })
  }

  return (
    <PageContainer contained>
      <div className="mb-5 grid grid-cols-2-right-auto text-3xl font-light text-slate-900">
        <div>
          All <span className="font-medium">conditions</span>
        </div>

        <div>
          <PermissionGuard permission="create-condition" auth={auth}>
            <div className="text-right">
              <div className="hidden justify-items-end md:block">
                <Anchor
                  style={{ minWidth: '200px' }}
                  onClick={showConditionModal}
                >
                  Add condition
                </Anchor>
              </div>
              <div className="md:hidden">
                <RoundedIconButton
                  icon="plus"
                  onClick={showConditionModal}
                />
              </div>
            </div>
          </PermissionGuard>
        </div>
      </div>

      <PermissionGuard permission="view-condition" auth={auth}>
        <ConditionsAll/>
      </PermissionGuard>
    </PageContainer>
  )
}

export default ConditionIndex
