import { debounce, isFunction } from 'lodash-es'
import { rem } from 'polished'
import { useEffect, useRef } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import styled from 'styled-components'

import { publish } from '@/Utilities/Events'

const ModalOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  height: 100vh;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 1200;
`

const ModalContainer = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  color: var(--text-light);
  left: 50%;
  max-height: calc(100vh - 120px);
  max-width: 90%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 24px;
  position: absolute;
  top: 60px;
  transform: translate(-50%, 0);
  width: ${(props) => {
    return props.modalWidth || '1000px'
  }};
  z-index: 1150;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

const Icon = styled.div`
  align-items: center;
  ${(props) => {
    return props.type === 'error-prompt' ? 'background-color: var(--modal-icon-background-color-error );' : 'background-color: var(--modal-icon-background-color );'
  }}
  border-radius: 100%;
  ${(props) => {
    return props.type === 'error-prompt' ? 'color: var(--error-text);' : 'color: var(--primary);'
  }}
  display: flex;
  height: 56px;
  justify-content: center;
  margin-right: 12px;
  width: 56px;

  svg {
    height: 30px;
    width: 30px;
    font-size: 30px;
  }

  ${(props) => {
    return props.iconFill && (
      `svg {
        fill: var(${props.type === 'error-prompt' ? '--error-text' : '--modal-icon-color'});
        stroke: var(${props.type === 'error-prompt' ? '--error-text' : '--modal-icon-color'});
      }`
    )
  }}
`

const Heading = styled.h2`
  font-size: ${rem(18)};
  font-weight: 400;
`

function Modal(props) {
  const modalElement = useRef()

  useEffect(() => {
    const element = modalElement.current

    const handleScroll = debounce((event) => {
      publish('modal.scroll', event)
    }, 200, {
      leading: true,
      trialing: false,
    })

    if (element) {
      element.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  return (
    <ModalOverlay>
      <OutsideClickHandler
        onOutsideClick={() => {
          if (props.closeOnOutsideClick && isFunction(props.close)) {
            props.close()
          }
        }}
      >
        <ModalContainer
          className="@container/modal"
          modalWidth={props.modalWidth}
          ref={modalElement}
        >
          <TitleContainer>
            {props.icon && (
              <Icon type={props.type} iconFill={props.iconFill} colorSelector={props.colorSelector || ''} >{props.icon}</Icon>
            )}
            <Heading>{props.title}</Heading>
          </TitleContainer>

          {props.children}
        </ModalContainer>
      </OutsideClickHandler>
    </ModalOverlay>
  )
}

export default Modal
