import * as Sentry from '@sentry/react'
import { debounce, filter, get, groupBy, head, includes, isEmpty, map, some } from 'lodash-es'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { components } from 'react-select'

import PageHeaderCard from '@/Components/card/PageHeaderCard'
import ProgramSetDashboardCard from '@/Components/card/ProgramSetDashboardCard'
import SelectField from '@/Components/form/Select'
import SkeletonDashboardCards from '@/Components/SkeletonDashboardCards'
import { getAllProgramSetsUsingQuery } from '@/Utilities/Accessors/ProgramSets'
import { getAllSitesUsingQuery } from '@/Utilities/Accessors/Sites'
import { standardActions } from '@/Utilities/Events'
import useApiClient from '@/Utilities/useApiClient'
import useEventSubscriber from '@/Utilities/useEventSubscriber'
import useTitle from '@/Utilities/useTitle'
import useUserIdle from '@/Utilities/useUserIdle'

const MultiValue = (props) => {
  const {
    index,
    selectProps,
  } = props
  const limit = 3

  if (index < limit) {
    return <components.MultiValue {...props} />
  }

  if (index === limit) {
    const remaining = selectProps.value.length - limit

    return (
      <div className="ml-3 flex items-center text-sm text-primary">
        <span>{`+${remaining} more`}</span>
      </div>
    )
  }

  return null
}

/**
 * Dashboard Component
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Dashboard() {
  const showDebugInfo = false
  const apiClient = useApiClient()

  const [, setInitialized] = useState(false)
  const [initialLoadInProgress, setInitialLoadInProgress] = useState(true)
  const [isRefreshing, setIsRefreshing] = useState(false)

  const [programSets, setProgramSets] = useState([])
  const [programSetStats, setProgramSetStats] = useState(null)
  const [sites, setSites] = useState()
  const [selectedSiteIds, setSelectedSiteIds] = useState([])
  const selectedSiteIdsRef = useRef(selectedSiteIds)
  const refreshInterval = useRef(null)

  // Sites
  const siteOptions = useMemo(() => {
    const groupedByArea = groupBy(sites, (site) => {
      return site.area.id
    })

    return map(groupedByArea, (sitesByArea) => {
      const siteOptions = map(sitesByArea, (site) => {
        return {
          label: site.name,
          value: site.id,
        }
      })

      return {
        label: head(sitesByArea).area.name,
        options: siteOptions,
      }
    })
  }, [sites])

  // Header pills
  const pageHeaderPills = useMemo(() => {
    return map(
      // Exclude statuses with zero counts
      filter(programSetStats, (programSetStat) => {
        return programSetStat.count > 0
      }),

      // Map (re-map) callBack
      (programSetStat) => {
        return {
          title: `${programSetStat.count} ${programSetStat.title}`,
          color: programSetStat.color,
        }
      },
    )
  }, [programSetStats])

  // Helper function used to fetch available sites from the API
  const fetchSites = async (apiClient, setSites) => {
    try {
      // Retrieve sites with areas relationships
      const siteData = await getAllSitesUsingQuery(
        apiClient,
        ['with[]', 'area'],
      )

      setSites(siteData)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  // Fetch program sets on screen load
  useEffect(() => {
    fetchSites(apiClient, setSites)
  }, [])

  /**
   * Activates the refreshing status.
   *
   * This function is used to activate the refreshing status by canceling any
   * pending deactivation and setting the isRefreshing state to true.
   *
   * @callback activateRefreshingStatus
   */
  const activateRefreshingStatus = useCallback(() => {
    deactivateRefreshingStatus.cancel()
    setIsRefreshing(true)
  }, [])

  /**
   * Function to deactivate refreshing status.
   *
   * @param {Function} callback - The function to be debounced.
   * @param {number} delay - The delay in milliseconds before executing the debounced function.
   * @param {Array} dependencies - An array of dependencies.
   * @returns {Function} - The debounced function.
   */
  const deactivateRefreshingStatus = useCallback(debounce(() => {
    setIsRefreshing(false)
  }, 1500), [])

  /**
   * Update the program sets based on the provided site IDs.
   *
   * @param {Array<number>} siteIds - The IDs of the sites to update program sets for.
   * @returns {Promise<void>} - A promise that resolves when the program sets are updated.
   */
  const updateProgramSets = useCallback(async (siteIds) => {
    activateRefreshingStatus()

    // Retrieve program set data (including options and stats)
    const [parsedProgramSetData, parsedProgramSetStats] = await getAllProgramSetsUsingQuery(
      apiClient,
      ...map(siteIds ?? selectedSiteIds, (siteId) => {
        return ['siteIds[]', siteId]
      }),
    )

    setProgramSets(parsedProgramSetData)
    setProgramSetStats(parsedProgramSetStats)
    deactivateRefreshingStatus()
  }, [selectedSiteIds])

  // Function to refresh the program sets. This is typically called when we receive subscribed events
  const doBackgroundRefresh = useCallback(debounce(() => {
    updateProgramSets(selectedSiteIdsRef.current)
  }, 1000, { maxWait: 5000 }), [])

  useEffect( () => {
    (async () => {
      setProgramSets([])
      await updateProgramSets()

      setInitialLoadInProgress(false)
      setInitialized(true)
    })()

    selectedSiteIdsRef.current = selectedSiteIds
  }, [selectedSiteIds])

  useUserIdle(() => {
    doBackgroundRefresh()
  })

  const eventSubscriberCallback = useCallback(() => {
    doBackgroundRefresh()
  }, [programSets, selectedSiteIds])

  const hasRunningProgram = useMemo(() => {
    return some(pageHeaderPills, (statuses) => {
      return includes(statuses?.title, 'Running')
    })
  }, [pageHeaderPills])

  useEffect(() => {
    if (hasRunningProgram) {
      refreshInterval.current = setInterval(() => {
        doBackgroundRefresh()
      }, 120000)
    } else if (get(refreshInterval, 'current')) {
      clearInterval(refreshInterval.current)
      refreshInterval.current = null
    }

    return () => {
      clearInterval(refreshInterval.current)
    }
  }, [hasRunningProgram])

  useEventSubscriber([
    'program',
    'programSet',
    'mainLine',
    'inputOutput',
  ], [
    ...standardActions,
    'stateStat',
    'stateChange',
  ], eventSubscriberCallback)

  useTitle('Dashboard')

  return (
    <>
      <PageHeaderCard
        className="mx-4 mb-4"
        title="Dashboard"
        pills={pageHeaderPills}
      >
        <div className="min-w-60 md:min-w-80 lg:min-w-96">
          <SelectField
            style={{ width: '100%' }}
            isMulti={true}
            autoFocus={true}
            menuIsOpen={true}
            isSearchable={true}
            maxLength={500}
            display="inline-block"
            width="auto"
            placeholder="All Sites"
            options={siteOptions}
            components={{ MultiValue }}
            onChange={(selected) => {
              setSelectedSiteIds(map(selected, (site) => {
                return site.value
              }))
            }}
          />
        </div>
      </PageHeaderCard>

      {(initialLoadInProgress === false && !isEmpty(programSets)) && (
        <div className="@container">
          <div
            className="mx-4 grid grid-cols-1 gap-4 @dashboard-card-2:grid-cols-2 @dashboard-card-3:grid-cols-3 @dashboard-card-4:grid-cols-4 @dashboard-card-5:grid-cols-5 @dashboard-card-6:grid-cols-6 @dashboard-card-7:grid-cols-7 @dashboard-card-8:grid-cols-8 @dashboard-card-9:grid-cols-9 @dashboard-card-10:grid-cols-10"
          >
            {!isEmpty(programSets) && map(programSets, (programSet, index) => {
              return (
                <ProgramSetDashboardCard
                  key={`programSet-${programSet.id}-${index}`}
                  testId={`programSet-${programSet.id}`}
                  title={programSet.name}
                  programSet={programSet}
                  tabIcons={['info', 'map-location-dot']}
                  debug={showDebugInfo}
                  refreshing={isRefreshing}
                  className="flex"
                />
              )
            })}
          </div>
        </div>
      )}

      {initialLoadInProgress && (
        <div className="@container">
          <div
            className="mx-4 grid grid-cols-1 gap-4 @dashboard-card-2:grid-cols-2 @dashboard-card-3:grid-cols-3 @dashboard-card-4:grid-cols-4 @dashboard-card-5:grid-cols-5 @dashboard-card-6:grid-cols-6  @dashboard-card-7:grid-cols-7 @dashboard-card-8:grid-cols-8 @dashboard-card-9:grid-cols-9 @dashboard-card-10:grid-cols-10"
          >
            <SkeletonDashboardCards/>
          </div>
        </div>
      )}
    </>
  )
}
