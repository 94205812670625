import * as Sentry from '@sentry/react'
import { map } from 'lodash-es'

export const getReportingDashboards = async (apiClient) => {
  try {
    const { data } = await apiClient.get('/reporting-dashboards')

    if (data) {
      return data
    }

  } catch (error) {
    Sentry.captureException(error)
  }

  return []
}

export const getReportingDashboardOptions = async (apiClient) => {
  const data = await getReportingDashboards(apiClient)

  let reportingDashboards = map(data.reportingDashboards, (reportingDashboard) => {
    return {
      value: reportingDashboard.id,
      label: reportingDashboard.name,
    }
  })

  return reportingDashboards
}
