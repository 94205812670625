import React from 'react'
import { twMerge } from 'tailwind-merge'

import useSubComponents from '@/Utilities/useSubComponents'

/**
 * Main TableHeader component
 *
 * @param children
 * @param className
 * @param testId
 * @returns {JSX.Element}
 * @constructor
 */
export default function TableHeader({
  children,
  className,
  testId,
}) {
  const subComponents = useSubComponents(children, [
    'title',
    'pills',
    'toolbar',
    'subHeader',
  ])

  // Automatically position pills to the left, beside the title in md and larger media breakpoints
  const autoPillsGridFlowClass = subComponents.pills
    ? 'md:grid-cols-2-left-auto'
    : undefined

  return (
    <header
      data-cy={testId}
      data-testid={testId}
    >
      <div
        data-cy="primary"
        data-testid="primary"
        className={
          twMerge(
            'grid grid-flow-row items-center border-b border-b-gray-200 py-3.5 px-6 md:grid-flow-col',
            autoPillsGridFlowClass,
            className,
          )
        }
      >
        {subComponents.title}
        {subComponents.pills}
        {subComponents.toolbar}
      </div>
      {subComponents.subHeader}
    </header>
  )
}

/**
 * Title component
 *
 * @param children
 * @param className
 * @returns {JSX.Element}
 * @constructor
 */
const Title = ({
  children,
  className,
}) => {
  return (
    <div
      data-cy="title"
      data-testid="title"
      className={
        twMerge(
          'grid grid-flow-row items-center md:grid-flow-col',
          className,
        )
      }>
      <div className="inline-block text-lg font-medium">
        {children}
      </div>
    </div>
  )
}

Title.uniqueTagId = 'Title'
TableHeader.Title = Title

/**
 * Pills component
 *
 * @param children
 * @param className
 * @returns {JSX.Element}
 * @constructor
 */
const Pills = ({
  children,
  className,
}) => {
  return (
    <div
      data-cy="pills"
      data-testid="pills"
      className={className}
    >
      {children}
    </div>
  )
}

Pills.uniqueTagId = 'Pills'
TableHeader.Pills = Pills

/**
 * Toolbar component
 *
 * @param children
 * @param className
 * @returns {JSX.Element}
 * @constructor
 */
const Toolbar = ({
  children,
  className,
}) => {
  return (
    <div
      data-cy="toolbar"
      data-testid="toolbar"
      className={
        twMerge(
          'relative items-center focus-within:z-10',
          className,
        )
      }
    >
      {children}
    </div>
  )
}

Toolbar.uniqueTagId = 'Toolbar'
TableHeader.Toolbar = Toolbar

/**
 * SubHeader component
 *
 * @param children
 * @param className
 * @returns {JSX.Element}
 * @constructor
 */
const SubHeader = ({
  children,
  className,
}) => {
  return (
    <div
      data-cy="sub"
      data-testid="sub"
      className={
        twMerge(
          'border-b border-b-gray-200 py-3.5 px-6',
          className,
        )
      }
    >
      {children}
    </div>
  )
}

SubHeader.uniqueTagId = 'SubHeader'
TableHeader.SubHeader = SubHeader
