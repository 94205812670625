import * as Sentry from '@sentry/react'
import Cookies from 'js-cookie'
import { difference, filter, forEach, indexOf, isEmpty, keys, reject, toArray } from 'lodash-es'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'

import { loginIntentState, userState } from '@/Config/Atoms/Auth'
import { formatKeys } from '@/Utilities/Form/Formatter'
import useApiClient from '@/Utilities/useApiClient'

export default function useAuth() {
  const apiClient = useApiClient()
  const [loginIntent, setLoginIntent] = useRecoilState(loginIntentState)
  const [user, setUser] = useRecoilState(userState)
  const navigate = useNavigate()

  const fetchUser = useCallback(async () => {
    let {
      data,
      status,
    } = await apiClient.get('/user/profile')

    if (status !== 401 && data.success) {
      setUser(data.user)
    } else {
      setUser(null)
    }
  }, [])

  const loggedIn = useMemo(() => {
    return !isEmpty(user)
  }, [user])

  const signIn = useCallback(async (credentials, callback) => {
    try {
      let { data } = await apiClient.post('/user/login', credentials)

      data = formatKeys(data, 'camel')

      if (data.success) {
        setUser(data.user)

        if (loginIntent?.url) {
          navigate(loginIntent.url, { replace: true })
          setLoginIntent(null)
          return
        }

        callback(true, data)
      } else {
        callback(false)
      }
    } catch (error) {
      Sentry.captureException(error)
      callback(false)
    }
  }, [])

  const googleSignIn = useCallback(async (accessToken, callback) => {
    try {
      let { data } = await apiClient.post('/user/login/google', { token: accessToken })

      data = formatKeys(data, 'camel')

      if (data.success) {
        setUser(data.user)

        if (loginIntent?.url) {
          navigate(loginIntent.url, { replace: true })
          setLoginIntent(null)
          return
        }

        callback(true, data)
      } else {
        callback(false)
      }
    } catch (error) {
      Sentry.captureException(error)
      callback(false)
    }
  }, [])

  const signOut = useCallback(async (callback) => {
    setUser(null)

    const cookiesToKeep = ['cookie_consent']
    const cookiesToClear = reject(keys(Cookies.get()), (cookie) => {
      return indexOf(cookiesToKeep, cookie) >= 0
    })

    forEach(cookiesToClear, (cookie) => {
      Cookies.remove(cookie)
    })

    // Ensure we get a CSRF token before logging out to prevent a 419 error
    await apiClient.get('/sanctum/csrf-cookie')
    await apiClient.post('/user/logout')

    callback(true)
  }, [])

  const can = useCallback((checkPermission) => {
    if (isEmpty(user?.permissions)) {
      return false
    }

    const userPermissions = toArray(user?.permissions)

    return indexOf(userPermissions, checkPermission) >= 0
  }, [user])

  const canAnyOf = useCallback((anyOfPermissions) => {
    // Prevent further processing when permissions are not available to check against
    if (isEmpty(user?.permissions)) {
      return false
    }

    const userPermissions = toArray(user.permissions)
    const anyOfDifference = difference(anyOfPermissions, userPermissions)

    return anyOfDifference.length !== anyOfPermissions.length
  }, [user])

  const isAdmin = useMemo(() => {
    if (!isEmpty(user)) {
      const roles = filter(user.roles, (role) => {
        return role.slug === 'administrator' || role.slug === 'super-administrator'
      })

      return !!roles.length
    }

    return false
  }, [user])

  const isSuperAdmin = useMemo(() => {
    if (!isEmpty(user)) {
      const roles = filter(user.roles, (role) => {
        return role.slug === 'super-administrator'
      })

      return !!roles.length
    }

    return false
  }, [user])

  return {
    isAdmin,
    isSuperAdmin,
    loggedIn,
    user,
    can,
    canAnyOf,
    fetchUser,
    googleSignIn,
    signIn,
    signOut,
  }
}
