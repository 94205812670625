import { atom } from 'recoil'

export const gpsCoordinatesCanSaveState = atom({
  key: 'siteGpsCoordinatesCanSave',
  default: false,
})

export const gpsCoordinatesState = atom({
  key: 'siteGpsCoordinates',
  default: '',
})
