import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/react'
import { isFunction } from 'lodash-es'
import { useForm } from 'react-hook-form'
import { useRecoilState, useSetRecoilState } from 'recoil'
import styled from 'styled-components'
import * as yup from 'yup'

import { Anchor, Button } from '@/Components/form/Buttons'
import InputError from '@/Components/form/InputError'
import Textarea from '@/Components/form/Textarea'
import Portal from '@/Components/layout/helpers/Portal'
import Modal from '@/Components/Modal'
import { isLoadingState, pageAlertState } from '@/Config/Atoms/General'
import useApiClient from '@/Utilities/useApiClient'

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
`

const Form = styled.form`
  label {
    margin-top: 20px;
  }

  .buttons {
    margin-top: 40px;
  }
`

const schema = yup.object({ comment: yup.string().label('Comment').required().min(3).max(1500) })

function CommentModal(props) {
  const apiClient = useApiClient()
  const setAlert = useSetRecoilState(pageAlertState)
  const [isLoading, setIsLoading] = useRecoilState(isLoadingState)
  const {
    register,
    handleSubmit,
    formState,
  } = useForm({ resolver: yupResolver(schema) })
  const { errors } = formState

  const onSubmit = async (data) => {
    try {
      setAlert(null)
      setIsLoading(true)

      let { data: responseData } = await apiClient.post(props?.data.comment ? `/comment/update/${props.data.comment.id}` : '/comment/create', {
        comment: data.comment,
        alarm_id: props.data.alarmId,
      })

      if (responseData.success && isFunction(props.complete)) {
        props.complete()
      }
    } catch (error) {
      Sentry.captureException(error)
      setAlert({
        type: 'error',
        content: 'An error has occured and we were not able to save this site. Please try again.',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Portal>
      <Modal
        title={props?.data.comment ? 'Edit comment' : 'Add comment'}
        close={props.close}
        closeOnOutsideClick={props.closeOnOutsideClick}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-12">
              <Textarea
                label="Comment"
                className={errors.comment && 'error'}
                {...register('comment', { value: props.data?.comment?.comment })}
              />
              {errors.comment && <InputError message={errors.comment.message} />}
            </div>
          </div>

          <ButtonGroup className="buttons">
            <Anchor
              style={{ width: 'calc(50% - 5px)' }}
              className="transparent"
              onClick={() => {
                setAlert(null)
                props.close()
              }}
            >
              Close
            </Anchor>

            <Button
              style={{ width: 'calc(50% - 5px)' }}
              disabled={isLoading ? true : false}
            >
              {isLoading ? <div className="primary-loader light"></div> : 'Save'}
            </Button>
          </ButtonGroup>
        </Form>
      </Modal>
    </Portal>
  )
}

export default CommentModal
