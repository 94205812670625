import { atom } from 'recoil'

export const authAlertState = atom({
  key: 'authAlert',
  default: {},
})

export const userState = atom({
  key: 'userState',
  default: false,
})

export const loginIntentState = atom({
  key: 'loginIntentState',
  default: {},
})

export const companyDetailsState = atom({
  key: 'companyDetails',
  default: {},
})
