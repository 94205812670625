import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import { isEmpty, isFunction } from 'lodash-es'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import * as yup from 'yup'

import { Anchor, Button } from '@/Components/form/Buttons'
import GooglePlacesAutocomplete from '@/Components/form/GooglePlacesAutocomplete'
import Input from '@/Components/form/Input'
import InputError from '@/Components/form/InputError'
import InstructionBox from '@/Components/InstructionBox'
import { modalState, pageAlertState } from '@/Config/Atoms/General'
import { gpsCoordinatesState } from '@/Config/Atoms/InputOutput'
import useApiClient from '@/Utilities/useApiClient'
import useTitle from '@/Utilities/useTitle'
import { transformToNumber } from '@/Utilities/Validation/Transforms/ToNumber'

const schema = yup.object({
  lat: yup.number().label('Latitude').nullable().required().min(-90).max(90).transform(transformToNumber),
  lng: yup.number().label('Longitude').nullable().required().min(-180).max(180).transform(transformToNumber),
})

function InputOutputManageCoordinates(props) {
  const apiClient = useApiClient()
  const [gpsCoordinates, setGpsCoordinates] = useRecoilState(gpsCoordinatesState)
  const navigate = useNavigate()
  const setAlert = useSetRecoilState(pageAlertState)
  const setModal = useSetRecoilState(modalState)
  const [savingCoords, setSavingCoords] = useState(false)
  const [hasChanges, setHasChanges] = useState(false)
  const urlParams = useParams()
  const {
    addMarker,
    inputOutput,
    onCoordinatesSaved,
    onCancel,
  } = props

  useTitle(['Managing I/O coordinates', inputOutput?.name])

  const {
    reset,
    watch,
    getValues,
    setValue,
    register,
    handleSubmit,
    formState,
  } = useForm({ resolver: yupResolver(schema) })
  const {
    errors,
    dirtyFields,
  } = formState

  const address = watch('address')

  // Manually register the address field
  useEffect(() => {
    register('address', { value: props.data?.address })
  }, [])

  useEffect(() => {
    if (!isEmpty(dirtyFields)) {
      setHasChanges(true)
    }
  }, [dirtyFields])

  useEffect(() => {
    if (gpsCoordinates && gpsCoordinates?.lat && gpsCoordinates?.lng) {
      let lat = parseFloat(gpsCoordinates.lat)
      let lng = parseFloat(gpsCoordinates.lng)

      setValue('lat', lat.toFixed(6), { shouldValidate: false })
      setValue('lng', lng.toFixed(6), { shouldValidate: false })
      setValue('address', null)

      reset((formValues) => {
        return { ...formValues }
      })

      setHasChanges(true)
    }
  }, [gpsCoordinates])

  const addressChange = useCallback((field, value) => {
    setValue(field, value)
    setValue('lat', null, { shouldValidate: false })
    setValue('lng', null, { shouldValidate: false })
    setHasChanges(false)
  }, [])

  const coordinatesChange = useCallback((field, coordinates, shouldValidate = true) => {
    setValue('lat', coordinates.lat, { shouldValidate: shouldValidate })
    setValue('lng', coordinates.lng, { shouldValidate: shouldValidate })
    addMarker(coordinates.lat, coordinates.lng, true, true)

    if (shouldValidate) {
      setHasChanges(true)
    }
  }, [inputOutput])

  const onSubmit = async () => {
    if (savingCoords) {
      return
    }

    if (isEmpty(dirtyFields)) {
      let latLng = {
        lat: getValues('lat') ? parseFloat(getValues('lat')) : null,
        lng: getValues('lng') ? parseFloat(getValues('lng')) : null,
      }

      try {
        setSavingCoords(true)

        let { data } = await apiClient.post(`/input-output/update-coordinates/${urlParams.id}`, latLng)

        if (data && data.success) {
          setModal(null)
          setAlert({
            type: 'success',
            content: 'Coordinates updated successfully.',
          })

          setGpsCoordinates(latLng)

          if (isFunction(onCoordinatesSaved)) {
            await onCoordinatesSaved()
          }
        }
      } catch (error) {
        setModal(null)
        setAlert({
          type: 'error',
          content: 'Failed to update coordinates.',
        })
      } finally {
        setSavingCoords(false)
      }
    } else {
      try {
        addMarker(getValues('lat'), getValues('lng'), true, !isEmpty(dirtyFields), true)

        reset((formValues) => {
          return { ...formValues }
        })
      } catch (error) {
        setAlert({
          type: 'error',
          content: 'Please enter valid coordinates.',
        })
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <div className="row">
        <div className="col-12">
          <InstructionBox heading="Pinpoint your I/O">
            <p>
              Click on the map to pinpoint your I/O. Alternatively, search by address or provide GPS coordinates to
              locate your I/O.
            </p>

            <div className="grid grid-cols-1 gap-0">
              <div className="grid grid-cols-1">
                <div className="text-left">
                  <GooglePlacesAutocomplete
                    label="Address"
                    name="address"
                    value={address || ''}
                    disabled={savingCoords}
                    helpTooltip="Enter an address to find coordinates quickly. Only the coordinates are saved, not the address."
                    onChange={addressChange}
                    coordinatesChange={coordinatesChange}
                    className={errors.address && 'error'}
                    background="white"
                  />
                  {errors.address && <InputError message={errors.address.message}/>}
                </div>

                <div className="justify-self-center">
                  <div className="text-md mt-2 mb-1 flex items-center font-medium uppercase text-slate-500/80">
                    or
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <Input
                    name="lat"
                    label="Latitude"
                    isRequired={true}
                    disabled={savingCoords}
                    className={errors.lat && 'error'}
                    background="white"
                    {...register('lat', { value: inputOutput?.lat })}
                  />
                  {errors.lat && <InputError message={errors.lat.message}/>}
                </div>

                <div>
                  <Input
                    name="lng"
                    label="Longitude"
                    isRequired={true}
                    disabled={savingCoords}
                    className={errors.lng && 'error'}
                    background="white"
                    {...register('lng', { value: inputOutput?.lng })}
                  />
                  {errors.lng && <InputError message={errors.lng.message}/>}
                </div>
              </div>
            </div>

          </InstructionBox>
        </div>
      </div>

      <div className="my-4 grid grid-cols-1 gap-4 @sm:grid-cols-2">
        <div>
          <Anchor
            className="cancel"
            style={{ width: '100%' }}
            onClick={() => {
              navigate(`/io/manage/${inputOutput?.id}`)

              if (isFunction(onCancel)) {
                onCancel(hasChanges)
              }
            }}
          >
            Cancel
          </Anchor>
        </div>

        <div>
          {hasChanges && (
            <Button
              disabled={savingCoords}
              isLoading={savingCoords}
              className={classNames({ 'success animate-tilt-shake': isEmpty(dirtyFields) })}
              style={{ width: '100%' }}
            >
              {isEmpty(dirtyFields) ? 'Save' : 'Update Map'}
            </Button>
          )}
        </div>
      </div>
    </form>
  )
}

export default InputOutputManageCoordinates
