import { filter, isEmpty, map } from 'lodash-es'
import { useCallback, useEffect, useState } from 'react'
import { TransitionGroup } from 'react-transition-group'
import { useRecoilState } from 'recoil'
import { v4 } from 'uuid'

import AlertItem from '@/Components/alerts/AlertItem'
import Portal from '@/Components/layout/helpers/Portal'
import { pageAlertState } from '@/Config/Atoms/General'

export default function AlertList() {
  const [activeAlerts, setActiveAlerts] = useState([])
  const [alertData, setAlertData] = useRecoilState(pageAlertState)

  // Add a new alert without closing other alerts
  useEffect(() => {
    if (!isEmpty(alertData) && !alertData?.clearOtherAlerts) {
      setActiveAlerts((prevAlerts) => {
        return [{
          uuid: v4(),
          ...alertData,
        }, ...prevAlerts]
      })

      setAlertData(null)
    }
  }, [alertData])

  // Close all alerts if the new alert is instructed to close other alerts
  useEffect(() => {
    if (!isEmpty(alertData) && alertData?.clearOtherAlerts) {
      map(activeAlerts, (alert) => {
        closeAlert(alert.uuid)
      })
    }
  }, [alertData])

  // When all activeAlerts have been closed, show the new alert
  useEffect(() => {
    if (isEmpty(activeAlerts) && !isEmpty(alertData) && alertData?.clearOtherAlerts) {
      setActiveAlerts([{
        uuid: v4(),
        ...alertData,
      }])

      setAlertData(null)
    }
  }, [activeAlerts, alertData])

  // Close alert by UUID
  const closeAlert = useCallback((uuid) => {
    setActiveAlerts((prevAlerts) => {
      return filter(prevAlerts, (alert) => {
        return alert.uuid !== uuid
      })
    })
  }, [])

  return (
    <Portal>
      <div className="pointer-events-none absolute top-20 right-0 z-5000 h-alert-list w-full md:w-3/6 xl:w-2/6">
        <div className="sticky top-5">
          {!isEmpty(activeAlerts) && (
            <div className="pointer-events-auto mx-6 my-3 grid grid-cols-1 gap-2">
              <TransitionGroup component={null}>
                {map(activeAlerts, (alert) => {
                  return (
                    <AlertItem
                      closeAlert={closeAlert}
                      duration={alert.duration || 5}
                      isCloseable={alert.isCloseable}
                      isPermanent={alert.isPermanent || false}
                      key={alert.uuid}
                      type={alert.type}
                      uuid={alert.uuid}
                    >
                      {alert.content}
                    </AlertItem>
                  )
                })}
              </TransitionGroup>
            </div>
          )}
        </div>
      </div>
    </Portal>
  )
}
