import { trimEnd, trimStart } from 'lodash-es'

const validUrl = (url) => {
  try {
    url = new URL(url)
  } catch (error) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}

const asset = (path, type = 's3') => {
  if (validUrl(path)) {
    return path
  }

  let assetUrl
  let filePath = trimStart(path, '/')

  switch (type) {
    case 's3':
    default:
      assetUrl = import.meta.env.VITE_S3_ASSET_URL
      break
  }

  assetUrl = trimEnd(assetUrl, '/')

  return `${assetUrl}/${filePath}`
}

export { asset }
