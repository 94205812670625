import { debounce } from 'lodash-es'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components'

import Portal from '@/Components/layout/helpers/Portal'

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
`

const Icon = styled.span`
  display: flex;
`

function Dropdown(props) {
  const [lastUpdated, setLastUpdated] = useState(false)
  const [menuVisible, setMenuVisible] = useState(false)
  const [delayHandler, setDelayHandler] = useState(null)
  const [visibile, setVisibile] = useState(false)
  const container = useRef(null)
  const wrapper = useRef(null)
  const { positionProgrammatically } = props

  const mouseOff = () => {
    if (props.hover) {
      setDelayHandler(setTimeout(() => {
        setVisibile(false)
      }, 500))
    }
  }

  const mouseOn = () => {
    if (props.hover) {
      setVisibile(true)
      clearTimeout(delayHandler)
    }
  }

  const computedStyles = useMemo(() => {
    let styles = {
      transform: 'translate(0, 10px)',
      display: 'none',
      visibile: 'hidden',
    }

    if (wrapper.current && container.current) {
      const relativeContainer = document.getElementsByTagName('document')[0] || document.getElementsByTagName('body')[0]

      const wrapperRect = wrapper.current.getBoundingClientRect()
      const containerRect = container.current.getBoundingClientRect()
      const relativeContainerRect = relativeContainer.getBoundingClientRect()

      styles = {
        ...styles,
        top: `${wrapperRect.bottom - relativeContainerRect.top}px`,
      }

      if (positionProgrammatically === 'left') {
        styles = {
          ...styles,
          left: `${window.scrollX + wrapperRect.width + wrapperRect.left - relativeContainerRect.left - containerRect.width}px`,
          right: 'auto',
        }
      } else {
        styles = {
          ...styles,
          right: `${document.documentElement.clientWidth - wrapperRect.right}px`,
          left: 'auto',
        }
      }
    }

    if (menuVisible) {
      styles = {
        ...styles,
        visibile: 'auto',
        display: 'block',
      }
    }

    return styles
  }, [lastUpdated, menuVisible])

  const resized = useCallback(() => {
    if (!lastUpdated) {
      setLastUpdated(moment())
    }
  }, [])

  useEffect(() => {
    let debouncedResize = debounce(resized, 200)

    if (container) {
      resized()

      window.addEventListener('resize', debouncedResize)

      return () => {
        window.removeEventListener('resize', debouncedResize)
      }
    }
  }, [resized])

  return (
    <Wrapper
      ref={wrapper}
      style={{
        position: 'static',
        ...props.wrapperStyle,
      }}
      onMouseLeave={() => {
        mouseOff()
      }}
      onMouseEnter={() => {
        mouseOn()
      }}
    >
      <Icon
        onClick={() => {
          if (!props.noOpenOnClick) {
            setVisibile(true)
          }

          setVisibile(!visibile)
        }}
      >
        {props.icon}
      </Icon>

      {(!props.disabled) ? (
        <CSSTransition
          timeout={100}
          in={visibile}
          classNames={{
            enter: 'opacity-0',
            enterActive: 'transition-opacity duration-100 opacity-100',
            exitActive: 'transition-opacity duration-100 opacity-0',
            exitDone: 'hidden',
          }}
          onEnter={() => {
            setMenuVisible(true)
          }}
          onExited={() => {
            setMenuVisible(false)
          }}
          nodeRef={container}
        >
          <Portal
            triggerOutsideClickOnScroll={visibile}
            shouldOutsideClick={visibile}
            onOutsideClick={(event) => {
              if (event?.type === 'modal.scroll' || !wrapper?.current?.contains(event.target)) {
                setVisibile(false)
                setMenuVisible(false)
              }
            }}
          >
            <div
              className="absolute z-1400 rounded border bg-white text-left shadow-md"
              ref={container}
              style={{
                ...computedStyles,
                ...props.style,
              }}
              onClick={() => {
                if (!props.noCloseOnClick) {
                  setVisibile(false)
                }
              }}
            >
              {props.children}
            </div>
          </Portal>
        </CSSTransition>
      ) : <></>}
    </Wrapper>
  )
}

export default Dropdown
