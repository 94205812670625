import * as yup from 'yup'

import { transformToNumber } from '@/Utilities/Validation/Transforms/ToNumber'

const programSchema = (programSet = {}) => {
  // Fert pump schema
  const fertigationPumps = {
    inputOutputId: yup.mixed()
      .label('Pump')
      .meta({ tabKey: 'fertigation' }),

    fertigationBasis: yup.mixed()
      .when('inputOutputId', {
        is: (inputOutputId) => {
          return !!inputOutputId
        },
        then: (schema) => {
          return schema.label('Fertigation Basis').populatedObject()
        },
        otherwise: (schema) => {
          return schema
        },
      })
      .meta({ tabKey: 'fertigation' }),

    fertigationFlowMeterId: yup.mixed()
      .when('inputOutputId', {
        is: (inputOutputId) => {
          return !!inputOutputId
        },
        then: (schema) => {
          return schema.label('Flow meter').populatedObject()
        },
        otherwise: (schema) => {
          return schema
        },
      })
      .meta({ tabKey: 'fertigation' }),

    fertigationMethod: yup.mixed()
      .when('inputOutputId', {
        is: (inputOutputId) => {
          return !!inputOutputId
        },
        then: (schema) => {
          return schema.label('Fertigation method').populatedObject()
        },
        otherwise: (schema) => {
          return schema
        },
      })
      .meta({ tabKey: 'fertigation' }),

    fertigationQuantityUnitOfMeasurement: yup.mixed()
      .when(['inputOutputId', 'fertigationBasis'], ([inputOutputId, fertigationBasis], schema) => {
        if (inputOutputId && fertigationBasis && fertigationBasis.value === 'quantity') {
          return schema.label('Unit of measure').populatedObject().required()
        }

        return schema
      })
      .meta({ tabKey: 'fertigation' }),

    fertigationDurationHours: yup.number()
      .when(['inputOutputId', 'fertigationBasis'], ([inputOutputId, fertigationBasis], schema) => {
        if (inputOutputId && fertigationBasis && fertigationBasis.value === 'time') {
          return schema.label('Fertigation duration hours').transform(transformToNumber).nullable().required().min(0).max(23)
        }

        return schema.transform(transformToNumber).nullable()
      })
      .meta({ tabKey: 'fertigation' }),

    fertigationDurationMinutes: yup.number()
      .when(['inputOutputId', 'fertigationBasis'], ([inputOutputId, fertigationBasis], schema) => {
        if (inputOutputId && fertigationBasis && fertigationBasis.value === 'time') {
          return schema.label('Fertigation duration minutes').transform(transformToNumber).nullable().required().min(0).max(59)
        }

        return schema.transform(transformToNumber).nullable()
      })
      .meta({ tabKey: 'fertigation' }),

    fertigationDurationSeconds: yup.number()
      .when(['inputOutputId', 'fertigationBasis'], ([inputOutputId, fertigationBasis], schema) => {
        if (inputOutputId && fertigationBasis && fertigationBasis.value === 'time') {
          return schema.label('Fertigation duration seconds').transform(transformToNumber).nullable().required().min(0).max(59)
        }

        return schema.transform(transformToNumber).nullable()
      }),

    fertigationQuantity: yup.number()
      .when(['inputOutputId', 'fertigationBasis'], ([inputOutputId, fertigationBasis], schema) => {
        if (inputOutputId && fertigationBasis && fertigationBasis.value === 'quantity') {
          return schema.label('Fertigation quantity').nullable().required().min(1).max(100000).transform(transformToNumber)
        }

        return schema.nullable().transform(transformToNumber)
      })
      .meta({ tabKey: 'fertigation' }),

    fertigationWaterBefore: yup.number()
      .when('inputOutputId', {
        is: (inputOutputId) => {
          return !!inputOutputId
        },
        then: (schema) => {
          return schema.label('Water before fertigation').nullable().required().min(1).max(100000).transform(transformToNumber)
        },
        otherwise: (schema) => {
          return schema.nullable().transform(transformToNumber)
        },
      })
      .meta({ tabKey: 'fertigation' }),

    fertigationWaterAfter: yup.number()
      .when('inputOutputId', {
        is: (inputOutputId) => {
          return !!inputOutputId
        },
        then: (schema) => {
          return schema.label('Water after fertigation').nullable().required().min(1).max(100000).transform(transformToNumber)
        },
        otherwise: (schema) => {
          return schema.nullable().transform(transformToNumber)
        },
      })
      .meta({ tabKey: 'fertigation' }),

    fertigationWaterBeforeTimeHours: yup.number()
      .label('Water before fert hours')
      .transform(transformToNumber)
      .nullable()
      .required()
      .min(0)
      .max(23)
      .meta({ tabKey: 'fertigation' }),

    fertigationWaterBeforeTimeMinutes: yup.number()
      .label('Water before fert minutes')
      .transform(transformToNumber)
      .nullable()
      .required()
      .min(0)
      .max(59)
      .meta({ tabKey: 'fertigation' }),

    fertigationWaterAfterTimeHours: yup.number()
      .label('Water after fert hours')
      .transform(transformToNumber)
      .nullable()
      .required()
      .min(0)
      .max(23)
      .meta({ tabKey: 'fertigation' }),

    fertigationWaterAfterTimeMinutes: yup.number()
      .label('Water before fert minutes')
      .transform(transformToNumber)
      .nullable()
      .required()
      .min(0)
      .max(59)
      .meta({ tabKey: 'fertigation' }),

    stopIrrigationOnFertigationNoFlow: yup.boolean()
      .meta({ tabKey: 'fertigation' }),
  }

  // Irrigation basis
  if (programSet?.irrigationBasis != 'time') {
    delete fertigationPumps.fertigationWaterBeforeTimeHours
    delete fertigationPumps.fertigationWaterBeforeTimeMinutes
    delete fertigationPumps.fertigationWaterAfterTimeHours
    delete fertigationPumps.fertigationWaterAfterTimeMinutes
  }

  if (programSet?.irrigationBasis != 'quantity') {
    delete fertigationPumps.fertigationWaterBefore
    delete fertigationPumps.fertigationWaterAfter
  }

  return yup.object().shape({
    irrigationBasis: yup.string()
      .required()
      .meta({ tabKey: 'general' }),

    programSetId: yup.mixed()
      .label('Program set')
      .populatedObject()
      .meta({ tabKey: 'general' }),

    name: yup.string()
      .label('Name')
      .required()
      .min(3)
      .max(50)
      .meta({ tabKey: 'general' }),

    description: yup.string()
      .label('Description')
      .max(250)
      .nullable()
      .meta({ tabKey: 'general' }),

    rtuProgramNumber: yup.mixed()
      .label('RTU program number')
      .populatedObject()
      .meta({ tabKey: 'general' }),

    mainLineId: yup.mixed()
      .label('Main line')
      .nullable()
      .meta({ tabKey: 'general' }),

    actionOnCommunicationFailure: yup.mixed()
      .label('Action on comms failure')
      .populatedObject()
      .meta({ tabKey: 'general' }),

    expectedFlow: yup.number()
      .label('Expected flow')
      .when(['expectedFlowDisabled', 'irrigationBasis'], ([expectedFlowDisabled, irrigationBasis], schema) => {
        if (expectedFlowDisabled && irrigationBasis != 'quantity') {
          return schema.transform(transformToNumber).nullable()
        }

        return schema.transform(transformToNumber).nullable().required()
      })
      .meta({ tabKey: 'general' }),

    lowTolerancePercentage: yup.number()
      .label('Low tolerance')
      .when(['expectedFlowDisabled', 'irrigationBasis'], ([expectedFlowDisabled, irrigationBasis], schema) => {
        if (expectedFlowDisabled && irrigationBasis != 'quantity') {
          return schema.transform(transformToNumber).nullable()
        }

        return schema.nullable().required().min(1).max(100).transform(transformToNumber)
      })
      .meta({ tabKey: 'general' }),

    highTolerancePercentage: yup.number()
      .label('High tolerance')
      .when(['expectedFlowDisabled', 'irrigationBasis'], ([expectedFlowDisabled, irrigationBasis], schema) => {
        if (expectedFlowDisabled && irrigationBasis != 'quantity') {
          return schema.transform(transformToNumber).nullable()
        }

        return schema.nullable().required().min(1).max(100).transform(transformToNumber)
      })
      .meta({ tabKey: 'general' }),

    expectedFlowUnitOfMeasurement: yup.mixed()
      .label('Expected flow unit of measurement')
      .when(['expectedFlowDisabled', 'irrigationBasis'], ([expectedFlowDisabled, irrigationBasis], schema) => {
        if (expectedFlowDisabled && irrigationBasis != 'quantity') {
          return schema.notRequired()
        }

        return schema.populatedObject()
      })
      .meta({ tabKey: 'general' }),

    runtimeHours: yup.number()
      .label('Runtime hours')
      .when('irrigationBasis', ([irrigationBasis], schema) => {
        if (irrigationBasis !== 'time') {
          return schema.transform(transformToNumber).nullable()
        }

        return schema.min(0).max(23).required()
      })
      .meta({ tabKey: 'general' }),

    runtimeMinutes: yup.number()
      .label('Runtime minutes')
      .when('irrigationBasis', ([irrigationBasis], schema) => {
        if (irrigationBasis !== 'time') {
          return schema.transform(transformToNumber).nullable()
        }

        return schema.min(0).max(59).required()
      })
      .meta({ tabKey: 'general' }),

    runtimeSeconds: yup.number()
      .label('Runtime seconds')
      .when('irrigationBasis', ([irrigationBasis], schema) => {
        if (irrigationBasis !== 'time') {
          return schema.transform(transformToNumber).nullable()
        }

        return schema.min(0).max(59).required()
      })
      .meta({ tabKey: 'general' }),

    waterQuantityUnitOfMeasurement: yup.mixed()
      .label('Water quantity unit of measurement')
      .when('irrigationBasis', ([irrigationBasis], schema) => {
        if (irrigationBasis === 'quantity') {
          return schema.populatedObject()
        }

        return schema.nullable()
      })
      .meta({ tabKey: 'general' }),

    waterQuantity: yup.number()
      .label('Water quantity')
      .when('irrigationBasis', ([irrigationBasis], schema) => {
        if (irrigationBasis === 'quantity') {
          return schema.nullable().required().min(0).transform(transformToNumber)
        }

        return schema.transform(transformToNumber).nullable()
      })
      .meta({ tabKey: 'general' }),

    inputOutputIds: yup.mixed()
      .label('I/Os')
      .populatedObject()
      .meta({ tabKey: 'io' }),

    startConditionType: yup.mixed()
      .label('Start Condition Type')
      .notRequired()
      .meta({ tabKey: 'conditions' }),

    waitConditionType: yup.mixed()
      .label('Wait Condition Type')
      .notRequired()
      .meta({ tabKey: 'conditions' }),

    stopConditionType: yup.mixed()
      .label('Stop Condition Type')
      .notRequired()
      .meta({ tabKey: 'conditions' }),

    startConditionId: yup.mixed()
      .label('Start Condition Name')
      .when('startConditionType', ([startConditionType], schema) => {
        if (startConditionType) {
          return schema.populatedObject()
        }

        return schema.notRequired()
      })
      .meta({ tabKey: 'conditions' }),

    startConditionState: yup.mixed()
      .label('Start Condition State')
      .when('startConditionType', ([startConditionType], schema) => {
        if (startConditionType) {
          return schema.populatedObject()
        }

        return schema.notRequired()
      })
      .meta({ tabKey: 'conditions' }),

    waitConditionId: yup.mixed()
      .label('Wait Condition Name')
      .when('waitConditionType', ([waitConditionType], schema) => {
        if (waitConditionType) {
          return schema.populatedObject()
        }

        return schema.notRequired()
      })
      .meta({ tabKey: 'conditions' }),

    waitConditionState: yup.mixed()
      .label('Wait Condition State')
      .when('waitConditionType', ([waitConditionType], schema) => {
        if (waitConditionType) {
          return schema.populatedObject()
        }

        return schema.notRequired()
      })
      .meta({ tabKey: 'conditions' }),

    stopConditionId: yup.mixed()
      .label('Stop Condition Name')
      .when('stopConditionType', ([stopConditionType], schema) => {
        if (stopConditionType) {
          return schema.populatedObject()
        }

        return schema.notRequired()
      })
      .meta({ tabKey: 'conditions' }),

    stopConditionState: yup.mixed()
      .label('Stop Condition State')
      .when('stopConditionType', ([stopConditionType], schema) => {
        if (stopConditionType) {
          return schema.populatedObject()
        }

        return schema.notRequired()
      })
      .meta({ tabKey: 'conditions' }),

    fertigationPumps: yup.array().of(
      yup.object(fertigationPumps)
        .nullable()
        .default([])
        .meta({ tabKey: 'fertigation' }),
    )
      .meta({ tabKey: 'fertigation' }),

    delays: yup.array().of(
      yup.object({
        onDelay: yup.number()
          .label('On delay')
          .min(0)
          .max(3600)
          .transform(transformToNumber)
          .nullable()
          .required()
          .meta({ tabKey: 'delays' }),

        offDelay: yup.number()
          .label('Off delay')
          .min(0)
          .max(3600)
          .transform(transformToNumber)
          .nullable()
          .required()
          .meta({ tabKey: 'delays' }),

        inputOutputId: yup.number()
          .transform(transformToNumber)
          .nullable()
          .meta({ tabKey: 'delays' }),
      })
        .nullable()
        .default([])
        .meta({ tabKey: 'delays' }),
    )
      .meta({ tabKey: 'delays' }),
  })
}

export default programSchema
