import * as Sentry from '@sentry/react'

import { formatKeys } from '@/Utilities/Form/Formatter'

export const getManufacturers = async (apiClient) => {
  try {
    let { data } = await apiClient.get('/manufacturers')

    if (data) {
      const manufacturers = formatKeys(data.manufacturers, 'camel')

      return manufacturers
    }
  } catch (error) {
    Sentry.captureException(error)
  }

  return []
}
