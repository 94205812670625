import classNames from 'classnames'
import { find, includes, isEmpty } from 'lodash-es'
import { useEffect, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import Input from '@/Components/form/Input'
import InputError from '@/Components/form/InputError'
import LightSwitch from '@/Components/form/LightSwitch'
import Select from '@/Components/form/Select'
import useTitle from '@/Utilities/useTitle'

function General(props) {
  const isEditing = props.data?.isEditing || false
  let mainLine

  if (props.data?.mainLine) {
    mainLine = props.data.mainLine
  }

  useTitle([`${mainLine ? 'Edit' : 'Add'} main line`, mainLine?.name])

  const {
    control,
    errors,
    register,
    setValue,
    watch,
  } = useFormContext()
  const mainValveStaysOpenEnabled = watch('mainValveStaysOpen')
  const selectedSiteId = watch('siteId')
  const flowMeterType = watch('flowMeterType')
  const flowMeterId = watch('flowMeterId')
  const mainValveId = watch('mainValveId')

  const selectedFlowMeter = useMemo(() => {
    return find(props.inputOutputFlowMeters, ['id', flowMeterId?.value])
  }, [props.inputOutputFlowMeters, flowMeterId])

  const selectedFlowMeterType = useMemo(() => {
    if (!isEmpty(selectedFlowMeter)) {
      if (includes(selectedFlowMeter.detailsType, 'digital')) {
        return 'digital'
      }

      if (includes(selectedFlowMeter.detailsType, 'analog')) {
        return 'analog'
      }
    }

    return null
  }, [selectedFlowMeter])

  useEffect(() => {
    const currentTab = document.getElementById('general')

    if (currentTab) {
      props.setTabErrors((prevState) => {
        return {
          ...prevState,
          general: !!currentTab.querySelector('.error-message'),
        }
      })
    }
  }, [errors, props.setTabErrors])

  return (
    <>
      {
        (!isEditing || (isEditing && props.siteOptions)) &&
        <>
          <Controller
            control={control}
            name="siteId"
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  isMulti={false}
                  isSearchable={true}
                  label="Site"
                  isRequired={true}
                  isLoading={props.sitesLoading}
                  options={props.siteOptions}
                  placeholder="Search"
                  hasError={!!errors.siteId}
                />
              )
            }}
          />
          {errors.siteId && <InputError message={errors.siteId.message} />}
        </>
      }

      <Input
        label="Name"
        isRequired={true}
        type="text"
        className={errors.name && 'error'}
        {...register('name', { value: mainLine?.name })}
      />
      {errors.name && <InputError message={errors.name.message} />}

      <Input
        label="Description"
        type="text"
        className={errors.description && 'error'}
        {...register('description', { value: mainLine?.description })}
      />
      {errors.description && <InputError message={errors.description.message} />}

      {
        (!isEditing || (isEditing && props.inputOutputValveOptions)) &&
        <>
          <Controller
            control={control}
            defaultValue={find(props.inputOutputValveOptions, ['value', mainLine?.mainValveId]) || ''}
            name="mainValveId"
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  isMulti={false}
                  isSearchable={true}
                  label="Main valve"
                  isLoading={props.inputOutputValvesLoading}
                  options={props.inputOutputValveOptions}
                  placeholder="Search"
                  isClearable={true}
                  afterChange={(option) => {
                    if (!option) {
                      setValue('mainValveId', null, { shouldValidate: true })
                    }
                  }}
                  hasError={!!errors.mainValveId}
                  noOptionsMessage={() => {
                    return selectedSiteId ? 'There are no options for the selected site.' : 'Select a site to see available options.'
                  }}
                />
              )
            }}
          />
          {errors.mainValveId && <InputError message={errors.mainValveId.message} />}
        </>
      }

      {
        (!isEditing || (isEditing && props.flowMeterTypeOptions)) &&
        <>
          <Controller
            control={control}
            defaultValue={find(props.flowMeterTypeOptions, ['value', mainLine?.flowMeterType]) || ''}
            name="flowMeterType"
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  isMulti={false}
                  isSearchable={true}
                  label="Flow meter type"
                  options={props.flowMeterTypeOptions}
                  placeholder="Select"
                  isClearable={true}
                  afterChange={(option) => {
                    if (!option) {
                      setValue('flowMeterId', null, { shouldValidate: true })
                    }
                  }}
                  hasError={!!errors.flowMeterType}
                />
              )
            }}
          />
          {errors.flowMeterType && <InputError message={errors.flowMeterType.message} />}
        </>
      }

      {
        (!isEditing || (isEditing && props.inputOutputFlowMeterOptions)) &&
        <div className={classNames({ hidden: !flowMeterType })}>
          <Controller
            control={control}
            defaultValue={find(props.inputOutputFlowMeterOptions, ['value', mainLine?.flowMeterId]) || ''}
            name="flowMeterId"
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  isMulti={false}
                  isSearchable={true}
                  label="Flow meter"
                  isRequired={true}
                  isLoading={props.inputOutputFlowMetersLoading}
                  options={props.inputOutputFlowMeterOptions}
                  placeholder="Search"
                  hasError={!!errors.flowMeterId}
                  noOptionsMessage={() => {
                    return selectedSiteId ? 'There are no options for the selected site.' : 'Select a site to see available options.'
                  }}
                />
              )
            }}
          />
          {errors.flowMeterId && <InputError message={errors.flowMeterId.message} />}
        </div>
      }

      <div className={classNames({ hidden: !flowMeterType })}>
        <Input
          label={`Uncontrolled detection (in ${selectedFlowMeterType === 'digital' ? 'meter pulses' : 'minutes'})`}
          isRequired={true}
          type="number"
          className={errors.uncontrolledDetection && 'error'}
          {...register('uncontrolledDetection', { value: mainLine?.uncontrolledDetection })}
        />
        {errors.uncontrolledDetection && <InputError message={errors.uncontrolledDetection.message} />}
      </div>

      <div
        className={classNames({ hidden: !mainValveId }, 'mt-6 hidden')} // Always hidden as this is not required for Ag
      >
        <LightSwitch
          label="Main valve stays open"
          name="mainValveStaysOpen"
          onToggle={setValue}
          defaultState={(mainLine?.mainValveStaysOpen ? true : mainValveStaysOpenEnabled) || false}
          {...register('mainValveStaysOpen', { value: mainLine?.mainValveStaysOpen || false })}
        />
      </div>
    </>
  )
}

export default General
