import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'

import PermissionGuard from '@/Components/auth/PermissionGuard'
import { Anchor, RoundedIconButton } from '@/Components/form/Buttons'
import PageContainer from '@/Components/layout/helpers/PageContainer'
import InputOutputsAll from '@/Components/tables/InputOutputsAll'
import { modalState } from '@/Config/Atoms/General'
import useAuth from '@/Utilities/useAuth'
import usePageGuard from '@/Utilities/usePageGuard'
import useTitle from '@/Utilities/useTitle'

function InputOutputIndex() {
  const setModal = useSetRecoilState(modalState)
  const navigate = useNavigate()
  const auth = useAuth()

  usePageGuard({
    auth,
    permission: 'view-input-output',
  })

  useTitle('All I/Os')

  const showIoModal = () => {
    setModal({
      name: 'io',
      data: {
        onSave: (inputOutput, mapLinkingEnabled) => {
          if (mapLinkingEnabled) {
            navigate(`/io/manage/${inputOutput.id}/coordinates`)
          }
        },
      },
    })
  }

  return (
    <>
      <PageContainer contained>
        <div className="mb-5 grid grid-cols-2 text-3xl font-light text-slate-900">
          <div>
            All <span className="font-medium">I/Os</span>
          </div>

          <PermissionGuard permission="create-input-output" auth={auth}>
            <div className="text-right">
              <div className="hidden justify-items-end md:block">
                <Anchor
                  style={{ minWidth: '200px' }}
                  onClick={showIoModal}
                >
                  Add I/O
                </Anchor>
              </div>
              <div className="md:hidden">
                <RoundedIconButton
                  icon="plus"
                  onClick={showIoModal}
                />
              </div>
            </div>
          </PermissionGuard>
        </div>

        <PermissionGuard permission="view-input-output" auth={auth}>
          <InputOutputsAll/>
        </PermissionGuard>
      </PageContainer>
    </>
  )
}

export default InputOutputIndex
