import * as Sentry from '@sentry/react'
import { rem } from 'polished'
import { useCallback, useEffect } from 'react'
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import styled from 'styled-components'

import ActionIcon from '@/Components/ActionIcon'
import Card from '@/Components/Card'
import CircleList from '@/Components/CircleList'
import DropdownList from '@/Components/DropdownList'
import PageContainer from '@/Components/layout/helpers/PageContainer'
import AreasAll from '@/Components/tables/AreasAll'
import { userState } from '@/Config/Atoms/Auth'
import { modalState } from '@/Config/Atoms/General'
import { formattedDateTime } from '@/Utilities/DateTime'
import { asset } from '@/Utilities/Url'
import useApiClient from '@/Utilities/useApiClient'
import useAuth from '@/Utilities/useAuth'
import useTitle from '@/Utilities/useTitle'

const userProfileState = atom({
  key: 'userProfile',
  default: null,
})

const AvatarContainer = styled.div`
  margin-right: 10px;
  position: relative;

  .overlay {
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    transition: all 300ms;
  }

  &:hover {
    cursor: pointer;

    .overlay {
      opacity: 1;
    }
  }
`

const Avatar = styled.img`
  border-radius: 100%;
  object-fit: cover;
  height: 50px;
  width: 50px;
`

const CardContainer = styled.div`
  margin-bottom: 20px;
`

const CardRow = styled.div`
  display: flex;
  font-size: ${rem(14)};
  justify-content: space-between;
`

const CardRowTitle = styled.div`
  align-items: center;
  color: var(--heading-color);
  display: flex;
  font-size: ${rem(14)};
  flex-basis: 50%;
`

const CardRowContent = styled.div`
  color: var(--text-light);
  display: flex;
  font-size: ${rem(14)};
  flex-basis: 50%;
`

function Profile() {
  const apiClient = useApiClient()
  const [user, setUser] = useRecoilState(userProfileState)
  const authUser = useRecoilValue(userState)
  const setModal = useSetRecoilState(modalState)
  const auth = useAuth()

  useTitle('My profile')

  const getUserData = useCallback(async () => {
    try {
      const { data } = await apiClient.get('/user/profile?with=roles')

      if (data && data.success) {
        setUser(data.user)
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }, [setUser])

  useEffect(
    () => {
      getUserData()
    },
    [getUserData],
  )

  return (
    <>
      {user && (
        <PageContainer contained>
          <div className="mb-5 text-3xl font-light text-slate-900">
            My <span className="font-medium">profile</span>
          </div>

          <CardContainer className="col-12 col-md-4">
            <Card
              title={(
                <div className="flex flex-row">
                  <AvatarContainer
                    onClick={() => {
                      setModal({
                        name: 'avatar',
                        data: {
                          onClose: () => {
                            auth.fetchUser()
                            getUserData()
                          },
                        },
                      })
                    }}>
                    {!authUser.avatar && (
                      <CircleList
                        circles={[{
                          text: authUser.name,
                          avatar: user.avatar || null,
                        }]}
                        style={{
                          height: '50px',
                          width: '50px',
                        }}
                      />
                    )}

                    {authUser.avatar && (
                      <Avatar
                        src={`${asset(user.avatar)}?t=${new Date().getTime()}`}
                        alt="avatar"
                      />
                    )}

                    <div className="overlay">
                      <i className="fa-light fa-user-pen fa-lg"></i>
                    </div>
                  </AvatarContainer>

                  <div className="flex items-center">
                    {user.name}
                  </div>
                </div>
              )}
              actions={
                <>
                  <DropdownList
                    icon={<ActionIcon />}
                    options={[{
                      label: 'Edit profile',
                      onClick: () => {
                        setModal({
                          name: 'userProfile',
                          data: {
                            user: user,
                            onComplete: () => {
                              getUserData()
                              setModal(null)
                            },
                          },
                        })
                      },
                    }, {
                      label: 'Change profile photo',
                      onClick: () => {
                        setModal({
                          name: 'avatar',
                          data: {
                            onClose: () => {
                              auth.fetchUser()
                              getUserData()
                            },
                          },
                        })
                      },
                    }]}
                  />
                </>
              }
            >
              <CardRow>
                <CardRowTitle>Role</CardRowTitle>
                <CardRowContent>{user.roles[0].name}</CardRowContent>
              </CardRow>

              <CardRow>
                <CardRowTitle>Date joined</CardRowTitle>
                <CardRowContent>{formattedDateTime(user?.created_at)}</CardRowContent>
              </CardRow>

              <CardRow>
                <CardRowTitle>Email</CardRowTitle>
                <CardRowContent>{user?.email}</CardRowContent>
              </CardRow>

              <CardRow>
                <CardRowTitle>Number</CardRowTitle>
                <CardRowContent>{user?.number}</CardRowContent>
              </CardRow>
            </Card>
          </CardContainer>

          <AreasAll />
        </PageContainer>
      )}
    </>
  )
}

export default Profile
