import classNames from 'classnames'
import { concat, includes, isEmpty, map, remove } from 'lodash-es'
import { useCallback, useState } from 'react'

import Pill from '@/Components/pill/Pill'

export default function Legend(props) {
  const [disabledAnnotations, setDisabledAnnotations] = useState([])
  const [disabledDataSets, setDisabledDataSets] = useState([])

  const toggleItem = (array, item) => {
    array = [...array]

    if (includes(array, item)) {
      remove(array, (currentItem) => {
        return currentItem === item
      })
    } else {
      array = concat(array, item)
    }

    return array
  }

  const toggleDisabledAnnotation = useCallback((index) => {
    const items = toggleItem(disabledAnnotations, index)
    setDisabledAnnotations(items)
  }, [disabledAnnotations])

  const toggleDisabledDataSets = useCallback((index) => {
    const items = toggleItem(disabledDataSets, index)
    setDisabledDataSets(items)
  }, [disabledDataSets])

  const {
    toggleDataSet,
    toggleAnnotation,
    dataSets,
    annotations,
  } = props

  return (
    <>
      {!isEmpty(dataSets) && (
        <div className={classNames({ 'pb-6': isEmpty(annotations) }, 'flex items-center')}>
          <div className="mr-2 w-20 shrink-0 text-right text-xs">
            Data Sets
          </div>

          <div className="text-left">
            {map(dataSets, (dataSet, index) => {
              return (
                <Pill
                  color={(includes(disabledDataSets, index) ? 'black' : dataSet.borderColor)}
                  padding="0 8px"
                  marginRight="2px"
                  fontSize="10px"
                  className="m-0 cursor-pointer select-none text-sm"
                  key={index}
                  onClick={() => {
                    toggleDataSet(dataSet.uuid)
                    toggleDisabledDataSets(index)
                  }}
                >
                  {dataSet.label}
                </Pill>
              )
            })}
          </div>
        </div>
      )}

      {!isEmpty(annotations) && (
        <div className={classNames({ 'pb-6': isEmpty(dataSets) }, 'flex items-center')}>
          <div className="mr-2 w-20 shrink-0 text-right text-xs">
            Annotations
          </div>

          <div className="text-left">
            {map(annotations, (annotation, index) => {
              return (
                <Pill
                  color={includes(disabledAnnotations, index) ? 'black' : annotation.borderColor}
                  padding="0 8px"
                  marginRight="2px"
                  fontSize="10px"
                  className="m-0 cursor-pointer select-none text-sm"
                  key={index}
                  onClick={() => {
                    toggleAnnotation(annotation.uuid)
                    toggleDisabledAnnotation(index)
                  }}
                >
                  {annotation.label}
                </Pill>
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}
