import 'react-phone-number-input/style.css'

import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/react'
import { isEmpty } from 'lodash-es'
import { rem } from 'polished'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { isValidPhoneNumber } from 'react-phone-number-input'
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import styled from 'styled-components'
import * as yup from 'yup'

import AuthAlert from '@/Components/AuthAlert'
import { Button } from '@/Components/form/Buttons'
import Input from '@/Components/form/Input'
import InputError from '@/Components/form/InputError'
import { authAlertState, companyDetailsState } from '@/Config/Atoms/Auth'
import useApiClient from '@/Utilities/useApiClient'
import useTitle from '@/Utilities/useTitle'

const Title = styled.h1`
  font-size: 30px;
  font-weight: 700;
`

const SubText = styled.div`
  margin-top: 12px;
`
const Form = styled.form`
  label {
    margin-top: 20px;
    @apply text-slate-500;
  }

  .buttons {
    margin-top: 40px;
  }
`

const SignUpText = styled.div`
  margin-top: 32px;

  a {
    color: var(--primary);
    text-decoration: none;
  }
`

const Label = styled.label`
  color: var(--text-dark);
  display: flex;
  font-size: ${rem(14)};
  margin-bottom: 6px;
`

const schema = yup.object({
  name: yup.string().label('Name').required(),
  email: yup.string().label('Email').email().required(),
  number: yup.string().label('Mobile number').min(8).max(15).nullable().required().test(
    'validNumber',
    'Please enter a valid mobile number.',
    (value) => {
      if (value) {
        return isValidPhoneNumber(value)
      }
    },
  ),
  password: yup.string().label('Password').required().min(6).max(100),
})

function Register() {
  const apiClient = useApiClient()
  const companyDetails = useRecoilValue(companyDetailsState)
  const [alert, setAlert] = useRecoilState(authAlertState)
  const {
    control,
    setValue,
    register,
    handleSubmit,
    formState,
  } = useForm({ resolver: yupResolver(schema) })
  const { errors } = formState

  useTitle('Register')

  const params = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    (async () => {
      let { data } = await apiClient.post('/invite/user', { hash: params.hash })

      setValue('name', data.user?.name)
      setValue('email', data.user?.email)
      setValue('number', data.user?.number)
    })()
  }, [setValue, params.hash])

  const handleRegister = async (data) => {
    try {
      setAlert(null)

      let { data: responseData } = await apiClient.post('/user/register', {
        hash: params.hash,
        company: companyDetails,
        ...data,
      })

      if (responseData.success) {
        if (!isEmpty(companyDetails)) {
          navigate('/admin/organization')
        } else {
          navigate('/map')
        }
      } else {
        setAlert({ message: responseData.message })
      }

    } catch (error) {
      Sentry.captureException(error)
      setAlert({ message: 'An error occured.' })
    }
  }

  return (
    <Form onSubmit={handleSubmit(handleRegister)} autoComplete="off">
      <div className="justify-center">
        <Title>Sign up</Title>
        <SubText className="text-slate-500">Sign up in less than 2 minutes.</SubText>

        <AuthAlert class={alert ? 'flex' : 'd-none'} message={alert?.message}/>

        <Input
          placeholder="Enter your name"
          label="Name*"
          type="text"
          className={errors.name && 'error'}
          {...register('name')}
        />
        {errors.name && <InputError message={errors.name.message}/>}

        <Input
          placeholder="Enter your email"
          label="Email*"
          type="email"
          autoComplete="username"
          readOnly={true}
          className={errors.email && 'error'}
          {...register('email')}
        />
        {errors.email && <InputError message={errors.email.message}/>}

        <Label htmlFor="number" style>Mobile number</Label>
        <PhoneInputWithCountry
          name="number"
          control={control}
          defaultCountry="US"
          placeholder="Enter your mobile number"
          className={errors.number && 'error'}
        />
        {errors.number && <InputError message={errors.number.message}/>}

        <Input
          data-sentry-mask
          placeholder="Create your password"
          label="Password*"
          type="password"
          autoComplete="new-password"
          className={errors.password && 'error'}
          {...register('password')}
        />
        {errors.password && <InputError message={errors.password.message}/>}

        <div className="buttons">
          <Button style={{ width: '100%' }}>Sign up</Button>
        </div>

        <div className="flex justify-center text-slate-500">
          <SignUpText>Already have an account? <Link to="/user/login">Sign In</Link></SignUpText>
        </div>

        <div className="mt-10 justify-center text-center text-slate-500">
          Use of FutureOps is subject to the Branif Systems <a href="#" className="text-primary">terms of use</a>.
        </div>
      </div>
    </Form>
  )
}

export default Register
