import { atom } from 'recoil'

export const inputOutputOptionsState = atom({
  key: 'inputOutputOptions',
  default: null,
})

export const digitalOutputOptionsState = atom({
  key: 'digitalOutputOptions',
  default: null,
})

export const gpsCoordinatesCanSaveState = atom({
  key: 'gpsCoordinatesCanSave',
  default: false,
})

export const gpsCoordinatesState = atom({
  key: 'ioGpsCoordinates',
  default: '',
})
