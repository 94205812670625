import * as Sentry from '@sentry/react'
import Tippy from '@tippyjs/react'
import classNames from 'classnames'
import { get, truncate } from 'lodash-es'
import { debounce, map } from 'lodash-es'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'

import ActionIcon from '@/Components/ActionIcon'
import AlarmIcon from '@/Components/alerts/AlarmIcon'
import TableHeader from '@/Components/common/table/Header'
import TypeSearch from '@/Components/common/TypeSearch'
import DropdownList from '@/Components/DropdownList'
import SkeletonTable from '@/Components/SkeletonTable'
import TableBasic from '@/Components/tables/base/Basic'
import { modalState, pageAlertState } from '@/Config/Atoms/General'
import { standardActions, subscribe, unsubscribe } from '@/Utilities/Events'
import { formatKeys } from '@/Utilities/Form/Formatter'
import { getPaginationMeta } from '@/Utilities/Pagination'
import useApiClient from '@/Utilities/useApiClient'
import useAuth from '@/Utilities/useAuth'
import useEventSubscriber from '@/Utilities/useEventSubscriber'

function RemoteUnitsAll() {
  // Utilities
  const auth = useAuth()
  const navigate = useNavigate()
  const apiClient = useApiClient()

  // Internal component state
  const [lastQuery, setLastQuery] = useState(null)
  const [tableData, setTableData] = useState()
  const [tableDataLoading, setTableDataLoading] = useState(true)
  const [tableSearchTerm, setTableSearchTerm] = useState({
    type: null,
    value: null,
  })
  const setAlert = useSetRecoilState(pageAlertState)
  const setModal = useSetRecoilState(modalState)

  // Events
  useEventSubscriber(['remoteUnits'], standardActions, () => {
    updateTable()
  })

  const updateTable = useCallback(() => {
    getTableData(getPaginationMeta(lastQuery))
  }, [lastQuery])

  const getTableData = useMemo(() => {
    return debounce(async ({
      pageIndex,
      pageSize,
      filters,
    }) => {
      const currentQuery = {
        pageIndex,
        pageSize,
        filters,
      }

      setLastQuery(currentQuery)

      const query = new URLSearchParams([
        ['page', pageIndex + 1],
        ['pageSize', pageSize],
        ['search', filters?.search?.value || ''],
        ['searchType', filters?.search?.type || ''],
        ['with[]', 'site.area'],
        ['with[]', 'remoteUnitType'],
      ])

      try {
        const { data } = await apiClient.get(`/remote-unit/query?${query}`)

        if (get(data, 'success', false)) {
          const remoteUnits = formatKeys(data.remoteUnits, 'camel')

          let remoteUnitData = map(remoteUnits, (remoteUnit) => {
            return {
              name: (
                <div className="flex items-center">
                  <div>
                    <Tippy content={`${remoteUnit.active ? 'Successfully' : 'Not yet'} activated with the FEP`} theme="light" placement="right">
                      <span className="mr-4 p-1">
                        <span className={classNames({
                          'bg-green-600': remoteUnit.active,
                          'bg-yellow-500': !remoteUnit.active,
                        }, 'w-3 h-3 inline-block rounded-full')}></span>
                      </span>
                    </Tippy>
                  </div>

                  <div>
                    <div>
                      {remoteUnit.name}

                      {remoteUnit.hasAlarms && (
                        <AlarmIcon type="remoteUnit" uuid={remoteUnit.uuid} />
                      )}
                    </div>
                    {remoteUnit.description ? (
                      <Tippy content={remoteUnit.description} delay={200} theme="light" disabled={remoteUnit.description.length < 50} placement="right">
                        <div className="inline-block text-xs text-slate-400">
                          {truncate(remoteUnit.description, { length: 50 })}
                        </div>
                      </Tippy>
                    ) : null}
                  </div>
                </div>
              ),
              area: remoteUnit.site.area.name,
              site: remoteUnit.site.name,
              details: (
                <>
                  <div>
                    Device ID: {remoteUnit.deviceId}
                  </div>
                  <div>
                    EUID: {remoteUnit.euid}
                  </div>
                </>
              ),
              action: auth.can('update-remote-unit') ? (
                <DropdownList
                  icon={<ActionIcon />}
                  options={[{
                    label: 'Edit remote unit',
                    topLine: true,
                    onClick: () => {
                      setModal({
                        name: 'remoteUnit',
                        data: {
                          remoteUnit: remoteUnit,
                          isEditing: true,
                          onSave: () => {
                            getTableDataStart(currentQuery)
                          },
                        },
                      })
                    },
                  }, {
                    label: 'Delete remote unit',
                    onClick: () => {
                      setModal({
                        name: 'warning',
                        data: {
                          title: 'Delete remote unit',
                          content: `Are you sure you want to delete ${remoteUnit.name}? This will delete all hardware and alarms attached to it. This may also affect reporting. This action cannot be undone.`,
                          endpoint: `/remote-unit/delete/${remoteUnit.id}`,
                          successFlashMessage: `${remoteUnit.name} deleted successfully.`,
                          onComplete: () => {
                            getTableData({
                              pageSize: 15,
                              pageIndex: 0,
                            })
                            setModal(null)
                          },
                        },
                      })
                    },
                  }]}
                />
              ) : '',
            }
          })

          let tableData = { data: remoteUnitData }

          setTableData(tableData)
          setTableDataLoading(false)
        }
      } catch (error) {
        Sentry.captureException(error)
      }
    }, 250)
  }, [
    setTableData,
    setTableDataLoading,
    navigate,
    setModal,
    setAlert,
  ])

  useEffect(() => {
    getTableData({
      pageSize: 15,
      pageIndex: 0,
    })
  }, [getTableData])

  const getTableDataStart = useCallback((params) => {
    setTableDataLoading(true)
    getTableData(getPaginationMeta(params))
  }, [getTableData, setTableDataLoading])

  const tableColumns = useMemo(
    () => {
      return [
        {
          Header: 'Name',
          accessor: 'name',
          width: '20%',
        },
        {
          Header: 'Area',
          accessor: 'area',
          width: '15%',
        },
        {
          Header: 'Site',
          accessor: 'site',
          width: '15%',
        },
        {
          Header: 'Details',
          accessor: 'details',
          width: '15%',
        },
        {
          Header: '',
          accessor: 'action',
          width: '5%',
          style: { textAlign: 'right' },
        },
      ]
    }, [],
  )

  const searchTypes = useMemo(() => {
    return [
      {
        value: 'remoteUnit',
        label: 'Remote Unit',
      },
      {
        value: 'site',
        label: 'Site',
      },
      {
        value: 'area',
        label: 'Area',
      },
    ]
  }, [])

  useEffect(() => {
    const remoteUnitCreated = () => {
      getTableDataStart(lastQuery)
    }

    if (lastQuery) {
      subscribe('remoteUnitCreated', remoteUnitCreated)
    }

    return () => {
      unsubscribe('remoteUnitCreated', remoteUnitCreated)
    }
  }, [lastQuery, getTableDataStart])

  return (
    <>
      {
        tableData ?
          <TableBasic
            testId="tableRemoteUnits"
            columns={tableColumns}
            data={tableData}
            getTableData={getTableDataStart}
            header={
              <TableHeader
                testId="tableRemoteUnitsTableHeader"
                className="grid-cols-2-right-auto"
              >
                <TableHeader.Title>Remote Units</TableHeader.Title>

                <TableHeader.Toolbar className="hidden md:block">
                  <TypeSearch
                    types={searchTypes}
                    search={tableSearchTerm}
                    onChange={(searchTerm) => {
                      return setTableSearchTerm(searchTerm)
                    }}
                  />
                </TableHeader.Toolbar>

                <TableHeader.SubHeader className="flex flex-col justify-between gap-3 @lg:flex-row @lg:items-center md:hidden">
                  <TypeSearch
                    types={searchTypes}
                    search={tableSearchTerm}
                    onChange={(searchTerm) => {
                      return setTableSearchTerm(searchTerm)
                    }}
                  />
                </TableHeader.SubHeader>
              </TableHeader>
            }
            loading={tableDataLoading}
            searchTerm={tableSearchTerm}
          /> : <SkeletonTable />
      }
    </>
  )
}

export default RemoteUnitsAll
