import styled from 'styled-components'

const Styles = styled.span`
  padding: 0 12px;

  &:hover {
    cursor: pointer;
  }
`

function ActionIcon() {
  return (
    <Styles>
      <i className="far fa-ellipsis-v"></i>
    </Styles>
  )
}

export default ActionIcon
