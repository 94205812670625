import { get } from 'lodash-es'
import moment from 'moment'
import { useCallback, useEffect, useRef, useState } from 'react'

/**
 * ElapsedTimerWithProgress is a React component that displays a progress bar and elapsed time for a given timer.
 *
 * @param {Object} options - The options for the timer.
 * @param {Object} options.timing - The timing object that contains information about the timer.
 * @param {number} options.timing.shouldTick - Indicates whether the timer should tick or not.
 * @param {number} options.timing.expectedTotalSeconds - The expected total seconds for the timer.
 * @param {number} options.timing.totalSeconds - The current total seconds for the timer.
 * @param {Date} [options.startedAt] - The start time of the timer. Defaults to the current time if not provided.
 * @param {string} [options.statusKey='stopped'] - The status key for the timer.
 *
 * @return {React.Element} The rendered component.
 */
export default function ElapsedTimer({
  timing,
  statusKey = 'stopped',
}) {
  // Internal component state variables
  const [elapsedSeconds, setElapsedSeconds] = useState(0)
  const [elapsedTime, setElapsedTime] = useState('00:00:00')
  const [totalSeconds, setTotalSeconds] = useState(0)
  const [totalTime, setTotalTime] = useState('00:00:00')

  // Internal interval reference for the timer
  const intervalRef = useRef(null)

  // React callback used to extract and parse certain properties from the timing object passed to the component
  const extractTimingData = useCallback((timing) => {
    const shouldTick = get(timing, 'shouldTick', false)
    const elapsed = get(timing, 'elapsedSeconds', 0)
    const expectedTotalSeconds = get(timing, 'expectedTotalSeconds', 0)
    const totalSecondsValue = get(timing, 'totalSeconds', expectedTotalSeconds)

    return {
      shouldTick,
      elapsed,
      totalSecondsValue,
    }
  }, [timing])

  // React callback function which ticks from a javascript interval and updates elapsed time
  const intervalTicker = useCallback(() => {
    // Update internal state
    setElapsedSeconds((current) => {
      return current + 1
    })
  }, [timing, totalSeconds])

  useEffect(() => {
    // We always clear the interval when either one of the dependencies are updated
    clearInterval(intervalRef.current)

    // Extract internal variables from the timing parameter
    const {
      shouldTick,
      elapsed,
      totalSecondsValue,
    } = extractTimingData(timing)

    // Update the total seconds
    setTotalSeconds(totalSecondsValue)
    setTotalTime(moment.utc(totalSecondsValue * 1000).format('HH:mm:ss'))
    setElapsedSeconds(elapsed)

    // Begin the interval when shouldTick is true
    if (shouldTick) {
      intervalRef.current = setInterval(() => {
        intervalTicker()
      }, 1000)
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [
    timing,
    extractTimingData,
    intervalTicker,
  ])

  // Automatically clear the interval and stop the timer when we've reached or exceeded the total expected seconds
  useEffect(() => {
    if (elapsedSeconds > totalSeconds || statusKey !== 'running') {
      clearInterval(intervalRef.current)
    }

    // Internal calculations
    const readableTime = moment.utc(elapsedSeconds * 1000).format('HH:mm:ss')

    // Update internal state
    setElapsedTime(readableTime)
  }, [
    elapsedSeconds,
    totalSeconds,
    statusKey,
  ])

  return (
    <>
      <span className="inline-block" style={{ width: 60 }}>
        {elapsedTime}
      </span>
      <span className="mx-1">/</span>
      <span className="inline-block" style={{ width: 60 }}>
        {totalTime}
      </span>
    </>
  )
}
