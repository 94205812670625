import { isEmpty, isObject } from 'lodash-es'
import { addMethod, mixed } from 'yup'

// Add Methods
addMethod(mixed, 'populatedObject', function populatedObject() {
  return this.transform((value, originalValue) => {
    if (originalValue === null || originalValue === undefined || originalValue === '') {
      return {}
    }

    return value
  }).test({
    name: 'populatedObject',
    test: (value, context) => {
      if (!isObject(value) || (isObject(value) && isEmpty(value))) {
        let name = context?.schema?.spec?.label || context.path

        return context.createError({
          path: context.path,
          message: `${name} is a required field`,
        })
      }

      return true
    },
  })
})
