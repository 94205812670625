import Tippy from '@tippyjs/react'
import { clamp, get, round } from 'lodash-es'
import moment from 'moment'
import { useCallback, useEffect, useRef, useState } from 'react'

import HorizontalProgressBar from '@/Components/common/progressbars/HorizontalProgressBar'

/**
 * ElapsedTimerWithProgress is a React component that displays a progress bar and elapsed time for a given timer.
 *
 * @param {Object} options - The options for the timer.
 * @param {Object} options.timing - The timing object that contains information about the timer.
 * @param {number} options.timing.shouldTick - Indicates whether the timer should tick or not.
 * @param {number} options.timing.expectedTotalSeconds - The expected total seconds for the timer.
 * @param {number} options.timing.totalSeconds - The current total seconds for the timer.
 * @param {Date} [options.startedAt] - The start time of the timer. Defaults to the current time if not provided.
 * @param {string} [options.statusKey='stopped'] - The status key for the timer.
 *
 * @return {React.Element} The rendered component.
 */
export default function ElapsedTimerWithProgress({
  timing,
  statusKey = 'stopped',
}) {
  // Internal component state variables
  const [elapsedSeconds, setElapsedSeconds] = useState(0)
  const [elapsedTime, setElapsedTime] = useState('00:00:00')
  const [totalSeconds, setTotalSeconds] = useState(0)
  const [totalTime, setTotalTime] = useState('00:00:00')
  const [percentage, setPercentage] = useState(0)
  const [timerOutOfSyncWarning, setTimerOutOfSyncWarning] = useState(false)

  // Internal interval reference for the timer
  let intervalRef = useRef(null)
  let noStopInterval = useRef(null)

  // React callback used to extract and parse certain properties from the timing object passed to the component
  const extractTimingData = useCallback((timing) => {
    const shouldTick = get(timing, 'shouldTick', false)
    const secondsRemaining = get(timing, 'totalSeconds', 0)
    const elapsed = secondsRemaining - get(timing, 'elapsedSeconds', 0)
    const readableTotalTime = get(timing, 'expectedTotalTime', '00:00:00')
    const fullRuntimeInSeconds = get(timing, 'expectedTotalSeconds', 0)

    return {
      shouldTick,
      elapsed,
      fullRuntimeInSeconds,
      readableTotalTime,
    }
  }, [timing])

  // React callback function which ticks from a javascript interval and updates elapsed time
  const intervalTicker = useCallback(() => {
    // Update internal state
    setElapsedSeconds((current) => {
      return current - 1
    })
  }, [timing, totalSeconds])

  useEffect(() => {
    // We always clear the interval when either one of the dependencies are updated
    clearInterval(intervalRef.current)

    // Extract internal variables from the timing parameter
    const {
      shouldTick,
      elapsed,
      fullRuntimeInSeconds,
      readableTotalTime,
    } = extractTimingData(timing)

    // Update the total seconds
    setTotalSeconds(fullRuntimeInSeconds)
    setTotalTime(readableTotalTime)
    setElapsedSeconds(elapsed)

    // Begin the interval when shouldTick is true
    if (shouldTick) {
      intervalRef.current = setInterval(() => {
        intervalTicker()
      }, 1000)
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }

      if (noStopInterval.current) {
        clearTimeout(noStopInterval.current)
      }
    }
  }, [
    timing,
    extractTimingData,
    intervalTicker,
  ])

  // Automatically clear the interval and stop the timer when we've reached or exceeded the total expected seconds
  useEffect(() => {
    if (elapsedSeconds > totalSeconds || statusKey !== 'running') {
      clearInterval(intervalRef.current)
    }

    // Internal calculations
    const readableTime = moment.utc((totalSeconds - elapsedSeconds) * 1000).format('HH:mm:ss')
    const progressPercentage = clamp(100 - round((elapsedSeconds / totalSeconds) * 100, 0), 0, 100)

    // Update internal state
    setElapsedTime(readableTime)

    if (!isNaN(progressPercentage)) {
      if (statusKey === 'running' && progressPercentage >= 100 && noStopInterval.current === null) {
        noStopInterval.current = setTimeout(() => {
          setTimerOutOfSyncWarning(true)
        }, 10000)
      }

      setPercentage(progressPercentage)
    }
  }, [
    elapsedSeconds,
    totalSeconds,
    statusKey,
  ])

  return (
    <>
      <div>
        Progress
        {timerOutOfSyncWarning && (
          <Tippy
            theme="light"
            placement="auto"
            trigger="click"
            interactive={true}
            content={
              <>
                Stop command not received.
                Timers may be outdated.
                Click <button onClick={() => {
                  window.location.reload()
                }} className="text-status-queued underline">here</button> to refresh the page.
                If the issue persists, contact support.
              </>
            }
          >
            <i className="fa fa-exclamation-triangle ml-1 text-red-500 hover:cursor-pointer"></i>
          </Tippy>
        )}
      </div>

      <div className="grid grid-cols-2-right-auto text-slate-500">
        <div>
          <span className="inline-block" style={{ width: 60 }}>
            {elapsedTime}
          </span>
          <span className="mx-1">/</span>
          <span className="inline-block" style={{ width: 60 }}>
            {totalTime}
          </span>
        </div>
        <div className="text-right" style={{ minWidth: 36 }}>{percentage}%</div>
      </div>

      <div className="hidden min-h-5 md:block"/>

      <HorizontalProgressBar
        colorName={`status-${statusKey}`}
        percent={percentage}
      />
    </>
  )
}
