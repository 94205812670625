import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/react'
import { filter, find, isFunction } from 'lodash-es'
import { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useRecoilState, useSetRecoilState } from 'recoil'
import styled from 'styled-components'
import * as yup from 'yup'

import { Anchor, Button } from '@/Components/form/Buttons'
import ColorPicker from '@/Components/form/ColorPicker'
import Input from '@/Components/form/Input'
import InputError from '@/Components/form/InputError'
import Select from '@/Components/form/Select'
import Modal from '@/Components/Modal'
import { isLoadingState, pageAlertState } from '@/Config/Atoms/General'
import { getProfiles } from '@/Utilities/Accessors/Profiles'
import { publish } from '@/Utilities/Events'
import { flattenSelectValues, formatKeys, toOptions } from '@/Utilities/Form/Formatter'
import useApiClient from '@/Utilities/useApiClient'
import useTitle from '@/Utilities/useTitle'

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
`

const ProfileForm = styled.form`
  label {
    margin-top: 20px;
  }

  .buttons {
    margin-top: 40px;
  }
`

const schema = yup.object({
  parentId: yup.mixed().label('Parent').nullable(),
  name: yup.string().label('Name').required().min(3).max(50),
  description: yup.string().label('Description').max(250).nullable(),
  color: yup.string().label('Color').required().min(3).max(250),
})

function ProfileModal(props) {
  const apiClient = useApiClient()
  const [isLoading, setIsLoading] = useRecoilState(isLoadingState)
  const [initialising, setInitialising] = useState(true)
  const [profileOptions, setProfileOptions] = useState([])
  const setAlert = useSetRecoilState(pageAlertState)
  const {
    reset,
    control,
    register,
    handleSubmit,
    formState,
  } = useForm({ resolver: yupResolver(schema) })
  const {
    errors,
  } = formState

  const isEditing = props.data?.isEditing || false
  let profile

  if (props.data?.profile) {
    profile = props.data.profile
  }

  useTitle([`${isEditing ? 'Edit' : 'Add'} profile`, profile?.name])

  useEffect(() => {
    (async () => {
      const query = new URLSearchParams([['pageSize', 1000]])

      const profiles = filter(await getProfiles(apiClient, query), (profile) => {
        return !profile.parentId
      })

      setProfileOptions(toOptions(profiles, ['name', 'label'], ['id', 'value']))
      setInitialising(false)
    })()
  }, [])

  const onSubmit = async (data) => {
    setAlert(null)

    try {
      setIsLoading(true)

      let endpoint
      if (isEditing) {
        endpoint = `/profile/update/${profile.id}`
      } else {
        endpoint = '/profile/create'
      }

      data = flattenSelectValues(data)
      data = formatKeys(data, 'snake')

      let { data: responseData } = await apiClient.post(`${endpoint}`, data)

      if (responseData.success) {
        if (isFunction(props.data?.onSave)) {
          props.data.onSave()
        }

        publish('profileCreated')

        setAlert({
          type: 'success',
          content: `Profile ${responseData.profile.name} has been successfully ${isEditing ? 'updated' : 'created'}.`,
        })

        props.close()
      }
    } catch (error) {
      Sentry.captureException(error)
      setAlert({
        type: 'error',
        content: 'An error has occured and we were not able to save this profile. Please try again.',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const tooltipText = useMemo(() => {
    if (isEditing && profile.children.length) {
      return `To use this profile as a parent you will need
        to remove all sub-categories first. This ensures that
        there is no data conflict or confusion between parent
        and child categories.`
    }

    return `To use this profile as a parent, leave this field blank.
      This is particularly useful when you want to create sub-categories
      under this profile.`
  }, [isEditing])

  return (
    <Modal
      title={isEditing ? `Edit Profile ${profile.name}` : 'Add Profile'}
      close={props.close}
      closeOnOutsideClick={props.closeOnOutsideClick}
    >
      <ProfileForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        {
          !initialising &&
          <>
            <Controller
              control={control}
              defaultValue={find(profileOptions, ['value', profile?.parentId]) || ''}
              name="parentId"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    isMulti={false}
                    isSearchable={true}
                    label="Parent"
                    isLoading={initialising}
                    options={profileOptions}
                    hasError={!!errors.parentId}
                    helpTooltip={tooltipText}
                    isDisabled={isEditing && profile.children.length}
                  />
                )
              }}
            />
            {errors.parentId && <InputError message={errors.parentId.message} />}
          </>
        }

        <Input
          name="name"
          label="Name"
          className={errors.name && 'error'}
          isRequired={true}
          {...register('name', { value: profile?.name })}
        />
        {errors.name && <InputError message={errors.name.message} />}

        <Input
          name="description"
          label="Description"
          colorPicker={true}
          className={errors.description && 'error'}
          {...register('description', { value: profile?.description })}
        />
        {errors.description && <InputError message={errors.description.message} />}

        <ColorPicker
          name="color"
          label="Color"
          anchor="bottom"
          isRequired={true}
          className={errors.color && 'error'}
          defaultValue={profile?.color}
          {...register('color', { value: profile?.color })}
        />
        {errors.color && <InputError message={errors.color.message} />}

        <ButtonGroup className="buttons">
          <Anchor
            style={{ width: 'calc(50% - 5px)' }}
            className="transparent"
            onClick={() => {
              setAlert(null)
              reset()
              props.close()
            }}
          >
            Close
          </Anchor>

          <Button
            style={{ width: 'calc(50% - 5px)' }}
            disabled={isLoading ? true : false}
          >
            {isLoading ? <div className="primary-loader light"></div> : 'Save'}
          </Button>
        </ButtonGroup>
      </ProfileForm>
    </Modal>
  )
}

export default ProfileModal
