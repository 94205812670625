import * as Sentry from '@sentry/react'
import classNames from 'classnames'
import { filter, head, isEmpty, map } from 'lodash-es'
import { useEffect, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useSetRecoilState } from 'recoil'

import Input from '@/Components/form/Input'
import InputError from '@/Components/form/InputError'
import Select from '@/Components/form/Select'
import { pageAlertState } from '@/Config/Atoms/General'
import { getProfileOptions } from '@/Utilities/Accessors/Profiles'
import useApiClient from '@/Utilities/useApiClient'

function CommonFields(props) {
  const apiClient = useApiClient()
  const [profileOptions, setProfileOptions] = useState()
  const [siteOptions, setSiteOptions] = useState(null)
  const [sitesLoading, setSitesLoading] = useState(true)
  const setAlert = useSetRecoilState(pageAlertState)

  const inputOutput = useMemo(() => {
    return props.data?.io
  }, [props.data?.io])

  const { inputOutputTypeOptions } = props

  const isEditing = !isEmpty(inputOutput)

  const {
    control,
    errors,
    register,
    watch,
    setValue,
  } = useFormContext()

  const inputOutputType = watch('type')

  useEffect(() => {
    if (inputOutputType?.value != 'digitalOutputValve') {
      setValue('profileIds', [])
    }
  }, [inputOutputType])

  useEffect(() => {
    (async () => {
      // Get sites
      try {
        const { data } = await apiClient.get('/sites')

        if (data && data.sites) {
          const siteOptions = map(data.sites, (site) => {
            return {
              value: site.id,
              label: site.name,
              isDisabled: !site.active,
            }
          })

          setSiteOptions(siteOptions)
          setSitesLoading(false)
        }
      } catch (error) {
        Sentry.captureException(error)
        setAlert({
          type: 'error',
          content: 'Failed to retrieve sites.',
        })
      }

      // Get profile options
      setProfileOptions(await getProfileOptions(apiClient))
    })()
  }, [])

  return (
    <>
      {
        siteOptions &&
        <>
          <Controller
            control={control}
            defaultValue={head(filter(siteOptions, ['value', inputOutput?.siteId]))}
            name="siteId"
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  isMulti={false}
                  isSearchable={true}
                  label="Site"
                  isRequired={true}
                  isLoading={sitesLoading}
                  options={siteOptions}
                  placeholder="Search"
                  hasError={!!errors?.siteId}
                />
              )
            }}
          />

          {errors?.siteId && <InputError message={errors?.siteId.message} />}
        </>
      }

      {(inputOutputTypeOptions) && (
        <>
          <Controller
            control={control}
            name="type"
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  isMulti={false}
                  isSearchable={true}
                  label="I/O type"
                  isRequired={true}
                  options={inputOutputTypeOptions}
                  placeholder="Search"
                  hasError={!!errors?.type}
                  isDisabled={isEditing}
                />
              )
            }}
          />

          {errors?.type && <InputError message={errors?.type.message} />}
        </>
      )}

      {
        (!isEditing || (isEditing && profileOptions)) && (
          <div className="hidden">
            <div className={classNames({ hidden: inputOutputType?.value != 'inputOutput.digitalOutputValve' })}>
              <Controller
                control={control}
                name="profileIds"
                defaultValue={
                  isEditing ? map(inputOutput?.profiles, (profile) => {
                    return {
                      value: profile.id,
                      label: profile.name,
                      color: profile.color,
                    }
                  }) : []
                }
                render={({ field: controlledField }) => {
                  return (
                    <>
                      <Select
                        {...controlledField}
                        isMulti={true}
                        isSearchable={true}
                        label="Profiles"
                        placeholder="Search"
                        options={profileOptions}
                        hasError={!!errors.profiles}
                      />
                    </>
                  )
                }}
              />

              {errors.profiles && <InputError message={errors.profiles.message} />}
            </div>
          </div>
        )
      }

      <Input
        label="Name"
        isRequired={true}
        type="text"
        className={errors?.name && 'error'}
        {...register('name', { value: inputOutput?.name })}
      />
      {errors?.name && <InputError message={errors?.name.message} />}

      <Input
        label="Description"
        type="text"
        className={errors?.description && 'error'}
        {...register('description', { value: inputOutput?.description })}
      />
      {errors?.description && <InputError message={errors?.description.message} />}
    </>
  )
}

export default CommonFields
