import '@/Utilities/Form/Yup'

import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/react'
import classNames from 'classnames'
import { get, isFunction, map } from 'lodash-es'
import { useCallback, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useRecoilState, useSetRecoilState } from 'recoil'

import { Anchor, Button } from '@/Components/form/Buttons'
import Modal from '@/Components/Modal'
import DataSets from '@/Components/modals/reporting/DataSets'
import General from '@/Components/modals/reporting/General'
import Tabs from '@/Components/Tabs'
import { isLoadingState, pageAlertState } from '@/Config/Atoms/General'
import { flattenSelectValues, formatKeys } from '@/Utilities/Form/Formatter'
import useApiClient from '@/Utilities/useApiClient'
import ChartSchema from '@/Utilities/Validation/ChartSchema'

export default function ChartModal(props) {
  let isEditing = props.data?.chart.isEditing || false

  const apiClient = useApiClient()
  const [isLoading, setIsLoading] = useRecoilState(isLoadingState)
  const [selectedTab, setSelectedTab] = useState('general')
  const form = useRef(null)
  const [tabErrors, setTabErrors] = useState({})
  const setAlert = useSetRecoilState(pageAlertState)
  const {
    trigger,
    reset,
    watch,
    setValue,
    getValues,
    control,
    register,
    handleSubmit,
    formState,
  } = useForm({
    resolver: yupResolver(ChartSchema),
    defaultValues: {
      dataSets: isEditing ? [] : [{
        type: '',
        inputOutputId: '',
        property: '',
      }],
    },
  })

  const {
    errors,
    dirtyFields,
  } = formState

  const tabs = [{
    title: 'General',
    key: 'general',
    component: General,
    hasError: tabErrors['general'],
  }, {
    title: 'Data sets',
    key: 'dataSets',
    component: DataSets,
    hasError: tabErrors['dataSets'],
  }]

  const tabChange = useCallback((value) => {
    setSelectedTab(value)
  }, [setSelectedTab])

  const onSubmit = async (data) => {
    try {
      setAlert(null)
      setIsLoading(true)

      data.dataSets = map(data.dataSets, (dataSet) => {
        return flattenSelectValues(dataSet)
      })

      data = flattenSelectValues(data)
      data = formatKeys(data, 'snake')

      let { data: responseData } = await apiClient.post(`${isEditing ? `/chart/update/${props.data?.chart?.id}` : '/chart/create'}`, data)

      if (responseData.success) {
        setAlert({
          type: 'success',
          content: `${responseData.chart.name} has been successfully ${isEditing ? 'updated' : 'created'}.`,
        })

        if (isFunction(props.data?.onSave)) {
          props.data.onSave()
        }

        if (isFunction(props.close)) {
          props.close()
        }
      }
    } catch (error) {
      Sentry.captureException(error)
      setAlert({
        type: 'error',
        content: 'An error has occured and we were not able to save this dashboard. Please try again.',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      title={`${isEditing ? 'Edit': 'Add'} chart ${get(props, 'data.chart.name', '')}`}
      close={props.close}
      closeOnOutsideClick={props.closeOnOutsideClick}
    >
      {isLoading ? (
        <div className="flex h-80 items-center justify-center">
          <div className="primary-loader"></div>
        </div>
      ) : (
        <>
          <Tabs
            currentlySelected={selectedTab}
            defaultTab="general"
            tabs={tabs}
            onChange={tabChange}
          />
          <FormProvider
            control={control}
            dirtyFields={dirtyFields}
            errors={errors}
            getValues={getValues}
            handleSubmit={handleSubmit}
            register={register}
            reset={reset}
            setValue={setValue}
            trigger={trigger}
            watch={watch}
          >
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" ref={form} className="mt-5">
              {map(tabs, (tab) => {
                return (
                  <div className={classNames({ hidden: tab.key != selectedTab })} key={tab.key} id={tab.key}>
                    <tab.component
                      {...props}
                      tabErrors={tabErrors}
                      setTabErrors={setTabErrors}
                    />
                  </div>
                )
              })}

              <div className="mt-7 grid grid-cols-2 justify-between gap-2.5">
                <Anchor
                  className="transparent"
                  onClick={() => {
                    setAlert(null)
                    reset()
                    props.close()
                  }}
                >
                    Close
                </Anchor>

                <Button
                  style={{ flexGrow: 1 }}
                  disabled={isLoading ? true : false}
                >
                  {isLoading ? <div className="primary-loader light"></div> : 'Save'}
                </Button>
              </div>
            </form>
          </FormProvider>
        </>
      )}
    </Modal>
  )
}
