import { compact, flattenDeep, isString, isUndefined, join } from 'lodash-es'
import { useEffect, useRef } from 'react'

/**
 * `useTitle` is a custom React Hook that updates the document title.
 * The updated title is a string consisting of the provided `title` and "FutureOps", separated by ' - '.
 * The hook also has the ability to restore the original title when the component unmounts.
 *
 * @param {string | string[]} title - The new title or an array of strings to be joined to form the new title.
 *                                    This will be appended with 'FutureOps'.
 * @param {Object} options - An optional parameter for additional configurations.
 * @param {boolean} options.restoreOnUnmount - If set to `true` or undefined, the original title is restored when
 *                                             the component using the hook unmounts. Defaults to `true`.
 *
 * @example
 * useTitle('My Page', { restoreOnUnmount: false })
 */
export default function useTitle(title, options = {}) {
  const originalTitle = useRef(document.title)

  useEffect(() => {
    if (isString(title)) {
      title = [title]
    }

    title = compact(flattenDeep([...title, 'FutureOps']))
    document.title = join(title, ' - ')

    if (isUndefined(options.restoreOnUnmount) || options.restoreOnUnmount) {
      return () => {
        document.title = originalTitle.current
      }
    }
  }, [title])
}
