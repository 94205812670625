import { isEmpty } from 'lodash-es'
import { useEffect } from 'react'
import { useRecoilState } from 'recoil'

import AreaModal from '@/Components/modals/AreaModal'
import AvatarModal from '@/Components/modals/AvatarModal'
import CardModal from '@/Components/modals/CardModal'
import ConditionModal from '@/Components/modals/condition/ConditionModal'
import ConfirmationPromptModal from '@/Components/modals/ConfirmationPromptModal'
import DebugPermissionsModal from '@/Components/modals/DebugPermissionsModal'
import InputOutputModal from '@/Components/modals/inputOutput/InputOutputModal'
import InviteModal from '@/Components/modals/InviteModal'
import MainLineModal from '@/Components/modals/mainLine/MainLineModal'
import OrganisationModal from '@/Components/modals/OrganisationModal'
import ProfileModal from '@/Components/modals/ProfileModal'
import ProgramModal from '@/Components/modals/program/ProgramModal'
import ProgramSetModal from '@/Components/modals/programSet/ProgramSetModal'
import RemoteUnitModal from '@/Components/modals/RemoteUnitModal'
import ChartModal from '@/Components/modals/reporting/ChartModal'
import ReportingDashboardModal from '@/Components/modals/reporting/ReportingDashboardModal'
import ReportingFilterModal from '@/Components/modals/reporting/ReportingFilterModal'
import SiteModal from '@/Components/modals/SiteModal'
import UserProfileModal from '@/Components/modals/UserProfileModal'
import WarningPromptModal from '@/Components/modals/WarningPromptModal'
import { modalState } from '@/Config/Atoms/General'

function Modals() {
  const [modal, setModal] = useRecoilState(modalState)

  useEffect(() => {
    document.body.style.overflow = isEmpty(modal) ? 'visible' : 'hidden'

    return () => {
      document.body.style.overflow = 'visible'
    }
  }, [modal])

  return !isEmpty(modal) ? (
    <>
      {modal.name === 'area' &&
        <AreaModal
          data={modal?.data}
          close={() => {
            setModal(null)
          }}
        />
      }

      {modal.name === 'site' &&
        <SiteModal
          data={modal?.data}
          close={() => {
            setModal(null)
          }}
        />
      }

      {modal.name === 'userProfile' &&
        <UserProfileModal
          data={modal?.data}
          close={() => {
            setModal(null)
          }}
        />
      }

      {modal.name === 'profile' &&
        <ProfileModal
          data={modal?.data}
          close={() => {
            setModal(null)
          }}
        />
      }

      {modal.name === 'avatar' &&
        <AvatarModal
          data={modal?.data}
          close={() => {
            setModal(null)
          }}
        />
      }

      {modal.name === 'organisation' &&
        <OrganisationModal
          data={modal?.data}
          close={() => {
            setModal(null)
          }}
        />
      }

      {modal.name === 'program' &&
        <ProgramModal
          data={modal?.data}
          close={() => {
            setModal(null)
          }}
        />
      }

      {modal.name === 'set' &&
        <ProgramSetModal
          data={modal?.data}
          close={() => {
            setModal(null)
          }}
        />
      }

      {modal.name === 'mainLine' &&
        <MainLineModal
          data={modal?.data}
          close={() => {
            setModal(null)
          }}
        />
      }

      {modal.name === 'io' &&
        <InputOutputModal
          data={modal?.data}
          close={() => {
            setModal(null)
          }}
        />
      }

      {modal.name === 'condition' &&
        <ConditionModal
          data={modal?.data}
          close={() => {
            setModal(null)
          }}
        />
      }

      {modal.name === 'remoteUnit' &&
        <RemoteUnitModal
          data={modal?.data}
          close={() => {
            setModal(null)
          }}
        />
      }

      {modal.name === 'invite' &&
        <InviteModal
          data={modal?.data}
          close={() => {
            setModal(null)
          }}
        />
      }

      {modal.name === 'warning' &&
        <WarningPromptModal
          data={modal?.data}
          close={() => {
            setModal(null)
          }}
        />
      }

      {modal.name === 'confirmation' &&
        <ConfirmationPromptModal
          data={modal?.data}
          close={() => {
            setModal(null)
          }}
        />
      }

      {modal?.name === 'reportingDashboard' &&
        <ReportingDashboardModal
          data={modal?.data}
          close={() => {
            setModal(null)
          }}
        />
      }

      {modal?.name === 'chart' &&
        <ChartModal
          data={modal?.data}
          close={() => {
            setModal(null)
          }}
        />
      }

      {modal?.name === 'reportingFilter' &&
        <ReportingFilterModal
          data={modal?.data}
          close={() => {
            setModal(null)
          }}
        />
      }

      {modal?.name === 'card' &&
        <CardModal
          data={modal?.data}
          close={() => {
            setModal(null)
          }}
        />
      }

      {modal?.name === 'debugPermissions' &&
        <DebugPermissionsModal
          data={modal?.data}
          close={() => {
            setModal(null)
          }}
        />
      }
    </>
  ) : null
}

export default Modals
