import { get, includes } from 'lodash-es'
import * as yup from 'yup'

export default yup.object().shape({
  reportingDashboardId: yup.mixed().label('Dashboard').populatedObject(),
  name: yup.string().label('Name').required().min(3).max(50),
  dataSets: yup.array()
    .of(
      yup.object({
        type: yup.mixed().label('Type').populatedObject(),
        inputOutputId: yup.mixed().label('Element').populatedObject(),
        property: yup.mixed().label('Property').populatedObject(),
        propertyDepth: yup.mixed().label('Depth').when('inputOutputId', ([inputOutputId], schema) => {
          const requiresDepth = ['sensorSoilProbe']

          if (includes(requiresDepth, get(inputOutputId, 'type'))) {
            return schema.populatedObject()
          }

          return schema.nullable()
        }),
      }),
    ),
})
