import routes from '@/Config/Routes'

export default function Router(props) {
  const { useSentryRoutes } = props

  const routing = useSentryRoutes(routes)

  return (
    <>
      {routing}
    </>
  )
}
