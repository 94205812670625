import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/react'
import { rem } from 'polished'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { atom, useRecoilState } from 'recoil'
import styled from 'styled-components'
import * as yup from 'yup'

import AuthAlert from '@/Components/AuthAlert'
import { Button } from '@/Components/form/Buttons'
import InputField from '@/Components/form/Input'
import InputError from '@/Components/form/InputError'
import { authAlertState } from '@/Config/Atoms/Auth'
import useApiClient from '@/Utilities/useApiClient'
import useTitle from '@/Utilities/useTitle'

const Icon = styled.div`
  align-items: center;
  background-color: #f5fbff;
  border-radius: 100%;
  color: var(--primary);
  display: flex;
  height: 56px;
  justify-content: center;
  width: 56px;
`

const ErrorContainter = styled.div`
  margin-bottom: 26px;
`

const Title = styled.h1`
  color: white;
  font-size: ${rem(30)};
  font-weight: 700;
  margin-top: 24px;
  text-align: center;
`

const SubText = styled.div`
  color: white;
  font-size: ${rem(16)};
  font-weight: 300;
  margin: 12px 0 32px;
  text-align: center;
`

const FormContainer = styled.form`
  margin: auto 0;
  width: 360px;
  max-width: 100%;

  label {
    margin-top: 20px;
  }

  button {
    margin-top: 24px;
  }
`

const BackText = styled.div`
  color: white;
  font-size: ${rem(14)};
  font-weight: 300;
  margin: 32px auto;
  text-align: center;

  a {
    text-decoration: none;
  }
`

const hashValidState = atom({
  key: 'hashValid',
  default: false,
})

const schema = yup.object({
  password: yup.string().label('Password').required().min(6).max(100),
  passwordConfirm: yup.string().label('Confirm password').oneOf([yup.ref('password'), null], 'Confirm password does not match').required(),
})

function ResetPassword() {
  const apiClient = useApiClient()
  const [alert, setAlert] = useRecoilState(authAlertState)
  const [hashValid, setHashValid] = useRecoilState(hashValidState)
  const navigate = useNavigate()
  const urlParams = useParams()
  const {
    register,
    handleSubmit,
    formState,
  } = useForm({ resolver: yupResolver(schema) })
  const { errors } = formState

  useTitle('Reset password')

  useEffect(() => {
    (async() => {
      try {
        const { data } = await apiClient.post('/user/reset-password/validate', { hash: urlParams.hash })

        if (data?.success) {
          setHashValid(true)
        } else {
          navigate('/user/login')
        }

        return () => {
          setHashValid(false)
        }
      } catch (error) {
        Sentry.captureException(error)
      }
    })()
  }, [
    urlParams,
    setHashValid,
    navigate,
  ])

  const sendReset = async (data) => {
    setAlert(null)

    try {
      let { data: responseData } = await apiClient.post('/user/reset-password/reset', {
        password: data.password,
        password_confirmation: data.passwordConfirm,
        hash: urlParams.hash,
      })

      if (responseData?.success) {
        navigate('/user/login')
        setAlert({
          type: 'success',
          message: 'Your password was reset successfully.',
        })
      }
    } catch (error) {
      setAlert({ message: 'Oops, something went wrong. Please try again.' })
      Sentry.captureException(error)
    }
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="flex-column col-12 flex items-center">
          {!hashValid && (
            <>
              <div className="col-12 flex justify-center">
                <Icon>
                  <i className="fa-solid fa-key-skeleton fa-spin"></i>
                </Icon>
              </div>

              <SubText>Validating password reset request</SubText>
            </>
          )}

          {hashValid && (
            <FormContainer onSubmit={handleSubmit(sendReset)} autoComplete="off">
              <div className="col-12 flex justify-center">
                <Icon>
                  <i className="fa-solid fa-key-skeleton"></i>
                </Icon>
              </div>

              <Title>Forgot password?</Title>
              <SubText>No worries, we'll send you reset instructions.</SubText>

              <ErrorContainter>
                <AuthAlert class={alert ? 'flex' : 'd-none'} message={alert?.message} />
              </ErrorContainter>

              <InputField
                data-sentry-mask
                label="New password"
                placeholder="Password"
                type="password"
                autoComplete="new-password"
                className={errors.password && 'error'}
                {...register('password')}
              />
              {errors.password && <InputError message={errors.password.message} />}

              <InputField
                data-sentry-mask
                label="Confirm Password"
                placeholder="Confirm password"
                type="password"
                autoComplete="new-password"
                className={errors.passwordConfirm && 'error'}
                {...register('passwordConfirm')}
              />
              {errors.passwordConfirm && <InputError message={errors.passwordConfirm.message} />}

              <Button buttonWidth="100%">
                Reset password
              </Button>

              <BackText>
                <Link to="/user/login"><i className="fa-solid fa-arrow-left"></i> Back to log in</Link>
              </BackText>
            </FormContainer>
          )}
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
