import * as Sentry from '@sentry/react'
import { get, map } from 'lodash-es'

import { rayOnce } from '@/Utilities/useRayDebug'

export const getSites = async (apiClient) => {
  try {
    const { data } = await apiClient.get('/sites')

    if (data) {
      return data
    }
  } catch (error) {
    Sentry.captureException(error)
  }

  return null
}

export const getAllSitesUsingQuery = async (apiClient, ...queries) => {
  const maxResults = 1000

  const query = new URLSearchParams([
    ['page', 1],
    ['pageSize', maxResults],
    ...queries,
  ])

  try {
    const response = await apiClient.get(`/site/query?${query}`)

    return get(response, 'data.sites.data')
  } catch (error) {
    rayOnce(error, {
      color: 'orange',
      label: 'Error',
    })
  }
}

export const getSiteOptions = async (apiClient) => {
  const data = await getSites(apiClient)

  let sites = map(data.sites, (site) => {
    return {
      value: site.id,
      label: site.name,
      isDisabled: !site.active,
    }
  })

  return sites
}
