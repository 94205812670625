import { flatMap, get, head, isArray, isEmpty, isInteger, nth, tail, toNumber, toPath } from 'lodash-es'

const getByPath = (object, path) => {
  // Split the path into components
  const parts = toPath(path)

  // The recursive function that will process each part of the path
  function innerGet(current, remainingPath) {
    if (isEmpty(remainingPath)) {
      // Base case: if there's no path left, we've reached a target value
      return current
    }

    // Use Lodash's head and tail to manage path parts
    const nextPart = head(remainingPath)
    const rest = tail(remainingPath)

    // Check if the next part is a positive integer
    if (isInteger(toNumber(nextPart))) {
      // Use Lodash's nth to handle negative indexes as well
      return innerGet(nth(current, toNumber(nextPart)), rest)
    } else if (nextPart === '*') {
      // Handle wildcards with flatMap for recursion
      if (isArray(current)) {
        return flatMap(current, (item) => {
          return innerGet(item, rest)
        })
      } else {
        // If it's not an array, we treat the '*' as a normal property
        return innerGet(current[nextPart], rest)
      }
    } else {
      // Handle normal properties with get for direct access
      return innerGet(get(current, nextPart), rest)
    }
  }

  // Start the recursive process using get to support paths without wildcards
  return innerGet(object, parts)
}

export { getByPath }
