import { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

import { subscribe, unsubscribe } from '@/Utilities/Events'

const portalWrapper = document.createElement('div')
document.body.appendChild(portalWrapper)

export default function Portal({
  children,
  shouldOutsideClick,
  triggerOutsideClickOnScroll,
  onOutsideClick,
}) {
  const portalElement = useRef(document.createElement('div'))

  useEffect(() => {
    portalWrapper.appendChild(portalElement.current)
    return () => {
      portalWrapper.removeChild(portalElement.current)
    }
  }, [])

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!portalElement?.current?.contains(event.target)) {
        onOutsideClick(event)
      }
    }

    const handleScroll = (event) => {
      onOutsideClick(event)
    }

    if (shouldOutsideClick) {
      window.addEventListener('mousedown', handleOutsideClick)
    }

    if (triggerOutsideClickOnScroll) {
      window.addEventListener('scroll', handleScroll)
      subscribe('modal.scroll', handleScroll)
    }

    return () => {
      window.removeEventListener('mousedown', handleOutsideClick)
      window.removeEventListener('scroll', handleScroll)
      unsubscribe('modal.scroll', handleScroll)
    }
  }, [onOutsideClick])

  return createPortal(children, portalElement.current)
}
