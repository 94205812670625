import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/react'
import classNames from 'classnames'
import { compact, countBy, debounce, filter, forEach, get, isEmpty, isFunction, map, size, toLower } from 'lodash-es'
import { useMemo } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useRecoilState, useSetRecoilState } from 'recoil'
import styled from 'styled-components'
import * as yup from 'yup'

import { Anchor, Button } from '@/Components/form/Buttons'
import Input from '@/Components/form/Input'
import InputError from '@/Components/form/InputError'
import Select from '@/Components/form/Select'
import Modal from '@/Components/Modal'
import { isLoadingState, pageAlertState } from '@/Config/Atoms/General'
import useApiClient from '@/Utilities/useApiClient'
import useTitle from '@/Utilities/useTitle'

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
`

const InviteForm = styled.form`
  label {
    margin-top: 20px;
  }

  .buttons {
    margin-top: 40px;
  }
`

const RemoveLine = styled.div`
  border-radius: 100%;
  color: var(--error-text);
  padding: 9px;

  &:hover {
    cursor: pointer;
  }
`

const checkEmail = debounce((apiClient, emails, callback) => {
  apiClient.post('/validation/user/emails', { emails })
    .then((response) => {
      const errors = map(get(response, 'data', []), (validation, index) => {
        if (validation.exists) {
          return new yup.ValidationError('This email address is already in use', validation.email, `invites[${index}].email`)
        }

        return null
      })

      if (!isEmpty(compact(errors))) {
        callback(new yup.ValidationError(compact(errors)))
      } else {
        callback(true)
      }
    })
    .catch(() => {
      callback(true)
    })
}, 300)

const generateSchema = (apiClient) => {
  return yup.object().shape({
    invites: yup.array()
      .of(
        yup.object({
          name: yup.string().label('Name').required().min(3).max(50),
          email: yup.string().email().label('Email').required().min(3).max(50),
        }),
      )
      .test('unique-email', 'Email must be unique', function (invites) {
        const emails = map(invites, (invite) => {
          return toLower(invite.email)
        })

        const emailCounts = countBy(emails)

        const errors = []
        forEach(emails, (email, index) => {
          if (emailCounts[email] > 1) {
            errors.push(new yup.ValidationError('Email must be unique', email, `invites[${index}].email`))
          }
        })

        if (size(errors) > 0) {
          return new yup.ValidationError(errors)
        }

        return true
      })
      .test('validate-email', 'This email address is already in use', (invites) => {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

        const validEmail = filter(invites, (invite) => {
          return regex.test(invite.email)
        })

        if (!isEmpty(validEmail)) {
          return new Promise((resolve) => {
            const emails = map(invites, 'email')

            checkEmail(apiClient, emails, resolve)
          })
        }

        return true
      }),
  })
}

function InviteModal(props) {
  const apiClient = useApiClient()
  const [isLoading, setIsLoading] = useRecoilState(isLoadingState)
  const setAlert = useSetRecoilState(pageAlertState)
  const {
    reset,
    register,
    handleSubmit,
    control,
    formState,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(generateSchema(apiClient)),
    defaultValues: {
      invites: [{
        name: '',
        email: '',
        role: {
          value: 'administrator',
          label: 'Administrator',
        },
      }],
    },
  })
  const { errors } = formState
  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'invites',
  })

  useTitle('Invite user')

  const roles = useMemo(() => {
    return [
      {
        value: 'administrator',
        label: 'Administrator',
      },
      {
        value: 'manager',
        label: 'Manager',
      },
      {
        value: 'editor',
        label: 'Editor',
      },
      {
        value: 'read-only',
        label: 'Read-only',
      },
    ]
  }, [])

  const onSubmit = async (data) => {
    setAlert(null)

    try {
      setIsLoading(true)

      let invites = []

      forEach(data.invites, (invite) => {
        invites.push({
          name: invite.name,
          email: invite.email,
          role: invite.role.value,
        })
      })

      let { data: responseData } = await apiClient.post('/invites/send', { invites })

      if (responseData.success) {
        setAlert({
          type: 'success',
          content: 'Congratulations your team is growing.',
        })

        if (isFunction(props.data.onSave)) {
          props.data.onSave()
        }

        props.close()
      }
    } catch (error) {
      Sentry.captureException(error)
      setAlert({
        type: 'error',
        content: 'Oops, something has gone wrong. Please try again.',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      title="Invite user"
      close={props.close}
      closeOnOutsideClick={props.closeOnOutsideClick}
    >
      <InviteForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        {map(fields, (field, index) => {
          return <div key={`invites-${index}`}>
            <div
              className={classNames({ 'border-b mb-5': size(fields) !== index + 1 }, 'flex pb-5')}
            >
              <div className="grid basis-full grid-cols-1 gap-4 md:grid-cols-3">
                <div>
                  <Input
                    className={errors.invites?.[index]?.name && 'error'}
                    placeholder="Name"
                    key={field.id}
                    {...register(`invites[${index}][name]`)}
                  />

                  {errors.invites?.[index]?.name && <InputError message={errors.invites?.[index].name.message} />}
                </div>

                <div>
                  <Input
                    className={errors.invites?.[index]?.email && 'error'}
                    placeholder="Email"
                    leftIcon={true}
                    icon={<i className="fa-regular fa-envelope fa-lg"></i>}
                    {...register(`invites[${index}][email]`)}
                  />
                  {errors.invites?.[index]?.email && <InputError message={errors.invites?.[index].email.message} />}
                </div>

                <div>
                  <Controller
                    control={control}
                    defaultValue={{
                      value: 'manager',
                      label: 'Manager',
                    }}
                    name={`invites.${index}.role`}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          isSearchable={true}
                          options={roles}
                          hasError={!!errors.invites?.[index]?.role}
                        />
                      )
                    }}
                  />
                </div>
              </div>

              <div className="flex w-12 justify-end">
                {index > 0 && (
                  <RemoveLine
                    onClick={() => {
                      return remove(index)
                    }}
                  >
                    <i className="fa-light fa-circle-minus fa-lg"></i>
                  </RemoveLine>
                )}
              </div>
            </div>
            {errors.invites?.[index]?.role && <InputError message={errors.invites?.[index].role.message} />}
          </div>
        })}

        {fields?.length < 10 && (
          <div
            className="mb-2 inline-block hover:cursor-pointer"
            onClick={() => {
              append({
                name: '',
                email: '',
                role: {
                  value: 'administrator',
                  label: 'Administrator',
                },
              }, { focusName: 'invites' })
            }}
          >
            <Anchor className="transparent">
              <i className="fa-solid fa-plus"></i> Add another user
            </Anchor>
          </div>
        )}

        <ButtonGroup className="buttons">
          <Anchor
            style={{ width: 'calc(50% - 5px)' }}
            className="transparent"
            onClick={() => {
              setAlert(null)
              reset()
              props.close()
            }}
          >
            Close
          </Anchor>

          <Button
            style={{ width: 'calc(50% - 5px)' }}
            disabled={isLoading ? true : false}
          >
            {isLoading ? <div className="primary-loader light"></div> : 'Save'}
          </Button>
        </ButtonGroup>
      </InviteForm>
    </Modal>
  )
}

export default InviteModal
