import styled from 'styled-components'

import { Anchor } from '@/Components/form/Buttons'
import ImageUpload from '@/Components/form/ImageUpload'
import Modal from '@/Components/Modal'

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
`

function AvatarModal(props) {
  return (
    <Modal
      modalWidth="300px"
    >
      <ImageUpload
        type="file"
        name="avatar"
      />

      <ButtonGroup className="buttons">
        <Anchor
          style={{ width: '100%' }}
          className="transparent"
          onClick={() => {
            props.data.onClose()
            props.close()
          }}
        >
          Close
        </Anchor>
      </ButtonGroup>
    </Modal>
  )
}

export default AvatarModal
