import { rem } from 'polished'
import styled from 'styled-components'

const InstructionWrapper = styled.div`
  align-items: center;
  background-color: #f5fbff;
  border-radius: 5px;
  color: var(--text-light);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  padding: 25px 15px;
  box-shadow: 0px 1px 3px rgba(16,24,40,0.1), 0px 1px 2px rgba(16,24,40,0.06);

  .heading {
    color: var(--primary);
    font-size: ${rem(18)};
  }

  .content {
    font-size: ${rem(14)};
    margin-top: 20px;
    text-align: center;
    width: 100%;

    p, .section {
      margin-bottom: ${rem(18)};
    }
  }

  & > .content {
    margin-top: 0;
  }
`

function InstructionBox(props) {
  return (
    <InstructionWrapper {...props}>
      {props.heading && <div className="heading">{props.heading}</div>}

      {props.children && <div className="content">{props.children}</div>}
    </InstructionWrapper>
  )
}

export default InstructionBox
