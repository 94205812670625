import * as Sentry from '@sentry/react'
import classNames from 'classnames'
import { each, head, isArray, isEmpty, isUndefined, map, uniqBy } from 'lodash-es'
import { rem } from 'polished'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import styled from 'styled-components'

import ActionIcon from '@/Components/ActionIcon'
import AlertContent from '@/Components/alerts/AlertContent'
import PermissionGuard from '@/Components/auth/PermissionGuard'
import CircleList from '@/Components/CircleList'
import DropdownList from '@/Components/DropdownList'
import { Anchor } from '@/Components/form/Buttons'
import HeadlessUISelect from '@/Components/headless/form/Select'
import Map from '@/Components/Map'
import AlarmsAll from '@/Components/tables/AlarmsAll'
import SitesAll from '@/Components/tables/SitesAll'
import Tabs from '@/Components/Tabs'
import { modalState, pageAlertState } from '@/Config/Atoms/General'
import { getByPath } from '@/Utilities/Accessors/Helpers'
import { standardActions } from '@/Utilities/Events'
import { formatKeys } from '@/Utilities/Form/Formatter'
import useApiClient from '@/Utilities/useApiClient'
import useAuth from '@/Utilities/useAuth'
import useEntityMonitor from '@/Utilities/useEntityMonitor'
import useEventSubscriber from '@/Utilities/useEventSubscriber'
import usePageGuard from '@/Utilities/usePageGuard'
import useTitle from '@/Utilities/useTitle'

const PageContainer = styled.div`
  padding: 0 24px;
  width: 100%;
`

const Card = styled.div`
  border: 1px solid #E4E7EC;
  border-radius: 5px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  width: 100%;
  margin-top: 15px;
  background: #ffffff;

  &:first-of-type {
    margin-top: 0;
  }
`

const CardDetails = styled.div`
  margin: 15px 0;
`

const CardTitle = styled.div`
  align-items: center;
  border-bottom: 1px solid #E4E7EC;
  color: var(--heading-color);
  display: flex;
  justify-content: space-between;
  font-size: ${rem(18)};
  padding: 14px 24px;

  .more-actions {
    align-items: center;
    display: flex;
    position: relative;

    svg {
      &:hover {
        cursor: pointer;
      }
    }
  }
`

const CardRow = styled.div`
  display: flex;
  font-size: ${rem(14)};
  justify-content: space-between;
  padding: 6px 24px;
`

const CardRowTitle = styled.div`
  align-items: center;
  color: var(--heading-color);
  display: flex;
  font-size: ${rem(14)};
  flex-basis: 50%;
`

const CardRowContent = styled.div`
  color: var(--text-light);
  display: flex;
  font-size: ${rem(14)};
  flex-basis: 50%;
`

const CircleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-basis: 50%;
`

const MapContainer = styled.div`
  display: flex;
  justify-content: center;
  height: calc(100vh - 265px);
`

const tabs = [
  {
    title: 'General',
    key: 'general',
  },
  {
    title: 'Sites',
    key: 'sites',
  },
  {
    title: 'Alarms',
    key: 'alarms',
  },
]

function AreaManage() {
  const [area, setArea] = useState({})
  const [mapInitialized, setMapInitialized] = useState(false)
  const [mapLoaded, setMapLoaded] = useState(false)
  const [selectedTab, setSelectedTab] = useState([])
  const apiClient = useApiClient()
  const auth = useAuth()
  const mapInstance = useRef(null)
  const navigate = useNavigate()
  const routerLocation = useLocation()
  const setAlert = useSetRecoilState(pageAlertState)
  const setModal = useSetRecoilState(modalState)
  const urlParams = useParams()
  const {
    isInRunningState,
    getRelatedProgramNames,
  } = useEntityMonitor()

  const { setSubscriberModelMap } = useEventSubscriber(['area'], standardActions, () => {
    getArea()
  })

  const preventSaving = isInRunningState('area', area?.id)
  const runningProgramNames = getRelatedProgramNames('area', area?.id)

  usePageGuard({
    auth,
    permission: 'view-area',
  })

  useTitle(['Managing area', area?.name])

  const mapInit = (map) => {
    mapInstance.current = map
    setMapInitialized(true)
  }

  const markers = useMemo(() => {
    let markerArray = []

    if (!isEmpty(area?.sites)) {
      each(area.sites, (site) => {
        const operationalStatuses = site.programSets.map((set) => {
          return set.operationalStatuses
        })
        const flattenedStatuses = [].concat(...operationalStatuses)
        const statuses = uniqBy(flattenedStatuses, 'key')

        if (parseFloat(site.lat) && parseFloat(site.lng)) {
          markerArray.push({
            id: `siteId#${site.id}`,
            position: {
              lat: parseFloat(site.lat),
              lng: parseFloat(site.lng),
            },
            title: site.name,
            // TODO: Marker fillColor
            fillColor: head(statuses)?.color,
            draggable: false,
            clickHandler: (event, id) => {
              const siteId = id.split('#')[1]
              navigate(`/site/manage/${siteId}`)
            },
          })
        }
      })

      if (!mapLoaded) {
        setMapLoaded(true)
      }

      return markerArray
    }

    return null
  }, [area])

  const getArea = useCallback(async () => {
    const query = new URLSearchParams([
      ['with[]', 'sites'],
      ['with[]', 'sites.programSets'],
      ['with[]', 'sites.programSets.programs'],
      ['with[]', 'sites.programSets.programs.mainLine'],
      ['with[]', 'sites.inputOutputs'],
    ])

    try {
      let { data } = await apiClient.get(
        `/area/view/${urlParams.id}?${query}`,
      )

      if (data && data.success) {
        const formattedArea = formatKeys(data.area, 'camel')
        setArea(formattedArea)
        setSubscriberModelMap({ area: [formattedArea.id] })
      }
    } catch (error) {
      navigate('/map')
      Sentry.captureException(error)
    }
  }, [urlParams])

  const setFallbackMapCoordinates = useCallback(async () => {
    mapInstance.current.groups.marker.clearLayers()

    let { data } = await apiClient.get(`/google/maps/search/text?query=${area.address}`)

    if (data.success && mapInstance.current.map) {
      const coordinates = data.result.geometry.location

      mapInstance.current.map.setView([coordinates.lat, coordinates.lng], 18)

      setMapLoaded(true)
    }
  }, [area])

  const reloadPage = () => {
    window.location.reload()
  }

  useEffect(() => {
    getArea()
  }, [])

  useEffect(() => {
    const areaIsNotEmpty = !isEmpty(area)
    const areSitesIsEmptyArray = isArray(area?.sites) && isEmpty(area.sites)

    if (mapInitialized && areaIsNotEmpty && areSitesIsEmptyArray) {
      setFallbackMapCoordinates()
    }
  }, [area, mapInitialized])

  useEffect(() => {
    if (routerLocation.state && routerLocation.state.showAlert) {
      setAlert({
        type: 'success',
        content: routerLocation.state.message,
      })
    }
  }, [setAlert, routerLocation])

  const [
    relatedSites,
    relatedProgramSets,
    relatedPrograms,
    relatedMainLines,
    relatedInputOutputs,
  ] = useMemo(() => {
    if (isEmpty(area)) {
      return []
    }

    const sites = getByPath(area, 'sites')
    const programSets = getByPath(area, 'sites.*.programSets')
    const programs = getByPath(area, 'sites.*.programSets.*.programs')
    const mainLines = uniqBy(getByPath(area, 'sites.*.programSets.*.programs.*.mainLine'), 'id')
    const inputOutputs = getByPath(area, 'sites.*.inputOutputs')

    return [
      {
        count: sites.length ? sites.length : 0,
        names: sites.length ? map(sites, 'name') : ['N/A'],
      },
      {
        count: programSets.length ? programSets.length : 0,
        names: programSets.length ? map(programSets, 'name') : ['N/A'],
      },
      {
        count: programs.length ? programs.length : 0,
        names: programs.length ? map(programs, 'name') : ['N/A'],
      },
      {
        count: mainLines.length ? mainLines.length : 0,
        names: mainLines.length ? map(mainLines, 'name') : ['N/A'],
      },
      {
        count: inputOutputs.length ? inputOutputs.length : 0,
        names: inputOutputs.length ? map(inputOutputs, 'name') : ['N/A'],
      },
    ]
  }, [area])

  const tabChange = useCallback((value) => {
    setSelectedTab(value)
  }, [setSelectedTab])

  return (
    <PageContainer>
      <div className="mb-4 grid grid-cols-1 items-center gap-4 @md:grid-cols-2">
        <div className="text-3xl font-light text-slate-900">
          Managing <span className="font-medium">{area.name}</span>
        </div>

        <div className="hidden @md:text-right md:inline-block">
          <Tabs
            defaultTab="general"
            tabs={tabs}
            onChange={tabChange}
          />
        </div>

        <div className="block md:hidden">
          <HeadlessUISelect
            className="mt-3 w-full rounded-md"
            defaultValue={head(tabs)}
            options={tabs}
            labelProperty="title"
            onChange={(selected) => {
              if (selected.key !== selectedTab) {
                setSelectedTab(selected.key)
              }
            }}
          />
        </div>
      </div>

      {selectedTab === 'general' && (
        <div className="row">
          <div className="col-12 col-md-4">
            {!isEmpty(area) && !isUndefined(area.active) && !area.active && (
              <AlertContent title={`Area "${area.name}" has not been setup yet`} hideIcon={false} type="warning" className="mb-5">
                <p className="mb-2">
                FutureOps has not received a response from the responsible FEP.
                You'll be able to use this area for site creation once setup is confirmed.
                </p>

                <p>
                If this issue persists, please try <a className="cursor-pointer underline" onClick={reloadPage}>refreshing</a> or contact our support team.
                </p>
              </AlertContent>
            )}

            <Card>
              <CardTitle>
                <div className="capitalize">
                  {area.name}
                </div>

                <div className="more-actions">
                  <PermissionGuard anyOf={['update-area', 'delete-area']} auth={auth}>
                    <DropdownList
                      icon={<ActionIcon />}
                      options={[{
                        label: 'Edit Area',
                        disabled: !auth.can('update-area'),
                        disabledWithReason: preventSaving && runningProgramNames,
                        onClick: () => {
                          setModal({
                            name: 'area',
                            data: {
                              ...area,
                              isEditing: true,
                              onSave: () => {
                                getArea()
                              },
                            },
                          })
                        },
                      }, {
                        label: 'Delete Area',
                        disabled: !auth.can('delete-area'),
                        disabledWithReason: preventSaving && runningProgramNames,
                        onClick: () => {
                          setModal({
                            name: 'warning',
                            data: {
                              endpoint: `/area/delete/${area.id}`,
                              title: 'Delete area',
                              content: `By deleting this area, ${area.name}, all associated data will be deleted. The following number of associations will be deleted:`,
                              successFlashMessage: `${area.name} has been successfully removed.`,
                              redirect: 'areas',
                              deleteConfirmation: true,
                              affectedRelationships: {
                                'Sites': relatedSites,
                                'Program Sets': relatedProgramSets,
                                'Programs': relatedPrograms,
                                'Main Lines': relatedMainLines,
                                'I/O\'s': relatedInputOutputs,
                              },
                              savePreventionState: {
                                model: 'area',
                                id: area.id,
                              },
                            },
                          })
                        },
                      }]}
                    />
                  </PermissionGuard>
                </div>
              </CardTitle>

              <CardDetails>
                {area.description ? (
                  <CardRow>
                    <CardRowTitle>Description</CardRowTitle>
                    <CardRowContent>{area.description}</CardRowContent>
                  </CardRow>
                ) : null}

                <CardRow>
                  <CardRowTitle>Address</CardRowTitle>
                  <CardRowContent>{area.address}</CardRowContent>
                </CardRow>

                <CardRow>
                  <CardRowTitle>Serial number</CardRowTitle>
                  <CardRowContent>{area.serialNumber || 'None'}</CardRowContent>
                </CardRow>

                <CardRow>
                  <CardRowTitle>Authorized users</CardRowTitle>
                  {
                    !isEmpty(area.users) ? (
                      <CircleContainer>
                        <CircleList
                          tooltips
                          circles={map(area.users, (user) => {
                            return {
                              text: user.name,
                              avatar: user.avatar || null,
                            }
                          })}
                        />
                      </CircleContainer>
                    ) : (
                      <CardRowContent>All</CardRowContent>
                    )
                  }
                </CardRow>
              </CardDetails>
            </Card>

            <div className={classNames('flex my-5', { hidden: !auth.can('create-site') })}>
              <Anchor
                style={{ width: 'calc(50% - 5px)' }}
                onClick={() => {
                  setModal({
                    name: 'site',
                    data: { site: { areaId: area.id } },
                  })
                }}
              >
              Add site
              </Anchor>
            </div>
          </div>

          <MapContainer className="col-12 col-md-8">
            <Map
              loading={mapLoaded ? false : 3000}
              onInit={mapInit}
              markers={markers}
            />
          </MapContainer>
        </div>
      )}

      {selectedTab === 'sites' && (
        <SitesAll
          extraParameters={[['area_id', area.id]]}
          basicSearch={true}
        />
      )}

      {selectedTab === 'alarms' && (
        <AlarmsAll
          extraParameters={[['area_id', area.id]]}
        />
      )}
    </PageContainer>
  )
}

export default AreaManage
