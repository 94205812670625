import { each, filter, find, findIndex, includes, isEmpty, map, replace, startCase, startsWith } from 'lodash-es'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import Input from '@/Components/form/Input'
import InputError from '@/Components/form/InputError'
import { formatKeys } from '@/Utilities/Form/Formatter'

export default function Delays(props) {
  const [firstLoad, setFirstLoad] = useState(true)
  const {
    errors,
    watch,
    register,
    delaysFields,
    delaysAppend,
    delaysRemove,
  } = useFormContext()

  const isEditing = props.data?.isEditing || false
  let program

  if (props.data?.program) {
    program = useMemo(() => {
      return formatKeys(props.data.program, 'camel')
    }, [props.data.program])
  }

  const inputOutputIds = watch('inputOutputIds')
  const delays = watch('delays')

  const digitalOutputs = useMemo(() => {
    const digitalOutputs = filter(props.mainLineAndAuxInputOutputs, (io) => {
      return includes(inputOutputIds, io.id) && startsWith(replace(io.detailsType, 'inputOutput.', ''), 'digitalOutput')
    })

    return digitalOutputs
  }, [props.mainLineAndAuxInputOutputs, inputOutputIds])

  useEffect(() => {
    const currentTab = document.getElementById('delays')

    if (currentTab) {
      props.setTabErrors((prevState) => {
        return {
          ...prevState,
          delays: !!currentTab?.querySelector('.error-message'),
        }
      })
    }
  }, [errors])

  useEffect(() => {
    if (!isEmpty(digitalOutputs) && firstLoad) {
      if (isEditing) {
        each(digitalOutputs, (output) => {
          let fieldValues = {}
          const existingValue = find(program?.inputOutputs, ['pivot.inputOutputId', output.id])

          fieldValues = {
            inputOutputId: output.id,
            onDelay: existingValue?.pivot?.onDelay,
            offDelay: existingValue?.pivot?.offDelay,
          }

          delaysAppend(fieldValues, { focusName: 'delays' })
        })
      } else {
        createDelayfields()
      }

      setFirstLoad(false)
    }
  }, [digitalOutputs, firstLoad])

  const createDelayfields = useCallback(() => {
    each(digitalOutputs, (digitalOutput) => {
      let fieldValues = {}
      const existingValue = find(delays, ['inputOutputId', digitalOutput.id])

      fieldValues = {
        inputOutputId: existingValue?.inputOutputId || digitalOutput.id,
        onDelay: existingValue?.onDelay || 0,
        offDelay: existingValue?.offDelay || 0,
      }

      delaysAppend(fieldValues, { focusName: 'delays' })

      if (!includes(digitalOutputs, digitalOutput)) {
        const indexToRemove = findIndex(delays, (item) => {
          return item.inputOutputId === digitalOutput.id
        })

        if (indexToRemove > -1) {
          delaysRemove(indexToRemove)
        }

        return
      }
    })
  }, [digitalOutputs, delays])

  useEffect(() => {
    if (!isEmpty(digitalOutputs) && !firstLoad) {
      delaysRemove()

      createDelayfields()
    }
  }, [digitalOutputs, firstLoad])

  return (
    !isEmpty(delaysFields) && !isEmpty(digitalOutputs) ? map(delaysFields, (field, index) => {
      const inputOutput = find(digitalOutputs, ['id', field.inputOutputId])

      if (inputOutput) {
        return (
          <div className="justify-stretch delays mt-3 grid grid-flow-col items-center rounded border p-4 first-of-type:mt-4" key={field.id}>
            <div>
              <div>{inputOutput.name}</div>
              <div className="text-xs text-slate-400">{startCase(replace(inputOutput.detailsType, 'inputOutput.', ''))}</div>
            </div>

            <div className="flex flex-row justify-around">
              <Input
                type="hidden"
                {...register(`delays.${index}.inputOutputId`)}
              />

              <div>
                <Input
                  label={<div>On delay <span className="text-slate-400">(seconds)</span></div>}
                  type="number"
                  min="0"
                  className={errors?.delays?.[index]?.onDelay && 'error'}
                  {...register(`delays.${index}.onDelay`)}
                />

                {errors?.delays?.[index]?.onDelay && <InputError message={errors?.delays?.[index]?.onDelay.message} />}
              </div>

              <div>
                <Input
                  label={<div>Off delay <span className="text-slate-400">(seconds)</span></div>}
                  type="number"
                  min="0"
                  className={errors?.delays?.[index]?.offDelay && 'error'}
                  {...register(`delays.${index}.offDelay`)}
                />

                {errors?.delays?.[index]?.offDelay && <InputError message={errors?.delays?.[index]?.offDelay.message} />}
              </div>
            </div>
          </div>
        )
      }
    }) : (
      <div className="border-light-grey mt-4 rounded border p-3 text-center">
        To set up on/off delays, please assign outputs in the I/O's tab of this program.
      </div>
    )
  )
}
