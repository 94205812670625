import moment from 'moment'

export const formattedDateTime = (dateTime) => {
  if (moment(dateTime).isValid()) {
    return moment.utc(dateTime).format('D MMM YYYY, h:mm A')
  }

  return null
}

export const formatToLocalTime = (dateTime) => {
  return moment.utc(dateTime).local().format('YYYY-MM-DD HH:mm:ss')
}
