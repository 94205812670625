import * as Sentry from '@sentry/react'
import { map, slice } from 'lodash-es'
import { rem } from 'polished'
import { useCallback, useEffect } from 'react'
import { atom, useRecoilState, useSetRecoilState } from 'recoil'
import styled from 'styled-components'

import ActionIcon from '@/Components/ActionIcon'
import PermissionGuard from '@/Components/auth/PermissionGuard'
import Card from '@/Components/Card'
import CircleList from '@/Components/CircleList'
import DropdownList from '@/Components/DropdownList'
import PageContainer from '@/Components/layout/helpers/PageContainer'
import UsersAll from '@/Components/tables/UsersAll'
import { modalState } from '@/Config/Atoms/General'
import useApiClient from '@/Utilities/useApiClient'
import useAuth from '@/Utilities/useAuth'
import usePageGuard from '@/Utilities/usePageGuard'
import useTitle from '@/Utilities/useTitle'

const CardContainer = styled.div`
  margin-bottom: 20px;
`

const CardRow = styled.div`
  display: flex;
  font-size: ${rem(14)};
  justify-content: space-between;
  padding: 6px 24px;
`

const CardRowTitle = styled.div`
  align-items: center;
  color: var(--heading-color);
  display: flex;
  font-size: ${rem(14)};
  flex-basis: 50%;
`

const CardRowContent = styled.div`
  color: var(--text-light);
  display: flex;
  font-size: ${rem(14)};
  flex-basis: 50%;
`

const organisationState = atom({
  key: 'organisation',
  default: null,
})

function OrganisationManage() {
  const auth = useAuth()
  const apiClient = useApiClient()
  const [organisation, setOrganisation] = useRecoilState(organisationState)
  const setModal = useSetRecoilState(modalState)

  usePageGuard({
    auth,
    permission: 'view-organization',
  })

  useTitle(['Managing organization', organisation?.name])

  const getOrganisation = useCallback(async () => {
    try {
      const { data } = await apiClient.get('/organisation/profile')

      if (data && data.success) {
        setOrganisation(data.organisation)
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }, [setOrganisation])

  useEffect(() => {
    getOrganisation()
  }, [getOrganisation])

  const dropdownOptions = () => {
    return (
      <PermissionGuard auth={auth} anyOf={['organization-owner', 'update-organization']}>
        <DropdownList
          icon={<ActionIcon />}
          options={[{
            label: 'Edit',
            onClick: () => {
              setModal({
                name: 'organisation',
                disabled: !auth.can('update-organization'),
                data: {
                  organisation: organisation,
                  onComplete: () => {
                    getOrganisation()
                    setModal(null)
                  },
                },
              })
            },
          }]}
        />
      </PermissionGuard>
    )
  }

  return (
    organisation && (
      <PageContainer contained>
        <CardContainer className="col-12 col-md-4">
          <Card
            title={organisation.name}
            actions={dropdownOptions()}
          >
            <CardRow>
              <CardRowTitle>Address</CardRowTitle>
              <CardRowContent>{organisation.address}</CardRowContent>
            </CardRow>

            <CardRow>
              <CardRowTitle>Owner</CardRowTitle>
              <CardRowContent>
                <CircleList
                  tooltips
                  circles={map(organisation.owners, (user, index, arr) => {
                    if (index < 6) {
                      return {
                        text: user.name,
                        avatar: user.avatar || null,
                      }
                    } else if (index + 1 === arr.length) {
                      return {
                        noInitialsText: `+${arr.length - 6}`,
                        text: <div>
                          {map(slice(organisation.users, 6), (user, index) => {
                            return <div key={`paginatedUsers-${index}`}>{user.name}</div>
                          })}
                        </div>,
                      }
                    }
                  })}
                />
              </CardRowContent>
            </CardRow>
          </Card>
        </CardContainer>

        <PermissionGuard auth={auth} permission="view-users">
          <UsersAll />
        </PermissionGuard>
      </PageContainer>
    )
  )
}

export default OrganisationManage
