import '@/Utilities/Form/Yup'

import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/react'
import { isFunction } from 'lodash-es'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import styled from 'styled-components'
import * as yup from 'yup'

import { Anchor, Button } from '@/Components/form/Buttons'
import Input from '@/Components/form/Input'
import InputError from '@/Components/form/InputError'
import Modal from '@/Components/Modal'
import { isLoadingState, pageAlertState } from '@/Config/Atoms/General'
import useApiClient from '@/Utilities/useApiClient'

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
`

const schema = (isEditing) => {
  let validationObject = { name: yup.string().label('Name').required().min(3).max(50) }

  if (isEditing) {
    delete validationObject.address
  }

  return yup.object(validationObject)
}

function ReportingDashboardModal(props) {
  const apiClient = useApiClient()
  let isEditing = props.data?.isEditing || false

  const [isLoading, setIsLoading] = useRecoilState(isLoadingState)
  const navigate = useNavigate()
  const setAlert = useSetRecoilState(pageAlertState)
  const {
    reset,
    register,
    handleSubmit,
    formState,
  } = useForm({
    resolver: yupResolver(schema(isEditing)),
    defaultValues: { isEditing: isEditing },
  })
  const { errors } = formState

  const onSubmit = async (data) => {
    try {
      setAlert(null)
      setIsLoading(true)

      let { data: responseData } = await apiClient.post(`${isEditing ? `/reporting-dashboard/update/${props.data?.reportingDashboard?.id}` : '/reporting-dashboard/create'}`, { name: data.name })

      if (responseData.success) {
        if (isFunction(props.data?.onSave)) {
          props.data.onSave(responseData.reportingDashboard.id)
        } else {
          navigate(`/reporting/${responseData.reportingDashboard.id}`)
        }

        setAlert({
          type: 'success',
          content: `${responseData.reportingDashboard.name} has been successfully ${isEditing ? 'updated' : 'created'}.`,
        })

        if (isFunction(props.close)) {
          props.close()
        }
      }
    } catch (error) {
      Sentry.captureException(error)
      setAlert({
        type: 'error',
        content: 'An error has occured and we were not able to save this dashboard. Please try again.',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      title={`${isEditing ? 'Edit' : 'Add' } dashboard`}
      close={props.close}
      closeOnOutsideClick={props.closeOnOutsideClick}
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Input
          name="name"
          label="Name"
          isRequired={true}
          className={errors.name && 'error'}
          {...register('name', { value: props.data?.reportingDashboard?.name })}
        />
        {errors.name && <InputError message={errors.name.message} />}

        <ButtonGroup className="buttons">
          <Anchor
            style={{ width: 'calc(50% - 5px)' }}
            className="transparent"
            onClick={() => {
              setAlert(null)
              reset()

              props.close()
            }}
          >
            Close
          </Anchor>

          <Button
            style={{ width: 'calc(50% - 5px)' }}
            disabled={isLoading ? true : false}
          >
            {isLoading ? <div className="primary-loader light"></div> : 'Save'}
          </Button>
        </ButtonGroup>
      </form>
    </Modal>
  )
}

export default ReportingDashboardModal
