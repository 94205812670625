import * as Sentry from '@sentry/react'
import classNames from 'classnames'
import { OverlayScrollbars } from 'overlayscrollbars'
import { rem } from 'polished'
import { useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'

import PermissionGuard from '@/Components/auth/PermissionGuard'
import { isSidebarOpenState } from '@/Config/Atoms/App'
import { mediaBreakpointDown } from '@/Utilities/Breakpoint'
import useAuth from '@/Utilities/useAuth'

const SideBar = styled.div`
  ${mediaBreakpointDown('lg', `
    left: 0;
    top: var(--top-bar-height);
  `)}

  .item {
    color: var(--white);
    margin-bottom: 4px;
    transition: background-color 0.3s ease;

    a {
      border-radius: 5px;
      display: block;
      font-size: ${rem(16)};
      line-height: ${rem(43)};
      padding: 0 20px;
      text-decoration: none;
      width: 100%;

      i {
        min-width: 30px;
        margin-right: 10px;
      }
    }
  }
`

const Content = styled.div`
  box-sizing: border-box;
  height: 100%;
  position: absolute;

  i {
    display: inline-flex;
    justify-content: center;
    width: 40px;
  }
`

const ToggleButtonContainer = styled.div`
  height: calc(100% - 80px);
  top: 80px;
  position: absolute;
  width: 0;
`

const Nav = styled.div`
  box-sizing: border-box;
  height: 100%;
  position: absolute;
`

function AppSidebar() {
  const auth = useAuth()
  const sidebarContentRef = useRef(null)
  const sidebarRef = useRef(null)

  const [isHovering, setIsHovering] = useState(false)
  const [isSidebarOpen, setSidebarOpen] = useRecoilState(isSidebarOpenState)

  const toggleSidebar = (isOpen) => {
    const newState = !isOpen

    localStorage.setItem('sidebar.visible', newState)
    setSidebarOpen(newState)
    setIsHovering(false)
  }

  const enterSideBar = () => {
    setIsHovering(true)
  }

  const leaveSideBar = () => {
    setIsHovering(false)
  }

  useEffect(() => {
    let osInstance

    // Initialize OverlayScrollbars
    if (sidebarRef.current) {
      osInstance = OverlayScrollbars(sidebarRef.current, {
        scrollbars: { autoHide: 'scroll' },
        overflow: {
          y: 'scroll',
          x: 'hidden',
        },
      })
    }

    // Cleanup
    return () => {
      if (osInstance) {
        // Attempt to destroy the OverlayScrollbars instance
        try {
          osInstance.destroy()
        } catch (error) {
          Sentry.captureException(error)
        }
      }
    }
  }, [])

  const linkClasses = ({ isActive }) => {
    return classNames({ 'bg-stone-100': isActive }, 'hover:bg-stone-100 text-slate-700 whitespace-nowrap')
  }

  return (
    <>
      <div
        className={classNames({ hidden: !isSidebarOpen }, 'absolute left-0 top-0 z-1000 h-full w-full bg-slate-900 opacity-50 lg:hidden cursor-pointer')}
        onClick={() => {
          return toggleSidebar(isSidebarOpen)
        }}
      />

      <SideBar
        className={classNames({
          isOpen: isSidebarOpen,
          isHovering: isHovering,
        }, 'z-1100 fixed select-none h-full-minus-top-bar')}
        onMouseLeave={leaveSideBar}
      >
        <ToggleButtonContainer
          className={classNames({
            'left-5': !isSidebarOpen && !isHovering,
            'left-72': isSidebarOpen || isHovering,
          }, 'z-1100 transition-all duration-300')}
          onMouseEnter={isHovering ? enterSideBar : () => {}}
        >
          <div
            className="top-16 right-0 grid h-8 w-8 -translate-x-1/2 cursor-pointer place-items-center rounded border border-stone-200 bg-white leading-8 text-slate-700 outline-none hover:text-slate-300"
            tabIndex={0}
            onClick={() => {
              return toggleSidebar(isSidebarOpen)
            }}
            title={isSidebarOpen ? 'Collapse' : 'Expand'}
          >
            <i className={isSidebarOpen ? 'fa-regular fa-angle-left' : 'fa-regular fa-angle-right'}></i>
          </div>
        </ToggleButtonContainer>
        <Nav
          ref={sidebarRef}
          className={classNames({
            'w-5 shadow-gray-300': !isSidebarOpen && !isHovering,
            'w-72 shadow-gray-500 lg:shadow-gray-300': isSidebarOpen || isHovering,
          }, 'z-5 h-full-minus-top-bar transition-all duration-300 bg-white text-slate-700 shadow-layout')}
          onMouseEnter={enterSideBar}
        >
          <Content
            className={classNames({
              '-translate-x-full': !(isSidebarOpen || isHovering),
              'translate-x-0': (isSidebarOpen || isHovering),
            }, 'w-72 transition-all duration-300')}
            ref={sidebarContentRef}
          >
            <div
              className={classNames({
                'opacity-100': (isSidebarOpen || isHovering),
                'opacity-0': !(isSidebarOpen || isHovering),
              }, 'max-h-full px-5 py-8 transition-all duration-300')}
            >
              <div className="item">
                <NavLink to="/dashboard" className={linkClasses}>
                  Dashboard
                </NavLink>
              </div>
              <div className="item">
                <NavLink to="/map" className={linkClasses}>
                  Map
                </NavLink>
              </div>
              <PermissionGuard permission="view-reporting-collection" auth={auth}>
                <div className="item">
                  <NavLink to="/reporting" className={linkClasses}>
                    Reporting
                  </NavLink>
                </div>
              </PermissionGuard>
              <PermissionGuard permission="view-alarm" auth={auth}>
                <div className="item">
                  <NavLink to="/alarms" className={linkClasses}>
                  Alarms
                  </NavLink>
                </div>
              </PermissionGuard>
              <PermissionGuard permission="view-organization" auth={auth}>
                <div className="item">
                  <NavLink to="/admin/organization" className={linkClasses}>
                  Administration
                  </NavLink>
                </div>
              </PermissionGuard>

              <div className="pt-3 pb-5">
                <div className="border border-stone-100"></div>
              </div>

              <PermissionGuard permission="view-area" auth={auth}>
                <div className="item">
                  <NavLink to="/areas" className={linkClasses}>
                    <i className="fa-light fa-globe fa-xl"></i> Areas
                  </NavLink>
                </div>
              </PermissionGuard>
              <PermissionGuard permission="view-site" auth={auth}>
                <div className="item">
                  <NavLink to="/sites" className={linkClasses}>
                    <i className="fa-light fa-map-location-dot fa-xl"></i> Sites
                  </NavLink>
                </div>
              </PermissionGuard>
              <PermissionGuard permission="view-remote-unit" auth={auth}>
                <div className="item">
                  <NavLink to="/remote-units" className={linkClasses}>
                    <i className="fa-light fa-satellite-dish fa-xl"></i> Remote units
                  </NavLink>
                </div>
              </PermissionGuard>
              <PermissionGuard permission="view-input-output" auth={auth}>
                <div className="item">
                  <NavLink to="/io" className={linkClasses}>
                    <i className="fa-light fa-sprinkler fa-xl"></i> I/Os
                  </NavLink>
                </div>
              </PermissionGuard>
              <PermissionGuard permission="view-main-line" auth={auth}>
                <div className="item">
                  <NavLink to="/main-lines" className={linkClasses}>
                    <i className="fa-light fa-arrow-up-from-water-pump fa-xl"></i> Main lines
                  </NavLink>
                </div>
              </PermissionGuard>
              <PermissionGuard permission="view-program" auth={auth}>
                <div className="item">
                  <NavLink to="/programs" className={linkClasses}>
                    <i className="fa-light fa-sliders-up fa-xl"></i> Programs
                  </NavLink>
                </div>
              </PermissionGuard>
              <PermissionGuard permission="view-condition" auth={auth}>
                <div className="item pb-4">
                  <NavLink to="/conditions" className={linkClasses}>
                    <i className="fa-light fa-clipboard-list fa-xl"></i> Conditions
                  </NavLink>
                </div>
              </PermissionGuard>
            </div>
          </Content>
        </Nav>
      </SideBar>
    </>
  )
}

export default AppSidebar
