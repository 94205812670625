import classNames from 'classnames'
import { map, startsWith } from 'lodash-es'
import { Fragment } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import Modal from '@/Components/Modal'
import { userState } from '@/Config/Atoms/Auth'
import { modalState } from '@/Config/Atoms/General'

export default function DebugPermissionsModal() {
  const user = useRecoilValue(userState)
  const [, setModal] = useRecoilState(modalState)

  return (
    <Modal
      title="Permissions"
      close={() => {
        setModal(null)
      }}
      closeOnOutsideClick={true}
    >
      {map(user?.roles, (role) => {
        return (
          <Fragment key={role.id}>
            <div className="mb-3 font-bold">{role.name}</div>

            <div className="grid grid-cols-3 gap-3">
              {map(role?.permissions, (permission, permissionIndex) => {
                let colorClassNames = 'bg-gray-100 text-gray-600'

                if (startsWith(permission, 'view-')) {
                  colorClassNames = 'bg-green-100 text-green-700'
                } else if (startsWith(permission, 'update-')) {
                  colorClassNames = 'bg-purple-100 text-purple-700'
                } else if (startsWith(permission, 'create-')) {
                  colorClassNames = 'bg-blue-100 text-blue-700'
                } else if (startsWith(permission, 'delete-')) {
                  colorClassNames = 'bg-pink-100 text-pink-700'
                } else if (startsWith(permission, 'start-') || startsWith(permission, 'stop-') || startsWith(permission, 'pause-')) {
                  colorClassNames = 'bg-indigo-100 text-indigo-700'
                } else if (startsWith(permission, 'manage-')) {
                  colorClassNames = 'bg-orange-100 text-orange-700'
                } else if (startsWith(permission, 'access-')) {
                  colorClassNames = 'bg-yellow-100 text-yellow-800'
                }

                return (
                  <span
                    key={`${role.id}-${permissionIndex}`}
                    className={classNames(
                      'inline-flex items-center rounded-md px-2 py-1 text-xs font-medium',
                      colorClassNames,
                    )}
                  >
                    {permission}
                  </span>
                )
              })}
            </div>
          </Fragment>
        )
      })}
    </Modal>
  )
}
