// Import Swiper styles
import 'swiper/css'

import Tippy from '@tippyjs/react'
import classNames from 'classnames'
import { find, findIndex, get, head, isEmpty, isNull, map, round, some } from 'lodash-es'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { Swiper, SwiperSlide } from 'swiper/react'
import { twMerge } from 'tailwind-merge'

import AlertContent from '@/Components/alerts/AlertContent'
import PermissionGuard from '@/Components/auth/PermissionGuard'
import ElapsedTimer from '@/Components/common/ElapsedTimer'
import ElapsedTimerWithProgress from '@/Components/common/input/ElapsedTimerWithProgress'
import HorizontalProgressBar from '@/Components/common/progressbars/HorizontalProgressBar'
import { RoundedIconButton } from '@/Components/form/Buttons'
import Pill from '@/Components/pill/Pill'
import PillWrapper from '@/Components/pill/PillWrapper'
import { modalState } from '@/Config/Atoms/General'
import { getMainlineFlowUnitOfMeasurement, isQuantityBasis, isTimeBasis } from '@/Utilities/Basis'
import { publish } from '@/Utilities/Events'
import { formatDecimals } from '@/Utilities/Internationalization'
import { abbreviationMap } from '@/Utilities/Units'
import useAuth from '@/Utilities/useAuth'
import usePendingChange from '@/Utilities/usePendingChange'

function ProgramSwiperCard({
  program,
  status,
  irrigationBasis,
}) {
  // Internal state
  const setModal = useSetRecoilState(modalState)

  const {
    hasPendingChange,
    getPendingChangeDisabledReason,
  } = usePendingChange()

  // Memoized function to determine whether or not a program can be edited in its current state
  const canEditProgram = useMemo(() => {
    const isRunningOrHasChange = some(program.operationalStatuses, { key: 'running' }) || hasPendingChange(program)

    return !isRunningOrHasChange
  }, [program])

  const quantityProgress = useMemo(() => {
    const returnValue = {
      totalQuantity: 0,
      progressPercentage: 0,
      accumulated: 0,
      unitOfMeasurement: null,
    }

    if (!isQuantityBasis(irrigationBasis)) {
      return returnValue
    }

    const stat = find(program.stats, { key: 'remainingQuantity' })
    const totalQuantity = get(program, 'waterQuantity', 0)
    const accumulated = totalQuantity - get(stat, 'value', 0)

    return {
      totalQuantity,
      accumulated,
      progressPercentage: round(accumulated / totalQuantity * 100),
      unitOfMeasurement: get(program, 'unitOfMeasurement', null),
    }
  }, [program])

  return (
    <>
      <div className="absolute top-4 right-6 inline-block cursor-pointer text-slate-500">
        <Tippy
          theme="light"
          placement="top"
          delay={200}
          content={canEditProgram
            ? 'Edit program'
            : (hasPendingChange(program) ? getPendingChangeDisabledReason(program) : 'You cannot edit or delete this as the program is currently running')
          }
        >
          <i
            className={
              twMerge(
                'fa-solid fa-pen-to-square cursor-pointer text-slate-300',
                classNames({ 'text-slate-500 hover:text-primary': canEditProgram }),
              )
            }
            onClick={() => {
              // Prevent editing a program when running
              if (!canEditProgram) {
                return
              }

              setModal({
                name: 'program',
                data: {
                  program,
                  isEditing: true,
                  onSave: () => {
                    publish('program.update', { id: program.id })
                  },
                },
              })
            }}
          />
        </Tippy>
      </div>

      {program.disabled && (
        <AlertContent type="warning" hideIcon={false} className="m-3">
          Program disabled
        </AlertContent>
      )}

      <div className="p-4 md:px-6">
        <div className="grid grid-cols-1 gap-y-2 text-sm md:grid-cols-2-info-column">
          <div>Status</div>
          <PillWrapper>
            <Pill hasDot color={get(status, 'color', '#647087')}>
              {get(status, 'title', 'No operational status')}
            </Pill>
          </PillWrapper>

          {isTimeBasis(irrigationBasis) && (
            <ElapsedTimerWithProgress
              statusKey={get(status, 'key', 'stopped')}
              timing={get(program, 'timing', null)}
            />
          )}

          {isQuantityBasis(irrigationBasis) && (
            <>
              <div>Progress</div>
              <div className="grid grid-cols-2-right-auto text-slate-500">
                <div>
                  <span className="w-30 inline-block">{formatDecimals(get(quantityProgress, 'accumulated', 0))}</span>
                  <span className="mx-1">/</span>
                  <span>{formatDecimals(get(quantityProgress, 'totalQuantity', 0))}</span>
                  <span className="w-30 inline-block">{get(quantityProgress, 'quantityUnitOfMeasurement', null)}</span>
                </div>
                <div>{get(quantityProgress, 'progressPercentage', 0)}%</div>
              </div>

              <div className="hidden min-h-5 md:block"/>

              <HorizontalProgressBar
                colorName={`status-${get(status, 'key', 'stopped')}`}
                percent={get(quantityProgress, 'progressPercentage', 0)}
              />
            </>
          )}

          <div>Program main line flow</div>
          <div className="text-slate-500">
            <span>
              {formatDecimals(get(program, 'mainLine.statMap.flow', 0))}
            </span>
            <span className="ml-1">
              {getMainlineFlowUnitOfMeasurement(program)}
            </span>
          </div>

          <div>Program main line total</div>
          <div className="text-slate-500">
            <span>
              {round(get(program, 'mainLine.statMap.total', 0) / 1000, 2)}
            </span>
            <span className="ml-1">
              {get(abbreviationMap, get(program, 'mainLine.flowMeter.details.deltaUnitOfMeasurement') === 'gal' ? 'kgal' : 'kl', 'N/A')}
            </span>
          </div>

          {!isEmpty(program.fertigationPumps) && (
            map(program.fertigationPumps, (fertigationPump) => {
              return (
                <Fragment key={fertigationPump.id}>
                  <div>
                    <Tippy
                      theme="light"
                      content={get(fertigationPump, 'inputOutput.name')}
                    >
                      <span>Fertigation</span>
                    </Tippy>
                  </div>
                  <div className="text-slate-500">
                    {isTimeBasis(fertigationPump.fertigationBasis) && (
                      <ElapsedTimer
                        statusKey={get(status, 'key', 'stopped')}
                        timing={get(fertigationPump, 'timing')}
                      />
                    )}
                  </div>
                </Fragment>
              )
            })
          )}
        </div>
      </div>
    </>
  )
}

/**
 * ProgramSetProgramSwiper Component
 *
 * @param programs
 * @param irrigationBasis
 * @param stateStats
 * @param status
 * @param defaultIndex
 * @returns {JSX.Element}
 * @constructor
 */
export default function ProgramSetProgramSwiper({
  programSetId,
  programs,
  irrigationBasis,
  stateStats,
  defaultIndex = 0,
  debug = false,
}) {
  const auth = useAuth()

  const { PendingChangeStatusIcon } = usePendingChange()

  const [swiperInstance, setSwiperInstance] = useState(null)
  const [activeIndex, setActiveIndex] = useState(defaultIndex)
  const setModal = useSetRecoilState(modalState)

  const addProgramToSetModal = (programSetId) => {
    setModal({
      name: 'program',
      data: { program: { programSetId } },
    })
  }

  const slideToProgramIndex = useCallback((index) => {
    if (!isNull(swiperInstance)) {
      swiperInstance.slideTo(index)
    }
  }, [swiperInstance])

  // Automatically switch to the first running program
  useEffect(() => {
    const runningIndex = findIndex(programs, { operationalStatuses: [{ key: 'running' }] })

    if (runningIndex >= 0 && runningIndex !== activeIndex) {
      setActiveIndex(runningIndex)
    }
  }, [programs, swiperInstance])

  useEffect(() => {
    if (!isNull(swiperInstance) && swiperInstance.realIndex !== activeIndex) {
      slideToProgramIndex(activeIndex)
    }
  }, [swiperInstance, activeIndex])

  return isEmpty(programs) ? (
    <div className="grid grid-cols-2-left-auto items-center gap-4 rounded-b bg-slate-50 p-4 px-6 text-sm">
      <PermissionGuard permission="create-program" auth={auth}>
        <Tippy
          content="Add first program to set"
          delay={200}
          theme="light"
          placement="top"
        >
          <span tabIndex="0">
            <RoundedIconButton
              className="bg-slate-300 hover:bg-primary"
              icon="plus"
              onClick={() => {
                addProgramToSetModal(programSetId)
              }}
            />
          </span>
        </Tippy>
      </PermissionGuard>
      <span className="text-slate-500">This program set has no programs</span>
    </div>
  ) : (
    <Swiper
      className="relative flex w-full"
      spaceBetween={0}
      slidesPerView={1}
      loop={programs.length > 1}
      grabCursor={true}
      autoHeight={true}
      onSwiper={setSwiperInstance}
      onRealIndexChange={(swiper) => {
        if (swiper.realIndex !== activeIndex) {
          setActiveIndex(swiper.realIndex)
        }
      }}
    >
      <div
        className="grid h-16 grid-cols-3-56-stretch items-center border-b border-b-slate-200 bg-slate-50/75"
        slot="container-start"
      >
        {programs.length > 1 && (
          <div className="ml-8 justify-self-start text-2xl">
            <i
              className="far fa-chevron-left cursor-pointer text-slate-400"
              onClick={() => {
                swiperInstance.slidePrev()
              }}
            />
          </div>
        )}

        <div
          className={
            classNames(
              'justify-self-center text-center',
              { 'col-span-3': programs.length <= 1 },
            )
          }
        >
          <div>
            <div className="flex items-center">
              <div>
                {get(programs, [activeIndex], null) && (
                  <PendingChangeStatusIcon
                    model={get(programs, [activeIndex])}
                    position="bottom"
                  />
                )}
              </div>

              <div>
                {get(programs, [activeIndex, 'name'], 'Unknown Program')}
              </div>
            </div>
          </div>
          {programs.length > 1 && (
            <div className="text-xs font-bold text-slate-400">
              {activeIndex + 1} / {programs.length}
            </div>
          )}
        </div>

        {programs.length > 1 && (
          <div className="mr-8 justify-self-end text-2xl">
            <i
              className="far fa-chevron-right cursor-pointer text-slate-400"
              onClick={() => {
                swiperInstance.slideNext()
              }}
            />
          </div>
        )}
      </div>

      {map(programs, (program, programIndex) => {
        const operationalStatus = getMostRecentOperationalStatus(program)

        return (
          <SwiperSlide className="relative" key={programIndex}>
            <ProgramSwiperCard
              irrigationBasis={irrigationBasis}
              program={program}
              status={operationalStatus}
              stateStats={stateStats}
              debug={debug}
            />
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}

const getMostRecentOperationalStatus = (program) => {
  const { operationalStatuses: statuses } = program

  return head(statuses)
}
