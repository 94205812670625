import classNames from 'classnames'
import { divIcon } from 'leaflet'

import { faIcon } from '@/Utilities/Icons/FaIcon'

export const mapIcon = (options = {}) => {
  let {
    wrapperClass = '',
    width = 40,
    height = 40,
  } = options

  let icon = faIcon(options)

  return divIcon({
    html: icon,
    iconSize: [width, height],
    iconAnchor: [width / 2, height / 2],
    className: classNames(wrapperClass),
  })
}
