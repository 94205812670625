import 'react-phone-number-input/style.css'

import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/react'
import { find, head, isFunction } from 'lodash-es'
import { useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { isValidPhoneNumber } from 'react-phone-number-input'
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form'
import { useRecoilState, useSetRecoilState } from 'recoil'
import styled from 'styled-components'
import * as yup from 'yup'

import { Anchor, Button } from '@/Components/form/Buttons'
import Input from '@/Components/form/Input'
import InputError from '@/Components/form/InputError'
import InputLabel from '@/Components/form/InputLabel'
import RequiredAsterisk from '@/Components/form/RequiredAsterisk'
import Select from '@/Components/form/Select'
import Modal from '@/Components/Modal'
import { isLoadingState, pageAlertState } from '@/Config/Atoms/General'
import { flattenSelectValues, formatKeys } from '@/Utilities/Form/Formatter'
import useApiClient from '@/Utilities/useApiClient'
import useAuth from '@/Utilities/useAuth'
import useTitle from '@/Utilities/useTitle'

const Form = styled.form`
  label {
    margin-top: 20px;
  }

  .buttons {
    margin-top: 40px;
  }
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
`

const schema = yup.object({
  name: yup.string().label('Name').required(),
  email: yup.string().label('Email').email().required(),
  number: yup.string().label('Mobile number').min(8).max(15).nullable().required().test(
    'validNumber',
    'Please enter a valid mobile number.',
    (value) => {
      if (value) {
        return isValidPhoneNumber(value)
      }
    },
  ),
})

function UserProfileModal(props) {
  const apiClient = useApiClient()
  const auth = useAuth()
  const setAlert = useSetRecoilState(pageAlertState)
  const [isLoading, setIsLoading] = useRecoilState(isLoadingState)
  const {
    control,
    setValue,
    watch,
    register,
    handleSubmit,
    formState,
  } = useForm({ resolver: yupResolver(schema) })
  const { errors } = formState

  useTitle([`${props.data?.user ? 'Edit' : 'Add'} profile`, props.data?.name])

  useEffect(() => {
    setValue('name', props.data.user?.name)
    setValue('email', props.data.user?.email)
    setValue('number', props.data.user?.number)
  }, [
    setValue,
    props,
    watch,
  ])

  const onSubmit = async (data) => {
    try {
      setAlert(null)
      setIsLoading(true)

      data = flattenSelectValues(data)
      data = formatKeys(data, 'snake')

      let { data: responseData } = await apiClient.post(`/user/profile/${props.data.user.id}`, data)

      if (responseData.success) {
        setAlert({
          type: 'success',
          content: 'Your profile has been successfully updated.',
        })

        if (isFunction(props.data?.onComplete)) {
          props.data.onComplete(responseData.user)
        }
      }
    } catch (error) {
      Sentry.captureException(error)
      setAlert({
        type: 'error',
        content: 'There was a problem with updating your profile. Please try again.',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const roles = useMemo(() => {
    return [
      {
        value: 'administrator',
        label: 'Administrator',
      },
      {
        value: 'manager',
        label: 'Manager',
      },
      {
        value: 'editor',
        label: 'Editor',
      },
      {
        value: 'read-only',
        label: 'Read-only',
      },
    ]
  }, [])

  return (
    <Modal
      title="Profile"
    >
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Input
          placeholder="Enter your name"
          label="Name"
          type="text"
          className={errors.name && 'error'}
          isRequired={true}
          {...register('name')}
        />
        {errors.name && <InputError message={errors.name.message} />}

        <Input
          placeholder="Enter your email"
          label="Email"
          type="email"
          autoComplete="username"
          readOnly={true}
          className={errors.email && 'error'}
          {...register('email')}
        />
        {errors.email && <InputError message={errors.email.message} />}

        <InputLabel htmlFor="number">Mobile number <RequiredAsterisk /></InputLabel>
        <PhoneInputWithCountry
          name="number"
          control={control}
          defaultCountry="US"
          placeholder="Enter your mobile number"
          className={errors.number && 'error'}
        />
        {errors.number && <InputError message={errors.number.message} />}

        {auth.isAdmin && props.data.user.id != auth.user.id && (
          <>
            <Controller
              control={control}
              defaultValue={find(roles, ['value', head(props.data.user.roles).slug])}
              name="roleId"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    isMulti={false}
                    isSearchable={false}
                    label="Role"
                    isLoading={false}
                    options={roles}
                    placeholder="Select role"
                    hasError={!!errors.roleId}
                  />
                )
              }}
            />
            {errors.roleId && <InputError message={errors.roleId.message} />}
          </>
        )}

        <ButtonGroup className="buttons">
          <Anchor
            style={{ width: 'calc(50% - 5px)' }}
            className="transparent"
            onClick={() => {
              setAlert(null)
              props.close()
            }}
          >
            Close
          </Anchor>

          <Button
            style={{ width: 'calc(50% - 5px)' }}
            disabled={isLoading ? true : false}
          >
            {isLoading ? <div className="primary-loader light"></div> : 'Save'}
          </Button>
        </ButtonGroup>
      </Form>
    </Modal>
  )
}

export default UserProfileModal
