import { isEmpty, map } from 'lodash-es'
import { twMerge } from 'tailwind-merge'

import Card from '@/Components/common/Card'
import Pill from '@/Components/pill/Pill'
import PillWrapper from '@/Components/pill/PillWrapper'

export default function PageHeaderCard({
  className,
  children,
  title,
  pills,
}) {
  return (
    <Card className={className}>
      <Card.Header
        className={
          twMerge(
            'grid grid-flow-row grid-cols-1 items-center gap-2 md:gap-4 md:grid-flow-col',
            // If pills are provided, then we apply md:grid-cols-2-left-auto to position them beside the title
            !isEmpty(pills) ? 'md:grid-cols-2-left-auto' : !isEmpty(children) ? 'md:grid-cols-2-right-auto' : null,
          )
        }
        title={title}
      >
        {(pills?.length > 0) && (
          <PillWrapper>
            {map(pills, (pill, index) => {
              return (
                <Pill color={pill.color} key={index}>
                  {pill.title}
                </Pill>
              )
            })}
          </PillWrapper>
        )}

        <div>
          {children}
        </div>
      </Card.Header>
    </Card>
  )
}
