import * as Sentry from '@sentry/react'
import Tippy from '@tippyjs/react'
import { debounce, map } from 'lodash-es'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'

import ActionIcon from '@/Components/ActionIcon'
import PermissionGuard from '@/Components/auth/PermissionGuard'
import DropdownList from '@/Components/DropdownList'
import CommentModal from '@/Components/modals/CommentModal'
import Pill from '@/Components/pill/Pill'
import SkeletonTable from '@/Components/SkeletonTable'
import Table from '@/Components/Table'
import AlarmCommentSection from '@/Components/tables/AlarmsCommentSection'
import { modalState } from '@/Config/Atoms/General'
import { formatToLocalTime } from '@/Utilities/DateTime'
import useApiClient from '@/Utilities/useApiClient'
import useAuth from '@/Utilities/useAuth'

const Dot = styled.div`
  background-color: ${(props) => {
    return props.color
  }};
  border-radius: 100%;
  display: inline-block;
  margin-right: 5px;
  height: 6px;
  width: 6px;
`

function DashboardAlarm() {
  const apiClient = useApiClient()
  const [tableData, setTableData] = useState(null)
  const [tableDataLoading, setTableDataLoading] = useState(true)
  const [alarmTypes, setAlarmTypes] = useState(null)
  const [modal, setModal] = useRecoilState(modalState)
  const [lastQuery, setLastQuery] = useState({})
  const auth = useAuth()
  const urlParams = useParams()

  const getTableData = useMemo(() => {
    return debounce(async ({
      pageIndex,
      pageSize,
      filters,
    }) => {
      const currentQuery = {
        pageIndex,
        pageSize,
        filters,
      }
      setLastQuery(currentQuery)

      const query = new URLSearchParams([
        ['page', pageIndex + 1],
        ['pageSize', pageSize],
        ['search', filters?.search?.value || ''],
        ['status', filters?.tab || ''],
        ['with[]', 'alarmable'],
        ['with[]', 'comments'],
        ['with[]', 'comments.user'],
      ])

      map(filters?.type, (filter) => {
        query.append('type[]', filter)
      })

      try {
        const { data } = await apiClient.get(`/alarm/query?${query}&site_id=${urlParams.id}`)

        let alarmData = map(data.alarms.data, (alarm) => {
          const status = () => {
            if (alarm.resolved_by) {
              return {
                title: 'Resolved',
                color: '#12B76A',
              }
            } else if (alarm.attended_by) {
              return {
                title: 'Attended',
                color: '#F79009',
              }
            } else {
              return {
                title: 'Unattended',
                color: '#F04438',
              }
            }
          }

          return {
            type: (
              <Pill color={(alarm.resolved_by) ? '#667085' : alarm.alarm_type.color}>
                <Dot color={(alarm.resolved_by) ? '#667085' : alarm.alarm_type.color} /> {alarm.alarm_type.title}
              </Pill>
            ),
            status: (
              <Tippy
                content={
                  <div className="flex flex-col">
                    <div>
                      <div className="font-bold text-slate-500">Attended by:</div> {alarm.attended_by ? alarm.attended_by.name : '-'}
                      <br/>
                      {alarm.date_attended ? formatToLocalTime(alarm.date_attended) : ''}
                    </div>

                    <div className="mt-3">
                      <div className="font-bold text-slate-500">Resolved by:</div> {alarm.resolved_by ? alarm.resolved_by.name : '-'}
                      <br/>
                      {alarm.date_resolved ? formatToLocalTime(alarm.date_resolved) : ''}
                    </div>

                  </div>
                }
                delay={200}
                theme="light"
                placement="right"
              >
                <Pill color={status().color}>
                  {status().title}
                </Pill>
              </Tippy>
            ),
            area: alarm.area.name || '',
            alarmable: alarm.alarmable?.name || '-',
            description: alarm.description,
            occurrenceInfo: (
              <Tippy
                theme="light"
                content={
                  <div className="-mb-1 text-slate-500">
                    <div className="mb-1">
                      <strong>First Occurrence:</strong> <br />
                      {formatToLocalTime(alarm.date_logged)}
                    </div>

                    <div className="mb-1">
                      <strong>Last Occurrence:</strong> <br />
                      {formatToLocalTime(alarm.last_occurrence)}
                    </div>

                    <div className="mb-1">
                      <strong>Number of Occurrences:</strong> <br />
                      {alarm.occurrence_count}
                    </div>
                  </div>
                }
              >
                <div className="inline-block cursor-pointer bg-transparent text-left text-sm text-primary hover:text-blue-800">
                  <i className="fa-regular fa-circle-info w-4 text-center"></i>

                  <span className="ml-2 inline-block">
                    Details
                  </span>
                </div>
              </Tippy>
            ),
            action: (
              <PermissionGuard anyOf={[
                'attend-alarm',
                'resolve-alarm',
                'comment-alarm',
              ]} auth={auth}>
                <DropdownList
                  icon={<ActionIcon />}
                  options={[
                    {
                      label: 'Attend to',
                      disabled: !auth.can('attend-alarm'),
                      onClick: () => {
                        setModal({
                          name: 'warning',
                          data: {
                            endpoint: `/alarm/attend/${alarm.id}`,
                            title: 'Attend to alarm',
                            content: 'I am working on this alarm and want to update the status accordingly.',
                            successFlashMessage: 'Alarm updated successfully.',
                            onComplete: () => {
                              getTableDataStart(currentQuery)
                              setModal(null)
                            },
                            close: () => {
                              setModal(null)
                            },
                            postData: {
                              attended_user_id: auth.user.id,
                              date_attended: moment().utc().format(),
                            },
                          },
                        })
                      },
                    },
                    {
                      label: 'Resolve',
                      disabled: !auth.can('resolve-alarm'),
                      onClick: () => {
                        setModal({
                          name: 'warning',
                          data: {
                            endpoint: `/alarm/resolve/${alarm.id}`,
                            title: 'Resolve alarm',
                            content: 'Do you want to update the alarm status to resolved?',
                            successFlashMessage: 'Alarm updated successfully.',
                            onComplete: () => {
                              getTableDataStart(currentQuery)
                              setModal(null)
                            },
                            close: () => {
                              setModal(null)
                            },
                            postData: {
                              resolved_user_id: auth.user.id,
                              date_resolved: moment().utc().format(),
                              attended_user_id: alarm.attended_by?.id || auth.user.id,
                              date_attended: alarm.date_attended || moment().utc().format(),
                            },
                          },
                        })
                      },
                    },
                    {
                      topLine: true,
                      label: 'Add comment',
                      disabled: !auth.can('comment-alarm'),
                      onClick: () => {
                        setModal({
                          name: 'comment',
                          data: { alarmId: alarm.id },
                        })
                      },
                    },
                  ]}
                />
              </PermissionGuard>
            ),
            renderRowSubComponent: (<AlarmCommentSection alarm={alarm} setModal={setModal} user={auth.user} />),
          }
        })

        let tableData = {
          ...data.alarms,
          data: alarmData,
        }

        setTableData(tableData)
        setTableDataLoading(false)
      } catch (error) {
        Sentry.captureException(error)
      }
    }, 250)
  }, [
    setLastQuery,
    setModal,
    setTableData,
    setTableDataLoading,
  ])

  useMemo(async () => {
    try {
      let { data } = await apiClient.get('/alarm/types')

      if (data.success) {
        setAlarmTypes(data.alarmTypes)
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }, [setAlarmTypes])

  useEffect(() => {
    getTableData({
      pageSize: 15,
      pageIndex: 0,
    })
  }, [])

  const getTableDataStart = useCallback((params) => {
    setTableDataLoading(true)
    getTableData(params)
  }, [getTableData, setTableDataLoading])

  const tableColumns = useMemo(
    () => {
      return [
        {
          Header: () => {
            return null
          },
          id: 'expander',
          width: '5%',
          Cell: ({ row }) => {
            return (
              <span {...row.getToggleRowExpandedProps()}>
                <i className={row.isExpanded ? 'fa-regular fa-chevron-down icon' : 'fa-regular fa-chevron-right icon'}></i>
              </span>
            )
          },
        },
        {
          Header: 'Alarm',
          accessor: 'type',
          width: '15%',
        },
        {
          Header: 'Status',
          accessor: 'status',
          width: '15%',
        },
        {
          Header: 'Area',
          accessor: 'area',
          width: '10%',
        },
        {
          Header: 'Element',
          accessor: 'alarmable',
          width: '15%',
        },
        {
          Header: 'Description',
          accessor: 'description',
          width: '25%',
        },
        {
          Header: 'Occurrence Info',
          accessor: 'occurrenceInfo',
          width: '10%',
        },
        {
          Header: '',
          accessor: 'action',
          width: '5%',
          style: { textAlign: 'right' },
        },
      ]
    }, [],
  )

  const filterTabs = useMemo(() => {
    return [
      {
        title: 'View all',
        key: null,
      },
      {
        title: 'Attended',
        key: 'attended',
      },
      {
        title: 'Resolved',
        key: 'resolved',
      },
      {
        title: 'Unattended',
        key: 'unattended',
      },
    ]
  }, [])

  return (
    <>
      {
        tableData ?
          <Table
            columns={tableColumns}
            data={tableData}
            filterSearch
            filterTabs={filterTabs}
            filters
            filterList={alarmTypes}
            getTableData={getTableDataStart}
            header="Alarms"
            loading={tableDataLoading}
            headerPills={[{
              title: `${tableData.total} total`,
              color: '#175CD3',
            }]}
          /> : <SkeletonTable />
      }

      {
        modal?.name === 'comment' &&
        <CommentModal
          data={modal?.data}
          close={() => {
            setModal(null)
          }}
          complete={() => {
            getTableDataStart(lastQuery)
            setModal(null)
          }}
        />
      }
    </>
  )
}

export default DashboardAlarm
