import '@/Utilities/Form/Yup'

import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/react'
import classNames from 'classnames'
import { isEmpty, isFunction, map, padStart, split } from 'lodash-es'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import styled from 'styled-components'

import AlertContent from '@/Components/alerts/AlertContent'
import { Anchor, Button } from '@/Components/form/Buttons'
import Modal from '@/Components/Modal'
import Conditions from '@/Components/modals/condition/Conditions'
import General from '@/Components/modals/condition/General'
import Tabs from '@/Components/Tabs'
import { pageAlertState } from '@/Config/Atoms/General'
import { getInputOutputsTypes } from '@/Utilities/Accessors/InputOutputs'
import { getSiteOptions } from '@/Utilities/Accessors/Sites'
import { publish } from '@/Utilities/Events'
import { defaultValue, flattenSelectValues, formatKeys } from '@/Utilities/Form/Formatter'
import useApiClient from '@/Utilities/useApiClient'
import useEntityMonitor from '@/Utilities/useEntityMonitor'
import useFormRevalidate from '@/Utilities/useFormRevalidate'
import useTitle from '@/Utilities/useTitle'
import conditionSchema from '@/Utilities/Validation/ConditionSchema'

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
`

const ButtonGroup = styled.div`
  display: flex;
  width: calc(50% - 5px);
`

const Form = styled.form`
  .buttons {
    margin-top: 40px;
  }
`

const tabs = [{
  title: 'General',
  key: 'general',
  component: General,
}, {
  title: 'Conditions',
  key: 'conditions',
  component: Conditions,
}]

export default function ConditionModal(props) {
  const isEditing = props.data?.isEditing || false

  const apiClient = useApiClient()
  const [inputOutputTypeOptions, setInputOutputTypeOptions] = useState(null)
  const [selectedTab, setSelectedTab] = useState('general')
  const [conditionCount, setConditionCount] = useState(0)
  const [siteOptions, setSiteOptions] = useState([])
  const [setupLoading, setSetupLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const firstLoad = useRef(true)
  const form = useRef(null)
  const navigate = useNavigate()
  const [allInputOutputs, setAllInputOutputs] = useState({})
  const {
    isInRunningState,
    getRelatedProgramNames,
  } = useEntityMonitor()

  let condition

  if (props.data?.condition) {
    condition = props.data?.condition
  }

  useTitle([`${condition ? 'Edit' : 'Add'} condition`, condition?.name])

  const preventSaving = isInRunningState('condition', condition?.id)
  const runningProgramNames = getRelatedProgramNames('condition', condition?.id)

  const setAlert = useSetRecoilState(pageAlertState)
  const {
    trigger,
    reset,
    watch,
    setValue,
    getValues,
    control,
    register,
    handleSubmit,
    formState,
  } = useForm({
    resolver: yupResolver(conditionSchema),
    context: { conditionCount: conditionCount },
    defaultValues: {
      siteId: defaultValue(condition || props?.data, 'site'),
      conditionRules: [],
    },
  })

  // Perform form revalidation when dirty and any field is updated
  const { submitErrorHandler } = useFormRevalidate({
    watch,
    formState,
    trigger,
    schema: conditionSchema,
    revalidateWhen: formState.isDirty && formState.isSubmitted,
    setSelectedTab,
  })

  const selectedSiteId = watch('siteId')

  const {
    errors,
    dirtyFields,
  } = formState

  const fieldArrayMethods = useFieldArray({
    control,
    name: 'conditionRules',
  })

  useEffect(() => {
    if (condition && firstLoad.current) {
      firstLoad.current = false

      const onStabilityTime = split(condition.onStabilityTime, ':')
      const offStabilityTime = split(condition.offStabilityTime, ':')

      setValue('onStabilityTimeHours', onStabilityTime[0])
      setValue('onStabilityTimeMinutes', onStabilityTime[1])
      setValue('onStabilityTimeSeconds', onStabilityTime[2])

      setValue('offStabilityTimeHours', offStabilityTime[0])
      setValue('offStabilityTimeMinutes', offStabilityTime[1])
      setValue('offStabilityTimeSeconds', offStabilityTime[2])
    }
  }, [
    reset,
    setValue,
    register,
    condition,
  ])

  useEffect(() => {
    return () => {
      firstLoad.current = true
    }
  }, [])

  const tabChange = useCallback((value) => {
    setSelectedTab(value)
  }, [setSelectedTab])

  const updateConditionCount = (count) => {
    setConditionCount(count)
  }

  const onSubmit = async (data, afterSave = null) => {
    data.onStabilityTime = `${padStart(data.onStabilityTimeHours, 2, 0)}:${padStart(data.onStabilityTimeMinutes, 2, 0)}:${padStart(data.onStabilityTimeSeconds, 2, 0)}`,
    data.offStabilityTime = `${padStart(data.offStabilityTimeHours, 2, 0)}:${padStart(data.offStabilityTimeMinutes, 2, 0)}:${padStart(data.offStabilityTimeSeconds, 2, 0)}`,

    data.conditionRules = map(data.conditionRules, (rule) => {
      return flattenSelectValues(rule)
    })
    data = formatKeys(data, 'snake')
    data.site_id = flattenSelectValues(data.site_id).value

    setAlert(null)
    setIsLoading(true)

    try {
      let { data: responseData } = await apiClient.post(`/condition/${condition ? `update/${condition?.id}`: 'create'} `, data)
      if (isFunction(afterSave)) {
        await afterSave()
      }

      if (responseData.success) {
        if (isFunction(props.data?.onSave)) {
          props.data.onSave()
        } else {
          navigate('/conditions')
        }

        setAlert({
          type: 'info',
          content: `Attempting to ${isEditing ? 'update' : 'create'} condition ${responseData.condition.name}.`,
        })

        publish('conditionCreated')

        props.close()
      }
    } catch (error) {
      setAlert({
        type: 'error',
        content: 'An error has occured and we were not able to save this condition. Please try again.',
      })
      Sentry.captureException(error)
    } finally {
      setIsLoading(false)
    }
  }

  const getInputOutputs = useCallback(async (selectedSiteId) => {
    try {
      const query = new URLSearchParams([
        ['pageSize', 1000],
        ['siteIds[]', selectedSiteId.value],
        ['with[]', 'details.manufacturerModel'],
      ])

      const { data } = await apiClient.get(`/input-output/query?${query}`)

      if (data && data.inputOutputs) {
        setAllInputOutputs(formatKeys(data.inputOutputs.data, 'camel'))
      }
    } catch (error) {
      Sentry.captureException(error)
      setAlert({
        type: 'error',
        content: 'Failed to retrieve I/Os.',
      })
    }
  }, [])

  useEffect(() => {
    (async () => {
      if (selectedSiteId) {
        getInputOutputs(selectedSiteId)
      }
    })()
  }, [selectedSiteId])

  useEffect(() => {
    (async () => {
      const types = await getInputOutputsTypes(apiClient)

      setInputOutputTypeOptions(types)
      setSetupLoading(false)
    })()
  }, [])

  useEffect(() => {
    (async () => {
      setSiteOptions(await getSiteOptions(apiClient))
    })()
  }, [])

  return (
    <Modal
      title={`${condition ? 'Edit': 'Add'} condition`}
      close={props.close}
      closeOnOutsideClick={props.closeOnOutsideClick}
    >
      <Tabs
        currentlySelected={selectedTab}
        defaultTab="general"
        tabs={tabs}
        onChange={tabChange}
        disabled={isEmpty(allInputOutputs)}
        tooltipMessage={isEmpty(allInputOutputs) ? (selectedSiteId?.value ? 'No I/Os available for the selected site' : 'Loading I/Os...') : null}
      />
      {setupLoading ?
        <div className="flex h-80 items-center justify-center">
          <div className="primary-loader"></div>
        </div> : (
          <FormProvider
            control={control}
            dirtyFields={dirtyFields}
            errors={errors}
            getValues={getValues}
            handleSubmit={handleSubmit}
            register={register}
            reset={reset}
            setValue={setValue}
            trigger={trigger}
            watch={watch}
            {...fieldArrayMethods}
          >
            {preventSaving && (
              <AlertContent className="mb-4">
                You cannot edit or delete this as the following programs are running: {runningProgramNames}
              </AlertContent>
            )}

            <Form onSubmit={handleSubmit(onSubmit, submitErrorHandler)} autoComplete="off" noValidate ref={form}>
              {map(tabs, (tab) => {
                return (
                  <div className={classNames({ hidden: tab.key != selectedTab })} key={tab.key} id={tab.key}>
                    <tab.component
                      {...props}
                      allInputOutputs={allInputOutputs}
                      inputOutputTypeOptions={inputOutputTypeOptions}
                      siteOptions={siteOptions}
                      updateConditionCount={updateConditionCount}
                    />
                  </div>
                )
              })}

              <ButtonWrapper className="buttons">
                <Anchor
                  style={{ width: 'calc(50% - 5px)' }}
                  className="transparent"
                  onClick={() => {
                    setAlert(null)
                    reset()
                    props.close()
                  }}
                >
                Close
                </Anchor>

                <ButtonGroup>
                  <Button
                    style={{ flexGrow: 1 }}
                    disabled={isLoading ? true : false}
                    disabledWithReason={preventSaving && runningProgramNames}
                  >
                    {isLoading ? <div className="primary-loader light"></div> : 'Save'}
                  </Button>
                </ButtonGroup>
              </ButtonWrapper>
            </Form>
          </FormProvider>
        )}
    </Modal>
  )
}
