import '@/Utilities/Form/Yup'

import { find } from 'lodash-es'
import { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import Input from '@/Components/form/Input'
import InputError from '@/Components/form/InputError'
import SelectField from '@/Components/form/Select'
import { getReportingDashboardOptions } from '@/Utilities/Accessors/ReportingDashboards'
import useApiClient from '@/Utilities/useApiClient'

export default function General(props) {
  const apiClient = useApiClient()
  let isEditing = props.data?.chart.isEditing || false

  const [reportingDashboardOptions, setReportingDashboardOptions] = useState([])
  const [setupLoading, setSetupLoading] = useState(true)

  const {
    control,
    errors,
    register,
  } = useFormContext()

  useEffect(() => {
    (async () => {
      const dashboardOptions = await getReportingDashboardOptions(apiClient)
      setReportingDashboardOptions(dashboardOptions)
      setSetupLoading(false)
    })()
  }, [])

  return (
    <>
      {setupLoading ?
        <div className="flex h-80 items-center justify-center">
          <div className="primary-loader"></div>
        </div> : (
          <>
            <>
              <Controller
                control={control}
                defaultValue={find(reportingDashboardOptions, { value: props.data?.chart?.reportingDashboardId }) || []}
                name="reportingDashboardId"
                render={({ field }) => {
                  return (
                    <SelectField
                      {...field}
                      isMulti={false}
                      isSearchable={true}
                      label="Dashboard"
                      isRequired={true}
                      options={reportingDashboardOptions}
                      hasError={!!errors.reportingDashboardId}
                      isDisabled={isEditing}
                    />
                  )
                }}
              />
              {errors.reportingDashboardId && <InputError message={errors.reportingDashboardId.message} />}
            </>

            <div className="mt-5">
              <Input
                name="name"
                label="Name"
                isRequired={true}
                className={errors.name && 'error'}
                {...register('name', { value: props.data?.chart?.name })}
              />
              {errors.name && <InputError message={errors.name.message} />}
            </div>
          </>
        )
      }
    </>
  )
}
