import { get, toNumber } from 'lodash-es'

/**
 * Extract pageIndex, pageSize and filters from currentQuery object.
 *
 * @param {Object}  currentQuery  An object containing either one of pageIndex, pageSize and/or filters.
 *
 * @returns {{pageIndex: *, pageSize: *, filters: *}}
 */
export const getPaginationMeta = (currentQuery) => {
  return {
    pageIndex: toNumber(get(currentQuery, 'pageIndex', 0)),
    pageSize: toNumber(get(currentQuery, 'pageSize', 15)),
    filters: get(currentQuery, 'filters', null),
  }
}
