import * as Sentry from '@sentry/react'
import { debounce, forEach, map } from 'lodash-es'
import { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import Pill from '@/Components/pill/Pill'
import PillWrapper from '@/Components/pill/PillWrapper'
import SkeletonTable from '@/Components/SkeletonTable'
import Table from '@/Components/Table'
import { publish, standardActions, subscribe, unsubscribe } from '@/Utilities/Events'
import { getPaginationMeta } from '@/Utilities/Pagination'
import useApiClient from '@/Utilities/useApiClient'
import useEventSubscriber from '@/Utilities/useEventSubscriber'

const ActionContainer = styled.div`
  .action-loader {
    display: none;
  }

  &.action-loading {
    .action {
      display: none;
    }

    .action-loader {
      display: block;
    }
  }
`

const Action = styled.div`
  align-items: center;
  background: var(--primary);
  border-radius: 50%;
  color: white;
  display: flex;
  font-size: 18px;
  height: 30px;
  justify-content: center;
  line-height: 1;
  width: 30px;
  cursor: pointer;

  &.loading {
    background: red;
  }
`

function AreasManage(props) {
  // Utilities
  const apiClient = useApiClient()

  // Internal component state
  const [tableData, setTableData] = useState(null)
  const [tableDataLoading, setTableDataLoading] = useState(true)
  const [lastQuery, setLastQuery] = useState()

  // Events
  useEventSubscriber(['area'], standardActions, () => {
    updateTable()
  })

  const updateTable = useCallback(() => {
    getTableData(getPaginationMeta(lastQuery))
  }, [lastQuery])

  const {
    actionType,
    dataUrl,
    tableGroup,
    userId,
  } = props

  const getTableData = useMemo(() => {
    return debounce(async ({
      pageIndex,
      pageSize,
      filters,
    }) => {
      const currentQuery = {
        pageIndex,
        pageSize,
        filters,
      }

      setLastQuery(currentQuery)

      const query = new URLSearchParams([
        ['page', pageIndex + 1],
        ['pageSize', pageSize],
        ['searchFields[]', 'name'],
        ['search', filters?.search?.value || ''],
        ['with[]', 'sites'],
      ])

      try {
        const { data } = await apiClient.get(`${dataUrl}?${query}`)

        let areaData = map(data.areas.data, (area) => {
          return {
            name: area.name,
            sites: (
              area.sites.length ?
                <PillWrapper>
                  {map(area.sites, (site, index, arr) => {
                    if (index < 2) {
                      return (
                        <Pill color="#344054" key={index}>
                          {site.name}
                        </Pill>
                      )
                    } else if (index + 1 === arr.length) {
                      return <Pill color="#1570ef" key={index}>{`+${arr.length - 2}`}</Pill>
                    }
                  })}
                </PillWrapper> :
                '-'
            ),
            action: (
              <ActionContainer>
                <Action
                  className="action"
                  onClick={async (event) => {
                    let endpoint = null

                    if (actionType === 'add') {
                      endpoint = '/user/area/add-permission'
                    }

                    if (actionType === 'remove') {
                      endpoint = '/user/area/remove-permission'
                    }

                    await apiClient.post(endpoint, {
                      user_id: userId,
                      area_id: area.id,
                    })

                    event.target.parentElement.classList.add('action-loading')
                    publish('areasManage.updated', true)
                  }}
                >
                  {actionType === 'add' ? '+' : ''}
                  {actionType === 'remove' ? '-' : ''}
                </Action>

                <div className="action-loader">
                  <div className="primary-loader small"></div>
                </div>
              </ActionContainer>
            ),
          }
        })

        let tableData = {
          ...data.areas,
          data: areaData,
        }

        setTableData(tableData)
        setTableDataLoading(false)

        // Clear active buttons
        forEach(document.querySelectorAll('.action-loading'), (element) => {
          element.classList.remove('action-loading')
        })
      } catch (error) {
        Sentry.captureException(error)
      }
    }, 250)
  }, [
    setTableData,
    setTableDataLoading,
    actionType,
    dataUrl,
    tableGroup,
    userId,
  ])

  useEffect(() => {
    const areasManageUpdated = () => {
      getTableData(lastQuery)
    }

    subscribe('areasManage.updated', areasManageUpdated)

    return () => {
      unsubscribe('areasManage.updated', areasManageUpdated)
    }
  }, [lastQuery, getTableData])

  const tableColumns = useMemo(
    () => {
      return [
        {
          Header: 'Name',
          accessor: 'name',
          width: '50%',
        },
        {
          Header: 'Sites',
          accessor: 'sites',
          width: '50%',
        },
        {
          Header: '',
          accessor: 'action',
          width: '30px',
          style: { textAlign: 'right' },
        },
      ]
    }, [],
  )

  const getTableDataStart = useCallback((params) => {
    setTableDataLoading(true)
    getTableData(getPaginationMeta(params))
  }, [getTableData, setTableDataLoading])

  useEffect(() => {
    getTableData({
      pageSize: 15,
      pageIndex: 0,
    })
  }, [getTableData])

  return (
    tableData ?
      <Table
        tableMinWidth="350px"
        columns={tableColumns}
        data={tableData}
        getTableData={getTableDataStart}
        header={props.title}
        loading={tableDataLoading}
        topSearch
        headerPills={[{
          title: `${tableData.total} areas`,
          color: '#175CD3',
        }]}
      /> : <SkeletonTable />
  )
}

export default AreasManage
