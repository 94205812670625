import { twMerge } from 'tailwind-merge'

export default function VerticalProgressBar({
  colorName = 'gray',
  percent,
  className,
}) {
  return (
    <div className={twMerge(
      'relative',
      'h-32',
      'w-3',
      className,
    )}>
      <div
        className={
          twMerge(
            'flex overflow-hidden rounded-full text-xs items-end',
            'h-full',
            `bg-${colorName}/50`,
          )
        }
      >
        <div
          style={{ height: `${percent}%` }}
          className={
            twMerge(
              'flex flex-col justify-center whitespace-nowrap text-center text-white shadow-none rounded-full flex-grow',
              `bg-${colorName}`,
            )
          }
        />
      </div>
    </div>
  )
}
