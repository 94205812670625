import { get, isArray, map } from 'lodash-es'

export const unitMap = {
  // Volume
  gal: 'Gallons',
  kgal: 'Kilogallons',
  l: 'Liters',
  kl: 'Kilolitres',

  // Flow Rate
  galPerHr: 'Gallons per hour',
  galPerMin: 'Gallons per minute',
  kGalPerHr: 'Kilogallons per hour',
  kGalPerMin: 'Kilogallons per minute',
  mPerHr: 'Miles per hour',
  lPerHr: 'Litres per hour',
  lPerMin: 'Litres per minute',
  klPerHr: 'Kilolitres per hour',
  klPerMin: 'Kilolitres per minute',

  // Area and Depth
  ac: 'Acres',
  acFt: 'Acres feet',
  acIn: 'Acres inches',
  ha: 'Hectares',
  sqMeters: 'Square meters',
  sqYards: 'Square yards',
  ft: 'Feet',
  mm: 'Millimetres',
  cm: 'Centimetres',
  in: 'Inches',

  // Time
  mins: 'Minutes',
  secs: 'Seconds',
  hrs: 'Hours',

  // Other
  hz: 'Hz',
  ph: 'pH',
  psi: 'PSI',
  resLevelCm: 'Reservoir level cm',
  resLevelInch: 'Reservoir level inch',
  ec: 'Electrical conductivity',
  percent: 'Percent (%)',

  // Range or Limits
  min: 'Minimum',
  max: 'Maximum',

  // Temperature
  fahrenheit: 'Fahrenheit',
  celsius: 'Celsius',
}

export const abbreviationMap = {
  // Volume
  gal: 'gal',
  kgal: 'kgal',
  l: 'l',
  kl: 'kl',

  // Flow Rate
  galPerHr: 'gph',
  galPerMin: 'gpm',
  kGalPerHr: 'kgph',
  kGalPerMin: 'kgpm',
  mPerHr: 'mph',
  lPerHr: 'lph',
  lPerMin: 'lpm',
  klPerHr: 'klph',
  klPerMin: 'klpm',

  // Area and Depth
  ac: 'ac',
  acFt: 'ac-ft',
  acIn: 'ac-in',
  ha: 'ha',
  sqMeters: 'sqm',
  sqYards: 'sqyd',
  ft: 'ft',
  mm: 'mm',
  cm: 'cm',
  in: 'in',

  // Time
  mins: 'min',
  secs: 'sec',
  hrs: 'hr',

  // Other
  hz: 'hz',
  ph: 'ph',
  psi: 'psi',
  resLevelCm: 'res. level cm',
  resLevelInch: 'res. level in',
  ec: 'ec',
  percent: '%',

  // Range or Limits
  min: 'min',
  max: 'max',

  // Temperature
  fahrenheit: '°F',
  celsius: '°C',
}

export const unitTypes = {
  rate: [
    'galPerMin',
    'galPerHr',
    'kGalPerMin',
    'kGalPerHr',
    'lPerMin',
    'lPerHr',
    'klPerMin',
    'klPerHr',
  ],
  scale: [
    'galPerHr',
    'galPerMin',
    'kGalPerHr',
    'kGalPerMin',
    'lPerHr',
    'lPerMin',
    'klPerHr',
    'klPerMin',
    'psi',
    'hz',
    'ph',
    'fahrenheit',
    'celsius',
    'ec',
  ],
  stabilisation: ['mins', 'secs'],
  delay: ['mins', 'secs'],
  time: [
    'mins',
    'secs',
    'hrs',
  ],
  area: ['ac', 'ha'],
  delta: [
    'gal',
    'l',
    'psi',
    'hz',
    'ph',
  ],
  flowMeterDelta: ['gal', 'l'],
  pulseValue: [
    'gal',
    'kgal',
    'l',
    'kl',
  ],
  waterQuantity: [
    'gal',
    'kgal',
    'l',
    'kl',
  ],
  expectedFlow: ['galPerMin', 'galPerHr'],
}

unitTypes.analogInputDelta = [
  ...unitTypes.delta,
  'fahrenheit',
  'celsius',
  'ec',
  'mPerHr',
  'acFt',
  'acIn',
  'ft',
  'mm',
  'cm',
  'in',
  'percent',
]

unitTypes.analogInputScale = [...unitTypes.analogInputDelta]

unitTypes.sensorSoilProbeDelta = [
  'fahrenheit',
  'celsius',
  'percent',
  'psi',
]

unitTypes.sensorSoilProbeScale = [...unitTypes.sensorSoilProbeDelta]

export const unitOptions = (type) => {
  if (isArray(type)) {
    return map(type, (unit) => {
      return {
        label: unitMap[unit],
        value: unit,
      }
    })
  }

  if (!unitTypes[type]) {
    throw `Unit type ${type} not found.`
  }

  const options = map(unitTypes[type], (unit) => {
    if (!unitMap[unit]) {
      throw `Unit ${unit} not found.`
    }

    return {
      label: unitMap[unit],
      value: unit,
    }
  })

  return options
}

export const abbreviationToText = (value) => {
  return get(unitMap, value, value)
}
