import classNames from 'classnames'
import { isEmpty } from 'lodash-es'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import Input from '@/Components/form/Input'
import InputError from '@/Components/form/InputError'
import InputLabel from '@/Components/form/InputLabel'
import LightSwitch from '@/Components/form/LightSwitch'
import RequiredAsterisk from '@/Components/form/RequiredAsterisk'
import Select from '@/Components/form/Select'

const GroupedInputs = styled.div`
  align-items: center;
  display: flex;

  input {
    margin-right: 20px;
  }
`

const TimeSeperator = styled.div`
  margin: 0 10px 0 -10px;
`

export default function General(props) {
  const {
    setValue,
    register,
    errors,
    getValues,
    control,
  } = useFormContext()

  let condition

  if (props.data?.condition) {
    condition = props.data?.condition
  }

  const { siteOptions } = props

  return (
    <div className="mt-5">
      {
        !isEmpty(siteOptions) &&
        <div className={classNames({ hidden: condition })}>
          <Controller
            control={control}
            name="siteId"
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  isMulti={false}
                  isSearchable={true}
                  label="Site"
                  isRequired={true}
                  options={siteOptions}
                  placeholder="Search"
                  hasError={!!errors.siteId}
                />
              )
            }}
          />
          {errors.siteId && <InputError message={errors.siteId.message} />}
        </div>
      }

      <div className="mt-5">
        <Input
          label="Name"
          isRequired={true}
          type="text"
          className={errors.name && 'error'}
          {...register('name', { value: condition?.name || '' })}
        />
        {errors.name && <InputError message={errors.name.message} />}
      </div>

      <div className="grid grid-cols-1 @md:grid-cols-2">
        <div className="mt-5">
          <InputLabel>On stability time <RequiredAsterisk /></InputLabel>

          <GroupedInputs>
            <Input
              placeholder="hh"
              maxlength="2"
              type="number"
              width="60px"
              textCenter
              className={errors.onStabilityTimeHours && 'error'}
              {...register('onStabilityTimeHours')}
            />

            <TimeSeperator>
              :
            </TimeSeperator>

            <Input
              placeholder="mm"
              maxlength="2"
              type="number"
              width="60px"
              textCenter
              className={errors.onStabilityTimeMinutes && 'error'}
              {...register('onStabilityTimeMinutes')}
            />

            <TimeSeperator>
              :
            </TimeSeperator>

            <Input
              placeholder="ss"
              maxlength="2"
              type="number"
              width="60px"
              textCenter
              className={errors.onStabilityTimeSeconds && 'error'}
              {...register('onStabilityTimeSeconds')}
            />
          </GroupedInputs>

          {
            (
              (
                errors.onStabilityTimeHours?.type === 'min' ||
                errors.onStabilityTimeHours?.type === 'max' ||
                errors.onStabilityTimeMinutes?.type === 'min' ||
                errors.onStabilityTimeMinutes?.type === 'max' ||
                errors.onStabilityTimeSeconds?.type === 'min' ||
                errors.onStabilityTimeSeconds?.type === 'max'
              ) &&
              <InputError message="On stability time should be between 00:00 and 23:59" />
            ) ||
            (
              (errors.onStabilityTimeHours || errors.onStabilityTimeMinutes || errors.onStabilityTimeSeconds) &&
              <InputError message="On stability time is a required field" />
            )
          }
        </div>

        <div className="mt-5">
          <InputLabel>Off stability time <RequiredAsterisk /></InputLabel>

          <GroupedInputs>
            <Input
              placeholder="hh"
              maxlength="2"
              type="number"
              width="60px"
              textCenter
              className={errors.offStabilityTimeHours && 'error'}
              {...register('offStabilityTimeHours')}
            />

            <TimeSeperator>
              :
            </TimeSeperator>

            <Input
              placeholder="mm"
              maxlength="2"
              type="number"
              width="60px"
              textCenter
              className={errors.offStabilityTimeMinutes && 'error'}
              {...register('offStabilityTimeMinutes')}
            />

            <TimeSeperator>
              :
            </TimeSeperator>

            <Input
              placeholder="ss"
              maxlength="2"
              type="number"
              width="60px"
              textCenter
              className={errors.offStabilityTimeSeconds && 'error'}
              {...register('offStabilityTimeSeconds')}
            />
          </GroupedInputs>

          {
            (
              (
                errors.offStabilityTimeHours?.type === 'min' ||
                errors.offStabilityTimeHours?.type === 'max' ||
                errors.offStabilityTimeMinutes?.type === 'min' ||
                errors.offStabilityTimeMinutes?.type === 'max' ||
                errors.offStabilityTimeSeconds?.type === 'min' ||
                errors.offStabilityTimeSeconds?.type === 'max'
              ) &&
              <InputError message="Off stability time should be between 00:00 and 23:59" />
            ) ||
            (
              (errors.offStabilityTimeHours || errors.offStabilityTimeMinutes || errors.offStabilityTimeSeconds) &&
              <InputError message="Off stability time is a required field" />
            )
          }
        </div>
      </div>

      <div className="mt-5 flex flex-col">
        <LightSwitch
          label="Reporting"
          helpTooltip="If enabled, data will be reported to FutureOps and the data will be available for reporting."
          onToggle={(name, value) => {
            setValue('changeOfState', value)
          }}
          defaultState={condition?.changeOfState || getValues('changeOfState') || true}
          {...register('changeOfState', { value: condition?.changeOfState || true })}
        />

        <LightSwitch
          label="Record history"
          onToggle={(name, value) => {
            setValue('recordHistory', value)
          }}
          defaultState={condition?.recordHistory || getValues('recordHistory') || false}
          {...register('recordHistory', { value: condition?.recordHistory || false })}
        />
      </div>
    </div>
  )
}
