import { endsWith, includes, startsWith, trim, trimEnd, trimStart } from 'lodash-es'

/**
 * Returns the initials of a given name.
 *
 * @param {string} name - The name to get the initials from.
 * @returns {string} - The initials.
 */
export const initials = (name) => {
  if (!name) {
    return null
  }

  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu')

  let initials = [...name.matchAll(rgx)] || []

  initials = (
    (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
  ).toUpperCase()

  return initials
}

/**
 * Matches a target string based on a pattern that can contain wildcard (*) characters.
 * The wildcard at the start or the end of the pattern string is used to perform partial matches.
 *
 * @param {string} pattern - The pattern string, which can contain '*' characters at the start and/or end.
 * @param {string} target - The string to be matched against the pattern.
 * @returns {boolean} - Returns true if the target matches the pattern (considering wildcard characters); otherwise, false.
 */
export const matchPattern = (pattern, target) => {
  if (startsWith(pattern, '*') && endsWith(pattern, '*')) {
    return includes(target, trim(pattern, '*'))
  } else if (startsWith(pattern, '*')) {
    return endsWith(target, trimStart(pattern, '*'))
  } else if (endsWith(pattern, '*')) {
    return startsWith(target, trimEnd(pattern, '*'))
  } else {
    return target === pattern
  }
}
