import Tippy from '@tippyjs/react'
import classNames from 'classnames'
import { debounce, filter, includes, isFunction, lowerCase, some } from 'lodash-es'
import { rgba } from 'polished'
import { forwardRef, useCallback, useMemo, useRef, useState } from 'react'
import Select from 'react-select'
import styled from 'styled-components'

import InputLabel from '@/Components/form/InputLabel'
import RequiredAsterisk from '@/Components/form/RequiredAsterisk'
import { mediaBreakpointDown } from '@/Utilities/Breakpoint'
import { normaliseContrast } from '@/Utilities/Colors'

const TrailingInputStyle = mediaBreakpointDown('sm', `
  width: 100%;

  .react-select {
    &__control {
      border-radius: 5px 5px 0 0;
    }
  }
`)

const InputWrapper = styled.div`
  color: var(--text-dark);
  display: ${(props) => {
    return props.display || 'initial'
  }};
  max-width: ${(props) => {
    return props.maxWidth ? `${props.maxWidth}px` : 'initial'
  }};
  width: ${(props) => {
    return props.width || '100%'
  }};

  > div {
    width: 100%;
  }

  ${(props) => {
    return props.trailingInput && `
      ${TrailingInputStyle}
    `
  }}
`

const selectStyles = (props) => {
  return {
    dropdownIndicator: (provided) => {
      if (props.small) {
        const padding = '4px 10px'

        return {
          ...provided,
          padding,
        }
      }

      return { ...provided }
    },
    indicatorSeparator: (provided) => {
      const display = 'none'

      return {
        ...provided,
        display,
      }
    },
    valueContainer: (provided) => {
      let padding = '0 12px'

      if (props.small) {
        padding = '4px 10px'
      }

      return {
        ...provided,
        padding,
      }
    },
    control: (provided, state) => {
      let borderRadius = '5px'
      let minHeight = '44px'
      let defaultBorderColor = '#D0D5DD'
      let errorBorderColor = '#FDA29B'
      let borderColor = defaultBorderColor
      let boxShadow = 'none'
      let outline = 'none'

      if (props.small) {
        minHeight = 'auto'
      }

      if (props.trailingInput) {
        borderRadius = '5px 0 0 5px'
      }

      // Menu open without error
      if (state.menuIsOpen) {
        outline = '2px solid #2684FF'
      }

      // Menu open with error
      if (props.hasError && state.menuIsOpen) {
        outline = '2px solid #FDA29B !important'
      }

      // Error regardless of menu open or closed
      if (props.hasError) {
        borderColor = errorBorderColor
      }

      return {
        ...provided,
        borderRadius,
        minHeight,
        borderColor,
        boxShadow,
        outline,
        '&:hover': { borderColor: props.hasError ? errorBorderColor : defaultBorderColor },
      }
    },
    multiValue: (provided, state) => {
      let backgroundColor = '#f9fafb'
      let border = '1px solid #D0D5DD'
      let color = 'var(--text-light)'

      if (props.multiValueColor) {
        color = `${props.multiValueColor}`
        backgroundColor = rgba(props.multiValueColor, 0.1)
        border = 'none'
      }

      if (state.data && state.data.color) {
        color = `${state.data.color}`
        backgroundColor = rgba(state.data.color, 0.1)
        border = 'none'
      }

      const borderRadius = '14px'

      return {
        ...provided,
        borderRadius,
        border,
        backgroundColor,
        color,
      }
    },
    multiValueLabel: (provided, state) => {
      let color = 'var(--text-light)'

      if (props.multiValueColor) {
        color = props.multiValueColor
      }

      if (state.data && state.data.color) {
        color = normaliseContrast(state.data.color, '#fff')
      }

      return {
        ...provided,
        color,
      }
    },
    singleValue: (provided) => {
      const color = 'var(--text-dark)'

      return {
        ...provided,
        color,
      }
    },
    multiValueRemove: (provided) => {
      const borderRadius = '0 14px 14px 0'

      return {
        ...provided,
        borderRadius,
      }
    },
    menuPortal: (provided) => {
      return {
        ...provided,
        zIndex: 11000,
      }
    },
  }
}

const SelectField = forwardRef((props, ref) => {
  const [inputValue, setInputValue] = useState('')
  const [menuIsOpen, setMenuOpen] = useState(false)
  const inputWrapperRef = useRef()

  const {
    helpTooltip,
    afterChange,
    onChange,
    afterMenuClose,
    ...restProps
  } = props

  const debouncedInputChange = debounce((value) => {
    setInputValue(value)
  }, 200, {
    maxWait: 500,
    leading: true,
    trailing: true,
  })

  const handleInputChange = useCallback(debouncedInputChange, [])

  const filterOptions = useCallback((option) => {
    if (inputValue) {
      // If the option is in a group, check the subOption labels
      if (option.options) {
        return some(option.options, (subOption) => {
          return includes(lowerCase(subOption.label), lowerCase(inputValue))
        },
        )
      }

      // If the option is not in a group, check the option label itself
      return includes(lowerCase(option.label), lowerCase(inputValue))
    }

    return true
  }, [props.options, inputValue])

  const filteredOptions = useMemo(() => {
    return filter(props.options || [], filterOptions)
  }, [props.options, inputValue])

  const suggestedMenuPlacement = useMemo(() => {
    if (menuIsOpen && inputWrapperRef.current) {
      const rect = inputWrapperRef.current.getBoundingClientRect()
      const spaceAbove = rect.top
      const totalHeight = window.innerHeight

      if (spaceAbove / totalHeight >= 0.6) {
        return 'top'
      } else {
        return 'bottom'
      }
    }

    return 'auto'
  }, [inputWrapperRef, menuIsOpen])

  return (
    <InputWrapper
      {...restProps}
      className={classNames({ 'error-message': props.hasError })}
      ref={inputWrapperRef}
    >
      {props.label && (
        <InputLabel htmlFor={props.name}>
          {props.label}

          {props.isRequired && <RequiredAsterisk />}

          {helpTooltip && (
            <Tippy content={helpTooltip} disabled={helpTooltip ? false : true} zIndex="99999" theme="light" placement="top" delay={200}>
              <i className="fa-solid fa-circle-question ml-3 flex h-5 cursor-pointer items-center text-base text-slate-500"></i>
            </Tippy>
          )}
        </InputLabel>
      )}

      <Select
        {...restProps}
        classNamePrefix="react-select"
        isSearchable={props.isSearchable || false}
        menuIsOpen={menuIsOpen}
        menuPlacement={suggestedMenuPlacement}
        menuPortalTarget={document.body}
        menuShouldBlockScroll={true}
        onInputChange={handleInputChange}
        options={filteredOptions}
        ref={ref}
        styles={selectStyles(props)}
        onMenuClose={() => {
          setMenuOpen(false)

          if (isFunction(afterMenuClose)) {
            afterMenuClose()
          }
        }}
        onMenuOpen={() => {
          return setMenuOpen(true)
        }}
        onChange={(event) => {
          if (isFunction(onChange)) {
            onChange(event)
          }

          if (isFunction(afterChange)) {
            afterChange(event)
          }
        }}
      />
    </InputWrapper>
  )
})

export default SelectField
