import Tippy from '@tippyjs/react'
import classNames from 'classnames'
import { isFunction, map } from 'lodash-es'
import { rem } from 'polished'
import styled from 'styled-components'

import Dropdown from '@/Components/Dropdown'

const Item = styled.div`
  border-bottom: ${(props) => {
    return props.bottomLine ? '1px solid #ededed' : 'none'
  }};
  border-top: ${(props) => {
    return props.topLine ? '1px solid #ededed' : 'none'
  }};
  color: var(--text-dark);
  font-size: ${rem(14)};
  min-width: 200px;
  padding: 10px 16px;
  line-height: ${rem(21)};
  user-select: none;
  white-space: nowrap;

  &:first-of-type {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:last-of-type {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &:hover {
    background-color: #ddebff;
  }
`

function DropdownList(props) {
  return (
    <Dropdown
      {...props}
      style={{ ...props.style }}
    >
      {
        props.options &&
        map(props.options, (item, index) => {
          if (!item.disabled || item.disabledWithReason) {
            return (
              <Tippy
                theme="light"
                disabled={!item.disabledWithReason}
                content={`You cannot edit or delete this as the following programs are running: ${item.disabledWithReason}`}
                key={`${item.label}-${index}`}
              >
                <Item
                  className={classNames({
                    'bg-gray-100 cursor-not-allowed': item.disabledWithReason,
                    'bg-white cursor-pointer': !item.disabledWithReason,
                  })}
                  topLine={item.topLine || false}
                  key={`${item.label}-${index}`}
                  onClick={(event) => {
                    if (item.disabledWithReason) {
                      event.preventDefault()
                      event.stopPropagation()
                      return
                    }

                    item.onClick()
                  }}
                  onMouseDown={(event) => {
                    if(isFunction(item.onMiddleClick) && event.button === 1) {
                      item.onMiddleClick()
                    }
                  }}
                >
                  {item.label}
                </Item>
              </Tippy>
            )
          }
        })
      }
    </Dropdown>
  )
}

export default DropdownList
