import classNames from 'classnames'
import { each, includes, map, replace, startCase, xor } from 'lodash-es'
import pluralize from 'pluralize'
import React, { useCallback, useEffect, useState } from 'react'

export default function FilterList(props) {
  const {
    mapLayerGroups,
    mapInstance,
    ioTypes,
  } = props
  const [mapFilters, setMapFilters] = useState(map(props.ioTypes, (type) => {
    return type.name
  }))

  const filterToggle = useCallback((e) => {
    const name = e.target.name

    setMapFilters(xor(mapFilters, [name]))
  }, [mapFilters])

  const clearLayers = useCallback(() => {
    if (mapInstance?.current?.map) {
      const map = mapInstance.current.map
      const groups = mapLayerGroups.current

      each(ioTypes, (type) => {
        if (map.hasLayer(groups[type.name])) {
          map.removeLayer(groups[type.name])
        }
      })
    }
  }, [])

  useEffect(() => {
    if (mapInstance?.current?.map) {
      const map = mapInstance.current.map
      const groups = mapLayerGroups.current

      clearLayers()

      each(mapFilters.length ? mapFilters : [], (filter) => {
        const filterName = filter.name ? filter.name : filter

        if (map.hasLayer(groups[filterName])) {
          map.removeLayer(groups[filterName])
        } else if (!map.hasLayer(groups[filterName])) {
          map.addLayer(groups[filterName])
        }
      })
    }
  }, [mapFilters])

  return (
    ioTypes && (
      <div className={classNames('absolute top-full mt-1 divide-y rounded bg-white shadow', props.className)}>
        {map(ioTypes, (type, key) => {
          return <div className="p-2.5" key={key}>
            <label className="flex items-center whitespace-nowrap text-sm text-slate-900 hover:cursor-pointer	" htmlFor={type.name}>
              <input
                className="mr-2 h-4 w-4"
                id={type.name}
                name={type.name}
                type="checkbox"
                value={type.name}
                checked={includes(mapFilters, type.name)}
                onChange={filterToggle}
              />
              {pluralize(startCase(replace(type.name, 'inputOutput.', '')), type.name === 'inputOutput.digitalOutputGeneral' ? 1 : type.count)}
            </label>
          </div>
        })}
      </div>
    )
  )
}
