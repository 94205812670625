import { camelCase, filter, find, isString, reduce, upperFirst } from 'lodash-es'
import React, { useMemo } from 'react'

export const childComponentsByTypeId = (children, tagId) => {
  return find(
    filter(
      React.Children.toArray(children),
      (child) => {
        return !isString(child.type) && React.isValidElement(child)
      },
    ),
    ['type.uniqueTagId', tagId],
  )
}

export default function useSubComponents(children, subComponentsMap) {
  return useMemo(() => {
    return reduce(subComponentsMap, (accumulator, propName) => {
      const subComponentTag = upperFirst(camelCase(propName))

      return {
        ...accumulator,
        [propName]: childComponentsByTypeId(children, subComponentTag),
      }
    }, {})
  }, [children])
}
