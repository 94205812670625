import { useIdle } from '@uidotdev/usehooks'
import moment from 'moment'
import { useEffect } from 'react'
import useLocalStorage from 'use-local-storage'

const useUserIdle = (refreshCallback, idleTimeout = 60000) => {
  const userIdle = useIdle(idleTimeout)
  const [userIdleAt, setUserIdleAt] = useLocalStorage('userIdleAt.siteOverview', null)

  useEffect(() => {
    if (userIdle) {
      setUserIdleAt(moment().toISOString())
    }
  }, [userIdle])

  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === 'visible') {
        const lastIdleTimestamp = moment(userIdleAt)
        const currentTime = moment()

        if (lastIdleTimestamp.isValid() && currentTime.diff(lastIdleTimestamp, 'milliseconds') > idleTimeout) {
          await refreshCallback()
          setUserIdleAt(null)
        }
      } else {
        setUserIdleAt(moment().toISOString())
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [idleTimeout, userIdleAt])

  return { userIdle }
}

export default useUserIdle
