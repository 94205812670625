import { featureGroup, geoJSON, latLng, latLngBounds, map, marker, tileLayer } from 'leaflet' // eslint-disable-line
import 'leaflet-draw' // eslint-disable-line

import { debounce, forEach, isFunction } from 'lodash-es'
import { useCallback, useEffect, useRef, useState } from 'react'
import labelIcon, { transparentIcon } from '@/Utilities/Map/Icons/LabelIcon'
import classNames from 'classnames'

const mapBounds = {
  southWest: latLng(-89.98155760646617, -180),
  northEast: latLng(89.99346179538875, 180),
  worldBounds: function () {
    return latLngBounds(this.southWest, this.northEast)
  },
}

function Map(props) {
  const [actualMap, setActualMap] = useState(null)
  const [drawingGroup, setDrawingGroup] = useState(null)
  const [markerGroup, setMarkerGroup] = useState(null)
  const clickEventSet = useRef(null)
  const {
    markers,
    onClick,
    onInit,
    id: propsId,
    disableMarkerTooltip = false,
    zoomControl = true,
  } = props

  const createMap = useCallback(() => {
    const mapInstance = new map(propsId, {
      attributionControl: false,
      maxBounds: mapBounds.worldBounds(),
      maxBoundsViscosity: 1.0,
      zoomControl,
    }).setView([0, 0], 18)

    const tileLayers = {
      google: 'https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
      mapbox: 'https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=sk.eyJ1IjoiYnJhbmlmIiwiYSI6ImNsaDd6NWE1ZzAzcXczcmpnN2pyNjkwOWkifQ.r5m6VbIcH2hZLiOYGLvOPg',
    }

    tileLayer(tileLayers.mapbox, {
      noWrap: false,
      maxZoom: 20,
      minZoom: 2,
      subdomains: [
        'mt0',
        'mt1',
        'mt2',
        'mt3',
      ],
    }).addTo(mapInstance)

    const newMarkerGroup = featureGroup().addTo(mapInstance)
    const newDrawingsGroup = geoJSON(null, {
      style: function(block) {
        return {
          fillColor: block.properties.color ? block.properties.color : '#1570ef',
          color: block.properties.color ? block.properties.color : '#1570ef',
        }
      },
    }).addTo(mapInstance)

    // Assign to ref
    setDrawingGroup(newDrawingsGroup)
    setMarkerGroup(newMarkerGroup)
    setActualMap(mapInstance)

    // Events
    if (isFunction(onInit)) {
      onInit({
        id: propsId,
        map: mapInstance,
        groups: {
          marker: newMarkerGroup,
          drawings: newDrawingsGroup,
        },
      })
    }
  }, [
    onClick,
    onInit,
    propsId,
  ])

  useEffect(() => {
    if (actualMap && isFunction(onClick)) {
      const clickEvent = (event) => {
        onClick(event)
      }

      if (clickEventSet.current) {
        actualMap.off('click', clickEventSet.current)
      }

      actualMap.on('click', clickEvent)

      clickEventSet.current = clickEvent
    }
  }, [actualMap, onClick])

  const destroyMap = useCallback(() => {
    if (markerGroup) {
      markerGroup.clearLayers()
    }

    if (drawingGroup) {
      drawingGroup.clearLayers()
    }

    if (actualMap) {
      actualMap.remove()
    }

    setMarkerGroup(null)
    setActualMap(null)
  }, [])

  const addMarkers = useCallback(() => {
    forEach(markers, (singleMarker) => {
      const newMarkerOptions = {
        draggable: singleMarker.draggable,
        icon: disableMarkerTooltip
          ? transparentIcon()
          : labelIcon(singleMarker.title, singleMarker.overrideClass),
      }

      let newMarker = marker([singleMarker.position.lat, singleMarker.position.lng], newMarkerOptions)

      if (isFunction(singleMarker.clickHandler)) {
        newMarker.on('click', (event) => {
          singleMarker.clickHandler(event, singleMarker.id)
        })
      }

      if (singleMarker.draggable && isFunction(singleMarker.dragEnd)) {
        newMarker.on('dragend', (event) => {
          singleMarker.dragEnd(event, singleMarker.id)
        })
      }

      newMarker.addTo(markerGroup)

      fitBounds()
    })
  }, [markers, markerGroup])

  const fitBounds = useCallback(debounce(() => {
    if (!markerGroup) {
      return
    }

    let bounds = mapBounds.worldBounds()

    if (markerGroup.getBounds().isValid()) {
      bounds = markerGroup.getBounds()
    }

    actualMap.fitBounds(bounds, { maxZoom: 18 })
  }, 100), [markerGroup])

  useEffect(() => {
    if (!actualMap) {
      createMap()
    }

    return () => {
      destroyMap()
    }
  }, [])

  useEffect(() => {
    if (actualMap && markerGroup) {
      addMarkers()
    }
  }, [
    actualMap,
    markerGroup,
    markers,
  ])

  return (
    <div
      className={classNames('map relative w-full h-full overflow-hidden', { rounded: !props.square })}
      id={props.id}
    >
    </div>
  )
}

export default Map
