import { rem } from 'polished'
import { useCallback } from 'react'
import styled from 'styled-components'

const Error = styled.span`
  color: #F04438;
  font-size: ${rem(14)};
`

function InputError(props) {
  const getError = useCallback((type) => {
    switch (type) {
      case 'email':
        return 'Enter a valid email address'

      case 'required':
      default:
        return 'This field is required'
    }
  }, [])

  return (
    <Error className="error-message">
      {props.children || props.message || getError(props.type)}
    </Error>
  )
}

export default InputError
