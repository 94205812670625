import '@/Utilities/DisableNumberScroll'
import 'leaflet-draw/dist/leaflet.draw.css'
import 'leaflet/dist/leaflet.css'
import 'react-loading-skeleton/dist/skeleton.css'
import 'tippy.js/dist/tippy.css'
import '@/Styles/app.scss'

import { GoogleOAuthProvider } from '@react-oauth/google'
import * as Sentry from '@sentry/react'
import Cookies from 'js-cookie'
import { isEmpty } from 'lodash-es'
import { OverlayScrollbars, ScrollbarsHidingPlugin } from 'overlayscrollbars'
import { useEffect } from 'react'
import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType, useRoutes } from 'react-router-dom'
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'

import Router from '@/Utilities/Router'

// Register scrollbar plugins
OverlayScrollbars.plugin(ScrollbarsHidingPlugin)

// Initialise Sentry
Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_API_KEY,
  beforeSend(event) {
    const cookieConsentValue = Cookies.get('cookie_consent')

    if (cookieConsentValue !== 'accepted' && !isEmpty(event.user)) {
      delete event.user
    }

    return event
  },
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      networkDetailAllowUrls: [window.location.origin, import.meta.env.VITE_API_URL],
    }),
    Sentry.metrics.metricsAggregatorIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 0,
})

const useSentryRoutes = Sentry.wrapUseRoutes(useRoutes)

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js')
      .then((registration) => {
        console.info('Service Worker registered with scope:', registration.scope)

        if (!navigator.serviceWorker.controller) {
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        }
      })
      .catch((error) => {
        Sentry.captureException(error)
      })
  })
} else {
  console.warn('Service workers are not supported in this browser. This may affect the accuracy of program timers and progress indicators.')
}

ReactDOM.render(
  <StrictMode>
    <Sentry.ErrorBoundary
      onError={(e) => {
        if (import.meta.env.MODE !== 'development') {
          window.location = '/error'
        } else {
          console.error('Sentry ErrorBoundary triggered: ', e)
        }
      }}
    >
      <RecoilRoot>
        <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
          <BrowserRouter>
            <Router useSentryRoutes={useSentryRoutes} />
          </BrowserRouter>
        </GoogleOAuthProvider>
      </RecoilRoot>
    </Sentry.ErrorBoundary>
  </StrictMode>,
  document.getElementById('root'),
)
