import { isFunction } from 'lodash-es'
import { rem, rgba } from 'polished'
import { forwardRef } from 'react'
import styled from 'styled-components'

const PillContent = styled.div`
  align-items: center;
  display: inline-flex;
  font-size: ${(props) => {
    return props.fontSize ? rem(props.fontSize) : rem(12)
  }};
  padding: ${(props) => {
    return props.padding ? props.padding : '2px 8px'
  }};
`

const Dot = styled.div`
  border-radius: 100%;
  display: inline-block;
  margin-right: 5px;
  height: 6px;
  width: 6px;
`

const PillBackground = styled.div`
  background: #ffffff;
  border-radius: 20px;
  display: inline-flex;
  overflow: hidden;
  margin-right: ${(props) => {
    return props.marginRight ? props.marginRight : '8px'
  }};
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: ${(props) => {
    return props.hasDot ? '4px' : '0'
  }};

  &:last-of-type {
    margin-right: 0;
  }
`

const Pill = forwardRef((props, ref) => {
  return (
    <PillBackground
      className={props.className}
      hasDot={props.hasDot}
      marginRight={props.marginRight}
      onClick={(event) => {
        if (props.onClick && isFunction(props.onClick)) {
          props.onClick(event)
        }
      }}
      ref={ref}
    >
      <PillContent
        color={props.color}
        fontSize={props.fontSize}
        padding={props.padding}
        style={{
          color: props.color ? props.color : '#101828',
          background: rgba(props.color || '#101828', 0.1),
        }}
      >
        {props.hasDot && (
          <Dot style={{ backgroundColor: props.color }} />
        )} {props.children}
      </PillContent>
    </PillBackground>
  )
})

export default Pill
