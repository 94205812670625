import { difference, isArray, isEmpty, isString, isUndefined, toArray } from 'lodash-es'
import { useMemo } from 'react'

/**
 * Permission Guard Component
 *
 * Minimalistic React component which only renders the children components passed when any of the desired parameters
 * variants are successful, and returns undefined when conditions are not met.
 *
 * @param {Object}        auth        Instance of useAuth().
 * @param {string=}       permission  A single permission name.
 * @param {string[]=}     allOf       An array of permission names which should ALL be present.
 * @param {string[]=}     anyOf       An array of permission names where at least ONE should be present.
 * @param {JSX.Element[]} children    Internal ReactJS children property.
 *
 * @returns {JSX.Element[]|undefined} Returns children passed to component when conditions are met, or undefined if not.
 * @constructor
 */
export default function PermissionGuard({
  auth,
  permission,
  allOf,
  anyOf,
  children,
}) {
  return useMemo(() => {
    // Prevent further processing when permissions are not available to check against
    if (isEmpty(auth?.user?.permissions)) {
      return false
    }

    // Continue permission checks against supplied parameters
    let shouldRender = false

    if (!isUndefined(anyOf) && isArray(anyOf) && !isEmpty(anyOf)) {
      const anyOfDifference = difference(anyOf, toArray(auth?.user?.permissions))
      shouldRender = anyOfDifference.length !== anyOf.length
    } else if (isArray(allOf) && !isEmpty(allOf)) {
      const allOfDifference = difference(allOf, toArray(auth?.user?.permissions))
      shouldRender = allOfDifference.length === allOf.length
    } else if (isString(permission)) {
      shouldRender = auth.can(permission)
    }

    // Render the children elements when permission checks pass
    return shouldRender && children
  }, [
    auth,
    permission,
    allOf,
    anyOf,
  ])
}
