import * as Sentry from '@sentry/react'

import { formatKeys } from '@/Utilities/Form/Formatter'

export const getManufacturerModels = async (apiClient, manufacturerId) => {
  try {
    let url

    if (manufacturerId) {
      url = `/manufacturer-models?manufacturerId=${manufacturerId}`
    } else {
      url = '/manufacturer-models'
    }
    let { data } = await apiClient.get(url)

    if (data) {
      const manufacturers = formatKeys(data.manufacturerModels, 'camel')

      return manufacturers
    }
  } catch (error) {
    Sentry.captureException(error)
  }

  return []
}
