import '@/Utilities/Form/Yup'

import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/react'
import { filter, find, get, head, isEmpty, isFunction, isNull, map } from 'lodash-es'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { atom, useRecoilState, useSetRecoilState } from 'recoil'
import styled from 'styled-components'
import * as yup from 'yup'

import AlertContent from '@/Components/alerts/AlertContent'
import CurrentDateTime from '@/Components/common/CurrentDateAndTime'
import { Anchor, Button } from '@/Components/form/Buttons'
import GooglePlacesAutocomplete from '@/Components/form/GooglePlacesAutocomplete'
import Input from '@/Components/form/Input'
import InputError from '@/Components/form/InputError'
import LightSwitch from '@/Components/form/LightSwitch'
import Select from '@/Components/form/Select'
import Modal from '@/Components/Modal'
import { isLoadingState, pageAlertState } from '@/Config/Atoms/General'
import { formatKeys } from '@/Utilities/Form/Formatter'
import useApiClient from '@/Utilities/useApiClient'
import useEntityMonitor from '@/Utilities/useEntityMonitor'
import useTitle from '@/Utilities/useTitle'
import { transformToNumber } from '@/Utilities/Validation/Transforms/ToNumber'

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
`

const SiteForm = styled.form`
  label {
    margin-top: 20px;
  }

  .buttons {
    margin-top: 40px;
  }
`

const areaState = atom({
  key: 'areas',
  default: {},
})

const fieldUnitTypesState = atom({
  key: 'fieldUnitTypes',
  default: null,
})

const rtuState = atom({
  key: 'rtuIds',
  default: [],
})

const timeZonesState = atom({
  key: 'timeZones',
  default: [],
})

const schema = (isEditing) => {
  let validationObject = {
    isEditing: yup.boolean(),
    name: yup.string().label('Name').required().min(3).max(50),
    description: yup.string().label('Description').max(250).nullable(),
    fieldUnitTypeId: yup.mixed().label('Field unit type').populatedObject(),
    rtuId: yup.mixed().label('Site ID').populatedObject(),
    areaId: yup.mixed().label('Area').populatedObject(),
    lat: yup.number().label('Latitude').when('isEditing', {
      is: (isEditing) => {
        return !isEditing
      },
      then: (schema) => {
        return schema.nullable().required().min(-90).max(90).transform(transformToNumber)
      },
    }),

    lng: yup.number().label('Longitude').when('isEditing', {
      is: (isEditing) => {
        return !isEditing
      },
      then: (schema) => {
        return schema.nullable().required().min(-180).max(180).transform(transformToNumber)
      },
    }),

    timeZone: yup.mixed()
      .label('Time zone')
      .nullable()
      .populatedObject(),

    communicationType: yup.mixed().label('Communication type').populatedObject(),
    radioZone: yup.mixed().when('communicationType', {
      is: (object) => {
        return object?.value === 1
      },
      then: (schema) => {
        return schema.label('Radio zone').populatedObject()
      },
    }),
    radioLinkId: yup.mixed().when('communicationType', {
      is: (object) => {
        return object?.value === 1
      },
      then: (schema) => {
        return schema.label('Radio link ID').populatedObject()
      },
    }),
    lineLinkId: yup.mixed().when('communicationType', {
      is: (object) => {
        return object?.value === 0
      },
      then: (schema) => {
        return schema.label('Line link ID').populatedObject()
      },
    }),
  }

  if (isEditing) {
    delete validationObject.address
  }

  return yup.object(validationObject)
}

function SiteModal(props) {
  let isEditing = props.data?.isEditing || false

  useTitle([`${isEditing ? 'Edit' : 'Add'} site`, props.data?.site.name])

  const apiClient = useApiClient()
  const [rtuOptions, setRtuOptions] = useRecoilState(rtuState)
  const [areas, setAreas] = useRecoilState(areaState)
  const [fieldUnitTypeOptions, setFieldUnitTypeOptions] = useRecoilState(fieldUnitTypesState)
  const [timeZoneOptions, setTimeZoneOptions] = useRecoilState(timeZonesState)
  const [currentTimeZone, setCurrentTimeZone] = useState(null)
  const [isLoading, setIsLoading] = useRecoilState(isLoadingState)
  const navigate = useNavigate()
  const setAlert = useSetRecoilState(pageAlertState)
  const urlParams = useParams()

  const {
    isInRunningState,
    getRelatedProgramNames,
  } = useEntityMonitor()

  const {
    reset,
    watch,
    setValue,
    control,
    register,
    handleSubmit,
    formState,
  } = useForm({
    resolver: yupResolver(schema(isEditing)),
    defaultValues: { isEditing: isEditing },
  })

  const {
    errors,
    isSubmitted,
  } = formState

  const communicationType = watch('communicationType')
  const address = watch('address')
  const areaId = watch('areaId')
  const activeTimeZone = watch('timeZone')

  const preventSaving = isInRunningState('site', props.data?.site?.id)
  const runningProgramNames = getRelatedProgramNames('site', props.data?.site?.id)

  // Manually register the address field
  useEffect(() => {
    register('address', { value: props.data?.address })
  }, [])

  const addressChange = useCallback((field, value) => {
    setValue(field, value)
    setValue('lat', null, { shouldValidate: false })
    setValue('lng', null, { shouldValidate: false })
  }, [isSubmitted, setValue])

  const coordinatesChange = useCallback(async (field, coordinates, shouldValidate = true) => {
    setValue('lat', coordinates.lat, { shouldValidate: shouldValidate })
    setValue('lng', coordinates.lng, { shouldValidate: shouldValidate })

    if (coordinates.lat && coordinates.lng) {
      const timeZone = await getTimeZoneByLatLng(coordinates.lat, coordinates.lng)

      if (timeZone) {
        const timeZoneName = get(timeZone, 'zoneName')

        setValue('timeZone', {
          label: timeZoneName,
          value: timeZoneName,
        })
      }
    }
  }, [isSubmitted, setValue])

  const getTimeZoneByLatLng = useCallback(async (lat, lng) => {
    const timeZoneQueryResponse = await apiClient.get(
      '/timezone/query',
      {
        params: {
          type: 'position',
          lat,
          lng,
        },
      },
    )

    return get(timeZoneQueryResponse, 'data.timezone')
  }, [])

  const getTimeZoneByZone = useCallback(async (timeZoneName) => {
    const timeZoneQueryResponse = await apiClient.get(
      '/timezone/query',
      {
        params: {
          type: 'zone',
          timezone: timeZoneName,
        },
      },
    )

    return get(timeZoneQueryResponse, 'data.timezone')
  }, [])

  const communicationTypeOptions = useMemo(() => {
    return [{
      value: 0,
      label: 'Line (Digital Radio or Ethernet)',
    }, {
      value: 1,
      label: 'Radio (Analog Radio)',
    }]
  }, [])

  const radioZoneOptions = useMemo(() => {
    let radioOptions = []

    for (let index = 0; index <= 9; index++) {
      radioOptions.push({
        value: index,
        label: `${index}`,
      })
    }

    return radioOptions
  }, [])

  const radioLinkOptions = useMemo(() => {
    let radioOptions = []

    for (let index = 1; index <= 10; index++) {
      radioOptions.push({
        value: index,
        label: `${index}`,
      })
    }

    return radioOptions
  }, [])

  const lineOptions = useMemo(() => {
    let lineLinkOptions = []

    for (let index = 1; index <= 29; index++) {
      lineLinkOptions.push({
        value: index,
        label: `${index}`,
      })
    }

    return lineLinkOptions
  }, [])

  const defaultRtuId = useMemo(() => {
    if (!props.data?.site?.rtuId) {
      return ''
    }

    return {
      label: props.data.site.rtuId,
      value: props.data.site.rtuId,
    }
  }, [])

  const updateAreas = useCallback(async () => {
    try {
      const { data } = await apiClient.get('/areas')

      if (data && data.success && data.areas.length) {
        setAreas(data.areas)

        if (urlParams.id) {
          let area = filter(data.areas, ['id', urlParams.id])
          setValue('area', {
            value: area.id,
            label: area.name,
          })
        }
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }, [
    setAreas,
    setValue,
    urlParams.id,
  ])

  /**
   * Fetches and updates the list of time zones from the API.
   *
   * This asynchronous function makes a GET request to the '/timezone' endpoint
   * of the API. If the request is successful and the response contains a list
   * of time zones, the time zones state is updated with the received list.
   * Any errors encountered during the request are captured by Sentry.
   *
   * @function
   * @name updateTimeZones
   * @async
   * @returns {Promise<void>} - A promise that resolves when the operation is complete.
   */
  const updateTimeZones = useCallback(async () => {
    try {
      const endpointResponse = await apiClient.get('/timezone')
      const isSuccessfulRequest = get(endpointResponse, 'data.success')

      if (isSuccessfulRequest) {
        const timeZonesList = get(endpointResponse, 'data.timezones')
        const options = timeZonesList.map((zone) => {
          return {
            label: zone,
            value: zone,
          }
        })

        setTimeZoneOptions(options)
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }, [setTimeZoneOptions, urlParams.id])

  useEffect(() => {
    updateAreas()
    updateTimeZones()
  }, [updateAreas, updateTimeZones])

  useEffect(() => {
    (async () => {
      try {
        const { data } = await apiClient.get('/field-unit-types')

        const fieldUnitTypes = map(data.fieldUnitTypes, (fieldUnitType) => {
          return {
            value: fieldUnitType.id,
            label: fieldUnitType.name,
          }
        })

        if (fieldUnitTypes?.length === 1) {
          setValue('fieldUnitTypeId', head(fieldUnitTypes))
        }

        setFieldUnitTypeOptions(fieldUnitTypes)
      } catch (error) {
        Sentry.captureException(error)
        setAlert({
          type: 'error',
          content: 'Failed to retrieve field unit types.',
        })
      }
    })()
  }, [
    setFieldUnitTypeOptions,
    setAlert,
    setValue,
  ])

  useEffect(() => {
    (async () => {
      if (areaId?.value) {
        try {
          const { data } = await apiClient.get(`/site/available-rtu-ids?areaId=${areaId.value}`)

          if (data?.success) {
            const options = map(data.rtuIds, (rtu) => {
              return {
                label: rtu,
                value: rtu,
              }
            })

            setRtuOptions(options)
          }
        } catch (error) {
          Sentry.captureException(error)
        }
      }
    })()
  }, [areaId])

  useEffect(() => {
    if (communicationType?.value === 0) {
      setValue('radioZone', '')
      setValue('radioLinkId', '')
    }

    if (communicationType?.value === 1) {
      setValue('lineLinkId', '')
    }
  }, [communicationType, setValue])

  const areaOptions = useMemo(() => {
    return map(areas, (area) => {
      return {
        value: area.id,
        label: area.name,
        isDisabled: !area.active,
      }
    })
  }, [areas])

  useEffect(() => {
    if (props.data?.site) {
      let site = formatKeys(props.data.site, 'camel')

      let communicationType = communicationTypeOptions[0]

      if (!isNull(site.radioZone) && site.radioLinkId) {
        communicationType = communicationTypeOptions[1]
      }

      setValue('communicationType', communicationType)
    }
  }, [
    props,
    communicationTypeOptions,
    lineOptions,
    areaOptions,
    radioLinkOptions,
    radioZoneOptions,
    rtuOptions,
    reset,
    setAlert,
    setValue,
    updateAreas,
    updateTimeZones,
  ])

  useEffect(() => {
    const timeZone = activeTimeZone?.value

    if (timeZone) {
      (async () => {
        try {
          setCurrentTimeZone(null)

          const currentTimeZoneResult = await getTimeZoneByZone(timeZone)

          setCurrentTimeZone(currentTimeZoneResult)
        } catch (error) {
          Sentry.captureException(error)
        }
      })()
    }
  }, [activeTimeZone])

  const onSubmit = async (data) => {
    delete data['communicationType']

    try {
      setAlert(null)
      setIsLoading(true)

      let { data: responseData } = await apiClient.post(`${isEditing ? `/site/update/${props.data?.site?.id}` : '/site/create'}`, {
        name: data.name,
        description: data.description,
        lat: data.lat,
        lng: data.lng,
        time_zone: data.timeZone?.value,
        rtu_id: data.rtuId?.value,
        field_unit_type_id: data.fieldUnitTypeId?.value,
        line_link_id: communicationType.value === 0 ? data.lineLinkId?.value : null,
        radio_zone: communicationType.value === 1 ? data.radioZone?.value : null,
        radio_link_id: communicationType.value === 1 ? data.radioLinkId?.value : null,
        area_id: props.data?.areaId || data.areaId?.value,
        gateway_enabled: data.gatewayEnabled,
      })

      if (responseData.success) {
        setAlert({
          type: 'info',
          content: `Attempting to ${isEditing ? 'update' : 'create'} Site ${responseData.site.name}.`,
        })

        navigate(`/site/manage/${responseData.site.id}`)

        if (isFunction(props.close)) {
          props.close()
        }
      }
    } catch (error) {
      Sentry.captureException(error)
      setAlert({
        type: 'error',
        content: 'An error has occured and we were not able to save this site. Please try again.',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      title={props.data?.site?.area?.length ? `${isEditing ? 'Edit' : 'Create' } site for ${props.data?.site?.area?.label || props.data?.site?.area[0]?.label}` : `${isEditing ? 'Edit' : 'Create' } site`}
      close={props.close}
      closeOnOutsideClick={props.closeOnOutsideClick}
    >
      {preventSaving && (
        <AlertContent className="mb-4">
          You cannot edit or delete this as the following programs are running: {runningProgramNames}
        </AlertContent>
      )}

      <SiteForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        {
          !isEmpty(areaOptions) &&
          <>
            <Controller
              control={control}
              defaultValue={find(areaOptions, {
                value: props.data?.site?.areaId,
                isDisabled: false,
              }) || ''}
              name="areaId"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    isMulti={false}
                    isSearchable={true}
                    label="Area"
                    isRequired={true}
                    options={areaOptions}
                    hasError={!!errors.areaId}
                    isDisabled={isEditing}
                  />
                )
              }}
            />
            {errors.areaId && <InputError message={errors.areaId.message} />}
          </>
        }

        <Input
          name="name"
          label="Name"
          isRequired={true}
          className={errors.name && 'error'}
          {...register('name', { value: props.data?.site?.name })}
        />
        {errors.name && <InputError message={errors.name.message} />}

        <Input
          name="description"
          label="Description"
          className={errors.description && 'error'}
          {...register('description', { value: props.data?.site?.description })}
        />
        {errors.description && <InputError message={errors.description.message} />}

        {!isEditing && (
          <div className="grid grid-cols-1 gap-0 @md:grid-cols-2 @md:gap-4">
            <div className="grid grid-cols-1 @md:grid-cols-2-right-auto">
              <div>
                <GooglePlacesAutocomplete
                  label="Address"
                  name="address"
                  value={address || ''}
                  helpTooltip="Enter an address to find coordinates quickly. Only the coordinates are saved, not the address."
                  onChange={addressChange}
                  coordinatesChange={coordinatesChange}
                  className={errors.address && 'error'}
                />
                {errors.address && <InputError message={errors.address.message} />}
              </div>

              <div className="justify-self-center">
                <div className="text-md mt-3 -mb-2 flex items-center font-medium uppercase text-slate-400/80 @md:mt-12 @md:mb-0 @md:ml-4 @md:h-11">
                  or
                </div>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <Input
                  name="lat"
                  label="Latitude"
                  isRequired={true}
                  className={errors.lat && 'error'}
                  {...register('lat', { value: props.data?.site?.lat })}
                />
                {errors.lat && <InputError message={errors.lat.message} />}
              </div>

              <div>
                <Input
                  name="lng"
                  label="Longitude"
                  isRequired={true}
                  className={errors.lng && 'error'}
                  {...register('lng', { value: props.data?.site?.lng })}
                />
                {errors.lng && <InputError message={errors.lng.message} />}
              </div>
            </div>
          </div>
        )}

        {timeZoneOptions && (
          <>
            <Controller
              control={control}
              defaultValue={find(timeZoneOptions, ['value', props.data?.site?.timeZone]) || ''}
              name="timeZone"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    isMulti={false}
                    isSearchable={true}
                    label="Time zone"
                    isRequired={false}
                    options={timeZoneOptions}
                    hasError={!!errors.timeZone}
                  />
                )
              }}
            />
            {errors.timeZone && <InputError message={errors.timeZone.message} />}
          </>
        )}

        {currentTimeZone && (
          <AlertContent type="info" hideIcon={false} className="mt-6">
            The current time for
            <span className="mx-1 font-bold">{get(activeTimeZone, 'value')}</span>
            is
            <span className="ml-1 font-bold">
              <CurrentDateTime
                initialTime={get(activeTimeZone, 'formatted')}
                timezone={get(activeTimeZone, 'value')}
              />.
            </span>
            {get(currentTimeZone, 'dst') === '1' && (
              <span className="ml-1">
                Daylight savings is active between
                <span className="mx-1 font-bold">{moment.unix(get(currentTimeZone, 'zoneStart')).format('YYYY-MM-DD HH:mm:ss')}</span>
                and
                <span className="ml-1 font-bold">{moment.unix(get(currentTimeZone, 'zoneEnd')).format('YYYY-MM-DD HH:mm:ss')}</span>.
              </span>
            )}
          </AlertContent>
        )}

        {
          fieldUnitTypeOptions &&
          <>
            <Controller
              control={control}
              defaultValue={find(fieldUnitTypeOptions, ['value', props.data?.site?.fieldUnitTypeId]) || ''}
              name="fieldUnitTypeId"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    isMulti={false}
                    isSearchable={true}
                    label="Field unit type"
                    isRequired={true}
                    options={fieldUnitTypeOptions}
                    hasError={!!errors.fieldUnitTypeId}
                  />
                )
              }}
            />
            {errors.fieldUnitTypeId && <InputError message={errors.fieldUnitTypeId.message} />}
          </>
        }

        {areaId && (
          <>
            <Controller
              control={control}
              defaultValue={defaultRtuId}
              name="rtuId"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    isMulti={false}
                    isSearchable={true}
                    label="Site ID"
                    isRequired={true}
                    options={rtuOptions}
                    hasError={!!errors.rtuId}
                  />
                )
              }}
            />
            {errors.rtuId && <InputError message={errors.rtuId.message} />}
          </>
        )}

        <Controller
          control={control}
          defaultValue={''}
          name="communicationType"
          render={({ field }) => {
            return (
              <Select
                {...field}
                isMulti={false}
                isSearchable={true}
                label="Communication type"
                isRequired={true}
                options={communicationTypeOptions}
                hasError={!!errors.communicationType}
              />
            )
          }}
        />
        {errors.communicationType && <InputError message={errors.communicationType.message} />}

        {communicationType && communicationType.value === 1 && (
          <div className="row">
            <div className="col-6">
              <Controller
                control={control}
                defaultValue={find(radioZoneOptions, ['value', props.data?.site?.radioZone]) || ''}
                name="radioZone"
                render={({ field }) => {
                  return (
                    <Select
                      {...field}
                      isMulti={false}
                      isSearchable={true}
                      label="Radio zone"
                      isRequired={true}
                      options={radioZoneOptions}
                      hasError={!!errors.radioZone}
                    />
                  )
                }}
              />
              {errors.radioZone && <InputError message={errors.radioZone.message} />}
            </div>

            <div className="col-6">
              <Controller
                control={control}
                defaultValue={find(radioLinkOptions, ['value', props.data?.site?.radioLinkId]) || ''}
                name="radioLinkId"
                render={({ field }) => {
                  return (
                    <Select
                      {...field}
                      isMulti={false}
                      isSearchable={true}
                      label="Radio link ID"
                      isRequired={true}
                      options={radioLinkOptions}
                      hasError={!!errors.radioLinkId}
                    />
                  )
                }}
              />
              {errors.radioLinkId && <InputError message={errors.radioLinkId.message} />}
            </div>
          </div>
        )}

        {communicationType && communicationType.value === 0 && (
          <>
            <Controller
              control={control}
              defaultValue={find(lineOptions, ['value', props.data?.site?.lineLinkId]) || ''}
              name="lineLinkId"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    isMulti={false}
                    isSearchable={true}
                    label="Line link ID"
                    isRequired={true}
                    options={lineOptions}
                    hasError={!!errors.lineLinkId}
                  />
                )
              }}
            />
            {errors.lineLinkId && <InputError message={errors.lineLinkId.message} />}
          </>
        )}

        <LightSwitch
          label="Enable remote gateway"
          helpTooltip="LoRa capabilities are required in order to use this feature."
          wrapperClassNames="mt-6"
          onToggle={(name, value) => {
            setValue('gatewayEnabled', value)
          }}
          defaultState={get(props, 'data.site.gatewayEnabled', false) ? true : false}
          {...register('gatewayEnabled', { value: get(props, 'data.site.gatewayEnabled', false) })}
        />

        {!isEditing && (
          <AlertContent type="warning" hideIcon={false} className="mt-6 -mb-4">
            When a site is removed from the system, its configuration remains on the hardware.
            If the same hardware is later reconnected and used for a new site, the system overwrites
            the old configuration to prevent discrepancies.
          </AlertContent>
        )}

        <ButtonGroup className="buttons">
          <Anchor
            style={{ width: 'calc(50% - 5px)' }}
            className="transparent"
            onClick={() => {
              setAlert(null)
              reset()

              props.close()
            }}
          >
            Close
          </Anchor>

          <Button
            style={{ width: 'calc(50% - 5px)' }}
            disabled={isLoading ? true : false}
            disabledWithReason={preventSaving && runningProgramNames}
          >
            {isLoading ? <div className="primary-loader light"></div> : 'Save'}
          </Button>
        </ButtonGroup>
      </SiteForm>
    </Modal>
  )
}

export default SiteModal
