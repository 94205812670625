import { get, isEmpty, isNull, isObject, toLower } from 'lodash-es'

import { abbreviationMap } from '@/Utilities/Units'

export const isTimeBasis = (value) => {
  if (isObject(value)) {
    return toLower(get(value, 'irrigationBasis')) === 'time'
  }

  return toLower(value) === 'time'
}

export const isQuantityBasis = (value) => {
  if (isObject(value)) {
    return toLower(get(value, 'irrigationBasis')) === 'quantity'
  }

  return toLower(value) === 'quantity'
}

/**
 * Retrieves the unit of measurement for the mainline flow.
 *
 * @param {object} program - The program object.
 * @returns {string|null} - The unit of measurement for the mainline flow, or null if not found.
 */
export const getMainlineFlowUnitOfMeasurement = (program) => {
  if (isEmpty(program)) {
    return null
  }

  // First attempt to retrieve unit of measurement from the main line's analog flow meter
  const flowMeterScaleUnitOfMeasurement = get(program, 'mainLine.flowMeter.details.scaleUnitOfMeasurement', null)

  if (!isNull(flowMeterScaleUnitOfMeasurement)) {
    return get(abbreviationMap, flowMeterScaleUnitOfMeasurement, null)
  }

  // Expected flow is enabled
  if (get(program, 'expectedFlowDisabled', null) === '0') {
    const expectedFlowUnitOfMeasurement = get(program, 'expectedFlowUnitOfMeasurement', null)

    return get(abbreviationMap, expectedFlowUnitOfMeasurement, expectedFlowUnitOfMeasurement)
  }

  return null
}
