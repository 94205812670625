import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond/dist/filepond.min.css'

import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import Cookies from 'js-cookie'
import { forwardRef, useState } from 'react'
import { FilePond, registerPlugin } from 'react-filepond'
import styled from 'styled-components'

import InputLabel from '@/Components/form/InputLabel'

registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateSize, FilePondPluginImageExifOrientation)

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 200px;
`

const ImageUpload = forwardRef((props, ref) => {
  const [files] = useState([])

  return (
    <div>
      {props.label && (
        <InputLabel htmlFor={props.name}>{props.label}</InputLabel>
      )}

      <Wrapper>
        <FilePond
          credits={false}
          stylePanelLayout="circle"
          files={files}
          allowMultiple={false}
          maxFiles={1}
          server={{
            url: `${import.meta.env.VITE_API_URL}/api/upload/avatar`,
            headers: {
              'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN'),
              'Accept': ' application/json',
            },
            process: { withCredentials: true },
          }}
          labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
          ref={ref}
          {...props}
          maxFileSize="5MB"
        />
      </Wrapper>
    </div>
  )
})

export default ImageUpload
