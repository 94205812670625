import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import useAuth from '@/Utilities/useAuth'
import useTitle from '@/Utilities/useTitle'

function Logout() {
  const { signOut } = useAuth()
  const navigate = useNavigate()

  useTitle('Logout')

  useEffect(() => {
    signOut(() => {
      navigate('/user/login', { replace: true })
    })
  }, [])

  return (
    <div className="flex justify-center">
      <div className="primary-loader"></div>
    </div>
  )
}

export default Logout
