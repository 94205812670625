import classNames from 'classnames'
import { divIcon } from 'leaflet'
import { twMerge } from 'tailwind-merge'

import { statusBackgroundColorClass } from '@/Utilities/StatusBackgroundColorClass'

const labelIcon = (title, options = {}) => {
  let {
    iconClass = '',
    wrapperClass = '',
    overrideClass = '',
  } = options

  iconClass = classNames('flex min-h-10 items-center justify-center rounded border-2 border-slate-400 bg-slate-700 p-2 text-center font-bold leading-4 text-slate-200', iconClass)

  if (overrideClass) {
    const backgroundClass = statusBackgroundColorClass(overrideClass)
    iconClass = twMerge(iconClass, backgroundClass)
  }

  return divIcon({
    html: `<div class="${iconClass}">${title}</div>`,
    iconSize: [130, 42],
    iconAnchor: [65, 42],
    className: classNames(wrapperClass),
  })
}

export const transparentIcon = () => {
  return divIcon({
    className: 'invisible',
    iconAnchor: [0, 0],
    iconSize: [0, 0],
  })
}

export default labelIcon
