import * as Sentry from '@sentry/react'
import { filter, find, isEmpty, replace, startsWith, toNumber } from 'lodash-es'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useSetRecoilState } from 'recoil'

import InputError from '@/Components/form/InputError'
import Select from '@/Components/form/Select'
import { pageAlertState } from '@/Config/Atoms/General'
import { formatKeys } from '@/Utilities/Form/Formatter'
import { toOptions } from '@/Utilities/Form/Formatter'
import useApiClient from '@/Utilities/useApiClient'

const conditionTypes = [
  {
    value: 'condition.digitalInput',
    label: 'Digital Input',
  },
  {
    value: 'condition.digitalOutput',
    label: 'Digital Output',
  },
  {
    value: 'condition.condition',
    label: 'Condition',
  },
]

const stateOptions = [{
  value: 1,
  label: 'On',
}, {
  value: 0,
  label: 'Off',
}]

export default function Conditions(props) {
  const apiClient = useApiClient()
  const [conditions, setConditions] = useState(null)
  const [startConditionOptions, setStartConditionOptions] = useState(null)
  const [waitConditionOptions, setWaitConditionOptions] = useState(null)
  const [stopConditionOptions, setStopConditionOptions] = useState(null)
  const setAlert = useSetRecoilState(pageAlertState)

  const {
    control,
    errors,
    watch,
    setValue,
  } = useFormContext()

  let program

  if (props.data?.program) {
    program = useMemo(() => {
      return formatKeys(props.data.program, 'camel')
    }, [props.data.program])
  }

  const startConditionType = watch('startConditionType')
  const waitConditionType = watch('waitConditionType')
  const stopConditionType = watch('stopConditionType')
  const selectedSiteId = watch('siteId')

  const getConditions = useCallback(async (selectedSiteId) => {
    let query = new URLSearchParams([['pageSize', 1000], ['siteId', selectedSiteId]])

    try {
      const { data } = await apiClient.get(`/condition/query?${query}`)

      if (data.success) {
        setConditions(data.conditions.data)
      }
    } catch (error) {
      Sentry.captureException(error)
      setAlert({
        type: 'error',
        content: 'Failed to retrieve conditions.',
      })
    }
  })

  useEffect(() => {
    if (
      selectedSiteId &&
      startConditionType?.value === 'condition.condition' ||
      waitConditionType?.value === 'condition.condition' ||
      stopConditionType?.value === 'condition.condition'
    ) {
      getConditions(selectedSiteId)
    }
  }, [
    selectedSiteId,
    startConditionType,
    waitConditionType,
    stopConditionType,
  ])

  useEffect(() => {
    const currentTab = document.getElementById('conditions')

    if (currentTab) {
      props.setTabErrors((prevState) => {
        return {
          ...prevState,
          conditions: !!currentTab?.querySelector('.error-message'),
        }
      })
    }
  }, [errors, props.setTabErrors])

  useEffect(() => {
    if (startConditionType) {
      let startConditionTypes = null
      let startConditionTypeValue = replace(startConditionType?.value, 'condition.', '')

      if (props.allInputOutputs && (startConditionTypeValue === 'digitalInput' || startConditionTypeValue === 'digitalOutput')) {
        startConditionTypes = filter(props.allInputOutputs, (inputOutput) => {
          return startsWith(replace(inputOutput.detailsType, 'inputOutput.', ''), startConditionTypeValue)
        })

        startConditionTypes = toOptions(startConditionTypes, ['name', 'label'], ['id', 'value'])

        setStartConditionOptions(startConditionTypes)
      }

      if (conditions && startConditionTypeValue === 'condition') {
        startConditionTypes = toOptions(conditions, ['name', 'label'], ['id', 'value'])

        setStartConditionOptions(startConditionTypes)
      }
    }
  }, [
    startConditionType,
    props.allInputOutputs,
    conditions,
  ])

  useEffect(() => {
    if (waitConditionType) {
      let waitConditionTypes
      let waitConditionTypeValue = replace(waitConditionType.value, 'condition.', '')

      if (props.allInputOutputs && (waitConditionTypeValue === 'digitalInput' || waitConditionTypeValue === 'digitalOutput')) {
        waitConditionTypes = filter(props.allInputOutputs, (inputOutput) => {
          return startsWith(replace(inputOutput.detailsType, 'inputOutput.', ''), waitConditionTypeValue)
        })

        waitConditionTypes = toOptions(waitConditionTypes, ['name', 'label'], ['id', 'value'])

        setWaitConditionOptions(waitConditionTypes)
      }

      if (conditions && waitConditionTypeValue === 'condition') {
        waitConditionTypes = toOptions(conditions, ['name', 'label'], ['id', 'value'])

        setWaitConditionOptions(waitConditionTypes)
      }
    }
  }, [
    waitConditionType,
    props.allInputOutputs,
    conditions,
  ])

  useEffect(() => {
    if (stopConditionType) {
      let stopConditionTypes
      let stopConditionTypeValue = replace(stopConditionType.value, 'condition.', '')

      if (props.allInputOutputs && (stopConditionTypeValue === 'digitalInput' || stopConditionTypeValue === 'digitalOutput')) {
        stopConditionTypes = filter(props.allInputOutputs, (inputOutput) => {
          return startsWith(replace(inputOutput.detailsType, 'inputOutput.', ''), stopConditionTypeValue)
        })

        stopConditionTypes = toOptions(stopConditionTypes, ['name', 'label'], ['id', 'value'])

        setStopConditionOptions(stopConditionTypes)
      }

      if (conditions && stopConditionTypeValue === 'condition') {
        stopConditionTypes = toOptions(conditions, ['name', 'label'], ['id', 'value'])

        setStopConditionOptions(stopConditionTypes)
      }
    }
  }, [
    stopConditionType,
    props.allInputOutputs,
    conditions,
  ])

  const NoOptions = () => {
    return (
      <div className="mt-4 rounded border py-2 px-3">
        No options for the selected type.
      </div>
    )
  }

  return (
    <div className="mt-5 grid grid-cols-1 gap-3 md:grid-cols-3">
      <div className="rounded border p-4">
        <h3>
          Start Condition
        </h3>

        {!isEmpty(conditionTypes) && (
          <>
            <Controller
              control={control}
              defaultValue={find(conditionTypes, ['value', program?.startConditionType])}
              name="startConditionType"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    isMulti={false}
                    isSearchable={true}
                    label="Type"
                    options={conditionTypes}
                    placeholder="Search"
                    hasError={!!errors.startConditionType}
                    isClearable={true}
                    afterChange={(option) => {
                      if (!option) {
                        setValue('startConditionType', null, { shouldValidate: true })
                        setStartConditionOptions(null)
                      }

                      setValue('startConditionId', null)
                      setValue('startConditionState', null)
                    }}
                  />
                )
              }}
            />
            {errors.startConditionType && <InputError message={errors.startConditionType.message} />}
          </>
        )}

        {!isEmpty(startConditionType) && !isEmpty(startConditionOptions) ? (
          <>
            <Controller
              control={control}
              defaultValue={find(startConditionOptions, ['value', toNumber(program?.startConditionId)])}
              name="startConditionId"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    isMulti={false}
                    isSearchable={true}
                    label="Name"
                    isRequired={true}
                    options={startConditionOptions}
                    placeholder="Search"
                    hasError={!!errors.startConditionId}
                  />
                )
              }}
            />
            {errors.startConditionId && <InputError message={errors.startConditionId.message} />}
          </>
        ) : ''}

        {!isEmpty(startConditionType) && !isEmpty(stateOptions) && !isEmpty(startConditionOptions) ? (
          <>
            <Controller
              control={control}
              defaultValue={find(stateOptions, ['value', toNumber(program?.startConditionState)])}
              name="startConditionState"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    isMulti={false}
                    isSearchable={false}
                    label="State"
                    isRequired={true}
                    options={stateOptions}
                    placeholder="Search"
                    hasError={!!errors.startConditionState}
                  />
                )
              }}
            />
            {errors.startConditionState && <InputError message={errors.startConditionState.message} />}
          </>
        ) : null}

        {!isEmpty(startConditionType) && isEmpty(startConditionOptions) && <NoOptions />}
      </div>

      <div className="rounded border p-4">
        <h3>
          Wait Condition
        </h3>

        {!isEmpty(conditionTypes) && (
          <>
            <Controller
              control={control}
              defaultValue={find(conditionTypes, ['value', program?.waitConditionType])}
              name="waitConditionType"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    isMulti={false}
                    isSearchable={true}
                    label="Type"
                    options={conditionTypes}
                    placeholder="Search"
                    isClearable={true}
                    hasError={!!errors.waitConditionType}
                    afterChange={(option) => {
                      if (!option) {
                        setValue('waitConditionType', null, { shouldValidate: true })
                        setWaitConditionOptions(null)
                      }

                      setValue('waitConditionId', null)
                      setValue('waitConditionState', null)
                    }}
                  />
                )
              }}
            />
            {errors.waitConditionType && <InputError message={errors.waitConditionType.message} />}
          </>
        )}

        {!isEmpty(waitConditionType) && !isEmpty(waitConditionOptions) ? (
          <>
            <Controller
              control={control}
              defaultValue={find(waitConditionOptions, ['value', toNumber(program?.waitConditionId)])}
              name="waitConditionId"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    isMulti={false}
                    isSearchable={true}
                    label="Name"
                    isRequired={true}
                    options={waitConditionOptions}
                    placeholder="Search"
                    hasError={!!errors.waitConditionId}
                  />
                )
              }}
            />
            {errors.waitConditionId && <InputError message={errors.waitConditionId.message} />}
          </>
        ) : null}

        {!isEmpty(waitConditionType) && !isEmpty(stateOptions) && !isEmpty(waitConditionOptions) ? (
          <>
            <Controller
              control={control}
              defaultValue={find(stateOptions, ['value', toNumber(program?.waitConditionState)])}
              name="waitConditionState"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    isMulti={false}
                    isSearchable={false}
                    label="State"
                    isRequired={true}
                    options={stateOptions}
                    placeholder="Search"
                    hasError={!!errors.waitConditionState}
                  />
                )
              }}
            />
            {errors.waitConditionState && <InputError message={errors.waitConditionState.message} />}
          </>
        ) : null}

        {!isEmpty(waitConditionType) && isEmpty(waitConditionOptions) && <NoOptions />}
      </div>

      <div className="rounded border p-4">
        <h3>
          Stop Condition
        </h3>

        {!isEmpty(conditionTypes) && (
          <>
            <Controller
              control={control}
              defaultValue={find(conditionTypes, ['value', program?.stopConditionType])}
              name="stopConditionType"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    isMulti={false}
                    isSearchable={true}
                    label="Type"
                    options={conditionTypes}
                    placeholder="Search"
                    isClearable={true}
                    hasError={!!errors.stopConditionType}
                    afterChange={(option) => {
                      if (!option) {
                        setValue('stopConditionType', null, { shouldValidate: true })
                        setStopConditionOptions(null)
                      }

                      setValue('stopConditionId', null)
                      setValue('stopConditionState', null)
                    }}
                  />
                )
              }}
            />
            {errors.stopConditionType && <InputError message={errors.stopConditionType.message} />}
          </>
        )}

        {!isEmpty(stopConditionType) && !isEmpty(stopConditionOptions) ? (
          <>
            <Controller
              control={control}
              defaultValue={find(stopConditionOptions, ['value', toNumber(program?.stopConditionId)])}
              name="stopConditionId"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    isMulti={false}
                    isSearchable={true}
                    label="Name"
                    isRequired={true}
                    options={stopConditionOptions}
                    placeholder="Search"
                    hasError={!!errors.stopConditionId}
                  />
                )
              }}
            />
            {errors.stopConditionId && <InputError message={errors.stopConditionId.message} />}
          </>
        ) : null}

        {!isEmpty(stopConditionType) && !isEmpty(stateOptions) && !isEmpty(stopConditionOptions) ? (
          <>
            <Controller
              control={control}
              defaultValue={find(stateOptions, ['value', toNumber(program?.stopConditionState)])}
              name="stopConditionState"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    isMulti={false}
                    isSearchable={false}
                    label="State"
                    isRequired={true}
                    options={stateOptions}
                    placeholder="Search"
                    hasError={!!errors.stopConditionState}
                  />
                )
              }}
            />
            {errors.stopConditionState && <InputError message={errors.stopConditionState.message} />}
          </>
        ) : null}

        {!isEmpty(stopConditionType) && isEmpty(stopConditionOptions) && <NoOptions />}
      </div>
    </div>
  )
}
