import '@/Utilities/Form/Yup'
import 'air-datepicker/air-datepicker.css'

import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/react'
import { forEach, isFunction, isUndefined } from 'lodash-es'
import moment from 'moment'
import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import styled from 'styled-components'
import * as yup from 'yup'

import { Anchor, Button } from '@/Components/form/Buttons'
import DateTimePicker from '@/Components/form/DateTimePicker'
import InputError from '@/Components/form/InputError'
import Modal from '@/Components/Modal'
import { isLoadingState, pageAlertState } from '@/Config/Atoms/General'

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
`

const schema = () => {
  let validationObject = {
    startDate: yup.string()
      .nullable()
      .test(
        'is-valid-date-or-empty',
        'Date and time must be in the format MM-DD-YYYY HH:mm or be empty',
        (value) => {
          return !value || moment(value, 'MM-DD-YYYY HH:mm', true).isValid()
        },
      ),
    endDate: yup.string()
      .nullable()
      .test(
        'is-valid-date-or-empty',
        'Date and time must be in the format MM-DD-YYYY HH:mm or be empty',
        (value) => {
          return !value || moment(value, 'MM-DD-YYYY HH:mm', true).isValid()
        },
      ),
  }

  return yup.object(validationObject)
}

function ReportingFilterModal(props) {
  const [isLoading, setIsLoading] = useRecoilState(isLoadingState)
  const setAlert = useSetRecoilState(pageAlertState)
  const navigate = useNavigate()
  const location = useLocation()

  const initialValues = (() => {
    const query = new URLSearchParams(location.search)

    return {
      startDateDefault: query.get('startDate') || moment().subtract(7, 'day').format('MM-DD-YYYY 00:00'),
      endDateDefault: query.get('endDate') || moment().format('MM-DD-YYYY 23:59'),
    }
  })()

  const {
    reset,
    handleSubmit,
    formState,
    watch,
    register,
    setValue,
  } = useForm({ resolver: yupResolver(schema()) })

  const { errors } = formState

  const startDate = watch('startDate')
  const endDate = watch('endDate')

  const addOneYear = (date) => {
    const result = new Date(date)
    result.setFullYear(result.getFullYear() + 1)
    return result
  }

  const subtractOneYear = (date) => {
    if (!date) return null
    const result = new Date(date)
    result.setFullYear(result.getFullYear() - 1)
    return result
  }

  const isValidDate = (date) => {
    return date && !isNaN(new Date(date).getTime())
  }

  const [validStartDate, validEndDate] = useMemo(() => {
    return [!isUndefined(startDate) && isValidDate(startDate), !isUndefined(endDate) && isValidDate(endDate)]
  }, [startDate, endDate])

  const maxDateForStartDate = validEndDate ? new Date(endDate) : addOneYear(new Date())
  const minDateForStartDate = validEndDate ? subtractOneYear(new Date(endDate)) : ''
  const minDateForEndDate = validStartDate ? new Date(startDate) : ''
  const maxDateForEndDate = validStartDate ? addOneYear(new Date(startDate)) : ''

  const onSubmit = useCallback(async (data) => {
    try {
      setAlert(null)
      setIsLoading(true)

      const currentSearchParams = new URLSearchParams()
      forEach(data, (value, key) => {
        currentSearchParams.append(key, value)
      })

      const searchParams = currentSearchParams.toString()

      navigate({
        pathname: location.pathname,
        search: `?${searchParams}`,
      })

      if (isFunction(props.close)) {
        props.close()
      }
    } catch (error) {
      Sentry.captureException(error)
      setAlert({
        type: 'error',
        content: 'An error has occured. Please try again.',
      })
    } finally {
      setIsLoading(false)
    }
  }, [navigate])

  return (
    <Modal
      title={'Chart filtering'}
      close={props.close}
      closeOnOutsideClick={props.closeOnOutsideClick}
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="mt-5 grid grid-cols-2 gap-4">
          <div>
            <DateTimePicker
              {...register('startDate', { value: initialValues.startDateDefault })}
              label="Start date"
              name="startDate"
              onChange={(value) => {
                setValue('startDate', value)
              }}
              options={{
                dateFormat: 'MM-dd-yyyy',
                timepicker: true,
                timeFormat: 'HH:mm',
                autoClose: true,
                selectedDates: [initialValues.startDateDefault],
              }}
              className={errors.startDate && 'error'}
              maxDate={maxDateForStartDate}
              minDate={minDateForStartDate}
            />
            {errors.startDate && <InputError message={errors.startDate.message} />}
          </div>

          <div>
            <DateTimePicker
              {...register('endDate', { value: initialValues.endDateDefault })}
              label="End date"
              name="endDate"
              onChange={(value) => {
                setValue('endDate', value)
              }}
              options={{
                dateFormat: 'MM-dd-yyyy',
                timepicker: true,
                timeFormat: 'HH:mm',
                autoClose: true,
                selectedDates: [initialValues.endDateDefault],
              }}
              className={errors.endDate && 'error'}
              maxDate={maxDateForEndDate}
              minDate={minDateForEndDate}
            />
            {errors.endDate && <InputError message={errors.endDate.message} />}
          </div>
        </div>

        <ButtonGroup className="buttons">
          <Anchor
            style={{ width: 'calc(50% - 5px)' }}
            className="transparent"
            onClick={() => {
              setAlert(null)
              reset()

              props.close()
            }}
          >
            Close
          </Anchor>

          <Button
            style={{ width: 'calc(50% - 5px)' }}
            disabled={isLoading ? true : false}
          >
            {isLoading ? <div className="primary-loader light"></div> : 'Apply'}
          </Button>
        </ButtonGroup>
      </form>
    </Modal>
  )
}

export default ReportingFilterModal
