import { concat, each, filter, forOwn, get, includes, join, map, set, uniq } from 'lodash-es'
import { atom, selector, selectorFamily } from 'recoil'

/**
 * Represents the state of tracked entities.
 *
 * @typedef {array} TrackedEntitiesState
 */
export const trackedEntitiesState = atom({
  key: 'TrackedEntitiesState',
  default: [],
})

/**
 * Atom representing the stale state of tracked entities.
 *
 * @type {Atom<boolean>}
 */
export const trackedEntitiesLastUpdatedState = atom({
  key: 'TrackedEntitiesLastUpdatedState',
  default: null,
})

/**
 * Represents the running state of tracked entities.
 *
 * @typedef {Object} RunningTrackedEntitiesState
 */
export const runningTrackedEntitiesState = selector({
  key: 'TrackedEntitiesState/Running',
  get: ({ get: getRecoilValue }) => {
    // Getting the trackedEntitiesState.
    const raw = getRecoilValue(trackedEntitiesState)
    // Initializing the response object structure.
    const response = {
      program: [],
      programSet: [],
      area: [],
      site: [],
      mainLine: [],
      inputOutput: [],
      condition: [],
      fertigationPump: [],
    }

    // Iterating over each item in the raw data.
    each(raw, (item, itemIndex) => {
      forOwn(response, (value, key) => {
        // Getting the list of impacted items for the current key.
        const list = get(raw, [
          itemIndex,
          'impacted',
          key,
        ], [])

        // Merging the list with the existing value, making sure there are no duplicates.
        set(response, key, uniq(concat(list, value)))
      })
    })

    return response
  },
})

/**
 * Returns a function that accepts a "get" function and retrieves the running program names for a given entity.
 */
export const runningProgramNamesForEntity = selectorFamily({
  key: 'TrackedEntitiesState/RunningProgramNames',
  get: ({
    model,
    entityId,
  }) => {
    return ({ get: getRecoilValue }) => {
      // Acquire the current tracked entities state array (containing programs)
      const programs = getRecoilValue(trackedEntitiesState)

      // Filter out unrelated programs by checking against the model and id
      const filteredPrograms = filter(programs, (program) => {
        return includes(get(program, ['impacted', model], []), entityId)
      })

      // Acquire the program name for each array item
      const programNames = map(filteredPrograms, (program) => {
        return program.name
      })

      // Join the program names and return as a comma separated string
      return join(programNames, ', ')
    }
  },
})
