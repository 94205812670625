import * as Sentry from '@sentry/react'
import { latLngBounds, marker } from 'leaflet'
import { filter, first, flatMapDeep, flatten, forEach, groupBy, head, isEmpty, isNull, map, orderBy, trimStart, uniqBy } from 'lodash-es'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import SelectField from '@/Components/form/Select'
import Map from '@/Components/Map'
import { standardActions } from '@/Utilities/Events'
import labelIcon from '@/Utilities/Map/Icons/LabelIcon'
import useApiClient from '@/Utilities/useApiClient'
import useEventSubscriber from '@/Utilities/useEventSubscriber'
import useTitle from '@/Utilities/useTitle'

const DashboardMap = styled.div`
  display: block;
  width: 100%;
  height: ${(props) => {
    return props.fullHeight ? 'calc(100vh - var(--top-bar-height))' : '440px'
  }};
  margin-bottom: ${(props) => {
    return props.fullHeight ? '-20px' : '20px'
  }};
  margin-top: -20px;
  position: relative;
`

const SelectContainer = styled.div`
  border-radius: 4px;
  border: 2px solid rgba(0,0,0,0.2);
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1000;
`

const ShowSelect = styled.div`
  align-items: center;
  background: #ffffff;
  border-radius: 4px;
  color: #5F5F5F;
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  width: 48px;

  &:hover {
    color: #333333;
  }
`

function MapAll() {
  const apiClient = useApiClient()
  const [mapLoaded, setMapLoaded] = useState(false)
  const [showSelect, setShowSelect] = useState(false)
  const [sites, setSites] = useState()
  const mapInstance = useRef(null)
  const hasFitBounds = useRef(false)
  const navigate = useNavigate()
  useEventSubscriber(['program', 'programSet'], standardActions, () => {
    getSites()
  })

  useTitle('Map')

  const getSites = useCallback(async () => {
    try {
      const query = new URLSearchParams([
        ['pageSize', '1000'],
        ['with[]', 'area'],
        ['with[]', 'programSets.programs.operationalStatuses'],
        ['with[]', 'programSets.programs.alarms'],
      ])

      let { data } = await apiClient.get(`/site/query?pageSize=1000&${query}`)

      if (data && data.success) {
        const siteData = data.sites.data

        setSites(siteData)
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  })

  const mapInit = useCallback((map) => {
    mapInstance.current = map
    setMapLoaded(true)
  }, [])

  useEffect(() => {
    getSites()
  }, [])

  useEffect(() => {
    addSiteMarkers()
  }, [sites, mapLoaded])

  const addSiteMarkers = useCallback(() => {
    if (!isEmpty(sites) && mapLoaded) {
      forEach(sites, (site) => {
        if (site.lat && site.lng) {
          // Check if a site has any programs with alarms
          const allAlarms = flatMapDeep(site.program_sets, (program_set) => {
            return flatMapDeep(program_set.programs, (program) => {
              return program.alarms
            })
          })

          const currentAlarms = filter(allAlarms, (alarm) => {
            return isNull(alarm.date_resolved)
          })

          // Set default status to alarms or nothing
          let status = !isEmpty(currentAlarms) && 'alarm'

          // If no alarms, find an appropriate status
          if (!status) {
            let allStatuses = flatten(map(site.program_sets, (programSet) => {
              return programSet.operational_statuses
            }))

            allStatuses = uniqBy(allStatuses, 'id')
            allStatuses = orderBy(allStatuses, ['sort_order'], ['asc'])

            if (!isEmpty(allStatuses)) {
              status = first(allStatuses).key
            }
          }

          let mapMarker = marker([site.lat, site.lng], {
            id: `siteId#${site.id}`,
            icon: labelIcon(site.name, { overrideClass: status }),
            draggable: false,
          })

          mapMarker.on('click', (event) => {
            const siteId = trimStart(event.target.options.id, 'siteId#')
            navigate(`/site/overview/${siteId}`)
          })

          mapMarker.addTo(mapInstance.current.groups.marker)
        }
      })

      if (!hasFitBounds.current) {
        const bounds = latLngBounds()

        if (mapInstance.current.groups.marker) {
          bounds.extend(mapInstance.current.groups.marker.getBounds())
        }

        if (bounds.isValid()) {
          mapInstance.current.map.fitBounds(bounds, { maxZoom: 18 })
          hasFitBounds.current = true
        }
      }
    }
  }, [sites, mapLoaded])

  const siteOptions = useMemo(() => {
    const groupedByArea = groupBy(sites, (site) => {
      return site.area.id
    })

    return map(groupedByArea, (sitesByArea) => {
      const siteOptions = map(sitesByArea, (site) => {
        return {
          label: site.name,
          value: site.id,
        }
      })

      return {
        label: head(sitesByArea).area.name,
        options: siteOptions,
      }
    })
  }, [sites])

  if (isEmpty(sites)) {
    return <></>
  }

  return (
    <>
      <DashboardMap fullHeight={true}>
        {siteOptions && (
          <SelectContainer>
            {
              showSelect ? (
                <SelectField
                  autoFocus={true}
                  menuIsOpen={true}
                  isSearchable={true}
                  display="inline-block"
                  width="300px"
                  placeholder="Search"
                  options={siteOptions}
                  onChange={(option) => {
                    setShowSelect(false)
                    navigate(`/site/overview/${option.value}`)
                  }}
                  afterMenuClose={() => {
                    setShowSelect(false)
                  }}
                />
              ) : (
                <ShowSelect
                  onClick={() => {
                    setShowSelect(true)
                  }}
                >
                  <i className="fa-solid fa-magnifying-glass"></i>
                </ShowSelect>
              )
            }
          </SelectContainer>
        )}

        <Map
          onInit={mapInit}
          square
          key="main"
          id="main"
        />
      </DashboardMap>
    </>
  )
}

export default MapAll
