import { get, isUndefined } from 'lodash-es'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

export default function useProgramTimer(props) {
  const {
    identifyingKey,
    identifyingId,
    displayed,
    setSecondsRemaining,
    status = '',
  } = props

  const [serviceWorkerReady, setServiceWorkerReady] = useState(false)
  const isRunning = useRef({})

  /**
   * Generate the timer ID
   */
  const timerId = useMemo(() => {
    return `${identifyingKey}-${identifyingId}`
  }, [identifyingKey, identifyingId])

  /**
   * Clear the timer
   */
  const pauseTimer = useCallback(() => {
    if (!serviceWorkerReady) {
      return
    }

    navigator.serviceWorker.controller.postMessage({
      command: 'pause',
      id: timerId,
    })
  }, [timerId, serviceWorkerReady])

  /**
   * Calculate the remaining runtime
   */
  const calculateRuntime = useCallback((displayed) => {
    const timing = displayed.timing

    if (!timing) {
      return false
    }

    const elapsedTimeSinceLastUpdate = timing.elapsedSeconds
    const remainingRuntime = timing.totalSeconds - elapsedTimeSinceLastUpdate

    return remainingRuntime
  }, [])

  /**
   * Set the service worker ready state
   */
  useEffect(() => {
    navigator.serviceWorker.ready.then(() => {
      setServiceWorkerReady(true)
    })

    return () => {
      setServiceWorkerReady(false)
    }
  }, [])

  /**
   * Handle messages from the service worker
   */
  useEffect(() => {
    if (!serviceWorkerReady) {
      return
    }

    const handleMessage = (event) => {
      const {
        id,
        time,
      } = event.data

      if (id === timerId && !isUndefined(time)) {
        setSecondsRemaining(time)
      }
    }

    navigator.serviceWorker.addEventListener('message', handleMessage)

    return () => {
      navigator.serviceWorker.removeEventListener('message', handleMessage)
    }
  }, [
    serviceWorkerReady,
    setSecondsRemaining,
    timerId,
  ])

  /**
   * Handle the timer
   */
  useEffect(() => {
    if (!serviceWorkerReady) {
      return
    }

    // Calculate the remaining runtime
    let remainingRuntime = calculateRuntime(displayed)

    if (status === 'queued') {
      remainingRuntime = moment.duration(displayed.runtime).asSeconds()
    }

    if (status !== 'running') {
      pauseTimer()

      isRunning.current[timerId] = false
    }

    // Set the time in the service worker
    if (isRunning.current[timerId] !== true) {
      navigator.serviceWorker.controller.postMessage({
        command: 'setTime',
        id: timerId,
        time: remainingRuntime,
      })

      // Start the timer if it should tick and hasn't already started
      if (status === 'running' && get(displayed, 'timing.shouldTick', false)) {
        navigator.serviceWorker.controller.postMessage({
          command: 'start',
          id: timerId,
        })

        isRunning.current[timerId] = true
      }
    }

    // Request an update for the time from the service worker
    navigator.serviceWorker.controller.postMessage({
      command: 'getTime',
      id: timerId,
    })
  }, [
    serviceWorkerReady,
    status,
    displayed,
    setSecondsRemaining,
    timerId,
  ])
}
