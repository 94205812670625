import { debounce, each,
  flatMap,
  includes,
  isEmpty,
  isFunction,
  map,
  reject,
  some,
  startCase,
  upperFirst } from 'lodash-es'
import moment from 'moment'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSetRecoilState } from 'recoil'

import { pageAlertState } from '@/Config/Atoms/General'
import { standardActions, subscribe, unsubscribe } from '@/Utilities/Events'

const friendlyModelNames = {
  inputOutput: 'I/O',
  mainLine: 'Main Line',
  programSet: 'Program Set',
}

let activeAlertSubscriptions = []

const alertMessageCache = {}

/**
 * `useEventSubscriber` is a custom hook that handles model event subscriptions.
 *
 * @param {string[]} subscribeModels - The models to which we want to subscribe.
 * @param {string[]} subscribeActions - The actions for which we want to listen.
 * @param {function} callback - A callback function to be executed when an event is received.
 *
 * Note: Currently, `subscribeModels` and `subscribeActions` are expected to be hardcoded.
 * The useEffect within this hook uses an empty dependency array to prevent unnecessary re-renders.
 * If the nature of `subscribeModels` or `subscribeActions` changes in the future (i.e., if they
 * become dynamic), the dependency array of the useEffect should be re-evaluated.
 */
export default function useEventSubscriber(subscribeModels, subscribeActions, callback) {
  const setAlert = useSetRecoilState(pageAlertState)
  const [subscriberModelMap, setSubscriberModelMap] = useState({})
  const staticSubscriberModelMap = useRef(subscriberModelMap)

  useEffect(() => {
    staticSubscriberModelMap.current = subscriberModelMap
  }, [subscriberModelMap])

  const getModelName = useCallback((model) => {
    let modelName = friendlyModelNames[model]

    if (!modelName) {
      modelName = upperFirst(startCase(model))
    }

    return modelName
  }, [])

  /**
   * This function will only display an alert if there wasn't a similar (having the same type, duration and message)
   * one shown within the last 'duration' seconds. The alertMessageCache is used to track alerts based on a unique
   * id generated from the type, duration, and content. If the cache does not have an entry corresponding to the
   * unique id, or if the previous alert was displayed more than 'duration' seconds ago, a new alert is set up.
   */
  const displayAlert = useCallback((type, duration, message) => {
    const uniqueId = `${type}:${duration}:${message}`
    const currentTime = moment()

    if (!alertMessageCache[uniqueId] || currentTime.diff(alertMessageCache[uniqueId], 'seconds') > duration) {
      setAlert({
        type,
        duration,
        content: message,
      })

      alertMessageCache[uniqueId] = currentTime
    }
  }, [alertMessageCache])

  const showAlert = useCallback((detail) => {
    const {
      name,
      action,
      model,
      success = true,
      fepMessage = '',
    } = detail

    if (detail.noAlert) {
      return true
    }

    let actionIsPresent = some(standardActions, (standardAction) => {
      return includes(standardAction, action)
    })

    if (actionIsPresent) {
      const modelName = getModelName(model)

      let alertType = 'success'
      let alertMessage = `${modelName} "${name}" has been successfully ${action}d.`
      let alertDuration = 5

      // If unsuccessful
      if (success !== true) {
        alertType = 'error'
        alertMessage = `${modelName} "${name}" has not been ${action}d.`
        alertDuration = 10

        if (fepMessage) {
          alertMessage = `${alertMessage} Message: ${fepMessage}`
        }
      }

      displayAlert(alertType, alertDuration, alertMessage)
    }
  }, [subscriberModelMap])

  // Handle subscription
  const handleUpdate = useCallback(({ detail }) => {
    const {
      id,
      model,
    } = detail

    const applicableIds = staticSubscriberModelMap.current?.[model] || []

    // If model IDs have been provided, make sure this event is one of them
    if (!isEmpty(applicableIds) && !includes(applicableIds, id)) {
      return false
    }

    showAlert(detail)

    if (isFunction(callback)) {
      debounce(callback, 500, { maxWait: 2000 })(detail)
    }
  }, [])

  // Remove subscriptions
  const removeSubscriptions = useCallback(() => {
    let updatedActiveAlertSubscriptions = activeAlertSubscriptions

    each(updatedActiveAlertSubscriptions, (eventListener) => {
      updatedActiveAlertSubscriptions = reject(updatedActiveAlertSubscriptions, (subscription) => {
        return subscription == eventListener
      })

      unsubscribe(eventListener, handleUpdate)
    })

    activeAlertSubscriptions = updatedActiveAlertSubscriptions
  }, [])

  // Create subscriptions
  const addSubscriptions = useCallback((subscribeModels, subscribeActions) => {
    const eventListeners = flatMap(subscribeModels, (subscribeModel) => {
      return map(subscribeActions, (subscribeAction) => {
        return `${subscribeModel}.${subscribeAction}`
      })
    })

    let updatedActiveAlertSubscriptions = activeAlertSubscriptions

    each(eventListeners, (eventListener) => {
      updatedActiveAlertSubscriptions = [...updatedActiveAlertSubscriptions, eventListener]

      subscribe(eventListener, handleUpdate)
    })

    activeAlertSubscriptions = [...activeAlertSubscriptions, ...updatedActiveAlertSubscriptions]
  }, [])

  useEffect(() => {
    if (isEmpty(subscribeModels) || isEmpty(subscribeActions)) {
      return
    }

    addSubscriptions(subscribeModels, subscribeActions)

    return () => {
      removeSubscriptions()
    }
  }, [subscriberModelMap])

  return { setSubscriberModelMap }
}
