import Tippy from '@tippyjs/react'
import classNames from 'classnames'
import { ceil,
  debounce,
  filter,
  find,
  forEach,
  get,
  includes,
  isEmpty,
  join,
  map,
  reject,
  replace,
  slice,
  some,
  split,
  startCase,
  toLower,
  toNumber,
  truncate } from 'lodash-es'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import Table from '@/Components/Table'
import { formatKeys } from '@/Utilities/Form/Formatter'
import useApiClient from '@/Utilities/useApiClient'

const tableColumns = [{
  Header: 'Name',
  accessor: 'name',
  width: '70%',
}, {
  Header: () => {
    return (
      <div className="text-center">
        Actions
      </div>
    )
  },
  accessor: 'actions',
  width: '30%',
}]

function InputOutputs(props) {
  const [availablePageInfo, setAvailablePageInfo] = useState({})
  const [availableTableData, setAvailableTableData] = useState(null)
  const [selectedInputOutputs, setSelectedInputOutputs] = useState([])
  const [selectedPageInfo, setSelectedPageInfo] = useState({})
  const [selectedTableData, setSelectedTableData] = useState(null)
  const apiClient = useApiClient()

  const firstLoad = useRef(true)

  let mainLine

  if (props.data?.mainLine) {
    mainLine = useMemo(() => {
      return formatKeys(props.data.mainLine, 'camel')
    }, [props.data.mainLine])
  }

  const {
    errors,
    setValue,
    register,
    watch,
  } = useFormContext()

  register('inputOutputIds')
  const selectedSiteId = watch('siteId')
  const mainValveId = watch('mainValveId')

  const { isEditing } = props

  const getAvailableTableData = useMemo(() => {
    return debounce(async ({
      pageIndex,
      pageSize,
      filters,
    }) => {
      let pageSearch = filters.search.value
      pageIndex = toNumber(pageIndex)
      pageSize = toNumber(pageSize)

      if (availablePageInfo.index != pageIndex || availablePageInfo.size != pageSize || availablePageInfo.search != pageSearch) {
        setAvailablePageInfo({
          index: pageIndex,
          size: pageSize,
          search: pageSearch,
        })
      }
    }, 0)
  }, [availablePageInfo])

  const getSelectedTableData = useMemo(() => {
    return debounce(async ({
      pageIndex,
      pageSize,
      filters,
    }) => {
      let pageSearch = filters.search.value
      pageIndex = toNumber(pageIndex)
      pageSize = toNumber(pageSize)

      if (selectedPageInfo.index != pageIndex || selectedPageInfo.size != pageSize || selectedPageInfo.search != pageSearch) {
        setSelectedPageInfo({
          index: pageIndex,
          size: pageSize,
          search: pageSearch,
        })
      }
    }, 0)
  }, [selectedPageInfo])

  const getAssociations = useCallback(debounce(async (ids) => {
    let searchParams = [['with[]', 'programs']]

    forEach(ids, (id) => {
      searchParams = [...searchParams, ['ids[]', id]]
    })

    const query = new URLSearchParams(searchParams)

    const response = await apiClient.get(`/association/input-output/?${query}`)

    return get(response, 'data')
  }, 50), [])

  const createRowData = (inputOutput, options) => {
    return {
      name: (
        <>
          <div>
            {inputOutput.name}
          </div>

          {inputOutput.description ? (
            <Tippy content={inputOutput.description} delay={200} theme="light" disabled={inputOutput.description.length < 35} placement="right">
              <div className="inline-block text-xs text-slate-400">
                {truncate(inputOutput.description, { length: 35 })}
              </div>
            </Tippy>
          ) : null}

          <div className="mt-0.5 text-xs text-slate-400">
            {startCase(replace(inputOutput.detailsType, 'inputOutput.', ''))}
          </div>
        </>
      ),
      actions: (
        <div className="grid grid-cols-1">
          <div className="flex items-center justify-center">
            <Tippy content={`Unable to remove from main line because it is in use by the following programs: ${join(options.programNames, ', ')}`} delay={200} theme="light" disabled={!options.hasPrograms}>
              <a
                className={classNames({
                  'bg-primary': !options.hasPrograms,
                  'bg-slate-500': options.hasPrograms,
                }, 'flex h-8 w-8 cursor-pointer items-center justify-center rounded-full text-xl text-white')}
                onClick={() => {
                  if (!options.hasPrograms) {
                    options.onClick()
                  }
                }}
              >
                {options.symbol}
              </a>
            </Tippy>
          </div>
        </div>
      ),
    }
  }

  useEffect(() => {
    if (!isEmpty(props.siteInputOutputs)) {
      const siteInputOutputs = filter(props.siteInputOutputs, (inputOutput) => {
        const isNotSelectedMainValve = inputOutput.id != mainValveId?.value
        const isNotMainValve = isEditing || isEmpty(inputOutput.mainLineMainValve)
        const isValidMainValve = isNotSelectedMainValve && isNotMainValve
        const belongsToMainLine = !isEmpty(find(inputOutput.mainLines, ['id', mainLine?.id]))

        return includes(toLower(replace(inputOutput.detailsType, 'inputOutput.', '')), 'output') && !inputOutput.auxiliary && isValidMainValve && (isEmpty(inputOutput.mainLines) || belongsToMainLine)
      })

      const selected = { data: selectedInputOutputs }

      // Determine Selected I/O
      if (firstLoad.current && mainLine?.id) {
        setSelectedInputOutputs(mainLine.inputOutputs)
      }

      firstLoad.current = false

      // Available I/O
      const available = {}

      available.data = reject(siteInputOutputs, (inputOutput) => {
        return !!find(selected.data, ['id', inputOutput.id])
      })

      if (availablePageInfo.search) {
        let searchTerms = split(availablePageInfo.search, ' ')
        available.data = filter(available.data, (inputOutput) => {
          return some(searchTerms, (term) => {
            return includes(toLower(inputOutput.name), toLower(term))
          })
        })
      }

      available.data = map(available.data, (inputOutput) => {
        return createRowData(inputOutput, {
          onClick: () => {
            setSelectedInputOutputs([...selectedInputOutputs, inputOutput])
          },
          symbol: (<i className="fa-solid fa-plus fa-2xs"></i>),
        })
      })

      available.start = availablePageInfo.index * availablePageInfo.size
      available.end = available.start + availablePageInfo.size
      available.totalPages = ceil(available.data.length / availablePageInfo.size, 0)

      available.paginated = slice(available.data, available.start, available.end)

      setAvailableTableData({
        lastPage: available.totalPages,
        data: available.paginated,
      })

      // Selected I/O
      if (selectedPageInfo.search) {
        let searchTerms = split(selectedPageInfo.search, ' ')
        selected.data = filter(selected.data, (inputOutput) => {
          return some(searchTerms, (term) => {
            return includes(toLower(inputOutput.name), toLower(term))
          })
        })
      }

      // Remove selected I/O that is used as the main line main valve
      selected.data = reject(selected.data, (inputOutput) => {
        return inputOutput.id == mainValveId?.value
      })

      const associations = getAssociations(map(selected.data, 'id'))

      selected.data = map(selected.data, (inputOutput) => {
        const inputOutputAssociation = find(associations, ['id', inputOutput.id])
        const programs = get(inputOutputAssociation, 'programs')
        const hasProgram = !isEmpty(programs)
        const programNames = map(programs, 'name')

        return createRowData(inputOutput, {
          onClick: () => {
            let currentId = inputOutput.id

            let newSelectedInputOutputs = reject(selectedInputOutputs, (inputOutput) => {
              return inputOutput.id == currentId
            })

            setSelectedInputOutputs(newSelectedInputOutputs)
          },
          symbol: (<i className="fa-solid fa-minus fa-2xs"></i>),
          hasPrograms: hasProgram,
          programNames: programNames,
        })
      })

      selected.start = selectedPageInfo.index * selectedPageInfo.size
      selected.end = selected.start + selectedPageInfo.size
      selected.totalPages = ceil(selected.data.length / selectedPageInfo.size, 0)

      selected.paginated = slice(selected.data, selected.start, selected.end)

      setSelectedTableData({
        lastPage: selected.totalPages,
        data: selected.paginated,
      })
    }
  }, [
    props.siteInputOutputs,
    availablePageInfo,
    selectedPageInfo,
    selectedInputOutputs,
    mainValveId,
  ])

  useEffect(() => {
    let selectedIds = map(selectedInputOutputs, (inputOutput) => {
      return inputOutput.id
    })

    // Remove selected I/O that is used as the main line main valve
    selectedIds = reject(selectedIds, (id) => {
      return id == mainValveId?.value
    })

    setValue('inputOutputIds', selectedIds)
  }, [selectedInputOutputs, mainValveId])

  useEffect(() => {
    const currentTab = document.getElementById('io')

    if (currentTab) {
      props.setTabErrors((prevState) => {
        return {
          ...prevState,
          io: !!currentTab.querySelector('[data-has-error="true"]'),
        }
      })
    }
  }, [errors, props.setTabErrors])

  return (
    <>
      {!isEmpty(selectedSiteId) ? (
        <div className="mt-4 grid grid-cols-1 gap-4 lg:grid-cols-2">
          <div>
            {
              availableTableData &&
              <Table
                tableMinWidth="250px"
                header="Available I/O"
                columns={tableColumns}
                data={availableTableData}
                loading={false}
                getTableData={getAvailableTableData}
                shouldFirstLoad={false}
                topSearch
              />
            }
          </div>

          <div>
            {
              selectedTableData &&
              <Table
                tableMinWidth="250px"
                header="Selected I/O"
                columns={tableColumns}
                data={selectedTableData}
                loading={false}
                getTableData={getSelectedTableData}
                shouldFirstLoad={false}
                topSearch
                hasError={!isEmpty(errors.inputOutputIds)}
                headerPills={!isEmpty(errors.inputOutputIds) && [{
                  title: 'At least one I/O is required',
                  color: 'red',
                }]}
              />
            }
          </div>
        </div>
      ) : (
        <div className="border-light-grey mt-4 rounded border p-3 text-center">
          Please select a site to see available I/O.
        </div>
      )}
    </>
  )
}

export default InputOutputs
