import { isNumber, isString, toNumber } from 'lodash-es'

export const transformToNumber = (value) => {
  if (isZero(value) || value) {
    return value === toNumber(value) ? value : toNumber(value)
  }

  return null
}

const isZero = (value) => {
  if (isNumber(value)) {
    return value === 0
  }

  if (isString(value)) {
    return value === '0'
  }

  return false
}
