import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'

import { authAlertState } from '@/Config/Atoms/Auth'

const Message = styled.div`
  background-color: ${(props) => {
    return props.type === 'success' ? '#f6fef9' : '#FFFBFA'
  }};
  border: ${(props) => {
    return props.type === 'success' ? '1px solid #6ce9a6' : '1px solid #FDA29B'
  }};
  border-radius: 5px;
  color: ${(props) => {
    return props.type === 'success' ? '#027a48' : 'var(--error-text)'
  }};
  display: flex;
  font-size: 14px;
  font-weight: 400;
  justify-content: center;
  margin-top: 26px;
  padding: 7px 16px;
  text-align: left;

  i {
    margin-right: 13px;
  }

  ${(props) => {
    return props.type === 'success' ? `
      i {
        color: '#027a48';
      }
    ` : `
      i {
        color: 'var(--error-text)';
      }
    `
  }};

  div {
    align-items: center;
    display: flex;
  }
`

function AuthAlert(props) {
  const [alertData, setAlertData] = useRecoilState(authAlertState)

  useEffect(() => {
    let alertTimeout = setTimeout(() => {
      setAlertData(null)
    }, 7000)

    return () => {
      clearTimeout(alertTimeout)
    }
  }, [alertData, setAlertData])

  return (
    <>
      {alertData?.message && (
        <Message className={props.class} type={props.type}>
          <div>{props.type === 'success' ? <i className="fa-regular fa-lg fa-circle-check"></i> : <i className="fa-regular fa-lg fa-circle-exclamation"></i>}</div>
          <div>{props.message}</div>
        </Message>
      )}
    </>
  )
}

export default AuthAlert
