import Tippy from '@tippyjs/react'
import { ceil, debounce, filter, find, includes, isEmpty, join, map, reject, replace, size, slice, some, split, startCase, toLower, toNumber, truncate } from 'lodash-es'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import Table from '@/Components/Table'
import { formatKeys } from '@/Utilities/Form/Formatter'

const tableColumns = [{
  Header: 'Name',
  accessor: 'name',
  width: '70%',
}, {
  Header: () => {
    return (
      <div className="text-center">
        Actions
      </div>
    )
  },
  accessor: 'actions',
  width: '30%',
}]

export default function InputOutputs(props) {
  const [availablePageInfo, setAvailablePageInfo] = useState({})
  const [availableTableData, setAvailableTableData] = useState(null)
  const [selectedInputOutputs, setSelectedInputOutputs] = useState([])
  const [selectedPageInfo, setSelectedPageInfo] = useState({})
  const [selectedTableData, setSelectedTableData] = useState(null)

  const firstLoad = useRef(true)

  const isEditing = props.data?.isEditing || false
  let program

  if (props.data?.program) {
    program = useMemo(() => {
      return formatKeys(props.data.program, 'camel')
    }, [props.data.program])
  }

  const {
    errors,
    setValue,
    register,
    watch,
  } = useFormContext()

  const selectedSiteId = watch('siteId')
  const selectedMainLineId = watch('mainLineId')

  register('inputOutputIds')
  register('freeInputOutputIds')

  const getAvailableTableData = useMemo(() => {
    return debounce(async ({
      pageIndex,
      pageSize,
      filters,
    }) => {
      let pageSearch = filters.search.value
      pageIndex = toNumber(pageIndex)
      pageSize = toNumber(pageSize)

      if (availablePageInfo.index != pageIndex || availablePageInfo.size != pageSize || availablePageInfo.search != pageSearch) {
        setAvailablePageInfo({
          index: pageIndex,
          size: pageSize,
          search: pageSearch,
        })
      }
    }, 0)
  }, [availablePageInfo])

  const getSelectedTableData = useMemo(() => {
    return debounce(async ({
      pageIndex,
      pageSize,
      filters,
    }) => {
      let pageSearch = filters.search.value
      pageIndex = toNumber(pageIndex)
      pageSize = toNumber(pageSize)

      if (selectedPageInfo.index != pageIndex || selectedPageInfo.size != pageSize || selectedPageInfo.search != pageSearch) {
        setSelectedPageInfo({
          index: pageIndex,
          size: pageSize,
          search: pageSearch,
        })
      }
    }, 0)
  }, [selectedPageInfo])

  const createRowData = (inputOutput, options) => {
    const tags = []

    if (some(inputOutput.mainLines, { id: selectedMainLineId?.value })) {
      tags.push('Main Line')
    }

    if (inputOutput.auxiliary) {
      tags.push('Auxiliary')
    }

    return {
      name: (
        <>
          <div>
            {inputOutput.name}
          </div>

          {inputOutput.description ? (
            <Tippy content={inputOutput.description} delay={200} theme="light" disabled={inputOutput.description.length < 35} placement="right">
              <div className="inline-block text-xs text-slate-400">
                {truncate(inputOutput.description, { length: 35 })}
              </div>
            </Tippy>
          ): null}

          <div className="mt-0.5 text-xs text-slate-400">
            {startCase(replace(inputOutput.detailsType, 'inputOutput.', ''))} {tags.length && `(${join(tags, ', ')})` || null}
          </div>
        </>
      ),
      actions: (
        <div className="grid">
          <div className="flex items-center justify-center">
            <a
              className="background-blue-500 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-primary text-xl text-white"
              onClick={options.onClick}
            >
              {options.symbol}
            </a>
          </div>
        </div>
      ),
    }
  }

  useEffect(() => {
    const currentTab = document.getElementById('io')

    if (currentTab) {
      props.setTabErrors((prevState) => {
        return {
          ...prevState,
          io: !!currentTab?.querySelector('[data-has-error="true"]'),
        }
      })
    }
  }, [errors])

  useEffect(() => {
    const selected = { data: selectedInputOutputs }

    // Determine Selected I/O
    if (firstLoad.current) {
      if (isEditing) {
        setSelectedInputOutputs(program?.inputOutputs || [])
      }

      firstLoad.current = false
    }

    const commonFilter = (inputOutput) => {
      return (
        !size(inputOutput.mainLineFlowMeters) &&
        !includes(props.selectedPumpIds, inputOutput.id) &&
        (inputOutput.auxiliary || !isEmpty(find(inputOutput.mainLines, ['id', selectedMainLineId?.value])))
      )
    }

    // Available I/O
    const available = {}

    available.data = filter(props.allInputOutputs, commonFilter)

    // Remove selected items
    available.data = filter(available.data, (inputOutput) => {
      return !find(selected.data, ['id', inputOutput.id])
    })

    if (availablePageInfo.search) {
      let searchTerms = split(availablePageInfo.search, ' ')
      available.data = filter(available.data, (inputOutput) => {
        return some(searchTerms, (term) => {
          return includes(toLower(inputOutput.name), toLower(term))
        })
      })
    }

    available.data = map(available.data, (inputOutput) => {
      return createRowData(inputOutput, {
        onClick: () => {
          setSelectedInputOutputs([...selectedInputOutputs, inputOutput])
        },
        symbol: (<i className="fa-solid fa-plus fa-2xs"></i>),
      })
    })

    available.start = availablePageInfo.index * availablePageInfo.size
    available.end = available.start + availablePageInfo.size
    available.totalPages = ceil(available.data.length / availablePageInfo.size, 0)

    available.paginated = slice(available.data, available.start, available.end)

    setAvailableTableData({
      lastPage: available.totalPages,
      data: available.paginated,
    })

    // Selected I/O
    if (selectedPageInfo.search) {
      let searchTerms = split(selectedPageInfo.search, ' ')
      selected.data = filter(selected.data, (inputOutput) => {
        return some(searchTerms, (term) => {
          return includes(toLower(inputOutput.name), toLower(term))
        })
      })
    }

    selected.data = filter(selected.data, commonFilter)

    selected.data = map(selected.data, (inputOutput) => {
      return createRowData(inputOutput, {
        onClick: () => {
          let currentId = inputOutput.id

          let newSelectedInputOutputs = reject(selectedInputOutputs, (inputOutput) => {
            return inputOutput.id == currentId
          })

          setSelectedInputOutputs(newSelectedInputOutputs)
        },
        symbol: (<i className="fa-solid fa-minus fa-2xs"></i>),
      })
    })

    selected.start = selectedPageInfo.index * selectedPageInfo.size
    selected.end = selected.start + selectedPageInfo.size
    selected.totalPages = ceil(selected.data.length / selectedPageInfo.size, 0)

    selected.paginated = slice(selected.data, selected.start, selected.end)

    setSelectedTableData({
      lastPage: selected.totalPages,
      data: selected.paginated,
    })
  }, [
    props.allInputOutputs,
    availablePageInfo,
    selectedPageInfo,
    selectedInputOutputs,
    selectedMainLineId,
    selectedSiteId,
    props.selectedPumpIds,
  ])

  useEffect(() => {
    let selectedIds = []

    if (!selectedMainLineId) {
      const inputOutputsNoMainLine = filter(selectedInputOutputs, (inputOutput) => {
        return isEmpty(inputOutput.mainLines)
      })

      selectedIds = map(inputOutputsNoMainLine, (inputOutput) => {
        return inputOutput.id
      })
    } else {
      selectedIds = map(selectedInputOutputs, (inputOutput) => {
        return inputOutput.id
      })
    }

    setValue('inputOutputIds', selectedIds, { shouldValidate: true })
  }, [selectedInputOutputs, selectedMainLineId])

  return (
    <>
      {selectedSiteId ? (
        <>
          <div className="mt-4 grid grid-cols-1 gap-4 lg:grid-cols-2">
            <div>
              {
                availableTableData && (
                  <Table
                    tableMinWidth="250px"
                    header="Available I/O"
                    columns={tableColumns}
                    data={availableTableData}
                    loading={false}
                    getTableData={getAvailableTableData}
                    shouldFirstLoad={false}
                    topSearch
                  />
                )
              }
            </div>

            <div>
              {
                selectedTableData && (
                  <Table
                    tableMinWidth="250px"
                    header="Selected I/O"
                    columns={tableColumns}
                    data={selectedTableData}
                    loading={false}
                    getTableData={getSelectedTableData}
                    shouldFirstLoad={false}
                    topSearch
                    hasError={!isEmpty(errors.inputOutputIds)}
                    headerPills={!isEmpty(errors.inputOutputIds) && [{
                      title: 'At least one I/O is required',
                      color: 'red',
                    }]}
                  />
                )
              }
            </div>
          </div>
        </>
      ) : (
        <div className="border-light-grey mt-4 rounded border p-3 text-center">
          Please select a program set and a main line to see available I/O.
        </div>
      )}
    </>
  )
}
