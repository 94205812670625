import axios from 'axios'
import Cookies from 'js-cookie'
import { filter, head, startsWith } from 'lodash-es'
import { useLocation } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'

import { pageAlertState } from '@/Config/Atoms/General'

let axiosInstance

export default function useApiClient() {
  const setAlert = useSetRecoilState(pageAlertState)
  const location = useLocation()

  if (!axiosInstance) {
    axiosInstance = axios.create({
      baseURL: `${import.meta.env.VITE_API_URL}/api`,
      withCredentials: true,
    })

    // Request interceptor
    axiosInstance.interceptors.request.use((config) => {
      config.headers['X-XSRF-TOKEN'] = Cookies.get('XSRF-TOKEN')

      return config
    }, (error) => {
      return Promise.reject(error)
    })

    // Response interceptor
    axiosInstance.interceptors.response.use((response) => {
      return response
    }, (error) => {
      if (error.response?.status === 401) {
        let currentPath = location.pathname
        let redirectExempt = [
          '/user/login',
          '/user/logout',
          '/user/register',
          '/user/forgot-password',
          '/user/reset-password',
        ]

        let matchingPath = head(filter(redirectExempt, (path) => {
          return startsWith(currentPath, path)
        }))

        if (!matchingPath) {
          setAlert({
            type: 'error',
            content: 'Error retrieving data.',
          })
        }

        return error.response
      }

      return Promise.reject(error)
    })
  }

  return axiosInstance
}
