import { map, some } from 'lodash-es'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import styled from 'styled-components'

import CircleList from '@/Components/CircleList'
import DropdownList from '@/Components/DropdownList'
import { userState } from '@/Config/Atoms/Auth'
import { showCookieConsentAtom } from '@/Config/Atoms/CookieConsent'
import { modalState } from '@/Config/Atoms/General'
import { asset } from '@/Utilities/Url'

const Actions = styled.div`
  align-items: center;
  display: flex;
  margin-right: 0;

  @media only screen and (min-width: 992px) {
    margin-right: 0;
  }

  * {
    cursor: pointer;
  }

  svg {
    color: var(--text-dark);
    margin: 6px;

    @media only screen and (min-width: 992px) {
      margin: 0 13px;
    }
  }
`

const Avatar = styled.img`
  border-radius: 100%;
  object-fit: cover;
  height: 36px;
  width: 36px;
`

const UserIcon = (name) => {
  return <CircleList
    compact
    circles={[{ text: name || null }]}
  />
}

function HeaderActions () {
  const navigate = useNavigate()
  const user = useRecoilValue(userState)
  const setModal = useSetRecoilState(modalState)
  const setShowCookieConsent = useSetRecoilState(showCookieConsentAtom)

  return (
    user && (
      <Actions>
        {some(user.roles, { slug: 'super-administrator' }) && (
          <span className="mr-5">
            {map(user.roles, (role, roleIndex) => {
              return (
                <span key={roleIndex} className="inline-flex items-center rounded-md bg-blue-400/10 px-2 py-1 text-xs font-medium text-blue-400 ring-1 ring-inset ring-blue-400/30" onClick={() => {
                  setModal({
                    name: 'debugPermissions',
                    data: null,
                  })
                }}>
                  {role.name}
                </span>
              )
            })}
          </span>
        )}

        <DropdownList
          relativeContainer={null}
          clickable
          icon={user.avatar ? <Avatar src={`${asset(user.avatar)}?t=${new Date().getTime()}`} alt="avatar"/> : UserIcon(user.name)}
          style={{ zIndex: 10010 }}
          options={[
            {
              label: 'Profile',
              onClick: () => {
                navigate('user/profile')
              },
            },
            {
              label: 'Cookie Consent',
              onClick: () => {
                setShowCookieConsent(true)
              },
            },
            {
              label: 'Logout',
              onClick: () => {
                navigate('/user/logout')
              },
            },
          ]}
        />
      </Actions>
    )
  )
}

export default HeaderActions
