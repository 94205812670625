import { get, head, isEmpty, isEqual, keys, noop } from 'lodash-es'
import { useCallback, useEffect, useRef, useState } from 'react'

/**
 * This function performs revalidation on form fields when certain conditions are met.
 * It is also responsible for setting the selected tab in the interface.
 *
 * @param {Object} options - Configuration options for the revalidation process.
 * @param {Function} options.watch - A function to watch changes in form data.
 * @param {Object} options.formState - Current state of the form.
 * @param {Function} options.trigger - A function that triggers revalidation.
 * @param {Boolean} options.revalidateWhen - A flag to decide when revalidation is to be triggered.
 * @param {Function} [options.setSelectedTab = noop] - A function to change the selected tab in the user interface.
 * @param {Object} [options.fieldTabs = {}] - An object mapping form fields to the interface's tabs.
 * @returns {Object} Contains handlers for submission error and the next error in the form state.
 */
export default function useFormRevalidate({
  watch,
  formState,
  trigger,
  schema,
  revalidateWhen,
  setSelectedTab = noop,
}) {
  // Store reference to the earlier state of form fields.
  const fieldsRef = useRef()
  const [handlerTriggered, setHandlerTriggered] = useState(false)

  // Get current state of form fields.
  const fields = watch()

  /**
   * Function to handle an error update.
   *
   * This function is used to handle an error update. It first retrieves the name of the first error field from the
   * formState's errors object. Then, it retrieves the error object and the nextErrorElement (ref) from the error
   * object. It tries to get the tabKey from the field's metadata in the schema. If no tabKey is found, it attempts
   * to locate the closest div with an id if nextErrorElement is an HTMLElement. If a tabKey is found, it sets the
   * selectedTab using the setSelectedTab function.
   *
   * @function
   * @name handleErrorUpdate
   * @param {object} schema - The schema object.
   * @param {object} formState.errors - The formState errors object.
   * @returns {void}
   */
  const handleErrorUpdate = useCallback(() => {
    const fieldName = head(keys(formState.errors))
    const error = get(formState.errors, fieldName)
    const nextErrorElement = get(error, 'ref')

    let tabKey = get(schema, [
      'fields',
      fieldName,
      'spec',
      'meta',
      'tabKey',
    ])

    // If no tab has been defined in the field's metadata, attempt to locate the tab if a
    // element is provided as a ref
    if (!tabKey && nextErrorElement instanceof HTMLElement) {
      // Getting the Id of the closest div from the error producing element.
      tabKey = get(nextErrorElement.closest('div[id]'), 'id')
    }

    if (tabKey) {
      setSelectedTab(tabKey)
    }
  }, [schema, formState.errors])

  /**
   * submitErrorHandler is a callback function that is designed to handle form submission errors.
   *
   * This is typically invoked by the useForm.handleSubmit() function in react-hook-form.
   *
   * When invoked, it sets the state of handlerTriggered to true, indicating that the handler has been triggered. It
   * takes no parameters. It should be called within a React component where the formState and setHandlerTriggered
   * functions are defined.  The useCallback hook is used to memoize the function, ensuring that it doesn't get
   * recreated on each render unless the dependencies change. The function is asynchronous and should handle any
   * async operations required for error handling. It depends on the formState.errors property, and will be triggered
   * whenever this property changes.
   *
   * @function
   * @name submitErrorHandler
   */
  const submitErrorHandler = useCallback(async () => {
    setHandlerTriggered(true)
  }, [formState.errors])

  useEffect(async () => {
    if (revalidateWhen && !isEqual(fieldsRef.current, fields)) {
      await trigger()
    }

    fieldsRef.current = fields
  }, [fields])

  useEffect(() => {
    if (revalidateWhen && handlerTriggered && !isEmpty(formState.errors)) {
      setHandlerTriggered(false)
      handleErrorUpdate()
    }
  }, [formState.errors])

  return { submitErrorHandler }
}
