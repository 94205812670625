import classNames from 'classnames'
import { each, filter, find, flatten, forEach, get, includes, isEmpty, map, size, times, uniqBy } from 'lodash-es'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

import { Anchor } from '@/Components/form/Buttons'
import InputError from '@/Components/form/InputError'
import RequiredAsterisk from '@/Components/form/RequiredAsterisk'
import SelectField from '@/Components/form/Select'
import { toOptions } from '@/Utilities/Form/Formatter'
import useApiClient from '@/Utilities/useApiClient'

export default function DataSets(props) {
  const apiClient = useApiClient()
  const [allSites, setAllSites] = useState([])
  const loadedDefaultValues = useRef(false)
  const existingDataSets = useRef(get(props, 'data.chart.dataSets', []))

  const {
    control,
    errors,
    watch,
    getValues,
    setValue,
  } = useFormContext()

  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'dataSets',
  })

  const dataSets = watch('dataSets')

  const propertyOptions = [
    {
      value: 'total',
      label: 'Totalizer Value',
    },
    {
      value: 'flow',
      label: 'Flow rate',
    },
    {
      value: 'value',
      label: 'Current value',
    },
    {
      value: 'moisture',
      label: 'Moisture',
    },
    {
      value: 'temperature',
      label: 'Temperature',
    },
  ]

  const propertyDepthOptions = useMemo(() => {
    const maxDepth = 16

    return map(times(maxDepth, Number), (depth) => {
      return {
        value: depth + 1,
        label: `Depth ${depth + 1}`,
      }
    })
  }, [])

  const chartTypeOptions = [
    {
      value: 'line',
      label: 'Line chart',
    },
    {
      value: 'bar',
      label: 'Bar chart',
    },
    {
      value: 'pie',
      label: 'Pie Chart',
    },
    {
      value: 'annotation',
      label: 'Annotation',
    },
  ]

  const propertyMapping = {
    analogFlowMeter: ['flow', 'total'],
    digitalFlowMeter: ['flow', 'total'],
    analogInput: ['value'],
    analogOutput: ['value'],
    sensorSoilProbe: ['moisture', 'temperature'],
    digitalInput: ['value'],
    digitalOutputBooster: ['value'],
    digitalOutputElectricLock: ['value'],
    digitalOutputFountain: ['value'],
    digitalOutputGeneral: ['value'],
    digitalOutputLight: ['value'],
    digitalOutputValve: ['value'],
    virtualDigitalOutput: ['value'],
  }

  const chartMapping = {
    analogFlowMeter: ['line'],
    digitalFlowMeter: ['line'],
    analogInput: ['line'],
    analogOutput: ['line'],
    sensorSoilProbe: ['line'],
    digitalInput: ['annotation'],
    digitalOutputBooster: ['annotation'],
    digitalOutputElectricLock: ['annotation'],
    digitalOutputFountain: ['annotation'],
    digitalOutputGeneral: ['annotation'],
    digitalOutputLight: ['annotation'],
    digitalOutputValve: ['annotation'],
    virtualDigitalOutput: ['annotation'],
  }

  const getTypeOptions = useCallback((index) => {
    const selectedInputOutput = getValues(`dataSets.${index}.inputOutputId`)

    if (isEmpty(selectedInputOutput)) {
      return []
    }

    const validOptions = filter(chartTypeOptions, (option) => {
      return includes(chartMapping[selectedInputOutput.type], option.value)
    })

    return validOptions
  }, [dataSets])

  const getPropertyOptions = useCallback((index) => {
    const selectedInputOutput = getValues(`dataSets.${index}.inputOutputId`)

    if (isEmpty(selectedInputOutput)) {
      return []
    }

    const validOptions = filter(propertyOptions, (option) => {
      return includes(propertyMapping[selectedInputOutput.type], option.value)
    })

    return validOptions
  }, [dataSets])

  const getPropertyDepthOptions = useCallback((index) => {
    const selectedInputOutput = getValues(`dataSets.${index}.inputOutputId`)

    if (isEmpty(selectedInputOutput)) {
      return []
    }

    const inputOutput = find(inputOutputs, (inputOutput) => {
      return selectedInputOutput.value === inputOutput.id
    })

    const depths = get(inputOutput, 'details.manufacturer_model.configuration.depths', false)

    let validOptions = []

    if (depths) {
      validOptions = filter(propertyDepthOptions, (option) => {
        return option.value <= depths
      })
    }

    return validOptions
  }, [dataSets])

  useEffect(() => {
    (async () => {
      const query = new URLSearchParams([
        ['pageSize', 1000],
        ['with[]', 'area'],
        ['with[]', 'inputOutputs.details.manufacturerModel'],
      ])

      const response = await apiClient.get(`/site/query?${query}`)

      setAllSites(get(response, 'data.sites.data'))
    })()
  }, [])

  const [inputOutputs, inputOutputOptions] = useMemo(() => {
    let inputOutputs = []
    let options = []

    forEach(allSites, (site) => {
      const inputOutputWithDetails = toOptions(site.input_outputs, ['id', 'id'], ['type', 'type'], ['details', 'details'])
      const inputOutputOptions = toOptions(site.input_outputs, ['name', 'label'], ['id', 'value'], ['type', 'type'])

      inputOutputs = [...inputOutputs, ...inputOutputWithDetails]

      options = [...options, {
        label: site.name,
        options: inputOutputOptions,
      }]
    })

    inputOutputs = uniqBy(inputOutputs, 'id')

    return [inputOutputs, options]
  }, [allSites])

  useEffect(() => {
    if (!loadedDefaultValues.current && !isEmpty(inputOutputOptions)) {
      const flatInputOutputOptions = flatten(map(inputOutputOptions, 'options'))

      each(existingDataSets.current, (dataSet) => {
        const selectedInputOutput = find(flatInputOutputOptions, (inputOutput) => {
          return inputOutput.value === dataSet.inputOutputId
        })

        const selectedProperty = find(propertyOptions, (property) => {
          return property.value === dataSet.property
        })

        const selectedPropertyDepth = find(propertyDepthOptions, (propertyDepth) => {
          return propertyDepth.value === dataSet.propertyDepth
        })

        const selectedType = find(chartTypeOptions, (chartType) => {
          return chartType.value === dataSet.type
        })

        append({
          id: dataSet.id,
          inputOutputId: selectedInputOutput,
          property: selectedProperty,
          propertyDepth: selectedPropertyDepth,
          type: selectedType,
        })
      })

      loadedDefaultValues.current = true
    }
  }, [
    inputOutputOptions,
    inputOutputs,
    getPropertyOptions,
  ])

  return (
    <>
      <div className="mt-3">
        {map(fields, (field, index) => {
          return (
            <div key={field.id}>
              <div className={classNames({ 'border-b mb-5': size(fields) !== index + 1 }, 'flex pb-5')}>
                <div className="grid basis-full grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
                  <div>
                    <Controller
                      control={control}
                      defaultValue={{}}
                      name={`dataSets.${index}.inputOutputId`}
                      render={({ field }) => {
                        return (
                          <SelectField
                            {...field}
                            label={
                              <>
                                Element <RequiredAsterisk />
                              </>
                            }
                            isSearchable={true}
                            afterChange={() => {
                              // Selected Option
                              const selectedType = getValues(`dataSets.${index}.type`)
                              const selectedProperty = getValues(`dataSets.${index}.property`)
                              const selectedPropertyDepth = getValues(`dataSets.${index}.propertyDepth`)

                              // Available Options
                              const availableTypeOptions = getTypeOptions(index)
                              const availablePropertyOptions = getPropertyOptions(index)
                              const availablePropertyDepthOptions = getPropertyDepthOptions(index)

                              let validTypeOptions = []
                              if (validTypeOptions) {
                                validTypeOptions = filter(availableTypeOptions, (option) => {
                                  return option.value === selectedType?.value
                                })
                              }

                              let validPropertyOptions = []
                              if (validPropertyOptions) {
                                validPropertyOptions = filter(availablePropertyOptions, (option) => {
                                  return option.value === selectedProperty?.value
                                })
                              }

                              let validPropertyDepthOptions = []
                              if (validPropertyDepthOptions) {
                                validPropertyDepthOptions = filter(availablePropertyDepthOptions, (option) => {
                                  return option.value === selectedPropertyDepth?.value
                                })
                              }

                              if (!size(validTypeOptions)) {
                                setValue(`dataSets.${index}.type`, '')
                              }

                              if (!size(validPropertyOptions)) {
                                setValue(`dataSets.${index}.property`, '')
                              }

                              if (!size(validPropertyDepthOptions)) {
                                setValue(`dataSets.${index}.propertyDepth`, '')
                              }
                            }}
                            options={inputOutputOptions}
                            hasError={!!errors.dataSets?.[index]?.inputOutputId}
                          />
                        )
                      }}
                    />
                    {get(errors, `dataSets.${index}.inputOutputId`) && (
                      <InputError message={get(errors, `dataSets.${index}.inputOutputId.message`)} />
                    )}
                  </div>

                  <div>
                    <Controller
                      control={control}
                      defaultValue={{}}
                      name={`dataSets.${index}.property`}
                      render={({ field }) => {
                        return (
                          <SelectField
                            {...field}
                            label={
                              <>
                                Property <RequiredAsterisk />
                              </>
                            }
                            isSearchable={true}
                            options={getPropertyOptions(index)}
                            hasError={!!errors.dataSets?.[index]?.property}
                          />
                        )
                      }}
                    />
                    {get(errors, `dataSets.${index}.property`) && (
                      <InputError message={get(errors, `dataSets.${index}.property.message`)} />
                    )}
                  </div>

                  <div className={classNames({ hidden: isEmpty(getPropertyDepthOptions(index)) })}>
                    <Controller
                      control={control}
                      defaultValue={{}}
                      name={`dataSets.${index}.propertyDepth`}
                      render={({ field }) => {
                        return (
                          <SelectField
                            {...field}
                            label={
                              <>
                                Depth <RequiredAsterisk />
                              </>
                            }
                            isSearchable={true}
                            options={getPropertyDepthOptions(index)}
                            hasError={!!errors.dataSets?.[index]?.propertyDepth}
                          />
                        )
                      }}
                    />
                    {get(errors, `dataSets.${index}.propertyDepth`) && (
                      <InputError message={get(errors, `dataSets.${index}.propertyDepth.message`)} />
                    )}
                  </div>

                  <div>
                    <Controller
                      control={control}
                      defaultValue={find(chartTypeOptions, { value: get(field, 'type.value') })}
                      name={`dataSets.${index}.type`}
                      render={({ field }) => {
                        return (
                          <SelectField
                            {...field}
                            label={
                              <>
                                Type <RequiredAsterisk />
                              </>
                            }
                            isSearchable={true}
                            options={getTypeOptions(index)}
                            hasError={!!errors.dataSets?.[index]?.type}
                          />
                        )
                      }}
                    />
                    {get(errors, `dataSets.${index}.type`) && (
                      <InputError message={get(errors, `dataSets.${index}.type.message`)} />
                    )}
                  </div>
                </div>

                <div className="flex w-12 justify-end">
                  {index > 0 && (
                    <div
                      className="border-radius-full mt-8 cursor-pointer pl-2 text-red-600 hover:text-red-900"
                      onClick={() => {
                        return remove(index)
                      }}
                    >
                      <i className="fa-light fa-circle-minus fa-lg"></i>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        })}
      </div>

      {fields?.length < 10 && (
        <div
          className="mb-2 inline-block hover:cursor-pointer"
          onClick={() => {
            append({}, { focusName: 'dataSets' })
          }}
        >
          <Anchor className="transparent">
            <i className="fa-solid fa-plus"></i> Add data set
          </Anchor>
        </div>
      )}
    </>
  )
}
