import '@/Utilities/Form/Yup'

import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/react'
import { filter, get, head, map } from 'lodash-es'
import { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSetRecoilState } from 'recoil'
import styled from 'styled-components'
import * as yup from 'yup'

import { Anchor, Button } from '@/Components/form/Buttons'
import Input from '@/Components/form/Input'
import InputError from '@/Components/form/InputError'
import Select from '@/Components/form/Select'
import Modal from '@/Components/Modal'
import { pageAlertState } from '@/Config/Atoms/General'
import { publish } from '@/Utilities/Events'
import useApiClient from '@/Utilities/useApiClient'
import useTitle from '@/Utilities/useTitle'

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
`

const ButtonGroup = styled.div`
  display: flex;
  width: calc(50% - 5px);
`

const Form = styled.form`
  label {
    margin-top: 20px;
  }

  .buttons {
    margin-top: 40px;
  }
`

const schema = yup.object({
  siteId: yup.mixed().label('Site').populatedObject(),
  name: yup.string().label('Name').required().min(3).max(50),
  description: yup.string().label('Description').max(250).nullable(),
  deviceId: yup.number().label('Device ID').required().typeError('Device ID must be less than 5 digits').test('len', 'Device ID must be less than 5 digits', (val) => {
    return parseFloat(val).toString().length <= 5
  }),
  euid: yup.string().label('EUID').required().length(16),
})

function RemoteUnitModal(props) {
  const apiClient = useApiClient()
  const [siteOptions, setSiteOptions] = useState(null)
  const [sitesLoading, setSitesLoading] = useState(true)
  const [remoteUnitTypes, setRemoteUnitTypes] = useState(null)
  const [remoteUnitTypesLoading, setRemoteUnitTypesLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const firstLoad = useRef(true)
  const form = useRef(null)
  const setAlert = useSetRecoilState(pageAlertState)
  const {
    reset,
    setValue,
    control,
    register,
    handleSubmit,
    formState,
  } = useForm({ resolver: yupResolver(schema) })
  const { errors } = formState

  const {
    isEditing = false,
    remoteUnit = {},
  } = get(props, 'data', {})

  useTitle([`${isEditing ? 'Edit' : 'Add'} remote unit`, remoteUnit?.name])

  useEffect(() => {
    (async () => {
      try {
        const { data } = await apiClient.get('/site/gateways')

        if (data && data.gateways) {
          const siteOptions = map(data.gateways, (gateway) => {

            return {
              value: gateway.id,
              label: gateway.name,
            }
          })

          setSiteOptions(siteOptions)
        }
      } catch (error) {
        Sentry.captureException(error)
        setAlert({
          type: 'error',
          content: 'Failed to retrieve sites.',
        })
      } finally {
        setSitesLoading(false)
      }
    })()
  }, [
    setSitesLoading,
    setSiteOptions,
    setAlert,
  ])

  useEffect(() => {
    return () => {
      firstLoad.current = true
    }
  }, [])

  useEffect(() => {
    (async () => {
      try {
        const { data } = await apiClient.get('/remote-unit-types')

        const remoteUnitTypes = map(data.remoteUnitTypes, (remoteUnitType) => {
          return {
            value: remoteUnitType.id,
            label: remoteUnitType.name,
          }
        })

        if (remoteUnitTypes?.length === 1) {
          setValue('remoteUnitType', head(remoteUnitTypes))
        }

        setRemoteUnitTypes(remoteUnitTypes)
      } catch (error) {
        Sentry.captureException(error)
        setAlert({
          type: 'error',
          content: 'Failed to retrieve remote unit types.',
        })
      } finally {
        setRemoteUnitTypesLoading(false)
      }
    })()
  }, [
    setRemoteUnitTypesLoading,
    setRemoteUnitTypes,
    setAlert,
    setValue,
  ])

  const onSubmit = async (data) => {
    setAlert(null)
    setIsLoading(true)

    try {
      let { data: responseData } = await apiClient.post(`/remote-unit/${props.data?.remoteUnit ? `update/${props.data.remoteUnit.id}`: 'create'} `, {
        site_id: data.siteId.value,
        name: data.name,
        description: data.description,
        device_id: data.deviceId,
        euid: data.euid,
        remote_unit_type_id: data.remoteUnitType.value,
      })

      if (responseData.success) {
        setAlert({
          type: 'success',
          content: `Remote Unit ${responseData.remoteUnit.name} has been successfully ${props.data?.remoteUnit ? 'updated' : 'created'}.`,
        })

        publish('remoteUnitCreated')

        props.close()
      }
    } catch (error) {
      setAlert({
        type: 'error',
        content: 'An error has occured and we were not able to save this remote unit. Please try again.',
      })
      Sentry.captureException(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      title={'Remote unit'}
      close={props.close}
      closeOnOutsideClick={props.closeOnOutsideClick}
    >
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off" ref={form}>
        {
          siteOptions &&
          <>
            <Controller
              control={control}
              defaultValue={head(filter(siteOptions, ['value', props.data?.siteId || remoteUnit?.siteId])) || ''}
              name="siteId"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    isMulti={false}
                    isSearchable={true}
                    isRequired={true}
                    label="Site"
                    isLoading={sitesLoading}
                    options={siteOptions}
                    placeholder="Search"
                    hasError={!!errors.siteId}
                    helpTooltip="Sites are used as gateways. Sites that have remote gateways enabled will be available for selection."
                  />
                )
              }}
            />
            {errors.siteId && <InputError message={errors.siteId.message} />}
          </>
        }

        <Input
          label="Name"
          type="text"
          className={errors.name && 'error'}
          isRequired={true}
          {...register('name', { value: remoteUnit?.name })}
        />
        {errors.name && <InputError message={errors.name.message} />}

        <Input
          label="Description"
          type="text"
          className={errors.description && 'error'}
          {...register('description', { value: remoteUnit?.description })}
        />
        {errors.description && <InputError message={errors.description.message} />}

        <Input
          label="Device ID"
          type="number"
          helpTooltip="The device ID is a unique identifier for the LoRa device."
          className={errors.deviceId && 'error'}
          isRequired={true}
          {...register('deviceId', { value: remoteUnit?.deviceId })}
        />
        {errors.deviceId && <InputError message={errors.deviceId.message} />}

        <Input
          label="EUID"
          type="text"
          helpTooltip="The EUID is a unique identifier for the application being used for the remote unit."
          className={errors.euid && 'error'}
          maxLength="16"
          isRequired={true}
          {...register('euid', { value: remoteUnit?.euid })}
        />
        {errors.euid && <InputError message={errors.euid.message} />}

        {
          remoteUnitTypes &&
          <>
            <Controller
              control={control}
              defaultValue={head(remoteUnitTypes) || ''}
              name="remoteUnitType"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    isMulti={false}
                    isSearchable={true}
                    isLoading={remoteUnitTypesLoading}
                    label="Type"
                    options={remoteUnitTypes}
                    hasError={!!errors.remoteUnitType}
                  />
                )
              }}
            />
            {errors.remoteUnitType && <InputError message={errors.remoteUnitType.message} />}
          </>
        }

        <ButtonWrapper className="buttons">
          <Anchor
            style={{ width: 'calc(50% - 5px)' }}
            className="transparent"
            onClick={() => {
              setAlert(null)
              reset()
              props.close()
            }}
          >
            Close
          </Anchor>

          <ButtonGroup>
            <Button
              disabled={isLoading ? true : false}
              style={{ flexGrow: 1 }}
            >
              {isLoading ? <div className="primary-loader light"></div> : 'Save'}
            </Button>
          </ButtonGroup>
        </ButtonWrapper>
      </Form>
    </Modal>
  )
}

export default RemoteUnitModal
