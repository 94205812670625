import { isFunction, map } from 'lodash-es'
import { useState } from 'react'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import OutsideClickHandler from 'react-outside-click-handler'
import styled from 'styled-components'

import InputField from '@/Components/form/Input'
import useApiClient from '@/Utilities/useApiClient'

const Options = styled.div`
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 1px 5px rgba(16, 24, 40, 0.25);
  position: absolute;
  width: 100%;
  z-index: 1;
  margin-top: 8px;
  padding: 4px 0;
`

const Item = styled.div`
  background-color: white;
  padding: 7px 12px;

  ${(props) => {
    return !props.disableHover && `
      &:hover {
        background-color: #ddebff;
      }
    `
  }}
`

function GooglePlacesAutocomplete(props) {
  const [showOptions, setShowOptions] = useState(false)
  const [handlingClick, setHandlingClick] = useState(false)
  const apiClient = useApiClient()

  const {
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: import.meta.env.VITE_GOOGLE_API_KEY,
    debounce: 1000,
  })

  return (
    <div className="relative">
      <InputField
        {...props}
        placeholder="Search"
        onChange={(event) => {
          getPlacePredictions({ input: event.target.value })
          setShowOptions(event.target.value != '')

          if (isFunction(props.onChange)) {
            props.onChange(props.name, event.target.value)
          }
        }}
        isLoading={isPlacePredictionsLoading}
        searchIcon={true}
        loadingIcon={handlingClick}
        value={props.value}
      />

      {
        (isPlacePredictionsLoading || showOptions) &&
        <OutsideClickHandler
          onOutsideClick={() => {
            if (showOptions) {
              setShowOptions(false)
            }
          }}
        >
          <Options>
            {isPlacePredictionsLoading && (
              <Item disableHover className="text-gray-500">
                Loading...
              </Item>
            )}

            {!isPlacePredictionsLoading && showOptions &&
              <>
                {
                  map(placePredictions, (item) => {
                    return <Item
                      className="text-gray-800"
                      key={item.description}
                      onClick={async () => {
                        setShowOptions(false)

                        if (isFunction(props.onChange)) {
                          props.onChange(props.name, item.description)
                        }

                        if (isFunction(props.coordinatesChange)) {
                          setHandlingClick(true)
                          props.coordinatesChange(props.name, {
                            lat: '',
                            lng: '',
                          }, false)

                          let { data } = await apiClient.get(`/google/maps/search/place-id?query=${item.place_id}`)

                          if (data.success) {
                            props.coordinatesChange(props.name, data.result.geometry.location)
                          }

                          setHandlingClick(false)
                        }
                      }}
                    >
                      {item.description}
                    </Item>
                  })
                }

                {!placePredictions.length && (
                  <Item disableHover className="text-gray-500">
                    No results.
                  </Item>
                )}
              </>
            }
          </Options>
        </OutsideClickHandler>
      }
    </div>
  )
}

export default GooglePlacesAutocomplete
