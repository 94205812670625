import classNames from 'classnames'
import { forwardRef, useState } from 'react'
import { useEffect } from 'react'
import { SketchPicker } from 'react-color'

import InputLabel from '@/Components/form/InputLabel'
import RequiredAsterisk from '@/Components/form/RequiredAsterisk'

// eslint-disable-next-line
const ColorPicker = forwardRef((props, ref) => {
  const [color, setColor] = useState()
  const [colorPickerOpen, setColorPickerOpen] = useState(false)

  useEffect(() => {
    if (props.defaultValue) {
      setColor(props.defaultValue)
    }
  }, [])

  return (
    <>
      {props.label && (
        <InputLabel htmlFor={props.name}>{props.label} {props.isRequired && (<RequiredAsterisk />)}</InputLabel>
      )}

      <div className="relative mb-1">
        {
          color ? (
            <div className="grid auto-cols-min grid-flow-col-dense">
              <div
                className="h-10 w-10 cursor-pointer rounded border shadow" style={{ background: color }}
                onClick={() => {
                  setColorPickerOpen(true)
                }}
              />

              <div className="flex items-center">
                <a
                  className="ml-4 inline-block cursor-pointer whitespace-nowrap px-1 text-gray-500 hover:text-gray-400"
                  onClick={() => {
                    setColor('')
                    props.onChange({
                      target: {
                        name: props.name,
                        value: '',
                      },
                    })
                  }}
                >
                  {!colorPickerOpen && (
                    <>
                      <i className="fa-light fa-trash"></i> <span className="ml-1 text-sm">Clear selection</span>
                    </>
                  )}
                </a>
              </div>
            </div>
          ) : (
            <div
              className="flex h-10 w-10 cursor-pointer items-center justify-center overflow-hidden rounded border text-gray-300 shadow hover:text-gray-400"
              onClick={() => {
                setColorPickerOpen(true)
              }}
            >
              <i className="fa-light fa-eye-dropper-full text-2xl"></i>
            </div>
          )
        }

        {
          colorPickerOpen &&
          <div
            className={classNames([props.anchor == 'bottom' ? 'bottom-0' : 'top-0', 'absolute z-20'])}
          >
            <div className="my-12">
              <div
                className={classNames([props.anchor == 'bottom' ? 'bottom-0' : 'top-0', 'absolute right-0 my-2 cursor-pointer text-gray-500 hover:text-gray-900 items-center flex'])}
                onClick={() => {
                  setColorPickerOpen(false)
                }}
              >
                <i className="fa-sharp fa-light fa-circle-check mr-2 text-xl text-green-700"></i> <span className="text-sm font-medium text-green-700">Done</span>
              </div>

              <SketchPicker
                color={color}
                disableAlpha={true}
                onChange={(color) => {
                  setColor(color.hex)
                }}
                onChangeComplete={(color) => {
                  props.onChange({
                    target: {
                      name: props.name,
                      value: color.hex,
                    },
                  })
                }}
              />
            </div>
          </div>
        }
      </div>
    </>
  )
})

export default ColorPicker
