import Tippy from '@tippyjs/react'
import { startCase, toLower } from 'lodash-es'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'

export default function AlarmIcon(props) {
  const {
    type,
    uuid,
  } = props

  const readableType = useMemo(() => {
    if (type === 'inputOutput') {
      return 'I/O'
    }

    return toLower(startCase(type))
  }, [type, uuid])

  return (
    <Tippy
      content={`This ${readableType} has an alarm`}
      delay={200}
      theme="light"
      placement="top"
    >
      <Link
        to={`/alarms?type=${type}&search=${uuid}`}
        className="ml-2 inline-block text-primary"
      >
        <i className="fa-solid fa-triangle-exclamation text-md inline-block p-1 text-red-500"></i>
      </Link>
    </Tippy>
  )
}
