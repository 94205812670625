import { forEach } from 'lodash-es'
import { darken, meetsContrastGuidelines } from 'polished'

export const acceptableContrast = (color1, color2, types = ['AA']) => {
  let result = meetsContrastGuidelines(color1, color2)

  let fail = false
  forEach(types, (type) => {
    if (!result[type]) {
      fail = true
      return false
    }
  })

  return !fail
}

/**
 * @param {string} color1 First of two colours for contrast to be compared
 * @param {string} color2 Second of two colours for contrast to be compared
 * @param {array} types Determines pass/fail requirements: AA, AAA, AALarge, AAALarge
 * @returns Colour one either darkened colour on fail or original colour on pass.
 */
export const normaliseContrast = (color1, color2, types = ['AA']) => {
  const maxTries = 10
  let tries = 0

  while (!acceptableContrast(color1, color2, types) && tries <= maxTries) {
    color1 = darken(0.1, color1)
    tries ++
  }

  return color1
}

export const hexFromText = (text) => {
  if (!text) {
    return '#ffffff'
  }

  let hash = 0
  for (let i = 0; i < text.length; i ++) {
    hash = text.charCodeAt(i) + ((hash << 5) - hash)
  }

  let colour = '#'
  for (let i = 0; i < 3; i ++) {
    let value = (hash >> (i * 8)) & 0xFF
    colour += ('00' + value.toString(16)).slice(-2)
  }

  return colour
}
