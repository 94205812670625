import Cookies from 'js-cookie'
import { isEmpty, isFunction } from 'lodash-es'
import { useCallback, useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'

import { Button } from '@/Components/form/Buttons'
import { showCookieConsentAtom } from '@/Config/Atoms/CookieConsent'
import { googleAnalyticsScript, microsoftClarityScript } from '@/Config/TrackingScripts'

export default function CookieConsent() {
  const [cookieConsent, setCookieConsent] = useState(null)
  const [showCookieConsent, setShowCookieConsent] = useRecoilState(showCookieConsentAtom)

  useEffect(() => {
    const cookieConsentValue = Cookies.get('cookie_consent')

    setCookieConsent(cookieConsentValue)
  }, [])

  useEffect(() => {
    if (cookieConsent === 'accepted') {
      // Microsoft Clarity
      const microsoftClarityScriptElement = document.getElementById('microsoft-clarity-script')

      if (!microsoftClarityScriptElement) {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.id = 'microsoft-clarity-script'
        script.textContent = microsoftClarityScript

        document.head.appendChild(script)
      }

      // Google Analytics
      const trackingId = import.meta.env.VITE_GOOGLE_ANALYTICS_ID
      const googleAnalyticsScriptElement = document.getElementById('google-analytics-script')
      const googleAnalyticsConfigElement = document.getElementById('google-analytics-config')

      if (!googleAnalyticsScriptElement && trackingId) {
        const script = document.createElement('script')
        script.id = 'google-analytics-script'
        script.async = true
        script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`
        document.head.appendChild(script)
      }

      if (!googleAnalyticsConfigElement && trackingId) {
        const scriptConfig = document.createElement('script')
        scriptConfig.id = 'google-analytics-config'
        scriptConfig.textContent = googleAnalyticsScript(trackingId)
        document.head.appendChild(scriptConfig)
      }
    }

    if (cookieConsent === 'rejected') {
      // Microsoft Clarity
      const microsoftClarityElement = document.getElementById('microsoft-clarity-script')

      if (microsoftClarityElement) {
        microsoftClarityElement.remove()
      }

      // Google Analytics
      const googleAnalyticsScriptElement = document.getElementById('google-analytics-script')
      const googleAnalyticsConfigElement = document.getElementById('google-analytics-config')

      if (isFunction(window.gtag)) {
        window.gtag('consent', 'update', {
          ad_storage: 'denied',
          ad_user_data: 'denied',
          ad_personalization: 'denied',
          analytics_storage: 'denied',
        })
      }

      if (googleAnalyticsScriptElement) {
        googleAnalyticsScriptElement.remove()
      }

      if (googleAnalyticsConfigElement) {
        googleAnalyticsConfigElement.remove()
      }
    }
  }, [cookieConsent])

  const handleClick = useCallback((value) => {
    Cookies.set('cookie_consent', value, { expires: 365 })
    setCookieConsent(value)
    setShowCookieConsent(false)
  }, [])

  return (
    (isEmpty(cookieConsent) || showCookieConsent) && (
      <div className="fixed bottom-0 z-10000 flex w-screen justify-center border-t border-neutral-200 bg-neutral-50 p-6">
        <div className="flex w-full max-w-5xl flex-col text-sm md:flex-row">
          <div className="pr-4">
            By clicking <strong>"Accept"</strong>, you agree to the storing of cookies on your device to
            enhance site navigation, analyze site usage, and assist in our marketing efforts.
            View our <a className="underline decoration-blue-700 decoration-2 underline-offset-4" href="https://www.branif.com/legal/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a> for more information.
          </div>

          <div className="flex items-center">
            <div className="flex justify-end pt-5 md:pt-0">
              <Button
                className="transparent min-w-32"
                onClick={() => {
                  handleClick('rejected')
                }}
              >
                Deny
              </Button>

              <Button
                className="ml-2 min-w-32"
                onClick={() => {
                  handleClick('accepted')
                }}
              >
                Accept
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  )
}
