import { filter, map } from 'lodash-es'

import { formatKeys } from '@/Utilities/Form/Formatter'

export const getProfiles = async (apiClient, query = '') => {
  const { data } = await apiClient.get(`/profile/query?${query}`)

  if (data?.profiles?.data) {
    return formatKeys(data.profiles.data, 'camel')
  }

  return null
}

export const getProfileOptions = async (apiClient, query = '') => {
  const profiles = await getProfiles(apiClient, query)

  const hasChildren = filter(profiles, (profile) => {
    return profile.children.length
  })

  const noChildren = filter(profiles, (profile) => {
    return !profile.children.length
  })

  let options = map(hasChildren, (profile) => {
    return {
      label: profile.name,
      options: map(profile.children, (child) => {
        return {
          label: child.name,
          value: child.id,
          color: child.color,
        }
      }),
      color: profile.color,
    }
  })

  if (noChildren.length) {
    options.push({
      label: 'Uncategorized',
      options: map(noChildren, (profile) => {
        return {
          label: profile.name,
          value: profile.id,
          color: profile.color,
        }
      }),
    })
  }

  return options
}
