import { twMerge } from 'tailwind-merge'

import useSubComponents from '@/Utilities/useSubComponents'

/**
 * Primary Card component
 *
 * @param children
 * @param className
 * @param testId
 * @returns {JSX.Element}
 * @constructor
 */
export default function Card({
  children,
  className,
  testId,
}) {
  const subComponents = useSubComponents(children, [
    'header',
    'subHeader',
    'body',
    'footer',
  ])

  return (
    <section
      data-cy={testId}
      data-testid={testId}
      className={
        twMerge(
          'relative flex flex-col border border-slate-200 rounded-lg bg-white shadow-sm',
          className,
        )
      }
    >
      {subComponents.header}
      {subComponents.subHeader}
      {subComponents.body}
      {subComponents.footer}
    </section>
  )
}

/**
 * Header component
 *
 * @param children
 * @param className
 * @param titleClassName
 * @param title
 * @returns {JSX.Element}
 * @constructor
 */
const Header = ({
  children,
  className,
  titleClassName,
  title = false,
}) => {
  return (
    <header
      data-cy="header"
      data-testid="header"
      className={
        twMerge(
          'items-center rounded-t-lg border-b border-b-slate-200 py-4 px-6',
          className,
        )
      }
    >
      {title !== false && (
        <h4 className={twMerge('text-lg font-medium line-clamp-2', titleClassName)}>
          {title}
        </h4>
      )}
      {children}
    </header>
  )
}

Header.uniqueTagId = 'Header'
Card.Header = Header

/**
 * SubHeader component
 *
 * @param children
 * @param className
 * @returns {JSX.Element}
 * @constructor
 */
const SubHeader = ({
  children,
  className,
}) => {
  return (
    <div
      data-cy="subHeader"
      data-testid="subHeader"
      className={
        twMerge(
          'text-base border-b border-b-slate-200',
          className,
        )
      }>
      {children}
    </div>
  )
}

SubHeader.uniqueTagId = 'SubHeader'
Card.SubHeader = SubHeader

/**
 * Body component
 *
 * @param children
 * @param className
 * @returns {JSX.Element}
 * @constructor
 */
const Body = ({
  children,
  className,
}) => {
  return (
    <main
      data-cy="body"
      data-testid="body"
      className={
        twMerge(
          'flex-1 text-base',
          className,
        )
      }>
      {children}
    </main>
  )
}

Body.uniqueTagId = 'Body'
Card.Body = Body

/**
 * Footer component
 *
 * @param children
 * @param className
 * @returns {JSX.Element}
 * @constructor
 */
const Footer = ({
  children,
  className,
}) => {
  return (
    <div
      data-cy="footer"
      data-testid="footer"
      className={
        twMerge(
          'shrink-0 items-center rounded-b-lg border-t border-t-slate-200 text-base',
          className,
        )
      }
    >
      {children}
    </div>
  )
}

Footer.uniqueTagId = 'Footer'
Card.Footer = Footer
