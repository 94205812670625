const types = [
  'inputOutput.analogInput',
  'inputOutput.sensorSoilProbe',
  'inputOutput.analogOutput',
  'inputOutput.digitalInput',
  'inputOutput.digitalOutput',
  'inputOutput.analogFlowMeter',
  'inputOutput.digitalFlowMeter',
  'inputOutput.digitalOutputBooster',
  'inputOutput.digitalOutputElectricLock',
  'inputOutput.digitalOutputFountain',
  'inputOutput.digitalOutputGeneral',
  'inputOutput.digitalOutputLight',
  'inputOutput.digitalOutputValve',
]

export { types }
