import * as Sentry from '@sentry/react'
import { each, filter, find, get, includes, isEmpty, map, split } from 'lodash-es'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { useSetRecoilState } from 'recoil'

import AlertContent from '@/Components/alerts/AlertContent'
import { Anchor } from '@/Components/form/Buttons'
import Input from '@/Components/form/Input'
import InputError from '@/Components/form/InputError'
import LightSwitch from '@/Components/form/LightSwitch'
import RequiredAsterisk from '@/Components/form/RequiredAsterisk'
import Select from '@/Components/form/Select'
import { pageAlertState } from '@/Config/Atoms/General'
import { formatKeys, toOptions } from '@/Utilities/Form/Formatter'
import { unitOptions } from '@/Utilities/Units'

export default function Fertigation(props) {
  const [pumpOptions, setPumpOptions] = useState(null)
  const [setupLoading, setSetupLoading] = useState(false)
  const setAlert = useSetRecoilState(pageAlertState)

  const {
    setValue,
    control,
    register,
    errors,
    watch,
    fertigationPumpsFields,
    fertigationPumpsAppend,
    fertigationPumpsRemove,
    getValues,
    trigger,
  } = useFormContext()

  const fertigationPumps = watch('fertigationPumps')
  const fertigationBasis = useWatch({
    control,
    name: map(fertigationPumpsFields, (field, index) => {
      return `fertigationPumps.${index}.fertigationBasis`
    }),
  })
  const waterQuantityUnitOfMeasurement = watch('waterQuantityUnitOfMeasurement')
  const selectedSiteId = watch('siteId')
  const inputOutputIds = watch('inputOutputIds')

  const { mainLineAndAuxInputOutputs } = props

  const isEditing = props.data?.isEditing || false
  let program

  if (props.data?.program) {
    program = useMemo(() => {
      return formatKeys(props.data?.program, 'camel')
    }, [props.data.program])
  }

  const fertigationMethodOptions = useMemo(() => {
    return [{
      value: 'absolute',
      label: 'Absolute',
    }]
  }, [])

  const fertigationBasisOptions = useMemo(() => {
    return [{
      value: 'time',
      label: 'Time',
    }, {
      value: 'quantity',
      label: 'Quantity',
    }]
  }, [])

  const fertigationQuantityTimeUnitOfMeasurementOptions = useMemo(() => {
    return unitOptions('time')
  }, [])

  const fertigationQuantityUnitOfMeasurementOptions = useMemo(() => {
    return unitOptions(['gal', 'l'])
  }, [])

  const inputOutputFlowMeterOptions = useMemo(() => {
    return toOptions(props.inputOutputFlowMeterOptions, ['name', 'label'], ['id', 'value'])

  }, [props.inputOutputFlowMeterOptions])

  useEffect(() => {
    const currentTab = document.getElementById('fertigation')

    if (currentTab) {
      props.setTabErrors((prevState) => {
        return {
          ...prevState,
          fertigation: !!currentTab?.querySelector('.error-message'),
        }
      })
    }
  }, [errors, props.setTabErrors])

  const getPumps = useCallback(async () => {
    try {
      if (!isEmpty(mainLineAndAuxInputOutputs)) {
        // Pumps
        const filteredArray = filter(mainLineAndAuxInputOutputs, (inputOutput) => {
          return !includes(inputOutputIds, inputOutput.id) && !includes(props.selectedPumpIds, inputOutput.id)
        })

        const inputOutputBoosterOptions = map(filter(filteredArray, ['detailsType', 'inputOutput.digitalOutputBooster']), (inputOutput) => {
          return {
            value: inputOutput.id,
            label: inputOutput.name,
          }
        })

        setPumpOptions(inputOutputBoosterOptions)

        // Reset Existing Selection
        if (!find(inputOutputBoosterOptions, getValues('mainValveId'))) {
          setValue('mainValveId', null)
        }

        setSetupLoading(false)
      }
    } catch (error) {
      Sentry.captureException(error)
      setAlert({
        type: 'error',
        content: 'Failed to retrieve I/Os.',
      })
    }
  }, [
    mainLineAndAuxInputOutputs,
    inputOutputIds,
    props.selectedPumpIds,
  ])

  useEffect(() => {
    if (mainLineAndAuxInputOutputs) {
      getPumps()
    }
  }, [
    mainLineAndAuxInputOutputs,
    inputOutputIds,
    props.selectedPumpIds,
  ])

  useEffect(() => {
    if (isEditing && !isEmpty(pumpOptions) && !fertigationPumpsFields.length && props.selectedProgramSet) {
      each(program?.fertigationPumps, (pump, index) => {
        const existingPumpValue = program?.fertigationPumps?.[index]

        fertigationPumpsAppend({
          inputOutputId: '',
          fertigationFlowMeterId: '',
          fertigationBasis: '',
          fertigationMethod: '',
          fertigationQuantityUnitOfMeasurement: '',
          fertigationQuantity: '',
          fertigationWaterBefore: '',
          fertigationWaterAfter: '',
          fertigationDuration: '',
          stopIrrigationOnFertigationNoFlow: false,
        }, { focusName: 'fertigationPumps' })

        setValue(`fertigationPumps.${index}.inputOutputId`, find(pumpOptions, ['value', existingPumpValue?.inputOutputId]), { shouldValidate: true })
        setValue(`fertigationPumps.${index}.fertigationFlowMeterId`, find(inputOutputFlowMeterOptions, ['value', existingPumpValue?.fertigationFlowMeterId]), { shouldValidate: true })
        setValue(`fertigationPumps.${index}.fertigationBasis`, find(fertigationBasisOptions, ['value', existingPumpValue?.fertigationBasis]), { shouldValidate: true })
        setValue(`fertigationPumps.${index}.fertigationMethod`, find(fertigationMethodOptions, ['value', existingPumpValue?.fertigationMethod]), { shouldValidate: true })
        setValue(
          `fertigationPumps.${index}.fertigationQuantityUnitOfMeasurement`,
          existingPumpValue.fertigationBasis === 'time' ?
            find(fertigationQuantityTimeUnitOfMeasurementOptions, ['value', existingPumpValue?.fertigationQuantityUnitOfMeasurement]) :
            find(fertigationQuantityUnitOfMeasurementOptions, ['value', existingPumpValue?.fertigationQuantityUnitOfMeasurement]),
          { shouldValidate: true },
        )
        setValue(`fertigationPumps.${index}.fertigationQuantity`, existingPumpValue?.fertigationQuantity, { shouldValidate: true })
        setValue(`fertigationPumps.${index}.stopIrrigationOnFertigationNoFlow`, existingPumpValue?.stopIrrigationOnFertigationNoFlow, { shouldValidate: true })

        if (props.selectedProgramSet?.irrigationBasis == 'quantity') {
          setValue(`fertigationPumps.${index}.fertigationWaterBefore`, existingPumpValue?.fertigationWaterBefore, { shouldValidate: true })
          setValue(`fertigationPumps.${index}.fertigationWaterAfter`, existingPumpValue?.fertigationWaterAfter, { shouldValidate: true })
        }

        if (props.selectedProgramSet?.irrigationBasis == 'time') {
          const fertigationWaterBeforeTime = split(existingPumpValue?.fertigationWaterBeforeTime, ':')
          const fertigationWaterAfterTime = split(existingPumpValue?.fertigationWaterAfterTime, ':')

          setValue(`fertigationPumps.${index}.fertigationWaterBeforeTimeHours`, fertigationWaterBeforeTime[0], { shouldValidate: true })
          setValue(`fertigationPumps.${index}.fertigationWaterBeforeTimeMinutes`, fertigationWaterBeforeTime[1], { shouldValidate: true })
          setValue(`fertigationPumps.${index}.fertigationWaterAfterTimeHours`, fertigationWaterAfterTime[0], { shouldValidate: true })
          setValue(`fertigationPumps.${index}.fertigationWaterAfterTimeMinutes`, fertigationWaterAfterTime[1], { shouldValidate: true })
        }

        if (existingPumpValue.fertigationBasis === 'time') {
          const fertigationDuration = split(existingPumpValue?.fertigationDuration, ':')

          setValue(`fertigationPumps.${index}.fertigationDurationHours`, fertigationDuration[0], { shouldValidate: true })
          setValue(`fertigationPumps.${index}.fertigationDurationMinutes`, fertigationDuration[1], { shouldValidate: true })
          setValue(`fertigationPumps.${index}.fertigationDurationSeconds`, fertigationDuration[2], { shouldValidate: true })
        }
      })
    }
  }, [
    pumpOptions,
    program,
    props.selectedProgramSet,
    fertigationPumpsFields,
  ])

  return (
    <>
      {(selectedSiteId && !setupLoading) ? (
        <>
          {map(fertigationPumpsFields, (field, index) => {
            let existingPumpValue = null

            if (isEditing) {
              existingPumpValue = program?.fertigationPumps?.[index]
            }

            return <div key={`fertigationPumps-${index}`} className="relative mt-8 rounded border px-4 pb-4">
              <div className="flex justify-end">
                <div
                  className="absolute -right-3 -top-3 cursor-pointer border-slate-100 bg-white hover:text-red-500"
                  onClick={() => {
                    return fertigationPumpsRemove(index)
                  }}
                >
                  <i className="fa-sharp fa-solid fa-circle-xmark fa-xl"></i>
                </div>
              </div>

              <>
                <Controller
                  control={control}
                  defaultValue={find(pumpOptions, ['value', existingPumpValue?.inputOutputId])}
                  name={`fertigationPumps.${index}.inputOutputId`}
                  render={({ field }) => {
                    return (
                      <Select
                        {...field}
                        isMulti={false}
                        isSearchable={false}
                        isClearable={true}
                        label="Pump"
                        isRequired={true}
                        isLoading={setupLoading}
                        options={pumpOptions}
                        placeholder="Select"
                        afterChange={(option) => {
                          if (!option) {
                            setValue(`fertigationPumps.${index}.inputOutputId`, '')
                            trigger(`fertigationPumps.${index}`)
                          }
                        }}
                        hasError={!!errors.fertigationPumps?.[index]?.inputOutputId}
                        noOptionsMessage={() => {
                          return 'There are no pumps available.'
                        }}
                      />
                    )
                  }}
                />
                {errors.fertigationPumps?.[index]?.inputOutputId && (
                  <InputError message={errors.fertigationPumps?.[index].inputOutputId.message}/>
                )}
              </>

              {(!!fertigationPumps?.[index]?.inputOutputId) && (
                <>
                  <div className="grid grid-cols-2 gap-6">
                    <div>
                      <Controller
                        control={control}
                        defaultValue={find(fertigationBasisOptions, ['value', program?.fertigationBasis])}
                        name={`fertigationPumps.${index}.fertigationBasis`}
                        render={({ field }) => {
                          return (
                            <Select
                              {...field}
                              isMulti={false}
                              isSearchable={true}
                              label="Fert basis"
                              isRequired={true}
                              options={fertigationBasisOptions}
                              placeholder="Search"
                              afterChange={(option) => {
                                setValue(`fertigationPumps.${index}.fertigationBasis`, option, { shouldValidate: true })
                                setValue(`fertigationPumps.${index}.fertigationQuantityUnitOfMeasurement`, '')
                                setValue(`fertigationPumps.${index}.fertigationQuantity`, '')
                                setValue(`fertigationPumps.${index}.fertigationDurationHours`, '')
                                setValue(`fertigationPumps.${index}.fertigationDurationMinutes`, '')
                                setValue(`fertigationPumps.${index}.fertigationDurationSeconds`, '')
                              }}
                              hasError={!!errors.fertigationPumps?.[index]?.fertigationBasis}
                            />
                          )
                        }}
                      />
                      {errors.fertigationPumps?.[index]?.fertigationBasis && (
                        <InputError message={errors.fertigationPumps?.[index].fertigationBasis.message}/>
                      )}
                    </div>

                    <div>
                      <Controller
                        control={control}
                        defaultValue={find(fertigationMethodOptions, ['value', program?.fertigationMethod])}
                        name={`fertigationPumps.${index}.fertigationMethod`}
                        render={({ field }) => {
                          return (
                            <Select
                              {...field}
                              isMulti={false}
                              isSearchable={true}
                              label="Fert method"
                              isRequired={true}
                              options={fertigationMethodOptions}
                              placeholder="Search"
                              hasError={!!errors.fertigationPumps?.[index]?.fertigationMethod}
                            />
                          )
                        }}
                      />
                      {errors.fertigationPumps?.[index]?.fertigationMethod && (
                        <InputError message={errors.fertigationPumps?.[index].fertigationMethod.message}/>
                      )}
                    </div>

                    {
                      get(fertigationPumps, `${index}.fertigationBasis.value`) &&
                      props.selectedProgramSet.irrigationBasis !== get(fertigationPumps, `${index}.fertigationBasis.value`) && (
                        <div className="col-span-2">
                          <AlertContent title="Irrigation and Fertigation Basis" hideIcon={false} type="warning">
                            The fertigation program currently does not match the irrigation basis of the program.
                            Please verify that the fertigation settings are correctly configured to prevent potential
                            fertiliser debt.
                          </AlertContent>
                        </div>
                      )
                    }
                  </div>

                  <>
                    <Controller
                      control={control}
                      defaultValue={find(inputOutputFlowMeterOptions, ['value', existingPumpValue?.fertigationFlowMeterId])}
                      name={`fertigationPumps.${index}.fertigationFlowMeterId`}
                      render={({ field }) => {
                        return (
                          <Select
                            {...field}
                            isMulti={false}
                            isSearchable={false}
                            isClearable={true}
                            label="Flow meter"
                            isRequired={true}
                            isLoading={setupLoading}
                            options={inputOutputFlowMeterOptions}
                            placeholder="Select"
                            afterChange={(option) => {
                              if (!option) {
                                setValue(`fertigationPumps.${index}.fertigationFlowMeterId`, '')
                                trigger(`fertigationPumps.${index}`)
                              }
                            }}
                            hasError={!!errors.fertigationPumps?.[index]?.fertigationFlowMeterId}
                            noOptionsMessage={() => {
                              return 'There are no flow meters available.'
                            }}
                          />
                        )
                      }}
                    />
                    {errors.fertigationPumps?.[index]?.fertigationFlowMeterId && (
                      <InputError message={errors.fertigationPumps?.[index].fertigationFlowMeterId.message}/>
                    )}
                  </>

                  {fertigationBasis[index]?.value === 'quantity' && (
                    <div className="grid grid-cols-2 gap-6">
                      <div>
                        <Controller
                          control={control}
                          name={`fertigationPumps.${index}.fertigationQuantityUnitOfMeasurement`}
                          render={({ field }) => {
                            return (
                              <Select
                                {...field}
                                isMulti={false}
                                isSearchable={true}
                                label="Unit of measure"
                                isRequired={true}
                                options={fertigationBasis[index]?.value === 'time' ? fertigationQuantityTimeUnitOfMeasurementOptions : fertigationQuantityUnitOfMeasurementOptions}
                                placeholder="Search"
                                hasError={!!errors.fertigationPumps?.[index]?.fertigationQuantityUnitOfMeasurement}
                              />
                            )
                          }}
                        />
                        {errors.fertigationPumps?.[index]?.fertigationQuantityUnitOfMeasurement && (
                          <InputError message={errors.fertigationPumps?.[index].fertigationQuantityUnitOfMeasurement.message}/>
                        )}
                      </div>

                      <div>
                        <Input
                          label="Fert quantity"
                          isRequired={true}
                          type="number"
                          min="1"
                          max="100000"
                          className={errors.fertigationPumps?.[index]?.fertigationQuantity && 'error'}
                          {...register(`fertigationPumps.${index}.fertigationQuantity`, { value: program?.fertigationQuantity })}
                        />
                        {errors.fertigationPumps?.[index]?.fertigationQuantity && (
                          <InputError message={errors.fertigationPumps?.[index].fertigationQuantity.message}/>
                        )}
                      </div>
                    </div>
                  )}

                  {fertigationBasis[index]?.value === 'time' && (
                    <div className="grid grid-cols-2 gap-6">
                      <div>
                        <div className="mb-2 mt-5 flex text-sm text-dark">Fert duration <RequiredAsterisk/></div>

                        <div className="flex items-center">
                          <Input
                            placeholder="hh"
                            maxlength="2"
                            type="number"
                            width="60px"
                            textCenter
                            className={errors.fertigationPumps?.[index]?.fertigationDurationHours && 'error'}
                            {...register(`fertigationPumps.${index}.fertigationDurationHours`, { value: existingPumpValue?.fertigationDurationHours })}
                          />

                          <div className="mx-2">
                            :
                          </div>

                          <Input
                            placeholder="mm"
                            maxlength="2"
                            type="number"
                            width="60px"
                            textCenter
                            className={errors.fertigationPumps?.[index]?.fertigationDurationMinutes && 'error'}
                            {...register(`fertigationPumps.${index}.fertigationDurationMinutes`, { value: existingPumpValue?.fertigationDurationMinutes })}
                          />

                          <div className="mx-2">
                            :
                          </div>

                          <Input
                            placeholder="ss"
                            maxlength="2"
                            type="number"
                            width="60px"
                            textCenter
                            className={errors.fertigationPumps?.[index]?.fertigationDurationSeconds && 'error'}
                            {...register(`fertigationPumps.${index}.fertigationDurationSeconds`, { value: existingPumpValue?.fertigationDurationSeconds })}
                          />
                        </div>

                        {
                          (errors.fertigationPumps?.[index]?.fertigationDurationHours || errors.fertigationPumps?.[index]?.fertigationDurationMinutes || errors.fertigationPumps?.[index]?.fertigationDurationSeconds) && (
                            <InputError message="Fert duration is a required field"/>
                          )
                        }
                      </div>
                    </div>
                  )}

                  {props?.selectedProgramSet?.irrigationBasis == 'time' ? (
                    <div className="grid grid-cols-2 gap-6">
                      <div>
                        <div className="mb-2 mt-5 flex text-sm text-dark">Water before fert <RequiredAsterisk/></div>

                        <div className="mb-2 flex items-center">
                          <Input
                            placeholder="hh"
                            maxlength="2"
                            type="number"
                            width="60px"
                            textCenter
                            className={errors.fertigationPumps?.[index]?.fertigationWaterBeforeTimeHours && 'error'}
                            {...register(`fertigationPumps.${index}.fertigationWaterBeforeTimeHours`, { value: existingPumpValue?.fertigationWaterBeforeTimeHours })}
                          />

                          <div className="mx-2">
                            :
                          </div>

                          <Input
                            placeholder="mm"
                            maxlength="2"
                            type="number"
                            width="60px"
                            textCenter
                            className={errors.fertigationPumps?.[index]?.fertigationWaterBeforeTimeMinutes && 'error'}
                            {...register(`fertigationPumps.${index}.fertigationWaterBeforeTimeMinutes`, { value: existingPumpValue?.fertigationWaterBeforeTimeMinutes })}
                          />
                        </div>

                        {
                          (errors.fertigationPumps?.[index]?.fertigationWaterBeforeTimeHours || errors.fertigationPumps?.[index]?.fertigationWaterBeforeTimeMinutes) && (
                            <InputError message="Water before fert is a required field"/>
                          )
                        }
                      </div>

                      <div>
                        <div className="mb-2 mt-5 flex text-sm text-dark">Water after fert <RequiredAsterisk/></div>

                        <div className="mb-2 flex items-center">
                          <Input
                            placeholder="hh"
                            maxlength="2"
                            type="number"
                            width="60px"
                            textCenter
                            className={errors.fertigationPumps?.[index]?.fertigationWaterAfterTimeHours && 'error'}
                            {...register(`fertigationPumps.${index}.fertigationWaterAfterTimeHours`, { value: existingPumpValue?.fertigationWaterAfterTimeHours })}
                          />

                          <div className="mx-2">
                            :
                          </div>

                          <Input
                            placeholder="mm"
                            maxlength="2"
                            type="number"
                            width="60px"
                            textCenter
                            className={errors.fertigationPumps?.[index]?.fertigationWaterAfterTimeMinutes && 'error'}
                            {...register(`fertigationPumps.${index}.fertigationWaterAfterTimeMinutes`, { value: existingPumpValue?.fertigationWaterAfterTimeMinutes })}
                          />
                        </div>

                        {
                          (errors.fertigationPumps?.[index]?.fertigationWaterAfterTimeHours || errors.fertigationPumps?.[index]?.fertigationWaterAfterTimeMinutes) && (
                            <InputError message="Water after fert is a required field"/>
                          )
                        }
                      </div>
                    </div>
                  ) : null}

                  {props?.selectedProgramSet?.irrigationBasis == 'quantity' ? (
                    <div className="grid grid-cols-2 gap-6">
                      <div>
                        <Input
                          label={`Water before fert ${waterQuantityUnitOfMeasurement?.value ? `(in ${waterQuantityUnitOfMeasurement?.value})` : ''}`}
                          isRequired={true}
                          type="number"
                          className={errors.fertigationPumps?.[index]?.fertigationWaterBefore && 'error'}
                          {...register(`fertigationPumps.${index}.fertigationWaterBefore`, { value: existingPumpValue?.fertigationWaterBefore })}
                        />
                        {errors.fertigationPumps?.[index]?.fertigationWaterBefore &&
                          <InputError message={errors.fertigationPumps?.[index].fertigationWaterBefore.message}/>}
                      </div>

                      <div>
                        <Input
                          label={`Water after fert ${waterQuantityUnitOfMeasurement?.value ? `(in ${waterQuantityUnitOfMeasurement?.value})` : ''}`}
                          isRequired={true}
                          type="number"
                          className={errors.fertigationPumps?.[index]?.fertigationWaterAfter && 'error'}
                          {...register(`fertigationPumps.${index}.fertigationWaterAfter`, { value: existingPumpValue?.fertigationWaterAfter })}
                        />
                        {errors.fertigationPumps?.[index]?.fertigationWaterAfter &&
                          <InputError message={errors.fertigationPumps?.[index].fertigationWaterAfter.message}/>}
                      </div>
                    </div>
                  ) : null}

                  <LightSwitch
                    label="Stop irrigation on fert no flow"
                    onToggle={(name, value) => {
                      setValue(`fertigationPumps.${index}.stopIrrigationOnFertigationNoFlow`, value)
                    }}
                    defaultState={existingPumpValue?.stopIrrigationOnFertigationNoFlow ? true : false}
                    {...register(`fertigationPumps.${index}.stopIrrigationOnFertigationNoFlow`, { value: existingPumpValue?.stopIrrigationOnFertigationNoFlow || false })}
                  />
                </>
              )}
            </div>
          })}

          {fertigationPumpsFields?.length < 10 && (
            <div
              className="mt-6 inline-block hover:cursor-pointer"
              onClick={() => {
                fertigationPumpsAppend({
                  inputOutputId: '',
                  fertigationFlowMeterId: '',
                  fertigationBasis: '',
                  fertigationMethod: '',
                  fertigationQuantityUnitOfMeasurement: '',
                  fertigationQuantity: '',
                  fertigationWaterBefore: '',
                  fertigationWaterAfter: '',
                  fertigationDuration: '',
                  stopIrrigationOnFertigationNoFlow: false,
                }, { focusName: 'fertigationPumps' })
              }}
            >
              <Anchor className="transparent mt-2">
                <i className="fa-solid fa-plus"></i> {fertigationPumpsFields.length ? 'Add another pump' : 'Add pump'}
              </Anchor>
            </div>
          )}
        </>
      ) : (
        <div className="border-light-grey mt-4 rounded border p-3 text-center">
          {setupLoading ? 'Loading...' : 'Please select a program set to add fertigation pumps'}
        </div>
      )}
    </>
  )
}
