import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/react'
import { darken, rem } from 'polished'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { atom, useRecoilState } from 'recoil'
import styled from 'styled-components'
import * as yup from 'yup'

import AuthAlert from '@/Components/AuthAlert'
import { Button } from '@/Components/form/Buttons'
import InputField from '@/Components/form/Input'
import InputError from '@/Components/form/InputError'
import { authAlertState } from '@/Config/Atoms/Auth'
import useApiClient from '@/Utilities/useApiClient'
import useTitle from '@/Utilities/useTitle'

const Icon = styled.div`
  align-items: center;
  background-color: #f5fbff;
  border-radius: 100%;
  color: var(--primary);
  display: flex;
  height: 56px;
  justify-content: center;
  width: 56px;
`

const ErrorContainter = styled.div`
  margin-bottom: 26px;
`

const Title = styled.h1`
  font-size: ${rem(30)};
  font-weight: 700;
  margin-top: 24px;
`

const SubText = styled.div`
  font-size: ${rem(16)};
  font-weight: 300;
  margin: 12px 0;
`

const SmallerText = styled.div`
  text-align: center;

  button {
    background-color: transparent;
    border: none;
    color: var(--primary);

    &:hover {
      color: ${darken(0.2, '#1570ef')};
      cursor: pointer;
    }
  }
`

const Form = styled.form`
  margin: auto 0;
  width: 360px;
  max-width: 100%;

    label {
        margin-top: 20px;
    }

    button {
    margin-top: 24px;
  }
`

const BackText = styled.div`
  color: var(--primary);
  margin: 32px auto;
  text-align: center;

  a {
    text-decoration: none;
  }
`

const emailSentState = atom({
  key: 'emailSent',
  default: false,
})

const schema = yup.object({ email: yup.string().label('Email address').email().required() })

function ForgotPassword() {
  const apiClient = useApiClient()
  const [emailSent, setEmailSent] = useRecoilState(emailSentState)
  const [alert, setAlert] = useRecoilState(authAlertState)
  const {
    watch,
    register,
    handleSubmit,
    formState,
  } = useForm({ resolver: yupResolver(schema) })
  const { errors } = formState

  const email = watch('email')

  useTitle('Forgot password')

  const sendReset = async (data) => {
    setAlert(null)

    try {
      let { data: responseData } = await apiClient.post('/user/reset-password/send', { ...data })

      if (responseData?.success) {
        setEmailSent(true)
      }
    } catch (error) {
      setAlert({ message: 'Oops, something went wrong. Please try again.' })
      Sentry.captureException(error)
    }
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="flex-column col-12 flex items-center">
          {!emailSent && (
            <Form onSubmit={handleSubmit(sendReset)} autoComplete="off">
              <Title>Forgot password?</Title>
              <SubText className="text-slate-500">No worries, we'll send you reset instructions.</SubText>

              <ErrorContainter>
                <AuthAlert class={alert ? 'flex' : 'd-none'} message={alert?.message} />
              </ErrorContainter>

              <InputField
                label="Email"
                placeholder="Enter your email"
                type="email"
                className={errors.email && 'error'}
                {...register('email')}
              />
              {errors.email && <InputError message={errors.email.message} />}

              <Button
                style={{ width: '100%' }}
              >
                Reset password
              </Button>

              <BackText>
                <Link to="/user/login"><i className="fa-solid fa-arrow-left"></i> Back to log in</Link>
              </BackText>
            </Form>
          )}

          {emailSent && (
            <div className="grid grid-cols-1">
              <div className="col-12 flex justify-center">
                <Icon>
                  <i className="fa-solid fa-key-skeleton"></i>
                </Icon>
              </div>

              <Title>Check your email</Title>
              <SubText>We sent a password reset link to {email}</SubText>

              <SmallerText>Didn't receive the email? <button onClick={handleSubmit(sendReset)}>Click to resend</button></SmallerText>

              <BackText>
                <Link to="/user/login"><i className="fa-solid fa-arrow-left"></i> Back to log in</Link>
              </BackText>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
