import classNames from 'classnames'
import { filter, find, get, isEmpty, split } from 'lodash-es'
import { useEffect, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import Input from '@/Components/form/Input'
import InputError from '@/Components/form/InputError'
import InputLabel from '@/Components/form/InputLabel'
import LightSwitch from '@/Components/form/LightSwitch'
import RequiredAsterisk from '@/Components/form/RequiredAsterisk'
import Select from '@/Components/form/Select'
import { toOptions } from '@/Utilities/Form/Formatter'

function FlowControl(props) {
  const {
    siteInputOutputs,
    data,
  } = props

  const {
    mainLine,
  } = useMemo(() => {
    return { mainLine: props.data?.mainLine }
  }, [data?.mainLine])

  const inputOutputOptions = useMemo(() => {
    return toOptions(siteInputOutputs, ['name', 'label'], ['id', 'value'], ['type'])
  }, [siteInputOutputs])

  const dpSwitchOptions = useMemo(() => {
    return filter(inputOutputOptions, ['type', 'digitalInput'])
  }, [inputOutputOptions])

  const {
    maximumSuspendTimeMinutes,
    maximumSuspendTimeSeconds,
  } = useMemo(() => {
    const timeParts = split(mainLine?.maximumSuspendTime, ':')

    return {
      maximumSuspendTimeMinutes: get(timeParts, '[1]'),
      maximumSuspendTimeSeconds: get(timeParts, '[2]'),
    }
  }, [mainLine?.maximumSuspendTime])

  const {
    control,
    errors,
    register,
    setValue,
    watch,
  } = useFormContext()

  const suspendFlowControlWhenFlushingEnabled = watch('suspendFlowControlWhenFlushing')
  const topPressureEnabled = watch('topPressure')
  const bottomPressureEnabled = watch('bottomPressure')
  const selectedSiteId = watch('siteId')

  useEffect(() => {
    const currentTab = document.getElementById('flowControl')

    if (currentTab) {
      props.setTabErrors((prevState) => {
        return {
          ...prevState,
          flowControl: !!currentTab.querySelector('.error-message'),
        }
      })
    }
  }, [errors, props.setTabErrors])

  return (
    <div className="mt-6">
      <LightSwitch
        label="Suspend flow control when flushing"
        name="suspendFlowControlWhenFlushing"
        onToggle={(name, value) => {
          setValue('suspendFlowControlWhenFlushing', value)

          if (!value) {
            setValue('maximumSuspendTimeMinutes', null)
            setValue('maximumSuspendTimeSeconds', null)
            setValue('maximumSuspendCycles', null)
          }
        }}
        defaultState={(mainLine?.suspendFlowControlWhenFlushing ? true : suspendFlowControlWhenFlushingEnabled) || false}
        {...register('suspendFlowControlWhenFlushing', { value: mainLine?.suspendFlowControlWhenFlushing || false })}
      />

      <div className={classNames({ hidden: !suspendFlowControlWhenFlushingEnabled })}>
        <div className="col-12 col-md-3 col-lg-3">
          <InputLabel>
            Maximum suspend time
            <RequiredAsterisk />
          </InputLabel>

          <div className="flex items-center justify-start">
            <Input
              placeholder="mm"
              maxlength="2"
              type="number"
              width="60px"
              textCenter
              className={classNames(errors.maximumSuspendTimeMinutes && 'error')}
              {...register('maximumSuspendTimeMinutes', { value: maximumSuspendTimeMinutes })}
            />

            <div className="mx-3">
              :
            </div>

            <Input
              placeholder="ss"
              maxlength="2"
              type="number"
              width="60px"
              textCenter
              className={classNames(errors.maximumSuspendTimeSeconds && 'error')}
              {...register('maximumSuspendTimeSeconds', { value: maximumSuspendTimeSeconds })}
            />
          </div>
        </div>

        {
          (
            (
              errors.maximumSuspendTimeMinutes?.type === 'min' ||
                errors.maximumSuspendTimeMinutes?.type === 'max' ||
                errors.maximumSuspendTimeSeconds?.type === 'min' ||
                errors.maximumSuspendTimeSeconds?.type === 'max'
            ) && (
              <InputError message="Maximum suspend time should be between 00:00 and 59:59" />
            )
          ) ||
          (
            (errors.maximumSuspendTimeMinutes || errors.maximumSuspendTimeSeconds) &&
            <InputError message="Maximum suspend time is a required field" />
          )
        }

        <Input
          label="Maximum suspend cycles"
          isRequired={true}
          type="text"
          className={errors.maximumSuspendCycles && 'error'}
          {...register('maximumSuspendCycles', { value: mainLine?.maximumSuspendCycles })}
        />
        {errors.maximumSuspendCycles && <InputError message={errors.maximumSuspendCycles.message} />}

        <div>
          <Controller
            control={control}
            defaultValue={find(dpSwitchOptions, ['value', mainLine?.dpSwitchInputId]) || ''}
            name="dpSwitchInputId"
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  isRequired={true}
                  isMulti={false}
                  isSearchable={true}
                  label="DP switch input"
                  options={dpSwitchOptions}
                  placeholder="Search"
                  isClearable={true}
                  afterChange={(option) => {
                    if (!option) {
                      setValue('dpSwitchInputId', null, { shouldValidate: true })
                    }
                  }}
                  hasError={!!errors.dpSwitchInputId}
                  noOptionsMessage={() => {
                    return selectedSiteId ? 'There are no options for the selected site.' : 'Select a site to see available options.'
                  }}
                />
              )
            }}
          />
          {errors.dpSwitchInputId && <InputError message={errors.dpSwitchInputId.message} />}
        </div>
      </div>

      <LightSwitch
        label="Top pressure enabled"
        name="topPressure"
        wrapperClassNames="mt-6"
        onToggle={(name, value) => {
          setValue('topPressure', value)

          if (!value) {
            setValue('topPressureInputId', null)
            setValue('topPressureValue', null)
          }
        }}
        defaultState={(mainLine?.topPressure ? true : topPressureEnabled) || false}
        {...register('topPressure', { value: mainLine?.topPressure || false })}
      />

      {!isEmpty(inputOutputOptions) && (
        <div className={classNames({ hidden: !topPressureEnabled }, 'grid grid-cols-1 gap-0 md:grid-cols-2 md:gap-4')}>
          <div>
            <Controller
              control={control}
              defaultValue={find(inputOutputOptions, ['value', mainLine?.topPressureInputId]) || ''}
              name="topPressureInputId"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    isRequired={true}
                    isMulti={false}
                    isSearchable={true}
                    label="Top pressure input"
                    isLoading={props.inputOutputValvesLoading}
                    options={inputOutputOptions}
                    placeholder="Search"
                    isClearable={true}
                    afterChange={(option) => {
                      if (!option) {
                        setValue('topPressureInputId', null, { shouldValidate: true })
                      }
                    }}
                    hasError={!!errors.topPressureInputId}
                    noOptionsMessage={() => {
                      return selectedSiteId ? 'There are no options for the selected site.' : 'Select a site to see available options.'
                    }}
                  />
                )
              }}
            />
            {errors.topPressureInputId && <InputError message={errors.topPressureInputId.message} />}
          </div>

          <div>
            <Input
              label="Top pressure value"
              isRequired={true}
              type="text"
              className={errors.topPressureValue && 'error'}
              {...register('topPressureValue', { value: mainLine?.topPressureValue })}
            />
            {errors.topPressureValue && <InputError message={errors.topPressureValue.message} />}
          </div>
        </div>
      )}

      <LightSwitch
        label="Bottom pressure enabled"
        name="bottomPressure"
        wrapperClassNames="mt-6"
        onToggle={(name, value) => {
          setValue('bottomPressure', value)

          if (!value) {
            setValue('bottomPressureInputId', null)
            setValue('bottomPressureValue', null)
          }
        }}
        defaultState={(mainLine?.bottomPressure ? true : bottomPressureEnabled) || false}
        {...register('bottomPressure', { value: mainLine?.bottomPressure || false })}
      />

      {!isEmpty(inputOutputOptions) && (
        <div className={classNames({ hidden: !bottomPressureEnabled }, 'grid grid-cols-1 gap-0 md:grid-cols-2 md:gap-4')}>
          <div>
            <Controller
              control={control}
              defaultValue={find(inputOutputOptions, ['value', mainLine?.bottomPressureInputId]) || ''}
              name="bottomPressureInputId"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    isRequired={true}
                    isMulti={false}
                    isSearchable={true}
                    label="Bottom pressure input"
                    isLoading={props.inputOutputValvesLoading}
                    options={inputOutputOptions}
                    placeholder="Search"
                    isClearable={true}
                    afterChange={(option) => {
                      if (!option) {
                        setValue('bottomPressureInputId', null, { shouldValidate: true })
                      }
                    }}
                    hasError={!!errors.bottomPressureInputId}
                    noOptionsMessage={() => {
                      return selectedSiteId ? 'There are no options for the selected site.' : 'Select a site to see available options.'
                    }}
                  />
                )
              }}
            />
            {errors.bottomPressureInputId && <InputError message={errors.bottomPressureInputId.message} />}
          </div>

          <div>
            <Input
              label="Bottom pressure value"
              isRequired={true}
              type="text"
              className={errors.bottomPressureValue && 'error'}
              {...register('bottomPressureValue', { value: mainLine?.bottomPressureValue })}
            />
            {errors.bottomPressureValue && <InputError message={errors.bottomPressureValue.message} />}
          </div>
        </div>
      )}
    </div>
  )
}

export default FlowControl
