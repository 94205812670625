import { latLngBounds } from 'leaflet'
import { marker } from 'leaflet'
import { each, filter, find, forEach, isArray, isFunction, isNull, map, reduce, reverse, split } from 'lodash-es'
import { useMemo } from 'react'

import { mapIcons } from '@/Utilities/Icons/InputOutputIcons'

export const addBlock = (block, drawingLayer, onClick = false) => {
  if (!block || !drawingLayer) {
    return false
  }

  drawingLayer.addData(block)

  let layer = find(drawingLayer.getLayers(), (layer) => {
    return layer.feature.properties.uuid === block.properties.uuid
  })

  if (layer.feature.properties.title) {
    layer.bindTooltip(layer.feature.properties.title, {
      permanent: true,
      direction: 'center',
      opacity: 1,
      className: 'flex w-max max-w-48 items-center justify-center whitespace-normal rounded border border-slate-400 bg-slate-700 px-4 py-2 text-center font-bold text-slate-200',
    }).openTooltip()
  }

  if (isFunction(onClick)) {
    layer.on('click', (event) => {
      onClick(event)
    })
  }
}

export const addBlocks = (options) => {
  let {
    blocks,
    drawingLayer,
    clearLayer = false,
    withBounds = false,
    onClick = false,
  } = options

  if (clearLayer && drawingLayer) {
    drawingLayer.clearLayers()
  }

  if (!isArray(blocks)) {
    blocks = [blocks]
  }

  let bounds = latLngBounds()

  forEach(blocks, (block) => {
    if (withBounds) {
      forEach(block?.geometry?.coordinates, (coordinates) => {
        forEach(coordinates, (coordinate) => {
          // Swap coordinates to extends bounds properly
          // GeoJSON standard requires coordinates to be swapped (lng, lat)
          bounds.extend(reverse([...coordinate]))
        })
      })
    }

    addBlock(block, drawingLayer, onClick)
  })

  return { bounds: withBounds ? bounds : null }
}

export const addMarker = (layer, _marker) => {
  const leafletMarker = marker([_marker.lat, _marker.lng], _marker.options)
  leafletMarker.addTo(layer)
}

export const addMarkers = (layer, markers) => {
  each(markers, (marker) => {
    addMarker(layer, marker)
  })
}

export const reduceToBlocks = (blocks, includeTitle = false) => {
  return useMemo(() => {
    const reduced = reduce(blocks, (accumulatedBlocks, block) => {
      const {
        id,
        geoJson,
      } = block

      const {
        geoJson: {
          properties: {
            title,
            ...otherProperties
          },
        },
      } = block

      return [...accumulatedBlocks, {
        ...geoJson,
        properties: {
          ...otherProperties,
          ...(includeTitle ? { title } : {}),
          id,
        },
      }]
    }, [])

    return reduced
  }, blocks)
}

export const mapInputOutputsToMarkers = (inputOutputs, dependencies) => {
  return useMemo(() => {
    // Exclude inputOutputs without lat/lng
    const filtered = filter(inputOutputs, (io) => {
      return !isNull(io.lat) && !isNull(io.lng)
    })

    return map(filtered, (io) => {
      const iconKey = split(io.detailsType, '.')?.[1]

      return {
        lat: io.lat,
        lng: io.lng,
        options: { icon: iconKey ? mapIcons[iconKey]({ title: io.name }) : undefined },
      }
    })
  }, dependencies)
}
