import * as Sentry from '@sentry/react'
import { layerGroup, marker } from 'leaflet'
import { each, isEmpty, isNull, map, reject, replace, startCase } from 'lodash-es'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import styled from 'styled-components'

import Map from '@/Components/Map'
import Tabs from '@/Components/Tabs'
import { pageAlertState } from '@/Config/Atoms/General'
import { gpsCoordinatesCanSaveState, gpsCoordinatesState } from '@/Config/Atoms/InputOutput'
import InputOutputManageCoordinates from '@/Pages/inputOutput/manage/InputOutputManageCoordinates'
import InputOutputManageDetailsCard from '@/Pages/inputOutput/manage/InputOutputManageDetailsCard'
import { standardActions } from '@/Utilities/Events'
import { formatKeys } from '@/Utilities/Form/Formatter'
import { mapIcons } from '@/Utilities/Icons/InputOutputIcons'
import { addBlocks } from '@/Utilities/Map/Map'
import useApiClient from '@/Utilities/useApiClient'
import useAuth from '@/Utilities/useAuth'
import useEventSubscriber from '@/Utilities/useEventSubscriber'
import usePageGuard from '@/Utilities/usePageGuard'
import useTitle from '@/Utilities/useTitle'

const PageContainer = styled.div`
  padding: 0 24px;
  width: 100%;
`

const DetailContainer = styled.div`
  .button-container {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
  }
`

const MapContainer = styled.div`
  display: flex;
  height: calc(100vh - 200px);
  justify-content: center;
  position: relative;
`

function InputOutputManage(props) {
  const apiClient = useApiClient()
  const auth = useAuth()

  const [mapInitialized, setMapInitialized] = useState(false)
  const [inputOutput, setInputOutput] = useState({})
  const [siteInputOutputs, setSiteInputOutputs] = useState({})
  const [firstLoad, setFirstLoad] = useState(true)
  const [showAdditionalMarkers, setShowAdditionalMarkers] = useState(false)
  const mapInstance = useRef(null)
  const navigate = useNavigate()
  const originalGpsCoordinates = useRef()
  const setGpsCoordinates = useSetRecoilState(gpsCoordinatesState)
  const setCanSaveCoordinates = useSetRecoilState(gpsCoordinatesCanSaveState)
  const setAlert = useSetRecoilState(pageAlertState)
  const urlParams = useParams()

  const {
    manageCoordinates,
    manageBlocks,
  } = props

  const { setSubscriberModelMap } = useEventSubscriber(['inputOutput'], standardActions, () => {
    getInputOutput()
  })

  usePageGuard({
    auth,
    permission: 'view-input-output',
  })

  useTitle(['Managing I/O', inputOutput?.name])

  useEffect(() => {
    if (mapInstance?.current?.map) {
      getInputOutput()
    }
  }, [mapInitialized])

  useEffect(() => {
    if (firstLoad && !isEmpty(inputOutput) && isEmpty(siteInputOutputs)) {
      getOtherInputOutputs(inputOutput)
      setFirstLoad(false)
    }
  }, [inputOutput])

  useEffect(() => {
    if (inputOutput.lat && inputOutput.lng) {
      addMarker(inputOutput.lat, inputOutput.lng, true, true)
    } else {
      clearMarkers()
    }
  }, [
    inputOutput.lat,
    inputOutput.lng,
    manageCoordinates,
  ])

  const mapInit = (map) => {
    mapInstance.current = map
    mapInstance.current.groups = {
      marker: layerGroup(),
      additionalMarkers: layerGroup(),
    }
    setMapInitialized(true)
  }

  useEffect(() => {
    if (showAdditionalMarkers && manageCoordinates) {
      mapInstance.current.groups.additionalMarkers.addTo(mapInstance.current.map)
    } else {
      mapInstance.current.groups.additionalMarkers.remove()
    }
  }, [showAdditionalMarkers, manageCoordinates])

  const toggleAdditionalMarkers = useCallback(() => {
    if (showAdditionalMarkers) {
      setShowAdditionalMarkers(false)
    } else {
      setShowAdditionalMarkers(true)
    }
  }, [showAdditionalMarkers])

  const clearMarkers = useCallback(() => {
    mapInstance.current.groups.marker.clearLayers()
  }, [mapInstance])

  const addMarker = useCallback((lat, lng, shouldClear = false, shouldPan = false, siteInputOutput = null) => {
    const createMarker = (inputOutput, isMainMarker = false) => {
      const createIcon = mapIcons[replace(inputOutput.detailsType, 'inputOutput.', '')]

      let mapMarker = marker([lat, lng], {
        icon: createIcon({ title: inputOutput.name }),
        draggable: isMainMarker && manageCoordinates,
      }).on('dragend', (event) => {
        if (isMainMarker) {
          setGpsCoordinates(event.target.getLatLng())
          setCanSaveCoordinates(true)
        }
      })

      if (isMainMarker) {
        mapInstance.current.groups.marker.addLayer(mapMarker)
      } else {
        mapInstance.current.groups.additionalMarkers.addLayer(mapMarker)
      }
    }

    if (siteInputOutput) {
      createMarker(siteInputOutput, false)
    } else {
      if (shouldClear) {
        mapInstance.current.groups.marker.clearLayers()
      }

      createMarker(inputOutput, true)
      mapInstance.current.groups.marker.addTo(mapInstance.current.map)

      if (shouldPan) {
        mapInstance.current.map.setView([lat, lng], 18)
      }
    }
  }, [inputOutput, manageCoordinates])

  const getOtherInputOutputs = useCallback(async (managedInputOutput) => {
    try {
      const query = new URLSearchParams([
        ['pageSize', 1000],
        ['siteIds[]', managedInputOutput.site.id || ''],
        ['options[]', 'hasLatLng'],
      ])

      const { data } = await apiClient.get(`/input-output/query?${query}`)
      if (data && data.success) {
        let inputOutputData = formatKeys(data.inputOutputs.data, 'camel')
        inputOutputData = reject(inputOutputData, (io) => {
          return io.id === managedInputOutput.id
        })
        setSiteInputOutputs(inputOutputData)

        each(inputOutputData, (siteInputOutput) => {
          addMarker(siteInputOutput.lat, siteInputOutput.lng, false, false, siteInputOutput)
        })
      }
    } catch (error) {
      Sentry.captureException(error)
      setAlert({
        type: 'error',
        content: 'Failed to retrieve site I/Os.',
      })
    }
  }, [urlParams])

  const getInputOutput = useCallback(async () => {
    try {
      const query = new URLSearchParams([['with[]', 'remoteUnit'], ['with[]', 'site.blocks']])
      let { data } = await apiClient.get(`/input-output/view/${urlParams.id}?${query}`)

      if (data && data.success) {
        const inputOutputData = formatKeys(data.inputOutput, 'camel')

        setInputOutput(inputOutputData)
        setSubscriberModelMap({ inputOutput: [inputOutput.id] })

        originalGpsCoordinates.current = {
          lat: inputOutputData.lat,
          lng: inputOutputData.lng,
        }

        if (inputOutputData.lat && inputOutputData.lng) {
          mapInstance.current.map.setView([inputOutputData.lat, inputOutputData.lng], 18)
        } else if (mapInstance.current.map) {
          mapInstance.current.map.setView([inputOutputData.site.lat, inputOutputData.site.lng], 18)
        }

        if (!isEmpty(inputOutputData.site.blocks)) {
          let blocks = map(inputOutputData.site.blocks, (block) => {
            let geoJson = block.geoJson

            return {
              ...geoJson,
              properties: {
                ...geoJson.properties,
                id: block.id,
              },
            }
          })

          const blocksResult = addBlocks({
            blocks: blocks,
            drawingLayer: mapInstance.current.groups.drawings,
            clearLayer: true,
            withBounds: true,
          })

          if (blocksResult.bounds && blocksResult.bounds.isValid() && !manageCoordinates) {
            mapInstance.current.map.fitBounds(blocksResult.bounds)
          }
        }
      }
    } catch (error) {
      Sentry.captureException(error)
      setAlert({
        type: 'error',
        content: 'Failed to retrieve I/O.',
      })
    }
  }, [urlParams])

  const mapClick = useCallback((event) => {
    if (!manageCoordinates) {
      return
    }

    addMarker(event.latlng.lat, event.latlng.lng, true, true)

    setInputOutput({
      ...inputOutput,
      lat: parseFloat(event.latlng.lat.toFixed(6)),
      lng: parseFloat(event.latlng.lng.toFixed(6)),
    })

    setGpsCoordinates(event.latlng)
    setCanSaveCoordinates(true)
  }, [
    manageCoordinates,
    mapInstance,
    inputOutput,
  ])

  return (
    <PageContainer>
      <div className="row">
        <div className="mb-5 text-3xl font-light text-slate-900">
          Managing <span className="font-medium">{inputOutput.name}</span>
          <div className="text-base text-slate-500">
            {startCase(replace(inputOutput.detailsType, 'inputOutput.', ''))}
          </div>
        </div>
      </div>

      <div className="row">
        <DetailContainer className="col-12 col-md-4">
          <InputOutputManageDetailsCard
            coordinates={originalGpsCoordinates.current}
            manageCoordinates={manageCoordinates}
            manageBlocks={manageBlocks}
            inputOutput={inputOutput}
          />

          {manageCoordinates && !isEmpty(inputOutput) && (
            <InputOutputManageCoordinates
              addMarker={addMarker}
              inputOutput={inputOutput}
              onCoordinatesSaved={async () => {
                await getInputOutput()

                navigate(`/io/manage/${inputOutput.id}`)
              }}
              onCancel={(hasChanges) => {
                const {
                  lat,
                  lng,
                } = originalGpsCoordinates.current

                if (hasChanges) {
                  if (isNull(lat) && isNull(lng)) {
                    setGpsCoordinates({
                      lat: null,
                      lng: null,
                    })

                    setInputOutput({
                      ...inputOutput,
                      lat: null,
                      lng: null,
                    })

                  } else {
                    setGpsCoordinates({
                      lat,
                      lng,
                    })

                    setInputOutput({
                      ...inputOutput,
                      lat,
                      lng,
                    })
                  }
                }
              }}
            />
          )}
        </DetailContainer>

        <MapContainer className="col-12 col-md-8">
          {(manageCoordinates && mapInstance?.current?.groups) && (
            <div className="absolute right-5 top-2.5 z-1000 flex gap-3">
              <Tabs
                defaultTab={showAdditionalMarkers ? 'showAdditionalInputOutputs' : 'hideAdditionalInputOutputs'}
                tabs={[{
                  title: 'Show all I/Os',
                  key: 'showAdditionalInputOutputs',
                }, {
                  title: 'Hide I/Os',
                  key: 'hideAdditionalInputOutputs',
                }]}
                onChange={toggleAdditionalMarkers}
              />
            </div>
          )}

          <Map
            loading={mapInitialized ? false : 3000}
            onInit={mapInit}
            onClick={mapClick}
          />
        </MapContainer>
      </div>
    </PageContainer>
  )
}

export default InputOutputManage
