import { constant, map, times } from 'lodash-es'
import Skeleton from 'react-loading-skeleton'

import Card from '@/Components/common/Card'

/**
 * Display a skeleton dashboard cards.
 *
 * @param {Object} options - The options for the skeleton dashboard cards.
 * @param {number} options.cardsToShow - The number of cards to show. Default value is 8.
 *
 * @returns {Array} - An array of React components representing the skeleton dashboard cards.
 */
export default function SkeletonDashboardCards({
  cardsToShow = 8,
}) {
  const cardList = times(cardsToShow, constant(undefined))

  return (
    map(cardList, (card, cardIndex) => {
      return (
        <Card key={cardIndex}>
          <Card.Header className="h-18 bg-gray-50">
            <div className="flex justify-between">
              <Skeleton className="h-6 w-56" />

              <div className="flex items-end space-x-1">
                <Skeleton className="h-6 w-6" circle />
                <Skeleton className="h-6 w-6" circle />
                <Skeleton className="h-6 w-6" circle />
              </div>
            </div>
          </Card.Header>

          <Card.SubHeader className="py-4 px-6">
            <div className="grid grid-cols-2 gap-x-3">
              <Skeleton className="w-full" inline />
              <Skeleton className="w-full" inline />
            </div>

            <div className="grid grid-cols-2 gap-x-3">
              <Skeleton className="w-full" inline />
              <Skeleton className="w-full" inline />
            </div>

            <div className="mt-4 grid grid-cols-2 gap-x-3">
              <Skeleton className="w-full" inline />
              <Skeleton className="w-full" inline />
            </div>

            <div className="grid grid-cols-2 gap-x-3">
              <Skeleton className="w-full" inline />
              <Skeleton className="w-full" inline />
            </div>
          </Card.SubHeader>

          <Card.Body>
            <div className="flex h-20 items-center justify-between border-b border-b-slate-200 bg-gray-50 p-4">
              <Skeleton className="h-8 w-8" inline />
              <Skeleton className="w-48" inline />
              <Skeleton className="h-8 w-8" inline />
            </div>

            <div className="px-6 py-4">
              <div className="grid grid-cols-2 gap-x-3">
                <Skeleton className="w-full" inline/>
                <Skeleton className="w-full" inline/>
              </div>

              <div className="grid grid-cols-2 gap-x-3">
                <Skeleton className="w-full" inline/>
                <Skeleton className="w-full" inline/>
              </div>

              <div className="mt-4 grid grid-cols-2 gap-x-3">
                <Skeleton className="w-full" inline/>
                <Skeleton className="w-full" inline/>
              </div>

              <div className="grid grid-cols-2 gap-x-3">
                <Skeleton className="w-full" inline/>
                <Skeleton className="w-full" inline/>
              </div>
            </div>
          </Card.Body>

          <Card.Footer>
            <div className="flex justify-between px-6 py-4">
              <Skeleton className="h-20 w-20" inline circle />
              <Skeleton className="h-20 w-20" inline circle />
              <Skeleton className="h-20 w-20" inline circle />
              <Skeleton className="h-20 w-20" inline circle />
            </div>
          </Card.Footer>
        </Card>
      )
    })
  )
}
