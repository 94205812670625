import Tippy from '@tippyjs/react'
import { forwardRef } from 'react'
import styled from 'styled-components'
import { twMerge } from 'tailwind-merge'

import InputLabel from '@/Components/form/InputLabel'
import RequiredAsterisk from '@/Components/form/RequiredAsterisk'
import { mediaBreakpointDown } from '@/Utilities/Breakpoint'

const Input = styled.input`
  background: ${(props) => {
    return props.background || 'transparent'
  }};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  color: var(--text-dark);
  font-size: 16px;
  height: 44px;
  line-height: 1;
  max-width: 100%;
  padding: 0 14px;
  position: relative;
  width: ${(props) => {
    return props.width || '100%'
  }};

  ${(props) => {
    return props.searchIcon && `
      padding: 0 10px 0 40px;
    `
  }}

  ${(props) => {
    return props.loadingIcon && `
      padding: 0 10px 0 40px;
    `
  }}

  ${(props) => {
    return props.leftIcon && `
      padding: 0 14px 0 40px;
    `
  }}

  ${(props) => {
    return props.leadingSelect && `
      border-radius: 0 5px 5px 0;
      border-left: none;

      ${mediaBreakpointDown('sm', `
        border-left: 1px solid var(--border-color);
        border-radius: 0 0 5px 5px;
        border-top: none;
      `)}
    `
  }}

  ${(props) => {
    return props.textCenter && `
      text-align: center;
    `
  }}

  ${(props) => {
    return props.showArrows ? null : `
      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type=number] {
        -moz-appearance: textfield;
      }
    `
  }}

  &::placeholder {
    color: #667085;
    font-size: 16px;
    font-weight: 300;
  }

  &.error {
    border-color: #FDA29B;

    &:focus {
      outline-color: #FDA29B;
    }
  }

  &:disabled {
    background-color: var(--disabled);
    cursor: not-allowed;
  }
`

const InputWrapper = styled.div`
  position: relative;

  ${(props) => {
    return props.leadingSelect && `
      display: inline-flex;
    `
  }}
`

const InputContainer = styled.div`
  position: relative;

  ${(props) => {
    return props.leadingSelect && `
      ${mediaBreakpointDown('sm', `
        width: 100%;
      `)}
    `
  }}
`

const IconContainer = styled.span`
  align-items: center;
  top: 0;
  color: var(--text-light);
  justify-content: center;
  display: flex;
  height: 44px;
  position: absolute;
  width: 40px;
`

const InputField = forwardRef((props, ref) => {
  const { helpTooltip } = props

  return (
    <InputWrapper leadingSelect={props.leadingSelect}>
      {props.label && (
        <InputLabel htmlFor={props.name}>
          {props.label}

          {props.isRequired ? <RequiredAsterisk /> : null}

          {helpTooltip && (
            <Tippy content={helpTooltip} disabled={helpTooltip ? false : true} zIndex="99999" theme="light" placement="top" delay={200}>
              <i className="fa-solid fa-circle-question ml-3 flex h-5 cursor-pointer items-center text-base text-slate-500"></i>
            </Tippy>
          )}
        </InputLabel>
      )}

      <InputContainer leadingSelect={props.leadingSelect}>
        {props.leftIcon && (
          <IconContainer>
            {props.icon}
          </IconContainer>
        )}

        <Input {...props} ref={ref} background="white" className={twMerge('rounded border border-gray-300 focus:outline-primary', props.className)} />

        {props.searchIcon && !props.loadingIcon && (
          <IconContainer>
            <i className="fa-regular fa-magnifying-glass"></i>
          </IconContainer>
        )}

        {props.loadingIcon && (
          <IconContainer>
            <div className="primary-loader tiny"></div>
          </IconContainer>
        )}
      </InputContainer>
    </InputWrapper>
  )
})

export default InputField
