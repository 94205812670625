import * as Sentry from '@sentry/react'
import { each, get, map, orderBy, setWith } from 'lodash-es'

import { formatKeys } from '@/Utilities/Form/Formatter'

export const getProgramSets = async (apiClient) => {
  const { data } = await apiClient.get('/program-sets')

  if (data) {
    return data
  }

  return null
}

function getProgramSetsQueryRequest(apiClient, query) {
  return apiClient.get(`/program-set/query?${query}`)
}

function getProgramSetStatsRequest(apiClient) {
  return apiClient.get('/program/stats')
}

export const getAllProgramSetsUsingQuery = async (apiClient, ...queries) => {
  const maxResults = 1000

  const query = new URLSearchParams([
    ['page', 1],
    ['pageSize', maxResults],
    ['status', ''],
    ['with[]', 'blocks'],
    ['with[]', 'site.inputOutputs'],
    ['with[]', 'programs.inputOutputs.mainLines'],
    ['with[]', 'programs.operationalStatuses'],
    ['with[]', 'programs.mainLine.flowMeter'],
    ['with[]', 'programs.fertigationPumps.inputOutput'],
    ['with[]', 'programs.fertigationPumps.flowMeter'],
    ...queries,
  ])

  try {
    // Retrieve all async data and wait for both request to complete before continuing
    const [programSets, programSetStats] = await Promise.all([getProgramSetsQueryRequest(apiClient, query), getProgramSetStatsRequest(apiClient)])

    // Acquire the raw data response using destructing
    let programSetsParsed = get(programSets, 'data.programSets.data', [])
    // Automatically sort the program set stats by count descending
    const programSetStatsParsed = orderBy(get(programSetStats, 'data', []), ['count'], ['desc'])

    // Convert keys from snake to camel case
    programSetsParsed = formatKeys(programSetsParsed, 'camel')

    each(programSetsParsed, (programSet, programSetIndex) => {
      each(programSetsParsed.stats ?? [], (value, key) => {
        setWith(programSetsParsed, [
          programSetIndex,
          'statMap',
          key,
        ], value, Object)
      })

      each(programSet.programs, (program, programIndex) => {
        const mainLineStats = get(program, 'mainLine.stats', [])

        each(mainLineStats, (stat) => {
          setWith(programSetsParsed, [
            programSetIndex,
            'programs',
            programIndex,
            'mainLine',
            'statMap',
            stat.key,
          ], stat.value, Object)
        })
      })
    })

    // Append options
    programSetsParsed.parsedOptions = map(programSetsParsed, (programSet) => {
      return {
        value: programSet.id,
        label: programSet.name,
      }
    })

    // Automatically sort the operational statuses by updatedAt timestamp
    programSetsParsed.operationalStatuses = orderBy(programSetsParsed.operationalStatuses, ['updatedAt'], ['desc'])

    return [programSetsParsed, programSetStatsParsed]
  } catch (error) {
    Sentry.captureException(error)

    return Promise.reject(error)
  }
}
