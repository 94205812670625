import classNames from 'classnames'
import { twMerge } from 'tailwind-merge'

function Card(props) {
  return (
    <div className={twMerge(classNames('flex flex-col w-full rounded border border-slate-200 bg-white shadow', { 'shadow-lg': props.active }), props.className)}>
      <div className={
        classNames(
          'flex items-center border-b justify-between p-3.5',
          { 'justify-start': props.pill },
        )}
      >
        <div className={classNames('items-center flex flex-wrap capitalize basis-auto', { 'basis-1/2': props.pill })}>
          {props.title}
        </div>

        {props.pill &&
          <div>
            {props.pill}
          </div>
        }

        <div className="relative flex items-center">
          {props.actions}
        </div>
      </div>

      <div className={classNames('grid grid-cols-1 py-3.5 px-3.5 gap-3', { 'grow items-center': props.centerContentVertically })}>
        {props.children}
      </div>
    </div>
  )
}

export default Card
