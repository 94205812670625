import { useSetRecoilState } from 'recoil'

import PermissionGuard from '@/Components/auth/PermissionGuard'
import { Anchor, RoundedIconButton } from '@/Components/form/Buttons'
import PageContainer from '@/Components/layout/helpers/PageContainer'
import MainLinesAll from '@/Components/tables/MainLinesAll'
import { modalState } from '@/Config/Atoms/General'
import useAuth from '@/Utilities/useAuth'
import usePageGuard from '@/Utilities/usePageGuard'
import useTitle from '@/Utilities/useTitle'

function MainLineIndex() {
  const setModal = useSetRecoilState(modalState)
  const auth = useAuth()

  usePageGuard({
    auth,
    permission: 'view-main-line',
  })

  useTitle('All main lines')

  const showMainLineModal = () => {
    setModal({ name: 'mainLine' })
  }

  return (
    <PageContainer contained>
      <div className="mb-5 grid grid-cols-2-right-auto text-3xl font-light text-slate-900">
        <div>
            All <span className="font-medium">main lines</span>
        </div>

        <PermissionGuard permission="create-main-line" auth={auth}>
          <div className="text-right">
            <div className="hidden justify-items-end md:block">
              <Anchor
                style={{ minWidth: '200px' }}
                onClick={showMainLineModal}
              >
                Add main line
              </Anchor>
            </div>
            <div className="md:hidden">
              <RoundedIconButton
                icon="plus"
                onClick={showMainLineModal}
              />
            </div>
          </div>
        </PermissionGuard>
      </div>

      <PermissionGuard permission="view-main-line" auth={auth}>
        <MainLinesAll/>
      </PermissionGuard>
    </PageContainer>
  )
}

export default MainLineIndex
