import * as Sentry from '@sentry/react'
import Tippy from '@tippyjs/react'
import classNames from 'classnames'
import { filter, find, findKey, get, includes, isEmpty, map, replace, split, startCase, toString } from 'lodash-es'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import Input from '@/Components/form/Input'
import InputError from '@/Components/form/InputError'
import InputLabel from '@/Components/form/InputLabel'
import LightSwitch from '@/Components/form/LightSwitch'
import RequiredAsterisk from '@/Components/form/RequiredAsterisk'
import Select from '@/Components/form/Select'
import Logging from '@/Components/modals/inputOutput/Logging'
import { getManufacturerModels } from '@/Utilities/Accessors/ManufacturerModels'
import { getManufacturers } from '@/Utilities/Accessors/Manufacturers'
import { toOptions } from '@/Utilities/Form/Formatter'
import { unitOptions } from '@/Utilities/Units'
import useApiClient from '@/Utilities/useApiClient'

function ConditionalFields(props) {
  const [moduleSlotOptions, setModuleSlotOptions] = useState([])
  const [moduleSlotsLoading, setModuleSlotsLoading] = useState(false)
  const [channelOptions, setChannelOptions] = useState([])
  const [channelsLoading, setChannelsLoading] = useState(false)
  const [manufacturerModels, setManufacturerModels] = useState([])
  const [manufacturerOptions, setManufacturerOptions] = useState([])
  const [remoteUnitOptions, setRemoteUnitOptions] = useState([])
  const [remoteUnitsLoading, setRemoteUnitsLoading] = useState(false)
  const apiClient = useApiClient()

  const {
    control,
    errors,
    getValues,
    register,
    reset,
    setValue,
    watch,
  } = useFormContext()

  const {
    details,
    activeSections,
    isEditing,
    inputOutput,
  } = props

  const auxiliaryEnabled = watch('auxiliary')
  const changeOfStateEnabled = watch('changeOfState')
  const loggingEnabled = watch('logging')
  const loggingType = watch('loggingType')
  const mapLinkingEnabled = watch('mapLinking')
  const physicalConnection = watch('physicalConnection')
  const remoteUnitId = watch('remoteUnitId')
  const selectedSite = watch('siteId')
  const selectedSlot = watch('moduleSlot')
  const selectedChannel = watch('channel')
  const selectedManufacturer = watch('manufacturer')
  const selectedManufacturerModel = watch('details.manufacturerModelId')

  const {
    ioType,
  } = props

  const shouldShowModuleAndChannel = useMemo(() => {
    const physicalConnectionValue = get(physicalConnection, 'value')

    if (physicalConnectionValue === 'localModule') {
      return true
    }

    const remoteUnitIdValue = get(remoteUnitId, 'value')

    if (physicalConnectionValue === 'remoteUnit' && remoteUnitIdValue) {
      return true
    }

    return false
  }, [physicalConnection, remoteUnitId])

  const scaleMeasurementOptions = useMemo(() => {
    let scaleOptionsKey

    switch (ioType) {
      case 'inputOutput.analogInput':
        scaleOptionsKey = 'analogInputScale'
        break

      case 'inputOutput.sensorSoilProbe':
        scaleOptionsKey = 'sensorSoilProbeScale'
        break

      default:
        scaleOptionsKey = 'scale'
        break
    }

    return unitOptions(scaleOptionsKey)
  }, [ioType])

  const rateMeasurementOptions = useMemo(() => {
    return unitOptions('rate')
  }, [])

  const stabilisationMeasurementOptions = useMemo(() => {
    return unitOptions('stabilisation')
  }, [])

  const delayMeasurementOptions = useMemo(() => {
    return unitOptions('delay')
  }, [])

  const areaMeasurementOptions = useMemo(() => {
    return unitOptions('area')
  }, [])

  const pulseValueMeasurementOptions = useMemo(() => {
    return unitOptions('pulseValue')
  }, [])

  const deltaMeasurementOptions = useMemo(() => {
    let deltaOptionsKey

    switch (ioType) {
      case 'inputOutput.analogInput':
        deltaOptionsKey = 'analogInputDelta'
        break

      case 'inputOutput.analogFlowMeter':
      case 'inputOutput.digitalFlowMeter':
        deltaOptionsKey = 'flowMeterDelta'
        break

      case 'inputOutput.sensorSoilProbe':
        deltaOptionsKey = 'sensorSoilProbeDelta'
        break

      default:
        deltaOptionsKey = 'delta'
        break
    }

    return unitOptions(deltaOptionsKey)
  }, [ioType])

  const reportingTime = useMemo(() => {
    const time = split(details?.reportingTime, ':', 3)

    return {
      minutes: time[1] || null,
      seconds: time[2] || null,
    }
  })

  const loggingTypeOptions = useMemo(() => {
    return [{
      value: 'interval',
      label: 'Interval',
    }, {
      value: 'delta',
      label: 'Delta',
    }]
  }, [])

  const physicalConnectionOptions = useMemo(() => {
    return [{
      value: 'localModule',
      label: 'Local Module',
    }, {
      value: 'remoteUnit',
      label: 'Remote Unit',
    }]
  }, [])

  const manufacturerModelOptions = useMemo(() => {
    if (selectedManufacturer) {
      const filteredModels = filter(manufacturerModels, (model) => {
        return model.manufacturerId === selectedManufacturer?.value
      })

      return toOptions(filteredModels, ['name', 'label'], ['id', 'value'], ['manufacturerId', 'manufacturerId'], ['configuration.depths', 'depths'])
    }

    return toOptions(manufacturerModels, ['name', 'label'], ['id', 'value'], ['manufacturerId', 'manufacturerId'], ['configuration.depths', 'depths'])
  }, [manufacturerModels, selectedManufacturer])

  const getRemoteUnits = useCallback(async (siteId) => {
    if (siteId) {
      setRemoteUnitsLoading(true)
      const { data } = await apiClient.get(`/remote-unit/query?siteId=${siteId}`)

      const remoteUnits = get(data, 'remoteUnits')

      if (remoteUnits) {
        const options = toOptions(remoteUnits, ['name', 'label'], ['id', 'value'])
        setRemoteUnitOptions(options)

        if (!getValues('remoteUnitId')) {
          const preselectRemoteUnit = find(options, ['value', get(inputOutput, 'remoteUnitId')])
          setValue('remoteUnitId', preselectRemoteUnit)
        }
      } else {
        setRemoteUnitOptions([])
      }

      setRemoteUnitsLoading(false)
    }
  }, [])

  const getModuleSlots = useCallback(async (id, moduleTypeKey) => {
    if (id) {
      try {
        setModuleSlotsLoading(true)
        const { data } = await apiClient.get(`/input-output/available-module-slots?${moduleTypeKey}=${id}`)

        if (data?.success) {
          const options = map(data.slots, (module, slot) => {
            return {
              label: (
                <span className="flex items-center">
                  Slot {slot}

                  <span className="ml-2 text-sm text-gray-400">
                    ({module.model_name})
                  </span>
                </span>
              ),
              value: slot,
            }
          })

          setModuleSlotOptions(options)

          if (!getValues('moduleSlot')) {
            const preselectModuleSlot = find(options, ['value', toString(get(inputOutput, 'moduleSlot'))])
            setValue('moduleSlot', preselectModuleSlot)
          }
        }
      } catch (error) {
        Sentry.captureException(error)
      } finally {
        setModuleSlotsLoading(false)
      }
    }
  }, [])

  const getChannels = useCallback(async (id, moduleTypeKey) => {
    if (id && selectedSlot) {
      try {
        setChannelsLoading(true)
        const { data } = await apiClient.get(`/input-output/available-channels?${moduleTypeKey}=${id}&module_slot=${selectedSlot.value}`)

        if (data?.success) {
          const selectedIoType = replace(ioType, 'inputOutput.', '')

          const typeMap = {
            digitalInput: ['digitalInput', 'digitalFlowMeter'],
            digitalOutput: [
              'digitalOutputValve',
              'digitalOutputBooster',
              'digitalOutputGeneral',
              'digitalOutputLight',
              'digitalOutputElectricLock',
              'digitalOutputFountain',
            ],
            analogInput: ['analogInput', 'analogFlowMeter'],
            analogOutput: ['analogOutput'],
            modbus: ['sensorSoilProbe'],
          }

          const rangeType = findKey(typeMap, (types) => {
            return includes(types, selectedIoType)
          })

          let options = map(data.channels, (moduleRange, channel) => {
            return {
              label: (
                <span className="flex items-center">
                  Channel {channel}

                  {moduleRange.type !== rangeType && (
                    <span className="ml-2 text-sm">
                      ({startCase(moduleRange.type)})
                    </span>
                  )}
                </span>
              ),
              value: channel,
              type: moduleRange.type,
              isDisabled: moduleRange.type != rangeType,
            }
          })

          const availableChannels = {
            label: 'Available channels',
            options: filter(options, (option) => {
              return !option.isDisabled
            }),
          }

          const disabledChannels = {
            label: 'Incompatible channels',
            options: filter(options, (option) => {
              return option.isDisabled
            }),
          }

          options = [availableChannels, disabledChannels]

          setChannelOptions(options)

          if (!getValues('channel')) {
            const channelValue = get(inputOutput, 'channel')

            if (channelValue) {
              setValue('channel', {
                label: `Channel ${channelValue}`,
                value: channelValue,
              })
            }
          }
        }
      } catch (error) {
        Sentry.captureException(error)
      } finally {
        setChannelsLoading(false)
      }
    }
  }, [selectedSlot, ioType])

  useEffect(() => {
    const physicalConnectionValue = get(physicalConnection, 'value')

    if (physicalConnectionValue) {
      let moduleTypeKey
      let moduleTypeValue

      switch (physicalConnectionValue) {
        case 'remoteUnit':
          moduleTypeKey = 'remote_unit_id'
          moduleTypeValue = get(remoteUnitId, 'value')
          break

        case 'localModule':
        default:
          moduleTypeKey = 'site_id'
          moduleTypeValue = get(selectedSite, 'value')
          break
      }

      getChannels(moduleTypeValue, moduleTypeKey)
    }
  }, [
    ioType,
    physicalConnection,
    selectedSite,
    remoteUnitId,
    selectedSlot,
  ])

  useEffect(() => {
    if (!get(selectedSite, 'value')) {
      return false
    }

    const physicalConnectionValue = get(physicalConnection, 'value')

    if (physicalConnectionValue) {
      let moduleTypeKey
      let moduleTypeValue

      switch (physicalConnectionValue) {
        case 'remoteUnit':
          moduleTypeKey = 'remote_unit_id'
          moduleTypeValue = get(remoteUnitId, 'value')
          break

        case 'localModule':
        default:
          moduleTypeKey = 'site_id'
          moduleTypeValue = get(selectedSite, 'value')
          break
      }

      getModuleSlots(moduleTypeValue, moduleTypeKey)
    }

    if (physicalConnectionValue === 'remoteUnit') {
      getRemoteUnits(selectedSite.value)
    }

    return () => {
      setModuleSlotOptions([])
      setChannelOptions([])
      setRemoteUnitOptions([])
    }
  }, [
    physicalConnection,
    selectedSite,
    remoteUnitId,
  ])

  useEffect(() => {
    (async () => {
      const manufacturers = await getManufacturers(apiClient)

      if (!isEmpty(manufacturers)) {
        setManufacturerOptions(toOptions(manufacturers, ['name', 'label'], ['id', 'value']))
      }
    })()
  }, [])

  useEffect(() => {
    (async () => {
      const manufacturerModels = await getManufacturerModels(apiClient)

      setManufacturerModels(manufacturerModels)
    })()
  }, [])

  // Reset values when changing IO type
  useEffect(() => {
    if (!isEditing) {
      setValue('remoteUnitId', null)
      setValue('moduleSlot', null)
      setValue('channel', null)
      setValue('manufacturer', null)
      setValue('details.manufacturerModelId', null)
      setValue('modbusSlaveAddress', null)
    }
  }, [ioType, isEditing])

  return (
    <>
      {/* Sensor Manufacturers */}
      {includes(activeSections, 'manufacturer') && (
        <>
          {!isEmpty(manufacturerOptions) && (!isEmpty(selectedManufacturerModel) || !isEditing) && (
            <>
              <Controller
                control={control}
                defaultValue={find(manufacturerOptions, ['value', selectedManufacturerModel?.manufacturerId])}
                name="manufacturer"
                render={({ field }) => {
                  return (
                    <Select
                      {...field}
                      isMulti={false}
                      isSearchable={true}
                      label="Manufacturer"
                      isRequired={true}
                      options={manufacturerOptions}
                      placeholder="Search"
                      hasError={!!errors?.manufacturer}
                      isClearable={true}
                      onChange={(value) => {
                        setValue('manufacturer', value)

                        if (!value) {
                          setValue('manufacturerModelId', null)
                        }
                      }}
                    />
                  )
                }}
              />

              {errors?.manufacturer && <InputError message={errors.manufacturer.message} />}
            </>
          )}

          {!isEmpty(manufacturerModelOptions) && (
            <>
              <Controller
                control={control}
                defaultValue={find(manufacturerModelOptions, ['value', inputOutput?.details?.manufacturerModelId])}
                name="details.manufacturerModelId"
                render={({ field }) => {
                  return (
                    <Select
                      {...field}
                      isMulti={false}
                      isSearchable={true}
                      label="Model"
                      isRequired={true}
                      options={manufacturerModelOptions}
                      placeholder="Model number"
                      getOptionLabel={(option) => {
                        return `${option.label} - Sensors (${option.depths})`
                      }}
                      hasError={!!errors?.details?.manufacturerModelId}
                    />
                  )
                }}
              />

              {errors?.details?.manufacturerModelId && <InputError message={errors?.details?.manufacturerModelId.message} />}
            </>
          )}
        </>
      )}

      {/* Physical Connection */}
      {includes(activeSections, 'physicalConnection') && (
        <>
          <div>
            <Controller
              control={control}
              defaultValue={find(physicalConnectionOptions, ['value', get(inputOutput, 'physicalConnection')])}
              name="physicalConnection"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    isMulti={false}
                    isSearchable={true}
                    label="Physical connection"
                    options={physicalConnectionOptions}
                    placeholder="Search"
                    hasError={!!errors?.physicalConnection}
                    isClearable={true}
                    onChange={(value) => {
                      setValue('physicalConnection', value)
                      setValue('remoteUnitId', null)
                      setValue('moduleSlot', null)
                      setValue('channel', null)
                    }}
                  />
                )
              }}
            />

            {errors?.physicalConnection && <InputError message={errors.physicalConnection.message} />}
          </div>

          <div className={classNames({ hidden: get(physicalConnection, 'value') !== 'remoteUnit' })}>
            <Controller
              control={control}
              defaultValue={''}
              name="remoteUnitId"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    isMulti={false}
                    isSearchable={true}
                    label="Remote unit"
                    options={remoteUnitOptions}
                    placeholder="Search"
                    hasError={!!errors?.remoteUnitId}
                    isRequired={true}
                    isLoading={remoteUnitsLoading}
                    onChange={(value) => {
                      setValue('remoteUnitId', value)
                      setValue('moduleSlot', null)
                      setValue('channel', null)
                    }}
                    noOptionsMessage={() => {
                      return selectedSite ? 'There are no options for the selected site.' : 'Select a site to see available options.'
                    }}
                  />
                )
              }}
            />

            {errors?.remoteUnitId && <InputError message={errors.remoteUnitId.message} />}
          </div>

          <div className={classNames({ hidden: !shouldShowModuleAndChannel })}>
            <Controller
              control={control}
              defaultValue={''}
              name="moduleSlot"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    isMulti={false}
                    isSearchable={true}
                    label="Module slot"
                    isRequired={true}
                    options={moduleSlotOptions}
                    isLoading={moduleSlotsLoading}
                    placeholder="Search"
                    hasError={!!errors?.moduleSlot}
                    noOptionsMessage={() => {
                      return selectedSite ? 'There are no options for the selected site.' : 'Select a site to see available options.'
                    }}
                  />
                )
              }}
            />

            {errors?.moduleSlot && <InputError message={errors?.moduleSlot.message} />}
          </div>

          <div className={classNames({ hidden: !shouldShowModuleAndChannel })}>
            <Controller
              control={control}
              defaultValue={''}
              name="channel"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    isMulti={false}
                    isSearchable={true}
                    label="Channel"
                    isRequired={true}
                    isLoading={channelsLoading}
                    options={channelOptions}
                    placeholder="Search"
                    hasError={!!errors?.channel}
                    noOptionsMessage={() => {
                      return selectedSlot ? 'There are no available channels for the selected module slot.' : 'Select a module slot to see available channels.'
                    }}
                  />
                )
              }}
            />

            {errors?.channel && <InputError message={errors?.channel.message} />}
          </div>

          <div className={classNames({ hidden: get(selectedChannel, 'type') !== 'modbus' })}>
            <Input
              label="Modbus slave address"
              isRequired={true}
              type="number"
              min="1"
              max="255"
              className={errors?.modbusSlaveAddress && 'error'}
              {...register('modbusSlaveAddress', { value: inputOutput?.modbusSlaveAddress })}
            />
            {errors?.modbusSlaveAddress && <InputError message={errors?.modbusSlaveAddress.message} />}
          </div>
        </>
      )}

      {/* Delta */}
      {includes(activeSections, 'delta') && (
        <div className="grid grid-cols-1 gap-x-4 @sm:grid-cols-2">
          <div>
            <>
              <Controller
                control={control}
                defaultValue={find(deltaMeasurementOptions, ['value', details?.deltaUnitOfMeasurement])}
                name="details.deltaUnitOfMeasurement"
                render={({ field }) => {
                  return (
                    <Select
                      {...field}
                      isMulti={false}
                      isSearchable={true}
                      label="Reporting delta unit of measure"
                      isRequired={true}
                      options={deltaMeasurementOptions}
                      placeholder="Search"
                      hasError={!!errors?.details?.deltaUnitOfMeasurement}
                    />
                  )
                }}
              />
              {errors?.details?.deltaUnitOfMeasurement && <InputError message={errors?.details?.deltaUnitOfMeasurement.message} />}
            </>
          </div>

          <div>
            <Input
              label="Reporting delta value"
              isRequired={true}
              helpTooltip={(
                <>
                  <div className="mb-2">
                      The delta value determines the degree of change required for
                      an option to be reported to the dashboard, optimizing communication
                      efficiency.
                  </div>

                  <div className="mb-2">
                      Adjust the delta value to suit your preference for reporting
                      changes in analog values. Higher delta values result in fewer
                      transmissions for smaller shifts, while lower delta values enable
                      more frequent reporting of even slight changes.
                  </div>

                  <div>
                      Keep in mind that excessive transmissions can strain the radio system.
                      Set an appropriate delta value to avoid overwhelming the radio with
                      constant updates and ensure optimal performance and reliability.
                  </div>
                </>
              )}
              type="number"
              step="0.01"
              className={errors?.details?.delta && 'error'}
              {...register('details.delta', { value: details?.delta })}
            />

            {errors?.details?.delta && <InputError message={errors?.details?.delta.message} />}
          </div>
        </div>
      )}

      {/* Reporting Time */}
      {includes(activeSections, 'reportingTime') && (
        <>
          <div className="grid grid-cols-1">
            <InputLabel>
              <span>
                Reporting time <RequiredAsterisk />
              </span>

              <Tippy
                content={`
                  The reporting time refers to the frequency at which the system sends data to the dashboard.
                  ${includes(activeSections, 'delta') && 'The interval will reset if the delta is triggered.'}
                `}
                zIndex="99999"
                theme="light"
                placement="top"
                delay={200}
              >
                <i className="fa-solid fa-circle-question ml-3 flex h-5 cursor-pointer items-center text-base text-slate-500"></i>
              </Tippy>
            </InputLabel>

            <div className="flex items-center">
              <Input
                placeholder="mm"
                maxlength="2"
                type="number"
                width="60px"
                textCenter
                className={classNames({ error: errors?.details?.reportingTimeMinutes })}
                {...register('details.reportingTimeMinutes', { value: reportingTime.minutes })}
              />

              <div className="my-0 mx-2">
                :
              </div>

              <Input
                placeholder="ss"
                maxlength="2"
                type="number"
                width="60px"
                textCenter
                className={classNames({ error: errors?.details?.reportingTimeSeconds })}
                {...register('details.reportingTimeSeconds', { value: reportingTime.seconds })}
              />
            </div>
          </div>

          {
            (
              (
                errors?.details?.reportingTimeMinutes?.type === 'min' ||
                  errors?.details?.reportingTimeMinutes?.type === 'max' ||
                  errors?.details?.reportingTimeSeconds?.type === 'min' ||
                  errors?.details?.reportingTimeSeconds?.type === 'max'
              ) &&
                <InputError message="Reporting time should be between 00:00 and 59:59" />
            ) ||
              (
                (errors?.details?.reportingTimeMinutes || errors?.details?.reportingTimeSeconds) &&
                <InputError message="Reporting time is a required field" />
              )
          }
        </>
      )}

      {/* Stabilization Time */}
      {includes(activeSections, 'stabilization') && (
        <div className="grid grid-cols-1 gap-x-4 @sm:grid-cols-2">
          <div>
            <>
              <Controller
                control={control}
                defaultValue={find(stabilisationMeasurementOptions, ['value', details?.stabilizationTimeUnitOfMeasurement || 'mins'])}
                name="details.stabilizationTimeUnitOfMeasurement"
                render={({ field }) => {
                  return (
                    <Select
                      {...field}
                      isMulti={false}
                      isSearchable={true}
                      label="Stabilization time unit of measure"
                      isRequired={true}
                      options={stabilisationMeasurementOptions}
                      placeholder="Search"
                      hasError={!!errors?.details?.stabilizationTimeUnitOfMeasurement}
                    />
                  )
                }}
              />

              {errors?.details?.stabilizationTimeUnitOfMeasurement && <InputError message={errors?.details?.stabilizationTimeUnitOfMeasurement.message} />}
            </>
          </div>

          <div>
            <Input
              label="Stabilization time"
              isRequired={true}
              helpTooltip={(
                <>
                  <div className="mb-2">
                    The stabilization time refers to the duration it takes
                    for the system to settle and stabilize after a change in
                    settings or inputs. It allows the system to adjust and
                    reach a steady state before further actions are taken.
                  </div>

                  <div className="mb-2">
                    Set the stabilization time according to the characteristics
                    of your irrigation system. Longer stabilization times provide
                    more time for the system to settle, ensuring accuracy and
                    consistency in measurements and operations. Shorter stabilization
                    times may expedite the response but may sacrifice precision.
                  </div>

                  <div>
                    Consider the complexity and dynamics of your irrigation system
                    when determining the appropriate stabilization time. Balancing
                    the need for stability with the desired level of responsiveness
                    will help optimize system performance.
                  </div>
                </>
              )}
              type="number"
              className={errors?.details?.stabilizationTime && 'error'}
              {...register('details.stabilizationTime', { value: details?.stabilizationTime })}
            />

            {errors?.details?.stabilizationTime && <InputError message={errors?.details?.stabilizationTime.message} />}
          </div>
        </div>
      )}

      {/* Rate */}
      {includes(activeSections, 'rate') && (
        <div className="grid grid-cols-1 gap-x-4 @sm:grid-cols-2">
          <div>
            <Controller
              control={control}
              defaultValue={find(rateMeasurementOptions, ['value', details?.rateUnitOfMeasurement])}
              name="details.rateUnitOfMeasurement"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    isMulti={false}
                    isSearchable={true}
                    label="Rate unit of measure"
                    options={rateMeasurementOptions}
                    placeholder="Search"
                    hasError={!!errors?.details?.rateUnitOfMeasurement}
                  />
                )
              }}
            />

            {errors?.details?.rateUnitOfMeasurement && <InputError message={errors?.details?.rateUnitOfMeasurement.message} />}
          </div>

          <div>
            <Input
              label="Rate"
              type="number"
              step="0.01"
              className={errors?.details?.rate && 'error'}
              {...register('details.rate', { value: details?.rate })}
            />

            {errors?.details?.rate && <InputError message={errors?.details?.rate.message} />}
          </div>
        </div>
      )}

      {/* Scale */}
      {includes(activeSections, 'scale') && (
        <>
          <div className="grid grid-cols-1">
            <div>
              <Controller
                control={control}
                defaultValue={find(scaleMeasurementOptions, ['value', details?.scaleUnitOfMeasurement])}
                name="details.scaleUnitOfMeasurement"
                render={({ field }) => {
                  return (
                    <Select
                      {...field}
                      isMulti={false}
                      isSearchable={true}
                      label="Scale unit of measure"
                      isRequired={true}
                      options={scaleMeasurementOptions}
                      placeholder="Search"
                      hasError={!!errors?.details?.scaleUnitOfMeasurement}
                    />
                  )
                }}
              />

              {errors?.details?.scaleUnitOfMeasurement && <InputError message={errors?.details?.scaleUnitOfMeasurement.message} />}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-x-4">
            <div>
              <Input
                label="Scale minimum"
                isRequired={true}
                type="number"
                step="0.01"
                className={errors?.details?.scaleMin && 'error'}
                {...register('details.scaleMin', { value: details?.scaleMin })}
              />

              {errors?.details?.scaleMin && <InputError message={errors?.details?.scaleMin.message} />}
            </div>

            <div>
              <Input
                label="Scale maximum"
                isRequired={true}
                type="number"
                step="0.01"
                className={errors?.details?.scaleMax && 'error'}
                {...register('details.scaleMax', { value: details?.scaleMax })}
              />

              {errors?.details?.scaleMax && <InputError message={errors?.details?.scaleMax.message} />}
            </div>
          </div>
        </>
      )}

      {/* Pulse value */}
      {includes(activeSections, 'pulseValue') && (
        <div className="grid grid-cols-1 gap-x-4 @sm:grid-cols-2">
          <div>
            <Controller
              control={control}
              defaultValue={find(pulseValueMeasurementOptions, ['value', details?.pulseValueUnitOfMeasurement])}
              name="details.pulseValueUnitOfMeasurement"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    isMulti={false}
                    isSearchable={true}
                    label="Pulse value unit of measure"
                    isRequired={true}
                    options={pulseValueMeasurementOptions}
                    placeholder="Search"
                    hasError={!!errors?.details?.pulseValueUnitOfMeasurement}
                  />
                )
              }}
            />

            {errors?.details?.pulseValueUnitOfMeasurement && <InputError message={errors?.details?.pulseValueUnitOfMeasurement.message} />}
          </div>

          <div>
            <Input
              label="Pulse value"
              isRequired={true}
              helpTooltip={(
                <>
                  <div className="mb-2">
                    The pulse value represents the level of detail or
                    granularity at which measurements or data are captured
                    and reported. It determines the precision or accuracy of
                    the information provided.
                  </div>

                  <div className="mb-2">
                    Adjust the pulse value based on your specific requirements.
                    Higher pulse values provide finer-grained measurements,
                    offering more precise data. However, keep in mind that higher
                    pulses may also result in larger data sizes and potentially
                    increased processing requirements.
                  </div>

                  <div className="mb-2">
                    Conversely, lower pulse values may result in coarser
                    measurements, sacrificing some precision but potentially
                    reducing data size and processing demands.
                  </div>

                  <div>
                    Consider the trade-off between precision and resource utilization
                    when setting the pulse value. Choose a value that aligns with
                    your needs for accurate data representation while considering the
                    system's capabilities and limitations.
                  </div>
                </>
              )}
              type="number"
              step="0.01"
              className={errors?.details?.pulseValue && 'error'}
              {...register('details.pulseValue', { value: details?.pulseValue })}
            />

            {errors?.details?.pulseValue && <InputError message={errors?.details?.pulseValue.message} />}
          </div>
        </div>
      )}

      {/* On Delay */}
      {includes(activeSections, 'onDelay') && (
        <div className="grid grid-cols-1 gap-x-4 @sm:grid-cols-2">
          <div>
            <>
              <Controller
                control={control}
                defaultValue={find(delayMeasurementOptions, ['value', details?.onDelayUnitOfMeasurement || 'mins'])}
                name="details.onDelayUnitOfMeasurement"
                render={({ field }) => {
                  return (
                    <Select
                      {...field}
                      isMulti={false}
                      isSearchable={true}
                      isRequired={true}
                      label="On delay unit of measure"
                      options={delayMeasurementOptions}
                      placeholder="Search"
                      hasError={!!errors?.details?.onDelayUnitOfMeasurement}
                    />
                  )
                }}
              />
              {errors?.details?.onDelayUnitOfMeasurement && <InputError message={errors?.details?.onDelayUnitOfMeasurement.message} />}
            </>
          </div>

          <div>
            <Input
              label="On delay"
              type="number"
              isRequired={true}
              className={errors?.details?.onDelay && 'error'}
              {...register('details.onDelay', { value: details?.onDelay })}
            />

            {errors?.details?.onDelay && <InputError message={errors?.details?.onDelay.message} />}
          </div>
        </div>
      )}

      {/* Off Delay */}
      {includes(activeSections, 'offDelay') && (
        <div className="grid grid-cols-1 gap-x-4 @sm:grid-cols-2">
          <div>
            <Controller
              control={control}
              defaultValue={find(delayMeasurementOptions, ['value', details?.offDelayUnitOfMeasurement || 'mins'])}
              name="details.offDelayUnitOfMeasurement"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    isMulti={false}
                    isSearchable={true}
                    isRequired={true}
                    label="Off delay unit of measure"
                    options={delayMeasurementOptions}
                    placeholder="Search"
                    hasError={!!errors?.details?.offDelayUnitOfMeasurement}
                  />
                )
              }}
            />
            {errors?.details?.offDelayUnitOfMeasurement && <InputError message={errors?.details?.offDelayUnitOfMeasurement.message} />}
          </div>

          <div>
            <Input
              label="Off delay"
              type="number"
              isRequired={true}
              className={errors?.details?.offDelay && 'error'}
              {...register('details.offDelay', { value: details?.offDelay })}
            />

            {errors?.details?.offDelay && <InputError message={errors?.details?.offDelay.message} />}
          </div>
        </div>
      )}

      {/* Area */}
      {includes(activeSections, 'area') && (
        <div className="grid grid-cols-1 gap-x-4 @sm:grid-cols-2">
          <Controller
            control={control}
            defaultValue={find(areaMeasurementOptions, ['value', details?.areaCoverageUnitOfMeasurement])}
            name="details.areaCoverageUnitOfMeasurement"
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  isMulti={false}
                  isSearchable={true}
                  label="Area coverage unit of measure"
                  options={areaMeasurementOptions}
                  placeholder="Search"
                  hasError={!!errors?.details?.areaCoverageUnitOfMeasurement}
                />
              )
            }}
          />

          {errors?.details?.areaCoverageUnitOfMeasurement && <InputError message={errors?.details?.areaCoverageUnitOfMeasurement.message} />}

          <Input
            label="Area coverage"
            type="number"
            step="0.01"
            className={errors?.details?.areaCoverage && 'error'}
            {...register('details.areaCoverage', { value: details?.areaCoverage })}
          />

          {errors?.details?.areaCoverage && <InputError message={errors?.details?.areaCoverage.message} />}
        </div>
      )}

      {/* Change of State / Reporting */}
      {includes(activeSections, 'changeOfState') && (
        <div className={classNames({ hidden: isEditing || getValues('auxiliary') })}>
          <LightSwitch
            label="Reporting"
            helpTooltip="If enabled, data will be reported to FutureOps and the data will be available for reporting."
            wrapperClassNames="mt-6"
            onToggle={(name, value) => {
              setValue('changeOfState', value)
            }}
            defaultState={changeOfStateEnabled}
            {...register('changeOfState', { value: changeOfStateEnabled })}
          />
        </div>
      )}

      {/* Map Linking */}
      {includes(activeSections, 'mapLinking') && !isEditing && (
        <LightSwitch
          label="Add map linking"
          onToggle={(name, value) => {
            setValue('mapLinking', value)
          }}
          defaultState={mapLinkingEnabled || false}
          {...register('mapLinking', { value: false })}
        />
      )}

      {/* Auxiliary */}
      {includes(activeSections, 'auxiliary') && (
        <div className={classNames({ hidden: isEditing })}>
          <LightSwitch
            label="Auxiliary"
            helpTooltip="Auxiliary I/Os are not assigned to a specific main line, but instead can be utilized by multiple programs within the same site, regardless of each program's main line. This setting cannot be changed once the I/O has been created."
            onToggle={(name, value) => {
              setValue('auxiliary', value)

              if (value) {
                setValue('changeOfState', true)
              } else {
                setValue('changeOfState', changeOfStateEnabled)
              }
            }}
            defaultState={auxiliaryEnabled}
            {...register('auxiliary', { value: auxiliaryEnabled })}
          />
        </div>
      )}

      {/* Logging */}
      {includes(activeSections, 'logging') && (
        <>
          <LightSwitch
            label="Enable logging"
            onToggle={(name, value) => {
              setValue('logging', value)

              if (!value) {
                const fieldsToClear = {
                  loggingType: '',
                  loggingDelta: '',
                  loggingDeltaUnitOfMeasurement: '',
                  loggingIntervalHours: '',
                  loggingIntervalMinutes: '',
                  loggingIntervalSeconds: '',
                  loggingScheduledTimeHours: '',
                  loggingScheduledTimeMinutes: '',
                }

                reset({
                  ...getValues(),
                  ...fieldsToClear,
                })
              }
            }}
            defaultState={loggingEnabled}
            {...register('logging', { value: loggingEnabled })}
          />

          {loggingEnabled && (
            <Logging {...props} inputOutput={inputOutput} loggingTypeOptions={loggingTypeOptions} loggingType={loggingType} />
          )}
        </>
      )}
    </>
  )
}

export default ConditionalFields
