import { head } from 'lodash-es'
import { rem } from 'polished'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import styled from 'styled-components'

import ActionIcon from '@/Components/ActionIcon'
import Card from '@/Components/Card'
import DropdownList from '@/Components/DropdownList'
import PageContainer from '@/Components/layout/helpers/PageContainer'
import AreasManage from '@/Components/tables/AreasManage'
import { modalState } from '@/Config/Atoms/General'
import useApiClient from '@/Utilities/useApiClient'
import useAuth from '@/Utilities/useAuth'
import usePageGuard from '@/Utilities/usePageGuard'
import useTitle from '@/Utilities/useTitle'

const CardContainer = styled.div`
  margin-bottom: 20px;
`

const CardRow = styled.div`
  display: flex;
  font-size: ${rem(14)};
  justify-content: space-between;
  padding: 6px 24px;
`

const CardRowTitle = styled.div`
  align-items: center;
  color: var(--heading-color);
  display: flex;
  font-size: ${rem(14)};
  flex-basis: 50%;
`

const CardRowContent = styled.div`
  color: var(--text-light);
  display: flex;
  font-size: ${rem(14)};
  flex-basis: 50%;
`

const TableContainer = styled.div`
  margin-top: 50px;
`

function UserManage() {
  const apiClient = useApiClient()
  const [user, setUser] = useState()
  const setModal = useSetRecoilState(modalState)
  const { id } = useParams()
  const auth = useAuth()

  usePageGuard({
    auth,
    permission: 'manage-organization-users',
  })

  useTitle(['Managing user', user?.name])

  useEffect(() => {
    (async () => {
      const { data } = await apiClient.get(`/user/profile/${id}?with=role`)

      if (data.success) {
        setUser(data.user)
      }
    })()
  }, [setUser, id])

  return (
    user ?
      <PageContainer contained>
        <div className="mb-5 text-3xl font-light text-slate-900">
          Managing <span className="font-medium">{user.name}</span>
        </div>

        <CardContainer className="col-12 col-md-4">
          <Card
            title={user.name}
            actions={auth.can('update-users') ? (
              <DropdownList
                icon={<ActionIcon />}
                options={[{
                  label: 'Edit profile',
                  onClick: () => {
                    setModal({
                      name: 'userProfile',
                      data: {
                        user: user,
                        onComplete: (updatedUser) => {
                          setModal(null)
                          setUser(updatedUser)
                        },
                      },
                    })
                  },
                }]}
              />
            ) : ''}
          >
            <CardRow>
              <CardRowTitle>Email</CardRowTitle>
              <CardRowContent>{user.email}</CardRowContent>
            </CardRow>

            <CardRow>
              <CardRowTitle>Contact number</CardRowTitle>
              <CardRowContent>{user.number}</CardRowContent>
            </CardRow>

            <CardRow>
              <CardRowTitle>Role</CardRowTitle>
              <CardRowContent>{head(user.roles).name}</CardRowContent>
            </CardRow>
          </Card>
        </CardContainer>

        <TableContainer className="row">
          <div className="col-12 col-md-6 mb-4">
            <AreasManage
              dataUrl={`/user/area/unauthorised/${user.id}`}
              actionType="add"
              tableGroup="areasManage"
              title="Available areas"
              userId={user.id}
            />
          </div>

          <div className="col-12 col-md-6 mb-4">
            <AreasManage
              dataUrl={`/user/area/authorised/${user.id}`}
              actionType="remove"
              tableGroup="areasManage"
              title="User assigned areas"
              userId={user.id}
            />
          </div>
        </TableContainer>
      </PageContainer> : null
  )
}

export default UserManage
