import moment from 'moment'
import { atom } from 'recoil'

export const pageAlertState = atom({
  key: 'alert',
  default: {},
})

export const isLoadingState = atom({
  key: 'isLoading',
  default: false,
})

export const modalState = atom({
  key: 'modal',
  default: null,
})

export const currentTimeState = atom({
  key: 'currentTime',
  default: moment(),
})
