import { cloneDeep, get, includes, indexOf, map, merge } from 'lodash-es'
import * as yup from 'yup'

import { transformToNumber } from '@/Utilities/Validation/Transforms/ToNumber'

const flattenedFields = (from) => {
  if (!from) {
    return {}
  }

  const allFields = map(cloneDeep(from), (from) => {
    return from.value
  })

  return merge(...allFields)
}

const requiresLogging = (type) => {
  const requiredTypes = [
    'inputOutput.analogInput',
    'inputOutput.analogOutput',
    'inputOutput.analogFlowMeter',
    'inputOutput.digitalFlowMeter',
    'inputOutput.sensorSoilProbe',
  ]

  return indexOf(requiredTypes, type) >= 0
}

const inputOutputSchema = yup.object().shape({
  siteId: yup.mixed().label('Site').populatedObject(),
  type: yup.mixed().label('IO type').populatedObject(),
  name: yup.string().label('Name').required().min(3).max(50),
  description: yup.string().label('Description').max(250).nullable(),
  physicalConnection: yup.mixed().label('Physical connection'),
  remoteUnitId: yup.mixed().label('Remote unit').when('physicalConnection', ([physicalConnection], schema) => {
    if (get(physicalConnection, 'value') === 'remoteUnit') {
      return schema.populatedObject()
    }

    return schema.nullable()
  }),
  moduleSlot: yup.mixed().label('Module slot').when(['type'], ([type], schema) => {
    if (includes(type?.value, 'virtualDigitalOutput')) {
      return schema.nullable()
    }

    return schema.populatedObject()
  }),
  channel: yup.mixed().label('Channel').when(['type'], ([type], schema) => {
    if (includes(type?.value, 'virtualDigitalOutput')) {
      return schema.nullable()
    }

    return schema.populatedObject().test('val', 'Channel must be a value between 1 and 32', (option) => {
      return option?.value >= 1 && option?.value <= 32
    })
  }),
  manufacturer: yup.mixed().label('Manufacturer').when('type', ([type], schema) => {
    if (type && type.value === 'inputOutput.sensorSoilProbe') {
      return schema.populatedObject()
    }

    return schema.nullable()
  }),
  modbusSlaveAddress: yup.number().label('Modbus slave address').when(['physicalConnection', 'channel'], ([physicalConnection, channel], schema) => {
    if (get(physicalConnection, 'value') === 'remoteUnit' && get(channel, 'type') === 'modbus') {
      return schema.transform(transformToNumber).nullable().required().min(1).max(255)
    }

    return schema.transform(transformToNumber).nullable()
  }),
  details: yup.object().shape({
    nothing: yup.string().nullable(),
    manufacturerModelId: yup.mixed().label('Model').when('nothing', (nothing, schema, context) => {
      let type = flattenedFields(context?.from)?.type
      let requiredTypes = ['inputOutput.sensorSoilProbe']

      if (indexOf(requiredTypes, type?.value) < 0) {
        return schema.nullable()
      }

      return schema.populatedObject()
    }),
    deltaUnitOfMeasurement: yup.mixed().label('Reporting delta unit of measurement').when('nothing', (nothing, schema, context) => {
      let type = flattenedFields(context?.from)?.type
      let requiredTypes = [
        'inputOutput.analogInput',
        'inputOutput.analogOutput',
        'inputOutput.analogFlowMeter',
        'inputOutput.digitalFlowMeter',
        'inputOutput.sensorSoilProbe',
      ]

      if (indexOf(requiredTypes, type?.value) < 0) {
        return schema.nullable()
      }

      return schema.populatedObject()
    }),
    delta: yup.number().label('Reporting delta value').when('nothing', (nothing, schema, context) => {
      let type = flattenedFields(context?.from)?.type
      let requiredTypes = [
        'inputOutput.analogInput',
        'inputOutput.analogOutput',
        'inputOutput.analogFlowMeter',
        'inputOutput.digitalFlowMeter',
        'inputOutput.sensorSoilProbe',
      ]

      if (indexOf(requiredTypes, type?.value) < 0) {
        return schema.transform(transformToNumber).nullable()
      }

      return schema.transform(transformToNumber).nullable().required()
    }),
    reportingTimeMinutes: yup.number().label('Reporting time').when('nothing', (nothing, schema, context) => {
      let type = flattenedFields(context?.from)?.type
      let requiredTypes = [
        'inputOutput.analogInput',
        'inputOutput.analogOutput',
        'inputOutput.analogFlowMeter',
        'inputOutput.digitalFlowMeter',
        'inputOutput.sensorSoilProbe',
      ]
      if (indexOf(requiredTypes, type?.value) < 0) {
        return schema.transform(transformToNumber).nullable()
      }

      return schema.transform(transformToNumber).nullable().required().min(0).max(59)
    }),
    reportingTimeSeconds: yup.number().label('Reporting time').when('nothing', (nothing, schema, context) => {
      let type = flattenedFields(context?.from)?.type
      let requiredTypes = [
        'inputOutput.analogInput',
        'inputOutput.analogOutput',
        'inputOutput.analogFlowMeter',
        'inputOutput.digitalFlowMeter',
        'inputOutput.sensorSoilProbe',
      ]

      if (indexOf(requiredTypes, type?.value) < 0) {
        return schema.transform(transformToNumber).nullable()
      }

      return schema.transform(transformToNumber).nullable().required().min(0).max(59)
    }),
    stabilizationTimeUnitOfMeasurement: yup.mixed().label('Stabilization time unit of measurement').when('nothing', (nothing, schema, context) => {
      let type = flattenedFields(context?.from)?.type
      let requiredTypes = [
        'inputOutput.analogInput',
        'inputOutput.analogFlowMeter',
        'inputOutput.digitalFlowMeter',
      ]

      if (indexOf(requiredTypes, type?.value) < 0) {
        return schema.nullable()
      }

      return schema.populatedObject()
    }),
    stabilizationTime: yup.number().label('Stabilization time').when('nothing', (nothing, schema, context) => {
      let type = flattenedFields(context?.from)?.type
      let requiredTypes = [
        'inputOutput.analogInput',
        'inputOutput.analogFlowMeter',
        'inputOutput.digitalFlowMeter',
      ]

      if (indexOf(requiredTypes, type?.value) < 0) {
        return schema.transform(transformToNumber).nullable()
      }

      return schema.transform(transformToNumber).nullable().required()
    }),
    scaleUnitOfMeasurement: yup.mixed().label('Scale unit of measurement').when('nothing', (nothing, schema, context) => {
      let type = flattenedFields(context?.from)?.type
      let requiredTypes = [
        'inputOutput.analogInput',
        'inputOutput.analogOutput',
        'inputOutput.analogFlowMeter',
      ]

      if (indexOf(requiredTypes, type?.value) < 0) {
        return schema.nullable()
      }

      return schema.populatedObject()
    }),
    scaleMin: yup.number().label('Scale minimum').when('nothing', (nothing, schema, context) => {
      let type = flattenedFields(context?.from)?.type
      let requiredTypes = [
        'inputOutput.analogInput',
        'inputOutput.analogOutput',
        'inputOutput.analogFlowMeter',
      ]

      if (indexOf(requiredTypes, type?.value) < 0) {
        return schema.transform(transformToNumber).nullable()
      }

      return schema.transform(transformToNumber).nullable().required()
    }).min(0),
    scaleMax: yup.number().label('Scale maximum').when('nothing', (nothing, schema, context) => {
      let type = flattenedFields(context?.from)?.type
      let requiredTypes = [
        'inputOutput.analogInput',
        'inputOutput.analogOutput',
        'inputOutput.analogFlowMeter',
      ]

      if (indexOf(requiredTypes, type?.value) < 0) {
        return schema.transform(transformToNumber).nullable()
      }

      return schema.transform(transformToNumber).nullable().required()
    }).min(0).max(100000),
    areaCoverage: yup.number().label('Area coverage').transform(transformToNumber).nullable(),
    areaCoverageUnitOfMeasurement: yup.mixed().label('Area coverage unit of measurement').nullable(),
    rate: yup.number().label('Rate').transform(transformToNumber).nullable(),
    rateUnitOfMeasurement: yup.mixed().label('Rate unit of measurement').when('rate', ([rate], schema) => {
      if (rate) {
        return schema.populatedObject()
      }

      return schema.nullable()
    }),
    pulseValue: yup.number().label('Pulse value').when('nothing', (nothing, schema, context) => {
      let type = flattenedFields(context?.from)?.type
      let requiredTypes = ['inputOutput.digitalFlowMeter']

      if (indexOf(requiredTypes, type?.value) < 0) {
        return schema.transform(transformToNumber).nullable()
      }

      return schema.transform(transformToNumber).nullable().required()
    }),
    pulseValueUnitOfMeasurement: yup.mixed().label('Pulse value unit of measurement').when('nothing', (nothing, schema, context) => {
      let type = flattenedFields(context?.from)?.type
      let requiredTypes = ['inputOutput.digitalFlowMeter']

      if (indexOf(requiredTypes, type?.value) < 0) {
        return schema.nullable()
      }

      return schema.populatedObject()
    }),
    onDelay: yup.number().label('On delay').when('nothing', (nothing, schema, context) => {
      let type = flattenedFields(context?.from)?.type
      let requiredTypes = [
        'inputOutput.digitalInput',
        'inputOutput.digitalOutputElectricLock',
        'inputOutput.digitalOutputFountain',
        'inputOutput.digitalOutputGeneral',
        'inputOutput.digitalOutputLight',
        'inputOutput.digitalOutputBooster',
      ]

      if (indexOf(requiredTypes, type?.value) < 0) {
        return schema.transform(transformToNumber).nullable()
      }

      return schema.transform(transformToNumber).nullable().required()
    }),
    onDelayUnitOfMeasurement: yup.mixed().label('On delay unit of measurement').when('nothing', (nothing, schema, context) => {
      let type = flattenedFields(context?.from)?.type
      let requiredTypes = [
        'inputOutput.digitalInput',
        'inputOutput.digitalOutputElectricLock',
        'inputOutput.digitalOutputFountain',
        'inputOutput.digitalOutputGeneral',
        'inputOutput.digitalOutputLight',
        'inputOutput.digitalOutputBooster',
      ]

      if (indexOf(requiredTypes, type?.value) < 0) {
        return schema.nullable()
      }

      return schema.populatedObject()
    }),
    offDelay: yup.number().label('Off delay').when('nothing', (nothing, schema, context) => {
      let type = flattenedFields(context?.from)?.type
      let requiredTypes = [
        'inputOutput.digitalInput',
        'inputOutput.digitalOutputElectricLock',
        'inputOutput.digitalOutputFountain',
        'inputOutput.digitalOutputGeneral',
        'inputOutput.digitalOutputLight',
        'inputOutput.digitalOutputBooster',
      ]

      if (indexOf(requiredTypes, type?.value) < 0) {
        return schema.transform(transformToNumber).nullable()
      }

      return schema.transform(transformToNumber).nullable().required()
    }),
    offDelayUnitOfMeasurement: yup.mixed().label('Off delay unit of measurement').when('nothing', (nothing, schema, context) => {
      let type = flattenedFields(context?.from)?.type
      let requiredTypes = [
        'inputOutput.digitalInput',
        'inputOutput.digitalOutputElectricLock',
        'inputOutput.digitalOutputFountain',
        'inputOutput.digitalOutputGeneral',
        'inputOutput.digitalOutputLight',
        'inputOutput.digitalOutputBooster',
      ]

      if (indexOf(requiredTypes, type?.value) < 0) {
        return schema.nullable()
      }

      return schema.populatedObject()
    }),
  }),
  logging: yup.boolean(),
  loggingType: yup.mixed().label('Logging type').when(['type', 'logging'], ([type, logging], schema) => {
    if (requiresLogging(type?.value) && logging) {
      return schema.populatedObject()
    }

    return schema.nullable()
  }),
  loggingScheduledTimeHours: yup.number().label('Scheduled time hours').when(['type', 'loggingType'], ([type, loggingType], schema) => {
    if (requiresLogging(type?.value) && loggingType?.value === 'time') {
      return schema.transform(transformToNumber).nullable().required().min(0).max(23)
    }

    return schema.transform(transformToNumber).nullable()
  }),
  loggingScheduledTimeMinutes: yup.number().label('Scheduled time minutes').when(['type', 'loggingType'], ([type, loggingType], schema) => {
    if (requiresLogging(type?.value) && loggingType?.value === 'time') {
      return schema.transform(transformToNumber).nullable().required().min(0).max(59)
    }

    return schema.transform(transformToNumber).nullable()
  }),
  loggingIntervalHours: yup.number().label('Interval hours').when(['type', 'loggingType'], ([type, loggingType], schema) => {
    if (requiresLogging(type?.value) && loggingType?.value === 'interval') {
      return schema.transform(transformToNumber).nullable().required().min(0).max(23)
    }

    return schema.transform(transformToNumber).nullable()
  }),
  loggingIntervalMinutes: yup.number().label('Interval minutes').when(['type', 'loggingType'], ([type, loggingType], schema) => {
    if (requiresLogging(type?.value) && loggingType?.value === 'interval') {
      return schema.transform(transformToNumber).nullable().required().min(0).max(59)
    }

    return schema.transform(transformToNumber).nullable()
  }),
  loggingIntervalSeconds: yup.number().label('Interval minutes').when(['type', 'loggingType'], ([type, loggingType], schema) => {
    if (requiresLogging(type?.value) && loggingType?.value === 'interval') {
      return schema.transform(transformToNumber).nullable().required().min(0).max(59)
    }

    return schema.transform(transformToNumber).nullable()
  }),
  loggingDeltaUnitOfMeasurement: yup.mixed().label('Delta unit of measure').when(['type', 'loggingType'], ([type, loggingType], schema) => {
    if (requiresLogging(type?.value) && loggingType?.value === 'delta') {
      return schema.populatedObject()
    }

    return schema.nullable()
  }),
  loggingDelta: yup.number().label('Delta').when(['type', 'loggingType'], ([type, loggingType], schema) => {
    if (requiresLogging(type?.value) && loggingType?.value === 'delta') {
      return schema.transform(transformToNumber).nullable().required().min(0).max(100000)
    }

    return schema.transform(transformToNumber).nullable()
  }),
})

export default inputOutputSchema
