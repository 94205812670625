import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/react'
import Tippy from '@tippyjs/react'
import { get, isEmpty, isFunction, join, map } from 'lodash-es'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import styled from 'styled-components'
import * as yup from 'yup'

import AlertContent from '@/Components/alerts/AlertContent'
import { Anchor, Button } from '@/Components/form/Buttons'
import Input from '@/Components/form/Input'
import InputError from '@/Components/form/InputError'
import Modal from '@/Components/Modal'
import Pill from '@/Components/pill/Pill'
import { isLoadingState, pageAlertState } from '@/Config/Atoms/General'
import useApiClient from '@/Utilities/useApiClient'
import useEntityMonitor from '@/Utilities/useEntityMonitor'
import usePendingChange from '@/Utilities/usePendingChange'

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
`

const schema = () => {
  let validationObject = {
    confirm: yup.string().label('Confirm').required('Due to the destructive nature of this irreversible action, please type "DELETE" to proceed.').test({
      name: 'confirmation',
      message: 'Due to the destructive nature of this irreversible action, please type "DELETE" to proceed.',
      test: (value) => {
        return value === 'DELETE'
      },
    }),
  }

  return yup.object(validationObject)
}

function WarningPromptModal(props) {
  const apiClient = useApiClient()
  const setPageAlert = useSetRecoilState(pageAlertState)
  const [isLoading, setIsLoading] = useRecoilState(isLoadingState)
  const navigate = useNavigate()
  const {
    isInRunningState,
    getProgramDisabledReason,
  } = useEntityMonitor()

  const {
    hasPendingChange,
    getPendingChangeDisabledReason,
  } = usePendingChange()

  const {
    register,
    trigger,
    watch,
    formState,
  } = useForm({ resolver: yupResolver(schema()) })

  const { errors } = formState
  const confirmationValue = watch('confirm')

  /**
   * If savePreventionState is provided to the warning modal, it will attempt to check the current
   * running state of the provided model and ID. If isInRunningState() returns true, then the
   * Confirm button will be disabled with a reason.
   *
   * This aims to prevent the user from confirming/deleting an entity associated to a running
   * program.
   */
  const { savePreventionState } = props.data
  const preventConfirmation = savePreventionState && isInRunningState(savePreventionState.model, savePreventionState.id) || hasPendingChange(savePreventionState)
  const runningProgramDisableReason = savePreventionState && getProgramDisabledReason(savePreventionState.model, savePreventionState.id)
  const pendingChangeDisableReason = getPendingChangeDisabledReason(savePreventionState)

  /**
   * Handle form submission.
   */
  const handleFormSubmit = (e) => {
    e.preventDefault()
    confirm()
  }

  const confirm = async () => {
    let postData = {}

    if (!isEmpty(props.data?.postData)) {
      postData = { ...props.data?.postData }
    }

    if (props.data?.deleteConfirmation) {
      if (confirmationValue !== 'DELETE') {
        trigger('confirm')
        return
      } else {
        postData.confirm = confirmationValue
      }
    }

    try {
      setIsLoading(true)
      let { data } = await apiClient.post(`${props.data.endpoint}`, postData)

      if (data && data.success) {
        if (props.data?.redirect) {
          navigate(props.data?.redirect)
        }

        if (isFunction(props.data?.onComplete)) {
          props.data.onComplete()
        }

        setPageAlert({
          type: 'success',
          content: props.data.successFlashMessage,
        })

        props.close()
      }
    } catch (error) {
      Sentry.captureException(error)

      if (isFunction(props.data?.onFailure)) {
        props.data.onFailure()
      }

      if (props.data?.errorFlashMessage) {
        setPageAlert({
          type: 'error',
          content: props.data.errorFlashMessage,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      icon={<i className="fa-regular fa-triangle-exclamation fa-xl"></i>}
      type="error-prompt"
      title={props.data?.title}
      close={props.close}
      closeOnOutsideClick={props.closeOnOutsideClick}
      modalWidth="544px"
    >
      {preventConfirmation && (
        <AlertContent className="mb-4">
          {runningProgramDisableReason || pendingChangeDisableReason}
        </AlertContent>
      )}

      {(!preventConfirmation && get(props.data, 'content')) && (
        <div className="text-slate-500">
          {props.data.content}
        </div>
      )}

      {get(props.data, 'affectedRelationships') && (
        <div className="mt-3">
          <ul className="mt-1 inline-flex flex-col">
            {map(props.data.affectedRelationships, (relationship, index) => {
              return <Tippy content={join(relationship.names, ', ')} key={index} theme="light">
                <li key={index}>
                  <span className="mr-1">{index}:</span>
                  <Pill>{relationship.count}</Pill>
                </li>
              </Tippy>
            })}
          </ul>
        </div>
      )}

      {get(props.data, 'deleteConfirmation') && (
        <form autoComplete="off" className="mt-3 select-none" onSubmit={handleFormSubmit}>
          <Input
            name="confirm"
            label="Type DELETE to confirm"
            isRequired={true}
            className={errors.confirm && 'error'}
            {...register('confirm', { value: '' })}
          />
          {errors.confirm && <InputError message={errors.confirm.message} />}
        </form>
      )}

      <ButtonGroup>
        <Anchor
          style={{ width: preventConfirmation ? '100%' : 'calc(50% - 5px)' }}
          className="transparent"
          onClick={() => {
            props.close()
          }}
        >
          Close
        </Anchor>

        {!preventConfirmation && (
          <Button
            style={{ width: 'calc(50% - 5px)' }}
            className="warning"
            onClick={() => {
              confirm()
            }}
            disabled={(isLoading ? true : false) || props.data.disableConfirmButton}
            disabledWithReason={preventConfirmation && (runningProgramDisableReason || pendingChangeDisableReason)}
          >
            {isLoading ? <div className="primary-loader light"></div> : 'Confirm'}
          </Button>
        )}
      </ButtonGroup>
    </Modal>
  )
}

export default WarningPromptModal
