import { isEmpty, map, startCase } from 'lodash-es'

export default function Fertigation(props) {
  const { program } = props

  return (
    !isEmpty(program.fertigationPumps) ? (
      <div className="grid grid-cols-3 gap-3 text-sm">
        {
          map(program.fertigationPumps, (fertigationPump) => {
            return (
              <div className="grid grid-cols-2 gap-6 rounded border border-slate-200 p-6 shadow">
                <div>
                    Fert basis
                </div>

                <div className="text-slate-500">
                  {startCase(fertigationPump.fertigationBasis)}
                </div>

                <div>
                    Fert method
                </div>

                <div className="text-slate-500">
                  {startCase(fertigationPump.fertigationMethod)}
                </div>

                <div>
                    Fert quantity
                </div>

                <div className="text-slate-500">
                  {fertigationPump.fertigationQuantity}{fertigationPump.fertigationQuantityUnitOfMeasurement}
                </div>

                <div>
                    Water before
                </div>

                <div className="text-slate-500">
                  {fertigationPump.fertigationWaterBefore}
                </div>

                <div>
                    Water after
                </div>

                <div className="text-slate-500">
                  {fertigationPump.fertigationWaterAfter}
                </div>

                <div>
                    Stop on no flow
                </div>

                <div className="text-slate-500">
                  {fertigationPump.stopIrrigationOnFertigationNoFlow == 1 ? 'Yes' : 'No'}
                </div>
              </div>
            )
          })
        }
      </div>
    ) : (
      <div className="rounded border border-slate-200 bg-white p-6 text-center text-sm text-slate-500 shadow">
          No fertigation pumps configured.
      </div>
    )
  )
}
