import { filter, head, split } from 'lodash-es'
import { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import Input from '@/Components/form/Input'
import InputError from '@/Components/form/InputError'
import InputLabel from '@/Components/form/InputLabel'
import RequiredAsterisk from '@/Components/form/RequiredAsterisk'
import Select from '@/Components/form/Select'

const GroupedInputs = styled.div`
  align-items: center;
  display: flex;

  input {
    margin-right: 20px;
  }
`

const TimeSeperator = styled.div`
  margin: 0 10px 0 -10px;
`

function Logging(props) {
  const {
    control,
    register,
    errors,
    reset,
    getValues,
  } = useFormContext()

  let inputOutput

  if (props.data?.io || props?.inputOutput) {
    inputOutput = useMemo(() => {
      return props.data?.io || props?.inputOutput
    }, [props.data?.io, props?.inputOutput])
  }

  const loggingDeltaMeasurementOptions = useMemo(() => {
    return [
      {
        value: 'ph',
        label: 'pH',
      },
      {
        value: 'rli',
        label: 'Reservoir level inch',
      },
      {
        value: 'rlcm',
        label: 'Reservoir level cm',
      },
      {
        value: 'psi',
        label: 'PSI',
      },
      {
        value: 'hz',
        label: 'Hz',
      },
    ]
  }, [])

  const loggingScheduledTime = useMemo(() => {
    const time = split(inputOutput?.loggingScheduledTime, ':', 2)

    return {
      hours: time[0] || null,
      minutes: time[1] || null,
    }
  })

  const loggingInterval = useMemo(() => {
    const time = split(inputOutput?.loggingInterval, ':', 3)

    return {
      hours: time[0] || null,
      minutes: time[1] || null,
      seconds: time[2] || null,
    }
  }, [inputOutput?.loggingInterval])

  return (
    <>
      <div className="grid grid-cols-1">
        <div>
          <Controller
            control={control}
            name="loggingType"
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  isMulti={false}
                  isSearchable={true}
                  label="Logging type"
                  options={props.loggingTypeOptions}
                  placeholder="Search"
                  hasError={!!errors?.loggingType}
                  afterChange={() => {
                    const fieldsToClear = {
                      loggingDelta: '',
                      loggingDeltaUnitOfMeasurement: '',
                      loggingIntervalHours: '',
                      loggingIntervalMinutes: '',
                      loggingIntervalSeconds: '',
                      loggingScheduledTimeHours: '',
                      loggingScheduledTimeMinutes: '',
                    }

                    reset({
                      ...getValues(),
                      ...fieldsToClear,
                    })
                  }}
                />
              )
            }}
          />
          {errors?.loggingType && <InputError message={errors?.loggingType.message} />}
        </div>
      </div>

      {props.loggingType?.value === 'interval' && (
        <div className="grid grid-cols-1 gap-x-4 @sm:grid-cols-2">
          <div>
            <InputLabel>
              <div>
                Interval <RequiredAsterisk />
              </div>
            </InputLabel>

            <GroupedInputs>
              <Input
                placeholder="hh"
                maxlength="2"
                type="number"
                width="60px"
                textCenter
                className={errors.loggingIntervalHours && 'error'}
                {...register('loggingIntervalHours', { value: loggingInterval.hours })}
              />

              <TimeSeperator>
                :
              </TimeSeperator>

              <Input
                placeholder="mm"
                maxlength="2"
                type="number"
                width="60px"
                textCenter
                className={errors.loggingIntervalMinutes && 'error'}
                {...register('loggingIntervalMinutes', { value: loggingInterval.minutes })}
              />

              <TimeSeperator>
                :
              </TimeSeperator>

              <Input
                placeholder="ss"
                maxlength="2"
                type="number"
                width="60px"
                textCenter
                className={errors.loggingIntervalSeconds && 'error'}
                {...register('loggingIntervalSeconds', { value: loggingInterval.seconds })}
              />
            </GroupedInputs>

            {
              (
                (
                  errors.loggingIntervalHours?.type === 'min' ||
                  errors.loggingIntervalHours?.type === 'max' ||
                  errors.loggingIntervalMinutes?.type === 'min' ||
                  errors.loggingIntervalMinutes?.type === 'max' ||
                  errors.loggingIntervalSeconds?.type === 'min' ||
                  errors.loggingIntervalSeconds?.type === 'max'
                ) &&
                <InputError message="Scheduled time should be between 00:00 and 23:59:59" />
              ) ||
              (
                (errors.loggingIntervalHours || errors.loggingIntervalMinutes || errors.loggingIntervalSeconds) &&
                <InputError message="Scheduled time is a required field" />
              )
            }
          </div>
        </div>
      )}

      {props.loggingType?.value === 'time' && (
        <div className="grid grid-cols-1">
          <div>
            <InputLabel>
              <div>
                Scheduled time <RequiredAsterisk />
              </div>
            </InputLabel>

            <GroupedInputs>
              <Input
                placeholder="hh"
                maxlength="2"
                type="number"
                width="60px"
                textCenter
                className={errors.loggingScheduledTimeHours && 'error'}
                {...register('loggingScheduledTimeHours', { value: loggingScheduledTime.hours })}
              />

              <TimeSeperator>
                :
              </TimeSeperator>

              <Input
                placeholder="mm"
                maxlength="2"
                type="number"
                width="60px"
                textCenter
                className={errors.loggingScheduledTimeMinutes && 'error'}
                {...register('loggingScheduledTimeMinutes', { value: loggingScheduledTime.minutes })}
              />
            </GroupedInputs>

            {
              (
                (
                  errors.loggingScheduledTimeHours?.type === 'min' ||
                  errors.loggingScheduledTimeHours?.type === 'max' ||
                  errors.loggingScheduledTimeMinutes?.type === 'min' ||
                  errors.loggingScheduledTimeMinutes?.type === 'max'
                ) &&
                <InputError message="Scheduled time should be between 00:00 and 23:59" />
              ) ||
              (
                (errors.loggingScheduledTimeHours || errors.loggingScheduledTimeMinutes) &&
                <InputError message="Scheduled time is a required field" />
              )
            }
          </div>
        </div>
      )}

      {props.loggingType?.value === 'delta' && (
        <div className="grid grid-cols-1 gap-x-4 @sm:grid-cols-2">
          <div>
            <Controller
              control={control}
              defaultValue={head(filter(loggingDeltaMeasurementOptions, ['value', inputOutput?.loggingDeltaUnitOfMeasurement]))}
              name="loggingDeltaUnitOfMeasurement"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    isMulti={false}
                    isSearchable={true}
                    label="Logging delta unit of measure"
                    isRequired={true}
                    options={loggingDeltaMeasurementOptions}
                    placeholder="Search"
                    hasError={!!errors?.loggingDeltaUnitOfMeasurement}
                  />
                )
              }}
            />
            {errors?.loggingDeltaUnitOfMeasurement && <InputError message={errors?.loggingDeltaUnitOfMeasurement.message} />}
          </div>

          <div>
            <Input
              label="Delta value"
              isRequired={true}
              helpTooltip={(
                <div>
                  <div className="mb-2">
                    The delta value determines the degree of change required for
                    an option to be reported to the dashboard, optimizing communication
                    efficiency.
                  </div>

                  <div className="mb-2">
                    Adjust the delta value to suit your preference for reporting
                    changes in analog values. Higher delta values result in fewer
                    transmissions for smaller shifts, while lower delta values enable
                    more frequent reporting of even slight changes.
                  </div>

                  <div>
                    Keep in mind that excessive transmissions can strain the radio system.
                    Set an appropriate delta value to avoid overwhelming the radio with
                    constant updates and ensure optimal performance and reliability.
                  </div>
                </div>
              )}
              type="number"
              className={errors?.loggingDelta && 'error'}
              {...register('loggingDelta', { value: inputOutput?.loggingDelta })}
            />

            {errors?.loggingDelta && <InputError message={errors?.loggingDelta.message} />}
          </div>
        </div>
      )}
    </>
  )
}

export default Logging
