import { every, get, includes, initial, isEmpty, isPlainObject, keys, last, map, size } from 'lodash-es'
import { ray } from 'node-ray/web'
import format from 'pretty-format'
import { useEffect, useRef } from 'react'

/**
 * List of valid options for configuring the Ray instance.
 * @type {Array<string>}
 * - replace: Determines if the current Ray instance should be replaced with a new one.
 * - color: Sets the color of the message background in the Ray application.
 * - confetti: Triggers a confetti animation in the Ray application if set to true.
 * - toJson: If true, converts the message content to JSON format before sending to Ray.
 * - label: Sets a label for the message in the Ray application.
 */
const validOptions = [
  'replace',
  'color',
  'confetti',
  'toJson',
  'label',
]

/**
 * Custom hook for sending messages to Ray, with support for useEffect to send updates.
 * @param {...any} args Arguments to be sent to Ray, including options object.
 * @example
 *
 * Example 1: Sends someState to Ray with specific options. Will replace the current Ray instance and set the message color to blue.
 * useRay(someState, {
 *   color: 'blue',
 *   replace: true,
 * })
 */
export const useRay = (...args) => {
  if (import.meta.env.mode !== 'production' && import.meta.env.BUILD_DEBUG) {
    const [filteredArgs, options] = prepareArgs(args)

    const rayRef = useRef(ray())

    useEffect(() => {
      let rayInstance = get(options, 'replace', false) === true ? rayRef.current : ray()
      let method = options.toJson ? 'toJson' : 'send'

      rayInstance[method](...filteredArgs)

      setOptions(rayInstance, options)
    }, [args])
  }
}

/**
 * Custom hook for sending a message to Ray once on component mount.
 * @param {...any} args Arguments to be sent to Ray, including options object.
 *
 * Example 1: Sends someState to Ray once, with the message background color set to red and triggers confetti animation.
 * useRayOnce(someState, {
 *   color: 'red',
 *   confetti: true,
 * })
 */
export const useRayOnce = (...args) => {
  if (import.meta.env.mode !== 'production' && import.meta.env.BUILD_DEBUG) {
    const [filteredArgs, options] = prepareArgs(args)

    useEffect(() => {
      let rayInstance = ray()
      let method = options.toJson ? 'toJson' : 'send'

      rayInstance[method](...filteredArgs)

      setOptions(rayInstance, options)
    }, [])
  }
}

/**
 * Function to send a message to Ray immediately, without useEffect.
 * @param {...any} args Arguments to be sent to Ray, including options object.
 * @example
 *
 * Example 1: Sends a simple object to Ray with the request to convert it to JSON. This is not tied to any component lifecycle.
 * rayOnce({ key: 'value' }, { toJson: true })
 */
export const rayOnce = (...args) => {
  if (import.meta.env.mode !== 'production' && import.meta.env.BUILD_DEBUG) {
    const [filteredArgs, options] = prepareArgs(args)

    let rayInstance = ray()
    let method = options.toJson ? 'toJson' : 'send'

    rayInstance[method](...filteredArgs)

    setOptions(rayInstance, options)
  }
}

/**
 * Applies the specified options to the given Ray instance.
 * @param {object} rayInstance Instance of Ray to configure.
 * @param {object} options Options to apply to the Ray instance.
 */
const setOptions = (rayInstance, options) => {
  if (options.color) {
    rayInstance.color(options.color)
  }

  if (options.confetti === true) {
    rayInstance.confetti()
  }

  if (options.label) {
    rayInstance.label(options.label)
  }
}

/**
 * Prepares the arguments for sending to Ray by filtering and formatting them.
 * @param {Array<any>} args The arguments to process, including the options object if present.
 * @returns {Array} Filtered and possibly transformed arguments along with the options object.
 */
const prepareArgs = (args) => {
  const potentialOptions = last(args)
  let options = {}
  let filteredArgs = []

  if (args.length > 1 && isPlainObject(potentialOptions) && size(potentialOptions)) {
    const isValidOptions = every(keys(potentialOptions), (key) => {
      return includes(validOptions, key)
    })

    if (isValidOptions) {
      options = potentialOptions
      filteredArgs = initial(args)

      if (!options.toJson) {
        filteredArgs = map(filteredArgs, (arg) => {
          const isPrimitive = includes([
            'string',
            'number',
            'boolean',
            'null',
            'undefined',
          ], typeof arg)

          if (isPrimitive) {
            return `${typeof arg}\n${arg}`
          } else {
            return format(arg)
          }
        })
      }
    }
  }

  if (isEmpty(filteredArgs)) {
    filteredArgs = args
  }

  return [filteredArgs, options]
}
