import classNames from 'classnames'
import { isUndefined } from 'lodash-es'
import { useCallback, useEffect, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'

export default function AlertItem(props) {
  const {
    children,
    closeAlert,
    duration,
    isCloseable,
    isPermanent,
    type,
    uuid,
  } = props

  const [showAlert, setShowAlert] = useState(false)
  const [inTimeout, setInTimeout] = useState(300)
  const transitionElement = useRef()

  useEffect(() => {
    // Show the alert as soon as the component has mounted.
    // This ensure the alert element exists in the DOM before
    // the transition classes are added. This is required for
    // CSS transitions otherwise there is no initial state.
    setShowAlert(true)
  }, [])

  useEffect(() => {
    let timer

    if (!isPermanent) {
      timer = setTimeout(() => {
        setShowAlert(false)
      }, duration * 1000)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [])

  const iconClass = useCallback((type) => {
    let icon

    switch (type) {
      case 'success':
        icon = 'fa-light fa-circle-check fa-2xl'
        break

      case 'error':
      case 'warning':
        icon = 'fa-light fa-circle-exclamation fa-2xl'
        break

      case 'info':
      default:
        icon = 'fa-light fa-circle-info fa-2xl'
        break
    }

    return icon
  }, [])

  const alertClasses = useCallback((type) => {
    let classes

    switch (type) {
      case 'success':
        classes = 'bg-emerald-600 text-white'
        break

      case 'error':
      case 'warning':
        classes = 'bg-red-600 text-white'
        break

      case 'info':
      default:
        classes = 'bg-blue-600 text-white'
        break
    }

    return classes
  }, [])

  return (
    <CSSTransition
      timeout={inTimeout}
      in={showAlert}
      classNames={{
        enter: 'opacity-0',
        enterActive: 'transition-opacity duration-300 opacity-100',
        exit: '',
        exitActive: 'transition-all duration-300 opacity-0 -translate-x-20',
        exitDone: 'hidden',
      }}
      nodeRef={transitionElement}
      onExited={() => {
        setShowAlert(false)
        closeAlert(uuid)
      }}
    >
      <div ref={transitionElement} className={classNames(alertClasses(type), 'grid w-full grid-cols-2-right-auto rounded-md overflow-hidden')}>
        <div className="grid grid-cols-2-left-auto items-center p-5 font-medium">
          <i className={classNames('pr-4', iconClass(type))}></i>

          <div>
            {children}
          </div>
        </div>

        {(isUndefined(isCloseable) || isCloseable) && (
          <div
            className="flex cursor-pointer items-center bg-black/20 p-5 px-6 text-right hover:bg-black/30"
            onClick={() => {
              setInTimeout(1)
              setShowAlert(false)
              closeAlert(uuid)
            }}
          >
            <i className="fa-regular fa-xmark fa-xl"></i>
          </div>
        )}
      </div>
    </CSSTransition>
  )
}
