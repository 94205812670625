export const breakpoints = {
  xs: 0,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
}

export const mediaBreakpointUp = (breakpoint, styles) => {
  return `
    @media (min-width: ${breakpoints[breakpoint] || breakpoint}px) {
      ${styles}
    }
  `
}

export const mediaBreakpointDown = (breakpoint, styles) => {
  return `
    @media (max-width: ${(breakpoints[breakpoint] || breakpoint) - 0.02}px) {
      ${styles}
    }
  `
}
