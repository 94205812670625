import PageContainer from '@/Components/layout/helpers/PageContainer'
import AlarmsAll from '@/Components/tables/AlarmsAll'
import useAuth from '@/Utilities/useAuth'
import usePageGuard from '@/Utilities/usePageGuard'
import useTitle from '@/Utilities/useTitle'

function AlarmIndex() {
  const auth = useAuth()

  usePageGuard({
    auth,
    permission: 'view-alarm',
  })

  useTitle('All alarms')

  return (
    <>
      <PageContainer contained>
        <div className="mb-5 text-3xl font-light text-slate-900">
          All <span className="font-medium">alarms</span>
        </div>

        <AlarmsAll />
      </PageContainer>
    </>
  )
}

export default AlarmIndex
