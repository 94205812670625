export const statusBackgroundColorClass = (status) => {
  let backgroundClass = null

  switch (status) {
    case 'running':
      backgroundClass = 'bg-status-running'
      break
    case 'queued':
      backgroundClass = 'bg-status-queued'
      break
    case 'stopped':
      backgroundClass = 'bg-status-stopped'
      break
    case 'finished':
      backgroundClass = 'bg-status-finished'
      break
    case 'failure':
      backgroundClass = 'bg-status-failure'
      break
    case 'paused':
      backgroundClass = 'bg-status-paused'
      break
    case 'alarm':
      backgroundClass = 'bg-status-alarm'
      break

    default:
      break
  }

  return backgroundClass
}
