import Tippy from '@tippyjs/react'
import { isFunction } from 'lodash-es'
import { darken } from 'polished'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { twMerge } from 'tailwind-merge'

const ButtonStyles = (props) => {
  return `
    background-color: ${props.disabledStyles ? '#9ca3af' : 'var(--primary)'};
    border-radius: 5px;
    border: none;
    color: #ffffff;
    cursor: ${props.disabledStyles ? 'not-allowed' : 'pointer'};
    display: inline-block;
    font-size: 16px;
    line-height: 44px;
    padding: 0 20px;
    text-align: center;
    text-decoration: none;
    transition: all 0.2s ease;
    width: auto;

    &:hover {
      background-color: ${darken(0.2, props.disabledStyles ? '#9ca3af' : '#1570ef')};
    }

    &.transparent {
      background-color: #ffffff;
      border: 1px solid #d0d5dd;
      color: #344054;

      &:hover {
        background-color: #d0d5dd;
      }
    }

    &.warning {
      background-color: var(--error-text);
      border: 1px solid #d0d5dd;

      &:hover {
        background-color: ${darken(0.2, '#d92d20')};
      }
    }

    &.success {
      background-color: #12B76A;

      &:hover {
        background-color: ${darken(0.2, '#12B76A')};
      }
    }

    &.cancel {
      background-color: #a2a3a7;

      &:hover {
        background-color: #b71212;
      }
    }
  `
}

const StyledRouterButton = styled(Link)`
  ${(props) => {
    return ButtonStyles(props)
  }}
`

const StyledButton = styled.button`
  ${(props) => {
    return ButtonStyles(props)
  }}
`

const StyledAnchor = styled.a`
  ${(props) => {
    return ButtonStyles(props)
  }}
`

const LoaderWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 44px;
  justify-content: center;
`

function RouterButton(props) {
  return (
    <StyledRouterButton to={props.href} {...props}>
      {props.children}
    </StyledRouterButton>
  )
}

function Button(props) {
  return (
    <Tippy
      theme="light"
      disabled={!props.disabledWithReason}
      content={props.disabledWithReason}
    >
      <StyledButton
        {...props}
        disabled={false}
        disabledStyles={props.disabled || props.disabledWithReason}
        onClick={(event) => {
          if (props.disabled || props.disabledWithReason) {
            event.preventDefault()
            return false
          }

          if (isFunction(props.onClick)) {
            props.onClick()
          }
        }}
      >
        {
          props.isLoading ? (
            <LoaderWrapper>
              <div className="primary-loader light"></div>
            </LoaderWrapper>
          ) : props.children
        }
      </StyledButton>
    </Tippy>
  )
}

function Anchor(props) {
  return (
    <StyledAnchor
      className={props.className}
      disabled={props.isLoading}
      {...props}
    >
      {
        props.isLoading ? (
          <LoaderWrapper>
            <div className="primary-loader light"></div>
          </LoaderWrapper>
        ) : props.children
      }
    </StyledAnchor>
  )
}

/**
 * RoundedIconButton component
 *
 * @param className
 * @param icon
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function RoundedIconButton({
  className,
  icon,
  ...props
}) {
  return (
    <button
      {...props}
      type="button"
      className={
        twMerge(
          'rounded-full align-middle bg-primary text-lg text-white w-10 h-10 outline-0',
          className,
        )
      }
    >
      <i className={twMerge('fa-solid', `fa-${icon}`)}></i>
    </button>
  )
}

export {
  Anchor,
  Button,
  RoundedIconButton,
  RouterButton,
}
