import Echo from 'laravel-echo'
import { clamp, startsWith, trimStart } from 'lodash-es'
import Pusher from 'pusher-js'
import { useEffect } from 'react'

import { publish } from '@/Utilities/Events'
import { formatKeys } from '@/Utilities/Form/Formatter'
import useApiClient from '@/Utilities/useApiClient'
import useAuth from '@/Utilities/useAuth'
import { rayOnce } from '@/Utilities/useRayDebug'

window.Pusher = Pusher

let echo
let tries = 1
const maxRetryDelay = 10000 // 10 seconds

export default function useEcho() {
  const apiClient = useApiClient()
  const {
    loggedIn,
    user,
  } = useAuth()

  const initEcho = () => {
    if (!echo && loggedIn) {
      echo = new Echo({
        broadcaster: 'pusher',
        key: import.meta.env.VITE_PUSHER_APP_KEY,
        cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
        forceTLS: false,
        authorizer: (channel) => {
          return {
            authorize: (socketId, callback) => {
              apiClient({
                method: 'post',
                url: import.meta.env.VITE_API_URL + '/api/broadcasting/auth',
                withCredentials: true,
                data: {
                  socket_id: socketId,
                  channel_name: channel.name,
                },
              })
                .then((response) => {
                  callback(false, response.data)
                })
                .catch((error) => {
                  callback(true, error)
                  rayOnce(error, {
                    color: 'red',
                    label: 'Error',
                  })
                })
            },
          }
        },
      })

      const currentChannel = echo.private(`user.${user.id}`)
        .listenToAll((event, { data }) => {
          rayOnce(event, data, {
            toJson: true,
            color: 'orange',
            label: 'Echo',
          })

          publish(trimStart(event, '.'), formatKeys(data, 'camel'))
        })

      echo.connector.pusher.connection.bind('error', function(error) {
        // Unbind all events
        try {
          echo.connector.pusher.connection.unbind_all()
        } catch (error) {
          rayOnce(error, {
            color: 'red',
            label: 'Error',
          })
        }

        // Leave the channel
        if (currentChannel) {
          try {
            echo.leave(`user.${user.id}`)
          } catch (error) {
            rayOnce(error, {
              color: 'red',
              label: 'Error',
            })
          }
        }

        // Throw the error provided by pusher
        throw error
      })

      if (import.meta.env.MODE === 'development') {
        echo.connector.pusher.connection.bind('message', function(event) {
          if (startsWith('.', event?.event)) {
            rayOnce(event, {
              color: 'orange',
              label: 'Echo',
            })
          }
        })

        echo.connector.pusher.connection.bind('connected', function() {
          rayOnce('Connected', {
            color: 'orange',
            label: 'Echo',
          })
        })

        echo.connector.pusher.connection.bind('disconnected', function() {
          rayOnce('Disconnected', {
            color: 'orange',
            label: 'Echo',
          })
        })
      }
    }
  }

  useEffect(() => {
    try {
      initEcho()
    } catch (error) {
      echo = null

      // Retry backoff, with a max delay
      const delay = clamp(tries * 2000, 0, maxRetryDelay)

      setTimeout(() => {
        tries ++
        initEcho()
      }, delay)
    }

    return () => {
      if (echo) {
        try {
          echo.connector.pusher.connection.unbind_all()
          echo.leave(`user.${user.id}`)
        } catch (error) {
          rayOnce(error, {
            color: 'red',
            label: 'Error',
          })
        }
      }

      // Reset echo to null and reset tries
      echo = null
      tries = 1
    }
  }, [loggedIn])

  return echo
}
