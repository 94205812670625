import classNames from 'classnames'
import { twMerge } from 'tailwind-merge'

export default function HorizontalProgressBar({
  colorName = 'gray',
  percent,
  className,
  style,
}) {
  const wrapperClassName = twMerge(
    'flex overflow-hidden rounded-full text-xs w-full h-2 min-h-2',
    `bg-${colorName}/50`,
    className,
  )

  const barClassName = classNames(
    'flex flex-col justify-center whitespace-nowrap text-center text-white shadow-none rounded-full',
    `bg-${colorName}`,
  )

  return (
    <div className="relative inline-block align-middle" style={style}>
      <div className={wrapperClassName}>
        <div style={{ width: `${percent}%` }} className={barClassName} />
      </div>
    </div>
  )
}
