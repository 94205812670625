import { useSetRecoilState } from 'recoil'

import PermissionGuard from '@/Components/auth/PermissionGuard'
import { Anchor, RoundedIconButton } from '@/Components/form/Buttons'
import PageContainer from '@/Components/layout/helpers/PageContainer'
import AreasAll from '@/Components/tables/AreasAll'
import { modalState } from '@/Config/Atoms/General'
import useAuth from '@/Utilities/useAuth'
import usePageGuard from '@/Utilities/usePageGuard'
import useTitle from '@/Utilities/useTitle'

function AreaIndex() {
  const setModal = useSetRecoilState(modalState)
  const auth = useAuth()

  usePageGuard({
    auth,
    permission: 'view-area',
  })

  useTitle('All areas')

  return (
    <>
      <PageContainer contained>
        <div className="mb-5 grid grid-cols-2 text-3xl font-light text-slate-900">
          <div>
            All <span className="font-medium">areas</span>
          </div>

          <PermissionGuard permission="create-area" auth={auth}>
            <div className="text-right">
              <div className="hidden justify-items-end md:block">
                <Anchor
                  style={{ minWidth: '200px' }}
                  onClick={() => {
                    setModal({ name: 'area' })
                  }}
                >
                  Add area
                </Anchor>
              </div>
              <div className="md:hidden">
                <RoundedIconButton
                  icon="plus"
                  onClick={() => {
                    setModal({ name: 'area' })
                  }}
                />
              </div>
            </div>
          </PermissionGuard>
        </div>

        <AreasAll/>
      </PageContainer>
    </>
  )
}

export default AreaIndex
