import 'react-phone-number-input/style.css'

import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/react'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useRecoilState, useSetRecoilState } from 'recoil'
import styled from 'styled-components'
import * as yup from 'yup'

import { Anchor, Button } from '@/Components/form/Buttons'
import GooglePlacesAutocomplete from '@/Components/form/GooglePlacesAutocomplete'
import Input from '@/Components/form/Input'
import InputError from '@/Components/form/InputError'
import Modal from '@/Components/Modal'
import { isLoadingState, pageAlertState } from '@/Config/Atoms/General'
import useApiClient from '@/Utilities/useApiClient'
import useTitle from '@/Utilities/useTitle'

const Form = styled.form`
  label {
    margin-top: 20px;
  }

  .buttons {
    margin-top: 40px;
  }
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
`

const schema = yup.object({
  name: yup.string().label('Name').required(),
  address: yup.string().label('Address').required().min(3).max(250),
})

function OrganisationModal(props) {
  const apiClient = useApiClient()
  const setAlert = useSetRecoilState(pageAlertState)
  const [isLoading, setIsLoading] = useRecoilState(isLoadingState)
  const {
    watch,
    setValue,
    register,
    handleSubmit,
    formState,
  } = useForm({ resolver: yupResolver(schema) })
  const {
    errors,
    isSubmitted,
  } = formState

  const address = watch('address')

  useTitle([`${props.data?.organisation ? 'Edit' : 'Add'} organization`, props.data?.organisation.name])

  // Manually register the address field
  useEffect(() => {
    register('address', { value: props.data.organisation.address })
  }, [register, props.data.organisation.address])

  useEffect(() => {
    setValue('name', props.data.organisation.name)
    setValue('address', props.data.organisation.address)
  }, [setValue, props])

  const fieldChange = useCallback((field, value) => {
    setValue(field, value, { shouldValidate: isSubmitted })
  }, [isSubmitted, setValue])

  const onSubmit = async (data) => {
    try {
      setAlert(null)
      setIsLoading(true)

      let { data: responseData } = await apiClient.post('/organisation/profile', { ...data })

      if (responseData.success) {
        setAlert({
          type: 'success',
          content: 'Your organization profile has been successfully updated.',
        })
        props.data.onComplete()
      }
    } catch (error) {
      Sentry.captureException(error)
      setAlert({
        type: 'error',
        content: 'There was a problem with updating your organization profile. Please try again.',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      title={`Edit ${props.data?.organisation.name}`}
    >
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Input
          placeholder="Company name"
          label="Name"
          type="text"
          className={errors.name && 'error'}
          isRequired={true}
          {...register('name')}
        />
        {errors.name && <InputError message={errors.name.message} />}

        <GooglePlacesAutocomplete
          label="Address"
          name="address"
          value={address || ''}
          onChange={fieldChange}
          isRequired={true}
          className={errors.address && 'error'}
        />
        {errors.address && <InputError message={errors.address.message} />}

        <ButtonGroup className="buttons">
          <Anchor
            style={{ width: 'calc(50% - 5px)' }}
            className="transparent"
            onClick={() => {
              setAlert(null)
              props.close()
            }}
          >
            Close
          </Anchor>

          <Button
            style={{ width: 'calc(50% - 5px)' }}
            disabled={isLoading ? true : false}
          >
            {isLoading ? <div className="primary-loader light"></div> : 'Save'}
          </Button>
        </ButtonGroup>
      </Form>
    </Modal>
  )
}

export default OrganisationModal
